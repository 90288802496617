import React from 'react';
import '../styles/KelimeBulmaOyunu.css';

const TimerDisplay = ({ timeRemaining }) => {
  const formatTime = (seconds) => {
    if (seconds < 0) seconds = 0;
    const minutes = Math.floor(seconds / 60)
      .toString()
      .padStart(2, '0');
    const remainingSeconds = (seconds % 60).toString().padStart(2, '0');
    return `${minutes}:${remainingSeconds}`;
  };

  return (
    <div className="timer mb-4 mt-2">
      <span>Kalan Süre: {formatTime(timeRemaining)}</span>
    </div>
  );
};

export default TimerDisplay;
