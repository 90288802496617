import React, { useState } from 'react';
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem, Button,} from '@mui/material';
import { API_URLS } from '../../../../config/config';

const StudentForm = ({ userData, sehirListesi, ogrenciListesi, setOgrenciListesi, setSnackbar }) => {
  const [ad, setAd] = useState('');
  const [soyad, setSoyad] = useState('');
  const [okulAdi, setOkulAdi] = useState('');
  const [telefon, setTelefon] = useState('');
  const [telefonHata, setTelefonHata] = useState(false);
  const [email, setEmail] = useState('');
  const [sifre, setSifre] = useState('');
  const [sinif, setSinif] = useState('');
  const [yas, setYas] = useState('');
  const [secilenSehirId, setSecilenSehirId] = useState('');
  const [secilenIlceId, setSecilenIlceId] = useState('');
  const [selectedPackageDuration, setSelectedPackageDuration] = useState('');

  // Telefon formatı kontrolü
  const handleTelefonChange = (event) => {
    const telefonRegex = /^0(\d{3}) (\d{3}) (\d{4})$/;
    let { value } = event.target;
    const numbers = value.replace(/\D/g, '');
    value =
      numbers.substring(0, 4) +
      (numbers.length > 4 ? ' ' : '') +
      numbers.substring(4, 7) +
      (numbers.length > 7 ? ' ' : '') +
      numbers.substring(7, 11);
    setTelefonHata(!telefonRegex.test(value) && value !== '');
    setTelefon(value);
  };

  // Seçili şehrin ilçeleri
  const secilenSehirinIlceleri =
    sehirListesi.find((sehir) => sehir._id === secilenSehirId)?.ilceler || [];

  // Form gönderim işlemi
  const handleEkle = async () => {
    let eksikAlanlar = [];
    if (!ad) eksikAlanlar.push('Adı');
    if (!soyad) eksikAlanlar.push('Soyadı');
    if (!email) eksikAlanlar.push('Email');
    if (!sifre) eksikAlanlar.push('Şifre');
    if (!telefon) eksikAlanlar.push('Telefon');
    if (!secilenSehirId) eksikAlanlar.push('Şehir');
    if (!secilenIlceId) eksikAlanlar.push('İlçe');
    if (!sinif) eksikAlanlar.push('Sınıf');
    if (!yas) eksikAlanlar.push('Yaş');
    if (!selectedPackageDuration) eksikAlanlar.push('Paket Süresi');

    if (eksikAlanlar.length > 0) {
      setSnackbar({
        open: true,
        message: `Lütfen ${eksikAlanlar.join(', ')} alanlarını doldurunuz.`,
        severity: 'error',
      });
      return;
    }
    if (telefonHata) {
      setSnackbar({
        open: true,
        message: 'Lütfen geçerli bir telefon numarası giriniz.',
        severity: 'error',
      });
      return;
    }

    try {
      const response = await fetch(API_URLS.SCHOOL_URL + 'student', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ad,
          soyad,
          email,
          password: sifre,
          password_open: sifre,
          okul_adi: okulAdi,
          telefon,
          sehir: secilenSehirId,
          ilce: secilenIlceId,
          sinif,
          yas,
          okulId: userData?._id,
          selectedPackageDuration,
        }),
      });
      const responseData = await response.json();
      if (response.ok) {
        setOgrenciListesi([...ogrenciListesi, responseData]);
        setSnackbar({
          open: true,
          message: 'Yeni Öğrenci başarıyla eklendi.',
          severity: 'success',
        });
        // Form alanlarını temizle
        setAd('');
        setSoyad('');
        setOkulAdi('');
        setEmail('');
        setSifre('');
        setTelefon('');
        setSecilenSehirId('');
        setSecilenIlceId('');
        setSinif('');
        setYas('');
        setSelectedPackageDuration('');
      } else {
        throw new Error(responseData.message || 'Bir hata oluştu');
      }
    } catch (err) {
      setSnackbar({ open: true, message: err.message, severity: 'error' });
    }
  };

  return (
    <Grid container spacing={2} marginBottom={4}>
      <Grid item xs={12} md={3}>
        <FormControl fullWidth margin="normal">
          <InputLabel id="package-select-label">Paket Süresi</InputLabel>
          <Select
            labelId="package-select-label"
            value={selectedPackageDuration}
            onChange={(e) => setSelectedPackageDuration(e.target.value)}
          >
            <MenuItem value="">
              <em>Paket Süresi Seçiniz</em>
            </MenuItem>
            <MenuItem value={3}>3 Aylık</MenuItem>
            <MenuItem value={6}>6 Aylık</MenuItem>
            <MenuItem value={12}>12 Aylık</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={3}>
        <TextField fullWidth label="Adı" value={ad} onChange={(e) => setAd(e.target.value)} margin="normal" />
      </Grid>

      <Grid item xs={12} md={3}>
        <TextField fullWidth label="Soyadı" value={soyad} onChange={(e) => setSoyad(e.target.value)} margin="normal" />
      </Grid>

      <Grid item xs={12} md={3}>
        <TextField
          fullWidth
          label="Okul Adı (isteğe bağlı)"
          value={okulAdi}
          onChange={(e) => setOkulAdi(e.target.value)}
          margin="normal"
        />
      </Grid>

      <Grid item xs={12} md={3}>
        <TextField fullWidth label="Sınıf" value={sinif} onChange={(e) => setSinif(e.target.value)} margin="normal" />
      </Grid>

      <Grid item xs={12} md={3}>
        <TextField fullWidth label="Yaş" value={yas} onChange={(e) => setYas(e.target.value)} margin="normal" />
      </Grid>

      <Grid item xs={12} md={3}>
        <FormControl fullWidth margin="normal">
          <InputLabel id="sehir-select-label">Şehir</InputLabel>
          <Select
            labelId="sehir-select-label"
            value={secilenSehirId}
            onChange={(e) => setSecilenSehirId(e.target.value)}
          >
            <MenuItem disabled value="">
              <em>Şehir Seçiniz</em>
            </MenuItem>
            {sehirListesi.map((sehir) => (
              <MenuItem key={sehir._id} value={sehir._id}>
                {sehir.sehir_adi}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      {secilenSehirId && (
        <Grid item xs={12} md={3}>
          <FormControl fullWidth margin="normal">
            <InputLabel id="ilce-select-label">İlçe</InputLabel>
            <Select
              labelId="ilce-select-label"
              value={secilenIlceId}
              onChange={(e) => setSecilenIlceId(e.target.value)}
            >
              <MenuItem disabled value="">
                <em>İlçe Seçiniz</em>
              </MenuItem>
              {secilenSehirinIlceleri.map((ilce) => (
                <MenuItem key={ilce._id} value={ilce._id}>
                  {ilce.ilce_adi}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}

      <Grid item xs={12} md={3}>
        <TextField
          fullWidth
          label="Telefon"
          value={telefon}
          onChange={handleTelefonChange}
          error={telefonHata}
          helperText={telefonHata ? 'Telefon numarası 05XX XXX XXXX formatında olmalıdır.' : ''}
          margin="normal"
        />
      </Grid>

      <Grid item xs={12} md={3}>
        <TextField fullWidth label="Email" value={email} onChange={(e) => setEmail(e.target.value)} margin="normal" />
      </Grid>

      <Grid item xs={12} md={3}>
        <TextField fullWidth label="Şifre" value={sifre} onChange={(e) => setSifre(e.target.value)} margin="normal" type="password" />
      </Grid>

      <Grid item xs={12}>
        <Button onClick={handleEkle} variant="contained" color="primary">
          Ekle
        </Button>
      </Grid>
    </Grid>
  );
};

export default StudentForm;
