import React from 'react';
// İsteğe bağlı: Diğer stiller veya ortak stilleriniz varsa ekleyebilirsiniz
// import '../styles/KelimeBulmaOyunu.css';

const SettingsModalKelimeBulma = ({
  onClose,
  difficulty,
  difficultyLocked,
  paragraphsList,
  selectedParagraphIndex,
  onDifficultyChange,
  onParagraphChange,
  theme,
  setTheme,
  font,
  setFont,
  fontSize,
  setFontSize,
}) => {
  const getWordCount = (htmlContent) => {
    if (!htmlContent) return 0;
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent;
    const textContent = tempDiv.textContent || tempDiv.innerText || '';
    const words = textContent.trim().split(/\s+/);
    return words.length;
  };

  return (
    <div className="settings-modal-backdrop">
      <div className="settings-modal-content">
        {/* Sağ üstteki kapatma butonu */}
        <button
          className="settings-modal-close-button"
          onClick={onClose}
          aria-label="Kapat"
        >
          X
        </button>
        <h2 className="settings-modal-title">Ayarlar</h2>

        {/* Zorluk Seçimi */}
        {difficultyLocked ? (
          <div className="settings-form-group">
            <label className="settings-form-label">Zorluk:</label>
            <strong>
              {difficulty === 'easy'
                ? 'Kolay'
                : difficulty === 'medium'
                ? 'Orta'
                : 'Zor'}
            </strong>
          </div>
        ) : (
          <div className="settings-form-group">
            <label className="settings-form-label" htmlFor="difficultySelect">
              Zorluk Seç:
            </label>
            <select
              id="difficultySelect"
              className="settings-form-select"
              value={difficulty}
              onChange={(e) => onDifficultyChange(e.target.value)}
            >
              <option value="easy">Kolay</option>
              <option value="medium">Orta</option>
              <option value="hard">Zor</option>
            </select>
          </div>
        )}

        {/* Paragraf Seçimi */}
        <div className="settings-form-group">
          <label className="settings-form-label" htmlFor="paragraphSelect">
            Paragraf Seç:
          </label>
          <select
            id="paragraphSelect"
            className="settings-form-select"
            value={selectedParagraphIndex}
            onChange={(e) => onParagraphChange(e.target.value)}
          >
            {paragraphsList.map((paragraph, index) => {
              const wordCount = getWordCount(paragraph.content);
              return (
                <option key={index} value={index}>
                  {paragraph.title} - {wordCount} kelime
                </option>
              );
            })}
          </select>
        </div>

        {/* Tema Seçimi */}
        <div className="settings-form-group">
          <label className="settings-form-label" htmlFor="themeSelect">
            Tema Seç:
          </label>
          <select
            id="themeSelect"
            className="settings-form-select"
            value={theme}
            onChange={(e) => setTheme(e.target.value)}
          >
            <option value="default">Varsayılan (Açık)</option>
            <option value="tomer">Tömer</option>
            <option value="dark">Koyu</option>
            <option value="blue">Mavi</option>
            <option value="green">Yeşil</option>
            <option value="kirmizi">Kırmızı</option>
            <option value="purple">Mor</option>
            <option value="orange">Turuncu</option>
            <option value="pink">Pembe</option>
          </select>
        </div>

        {/* Font Seçimi */}
        <div className="settings-form-group">
          <label className="settings-form-label" htmlFor="fontSelect">
            Yazı Tipi:
          </label>
          <select
            id="fontSelect"
            className="settings-form-select"
            value={font}
            onChange={(e) => setFont(e.target.value)}
          >
            <option value="Poppins">Poppins</option>
            <option value="Orbitron">Orbitron</option>
            <option value="Roboto">Roboto</option>
            <option value="Arial">Arial</option>
            <option value="Courier New">Courier New</option>
          </select>
        </div>

        {/* Yazı Boyutu */}
        <div className="settings-form-group">
          <label className="settings-form-label" htmlFor="fontSizeSelect">
            Yazı Boyutu:
          </label>
          <select
            id="fontSizeSelect"
            className="settings-form-select"
            value={fontSize}
            onChange={(e) => setFontSize(e.target.value)}
          >
            <option value="1rem">Küçük</option>
            <option value="1.5rem">Orta</option>
            <option value="2rem">Büyük</option>
          </select>
        </div>

        {/* Alt "Kapat" butonu */}
        <div style={{ marginTop: '1rem', textAlign: 'center' }}>
          <button
            className="settings-modal-bottom-close-button"
            onClick={onClose}
          >
            Kapat
          </button>
        </div>
      </div>
    </div>
  );
};

export default SettingsModalKelimeBulma;
