import React from 'react';
import '../styles/kayanYazilarUcerStyle.css';

const SettingsModalUcer = ({
  onClose,
  difficulty,
  difficultyLocked,
  paragraphsList,
  selectedParagraphIndex,
  handleDifficultyChange,
  handleParagraphChange,
  theme,
  handleThemeChange,
  font,
  handleFontChange,
  fontSize,
  handleFontSizeChange,
}) => {
  const getWordCount = (htmlContent) => {
    if (!htmlContent) return 0;
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent;
    const textContent = tempDiv.textContent || tempDiv.innerText || '';
    const words = textContent.trim().split(/\s+/);
    return words.length;
  };

  return (
    <div className="settings-modal-backdrop">
      <div className="settings-modal-content">
        <button
          className="settings-modal-close-button"
          onClick={onClose}
          aria-label="Kapat"
        >
          X
        </button>
        <h2 className="settings-modal-title">Ayarlar</h2>

        {/* Zorluk */}
        {difficultyLocked ? (
          <div className="settings-form-group">
            <label className="settings-form-label">Zorluk:</label>
            <strong>
              {difficulty === 'easy'
                ? 'Kolay'
                : difficulty === 'medium'
                ? 'Orta'
                : 'Zor'}
            </strong>
          </div>
        ) : (
          <div className="settings-form-group">
            <label className="settings-form-label" htmlFor="difficultySelect">
              Zorluk Seç:
            </label>
            <select
              id="difficultySelect"
              className="settings-form-select"
              value={difficulty}
              onChange={handleDifficultyChange}
            >
              <option value="easy">Kolay</option>
              <option value="medium">Orta</option>
              <option value="hard">Zor</option>
            </select>
          </div>
        )}

        {/* Paragraf Seç */}
        <div className="settings-form-group">
          <label className="settings-form-label" htmlFor="paragraphSelect">
            Paragraf Seç:
          </label>
          <select
            id="paragraphSelect"
            className="settings-form-select"
            value={selectedParagraphIndex}
            onChange={handleParagraphChange}
          >
            {paragraphsList.map((paragraph, index) => {
              const wordCount = getWordCount(paragraph.content);
              return (
                <option key={index} value={index}>
                  {paragraph.title} - {wordCount} kelime
                </option>
              );
            })}
          </select>
        </div>

        {/* Tema */}
        <div className="settings-form-group">
          <label className="settings-form-label" htmlFor="themeSelect">
            Tema Seç:
          </label>
          <select
            id="themeSelect"
            className="settings-form-select"
            value={theme}
            onChange={handleThemeChange}
          >
            <option value="default">Varsayılan (Açık)</option>
            <option value="tomer">Tömer</option>
            <option value="dark">Koyu</option>
            <option value="blue">Mavi</option>
            <option value="green">Yeşil</option>
            <option value="red">Kırmızı</option>
            <option value="purple">Mor</option>
            <option value="orange">Turuncu</option>
            <option value="pink">Pembe</option>
          </select>
        </div>

        {/* Yazı Tipi */}
        <div className="settings-form-group">
          <label className="settings-form-label" htmlFor="fontSelect">
            Yazı Tipi:
          </label>
          <select
            id="fontSelect"
            className="settings-form-select"
            value={font}
            onChange={handleFontChange}
          >
            <option value="Arial">Arial</option>
            <option value="Poppins">Poppins</option>
            <option value="Orbitron">Orbitron</option>
            <option value="Roboto">Roboto</option>
            <option value="Courier New">Courier New</option>
          </select>
        </div>

        {/* Font Size */}
        <div className="settings-form-group">
          <label className="settings-form-label" htmlFor="fontSizeSelect">
            Yazı Boyutu:
          </label>
          <select
            id="fontSizeSelect"
            className="settings-form-select"
            value={fontSize}
            onChange={handleFontSizeChange}
          >
            <option value="1rem">Küçük</option>
            <option value="1.5rem">Orta</option>
            <option value="2rem">Büyük</option>
          </select>
        </div>

        {/* Alt "Kapat" butonu */}
        <div style={{ marginTop: '1rem', textAlign: 'center' }}>
          <button
            onClick={onClose}
            className="settings-modal-bottom-close-button"
          >
            Kapat
          </button>
        </div>
      </div>
    </div>
  );
};

export default SettingsModalUcer;
