import React, { useRef } from 'react';
import { faPlay, faPause, faRedo, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../styles/rasgeleKelimelerStyle.css';
import ProgressBar from './ProgressBarRandomWords';
import GameTimer from './GameTimerRandomWords';

const GameDisplay = ({
  isPlaying,
  paragraphsList,
  selectedParagraphIndex,
  displayedWordsGroup,
  handlePlayPause,
  handleReset,
  message,
  gameOver,
  fontSize,
  currentWordIndex,
  timeLeft,
  handleSpeedDecrease,
  speedFactor,
  handleSpeedIncrease

}) => {
  const containerRef = useRef(null);

  // HTML içeriğinden düz metin elde edip, noktalama işaretlerini kaldırıp, kelimelere ayıran fonksiyon
  const getCleanWords = (htmlContent) => {
    if (!htmlContent) return [];
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent;
    const textContent = tempDiv.textContent || tempDiv.innerText || '';
    // Noktalama işaretlerini kaldır (Türkçe karakterleri koruyarak)
    const cleanText = textContent.replace(/[^\p{L}\p{N}\s]+/gu, '');
    const words = cleanText.trim().split(/\s+/);
    // Sadece 3 karakter veya daha uzun kelimeleri döndür
    return words.filter(word => word.length >= 3);
  };

  const getWordCount = (htmlContent) => {
    if (!htmlContent) return 0;
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent;
    const textContent = tempDiv.textContent || tempDiv.innerText || '';
    const words = textContent.trim().split(/\s+/);
    return words.filter((w) => w).length;
  };

  const totalWords =
    paragraphsList[selectedParagraphIndex]
      ? getWordCount(paragraphsList[selectedParagraphIndex].content)
      : 1;

  const progress = totalWords > 0 ? (currentWordIndex / totalWords) * 100 : 0;

  return (
    <>  

    <GameTimer timeLeft={timeLeft} isPlaying={isPlaying} />
      <ProgressBar progress={progress} />
      <div className="game-buttons">
  <button
    className="game-button"
    onClick={handlePlayPause}
    disabled={gameOver}
    aria-label={isPlaying ? ' Duraklat' : ' Başlat'}
  >
    <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} /> 
    {isPlaying ? ' Duraklat' : ' Başlat'}
  </button>
  <button
    className="game-button"
    onClick={handleReset}
    aria-label="Yeniden Başlat"
  >
     <FontAwesomeIcon icon={faRedo} /> 
    Yeniden Başlat
  </button>
</div>

<div className="rasgele-kelimeler-controls">
  <div className="speed-control-container">
    <div className="speed-label">Hız Ayarı</div>
    <div className="speed-display">
      <button 
        className="speed-button" 
        onClick={handleSpeedDecrease}
        aria-label="Hızı Azalt"
      >
        -
      </button>
      <div className="speed-value">
        {speedFactor}
      </div>
      <button 
        className="speed-button" 
        onClick={handleSpeedIncrease}
        aria-label="Hızı Arttır"
      >
        +
      </button>
    </div>      
  </div>
</div>

      <div
        className="rasgele-kelimeler-game-display"
        style={{ fontSize, position: 'relative', overflow: 'hidden' }}
        id="game-display-container"
        ref={containerRef}
      >
        {!paragraphsList[selectedParagraphIndex] ? (
          <div className="rasgele-kelimeler-message">
            Paragraf bulunamadı. Lütfen farklı bir paragraf veya zorluk seviyesi seçin.
          </div>
        ) : (
          <div
            className="rasgele-kelimeler-content"
            style={{ width: '100%', height: '100%', position: 'relative' }}
          >
            {displayedWordsGroup && (
              <div
                key={displayedWordsGroup.id}
                style={{
                  position: 'absolute',
                  left: `${displayedWordsGroup.x}px`,
                  top: `${displayedWordsGroup.y}px`,
                  whiteSpace: 'nowrap',
                  color: 'var(--text-color)',
                }}
              >
                {getCleanWords(displayedWordsGroup.text).map((word, index) => (
                  <span key={index} style={{ marginRight: '5px' }}>
                    {word}
                  </span>
                ))}
              </div>
            )}
          </div>
        )}
      </div>

      {message && <div className="rasgele-kelimeler-message">{message}</div>}
    </>
  );
};

export default GameDisplay;
