import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';

function SpeedSliderAndScore({ score }) {
  return (
    <div className="zigzag-score-container">
      <FontAwesomeIcon icon={faTrophy} className="zigzag-score-icon" />
      <div className="zigzag-score-text">
        <span>Skor:</span>
        <strong>{Math.floor(score)}</strong>
      </div>
    </div>
  );
}

export default SpeedSliderAndScore;
