import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faArrowLeft, faCog } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import '../styles/tachistoscopicReadingStyle.css';


const Header = ({ stage, handleReturnToPreviousStage, handleSettingsClick, hideSettings  }) => {
  const navigate = useNavigate();

  const handleBackButton = () => {
    if (stage) {
      handleReturnToPreviousStage();
    } else {
      navigate(-1);
    }
  };

  const backButtonLabel = stage ? 'Önceki Aşamaya Dön' : 'Geri Dön';

  return (
    <div className="header-buttons">
      <button
        className="header-button"
        onClick={handleBackButton}
        aria-label={backButtonLabel}
      >
        <FontAwesomeIcon icon={faArrowLeft} /> {backButtonLabel}
      </button>
      <button
        className="header-button"
        onClick={() => navigate('/')}
        aria-label="Ana Sayfa"
      >
        <FontAwesomeIcon icon={faHome} /> Ana Sayfa
      </button>
      {!hideSettings && (
              <button
                className="header-button"
                onClick={handleSettingsClick}
                aria-label="Ayarlar"
              >
                <FontAwesomeIcon icon={faCog} /> Ayarlar
              </button>
            )}
    </div>
  );
};

export default Header;
