import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
import '../styles/cumleleriOkuStyle.css';

const GameOverModal = ({
  displayedWords = [],
  onRestart,
}) => {
  const navigate = useNavigate();

  return (
    <div
      className="cumleleri-oku-modal-backdrop"
      onClick={onRestart}
      aria-modal="true"
      role="dialog"
    >
      <div
        className="cumleleri-oku-modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          className="cumleleri-oku-close-button"
          onClick={onRestart}
          aria-label="Close Modal"
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>

        <h2 className="modal-title">Çalışma Bitti!</h2>
        <p>Gösterilen Cümle Sayısı: {displayedWords.length}</p>

        <div className="cumleleri-oku-button-group">
          <button className="cumleleri-oku-button" onClick={onRestart}>
            Bitti
          </button>
          <button
            className="cumleleri-oku-button"
            onClick={() => navigate(-1)}
            aria-label="Geri Dön"
          >
            <FontAwesomeIcon icon={faArrowLeft} /> Geri Dön
          </button>
        </div>
      </div>
    </div>
  );
};

export default GameOverModal;
