import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Paper, Grid, Typography, Snackbar, Alert,} from '@mui/material';
import { API_URLS } from '../../../config/config';
import useFetchUserData from '../../../hooks/okul/useFetchUserData';
import StudentForm from './components/StudentForm';
import StudentTable from './components/StudentTable';
import StudentDialogs from './components/StudentDialogs';
import StudentAtamaDialog from './components/StudentAtamaDialog';

const Ogrenci = () => {
  const [ogrenciListesi, setOgrenciListesi] = useState([]);
  const [sehirListesi, setSehirListesi] = useState([]);
  const [shouldFetch, setShouldFetch] = useState(true);
  const [userData, setUserData] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  
  // ATAMA için state’ler
  const [okulListesi, setOkulListesi] = useState([]);
  const [kurumListesi, setKurumListesi] = useState([]);
  const [ogretmenListesi, setOgretmenListesi] = useState([]);
  const [atamaDialogOpen, setAtamaDialogOpen] = useState(false);
  const [selectedStudentId, setSelectedStudentId] = useState(null);

  const navigate = useNavigate();

  // Kullanıcı (öğretmen) verisini çek
  useFetchUserData(setUserData);

  // Şehir listesini çek
  useEffect(() => {
    const fetchSehirler = async () => {
      if (!shouldFetch) return;
      try {
        const response = await fetch(API_URLS.ADMIN_URL + 'sehirler');
        if (!response.ok) throw new Error('Şehirler yüklenirken hata oluştu');
        const sehirler = await response.json();
        setSehirListesi(sehirler);
      } catch (err) {
        console.error(err.message);
      } finally {
        setShouldFetch(false);
      }
    };
    fetchSehirler();
  }, [shouldFetch]);

  // Okul modülü için öğrenci listesini çek
  const fetchOgrenciListesi = async () => {
    if (!userData?._id) return;
    try {
      const response = await fetch(`${API_URLS.SCHOOL_URL}student/${userData._id}`);
      if (!response.ok) throw new Error('Öğrenci listesi yüklenirken hata oluştu');
      const data = await response.json();
      // Gelen veriye şehir bilgilerini ekleyelim:
      const enhancedData = data.map(ogrenci => {
        const sehir = sehirListesi.find(s => s._id === ogrenci.sehir);
        const ilce = sehir?.ilceler.find(i => i._id === ogrenci.ilce);
        return {
          ...ogrenci,
          sehirAdi: sehir ? sehir.sehir_adi : 'Bulunamadı',
          ilceAdi: ilce ? ilce.ilce_adi : 'Bulunamadı',
          sinif: ogrenci.sinif?.toString() || '',
          yas: ogrenci.yas?.toString() || '',
        };
      });
      setOgrenciListesi(enhancedData);
    } catch (error) {
      console.error(error);
    }
  };

  // Kullanıcı ve sehirListesi mevcutsa öğrenci listesini çek
  useEffect(() => {
    if (userData?._id && sehirListesi.length > 0) {
      fetchOgrenciListesi();
    }
  }, [userData, sehirListesi]);

  // *** ATAMA MODÜLÜ İÇİN: Okul, Kurum ve Öğretmen listelerini çek ***
  useEffect(() => {
    if (!userData?._id) return;
    const token = localStorage.getItem('userToken'); 

    // Okul Listesi
    fetch(`${API_URLS.SCHOOL_URL}schools/${userData._id}`)
      .then((res) => {
        if (!res.ok) throw new Error("Okullar yüklenirken bir hata oluştu.");
        return res.json();
      })
      .then((data) => setOkulListesi(data))
      .catch((err) => console.error("Okulları yükleme hatası:", err));

    // Kurum Listesi (varsa)
    fetch(API_URLS.SCHOOL_URL + 'kurumsal')
      .then((res) => res.json())
      .then((data) => setKurumListesi(data))
      .catch((err) => console.error("Kurumsal liste yükleme hatası:", err));

    // Öğretmen Listesi
    fetch(`${API_URLS.SCHOOL_URL}teachers/${userData._id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (!res.ok) throw new Error("Öğretmen listesi yüklenirken bir hata oluştu.");
        return res.json();
      })
      .then((data) => setOgretmenListesi(data))
      .catch((err) => console.error("Öğretmen liste yükleme hatası:", err));
  }, [userData]);

  // *** ATAMA İŞLEVİ: Öğrenci atama diyaloğunu açmak ***
  const handleOpenAssignDialog = (ogrenci) => {
    setSelectedStudentId(ogrenci._id);
    setAtamaDialogOpen(true);
  };

  // *** ATAMA İŞLEVİ: Öğrenci atama API çağrısı (SCHOOL_URL kullanılıyor) ***
  const handleAssign = async (ogrenciId, { okulId, kurumId, ogretmenId }) => {
    try {
      const body = {};
      if (okulId) body.okulId = okulId;
      if (kurumId) body.kurumId = kurumId;
      if (ogretmenId && ogretmenId.length > 0) body.ogretmenId = ogretmenId;
      const token = localStorage.getItem('userToken');
      const response = await fetch(`${API_URLS.SCHOOL_URL}student/${ogrenciId}/assign`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
      if (!response.ok) {
        throw new Error('Öğrenci atama işlemi başarısız.');
      }
      const data = await response.json();
      alert('Öğrenci ataması başarılı: ' + data.message);
      setAtamaDialogOpen(false);
      fetchOgrenciListesi();
    } catch (error) {
      console.error('Öğrenci atama hatası:', error);
      alert('Öğrenci atama hatası: ' + error.message);
    }
  };

  // Snackbar'ı kapatma işlemi
  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Paper style={{ padding: 20 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h3" marginBottom={2}>
            Öğrenci Kayıt Formu
          </Typography>
        </Grid>

        {/* Öğrenci Ekleme Formu */}
        <Grid item xs={12}>
          <StudentForm
            userData={userData}
            sehirListesi={sehirListesi}
            ogrenciListesi={ogrenciListesi}
            setOgrenciListesi={setOgrenciListesi}
            setSnackbar={setSnackbar}
          />
        </Grid>

        {/* Öğrenci Listesi */}
        <Grid item xs={12}>
          <StudentTable
            ogrenciListesi={ogrenciListesi}
            sehirListesi={sehirListesi}
            fetchOgrenciListesi={fetchOgrenciListesi}
            navigate={navigate}
            setSnackbar={setSnackbar}
            onAssignStudent={handleOpenAssignDialog}  // Atama butonunu aktifleştirmek için
          />
        </Grid>
      </Grid>

      {/* Silme ve Güncelleme Diğer Diyaloglar */}
      <StudentDialogs
        fetchOgrenciListesi={fetchOgrenciListesi}
        setSnackbar={setSnackbar}
      />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      {/* ATAMA DİYALOĞU */}
      <StudentAtamaDialog
        open={atamaDialogOpen}
        onClose={() => setAtamaDialogOpen(false)}
        ogrenciId={selectedStudentId}
        okulListesi={okulListesi}
        kurumListesi={kurumListesi}
        ogretmenListesi={ogretmenListesi}
        handleAssign={handleAssign}
      />
    </Paper>
  );
};

export default Ogrenci;
