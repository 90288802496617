// TeacherAtamaDialog.js
import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, FormControl, InputLabel, Select, MenuItem, Typography,} from '@mui/material';
import { API_URLS } from '../../../../config/config';
import useFetchUserData from '../../../../hooks/kurum/useFetchUserData';

const TeacherAtamaDialog = ({ open, onClose, teacher }) => {
  const [schoolList, setSchoolList] = useState([]);
  const [selectedSchoolId, setSelectedSchoolId] = useState('');
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);

  useFetchUserData(setUserData);

  useEffect(() => {
    if (open) {
      fetchSchools();
    }
  }, [open]);

  const fetchSchools = async () => {
  
       if (!userData || !userData._id) {
            console.log('Kullanıcı bilgisi yüklenemedi veya kullanıcı ID\'si bulunamadı.');
            return;
          }
    
          try {
            // GET /schools/:kurumId
            const response = await fetch(`${API_URLS.KURUMSAL_URL}schools/${userData._id}`);
            if (!response.ok) {
              throw new Error('Okullar yüklenirken bir hata oluştu.');
            }
            const data = await response.json();
            setSchoolList(data);
          } catch (error) {
            console.error('Okulları yükleme hatası:', error);
           
           
          }
  };

  const handleAssign = async () => {
    if (!teacher) return;
    if (!selectedSchoolId) {
      alert('Lütfen bir okul seçin!');
      return;
    }
    setLoading(true);

    try {
      const bodyData = { okulId: selectedSchoolId };
      const response = await fetch(`${API_URLS.KURUMSAL_URL}teachers/${teacher._id}/assign`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyData),
      });

      const result = await response.json();
      if (!response.ok) {
        alert(result.message || 'Okula atama işlemi başarısız.');
      } else {
        alert('Öğretmen okula atandı!');
        onClose();
      }
    } catch (error) {
      console.error(error);
      alert('Bir hata oluştu.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Öğretmeni Okula Ata</DialogTitle>
      <DialogContent>
        {teacher && (
          <Typography variant="subtitle1" gutterBottom>
            <strong>Öğretmen:</strong> {teacher.ad} {teacher.soyad}
          </Typography>
        )}
        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel>Okul Seç</InputLabel>
          <Select
            label="Okul Seç"
            value={selectedSchoolId}
            onChange={(e) => setSelectedSchoolId(e.target.value)}
          >
            <MenuItem value="">
              <em>Seçiniz</em>
            </MenuItem>
            {schoolList.map((school) => (
              <MenuItem key={school._id} value={school._id}>
                {school.okul_adi}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>
          İptal
        </Button>
        <Button onClick={handleAssign} variant="contained" color="primary" disabled={loading}>
          Kaydet
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TeacherAtamaDialog;
