import React from 'react';
import '../styles/cumleleriOkuStyle.css';

const CumleGameArea = ({ displayedSentences }) => {
  return (
    <div className="cumleleri-oku-game-area">
      {displayedSentences.map((sentence, index) => (
        <div
          key={index}
          className={`cumleleri-oku-item ${
            index === displayedSentences.length - 1 ? 'highlight' : ''
          }`}
        >
          {sentence}
        </div>
      ))}
    </div>
  );
};

export default CumleGameArea;
