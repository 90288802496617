import React from 'react';
import { Box, Typography, Card, CardContent } from '@mui/material';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';

/** Dakika-saniye formatı */
const formatTime = (totalSeconds) => {
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  return `${minutes} dakika ${seconds} saniye`;
};

/** 
 * Anlama oranını hesaplayan yardımcı fonksiyon (%100'ü aşamaz). 
 */
const getComprehensionRate = (stage, initialResult) => {
  let comprehensionRate = 0;

  if (stage.testResult && stage.testResult.totalQuestions > 0) {
    // Soru tabanlı
    comprehensionRate =
      (stage.testResult.correctAnswers / stage.testResult.totalQuestions) * 100;
  } else if (
    stage.testResult &&
    initialResult &&
    initialResult.readingSpeed > 0 &&
    stage.testResult.readingSpeed > 0
  ) {
    // Okuma hızı tabanlı
    comprehensionRate =
      (stage.testResult.readingSpeed / initialResult.readingSpeed) * 100;
  } else {
    comprehensionRate = 0;
  }

  return Math.min(parseFloat(comprehensionRate.toFixed(2)), 100);
};

/** 
 * Anlama oranı datasını hazırlar (LineChart için).
 */
const prepareComprehensionData = (stages, initialTestResult) => {
  const data = [];

  // Başlangıç testi => 0
  if (initialTestResult) {
    data.push({
      name: 'Başlangıç Testi',
      comprehensionRate: 0,
    });
  }

  stages.forEach((stage) => {
    const comprehensionRate = getComprehensionRate(stage, initialTestResult);
    data.push({
      name: `Aşama ${stage.stageNumber}`,
      comprehensionRate,
    });
  });

  return data;
};

function ComprehensionRateSection({ stages, initialTestResult }) {
  const comprehensionData = prepareComprehensionData(stages, initialTestResult);

  return (
    <Box
      sx={{
        padding: { xs: 2, md: 4 },
        backgroundColor: '#fff3e0',
        color: '#000',
        borderRadius: 3,
        marginBottom: 4,
      }}
    >
      <Typography
        variant="h4"
        gutterBottom
        align="center"
        sx={{
          marginBottom: 4,
          fontWeight: 'bold',
        }}
      >
        Anlama Oranı
      </Typography>

      {/* Anlama Oranı Grafiği */}
      <Card
        sx={{
          boxShadow: 6,
          borderRadius: 3,
          backgroundImage: 'linear-gradient(to right, #f3e5f5, #e1bee7)',
          color: '#000',
          marginBottom: 4,
        }}
      >
        <CardContent>
          <Typography
            variant="h6"
            gutterBottom
            sx={{ fontWeight: 'bold', color: '#000' }}
          >
            Anlama Oranı Grafiği
          </Typography>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart
              data={comprehensionData}
              margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
            >
              <CartesianGrid strokeDasharray="3 3" stroke="#000" />
              <XAxis dataKey="name" stroke="#000" />
              <YAxis stroke="#000" domain={[0, 100]} />
              <Tooltip
                formatter={(value) => [`${value}%`, 'Anlama Oranı']}
                contentStyle={{
                  backgroundColor: '#f3e5f5',
                  color: '#000',
                  borderRadius: '10px',
                }}
              />
              <Legend wrapperStyle={{ color: '#000' }} />
              <Line
                type="monotone"
                dataKey="comprehensionRate"
                name="Anlama Oranı (%)"
                stroke="#8e24aa"
                strokeWidth={3}
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </CardContent>
      </Card>

      {/* Başlangıç Test Sonucu */}
      {initialTestResult ? (
        <Box sx={{ marginBottom: 4 }}>
          <Typography variant="h6" gutterBottom>
            Başlangıç Test Sonucu:
          </Typography>
          <Typography variant="body1">
            Okuduğunuz Kelime Sayısı: {initialTestResult.wordCount}
          </Typography>
          <Typography variant="body1">
            Toplam Süre: {formatTime(initialTestResult.totalTime)}
          </Typography>
          <Typography variant="body1">
            Okuma Hızınız: {initialTestResult.readingSpeed} kelime/dakika
          </Typography>
        </Box>
      ) : (
        <Typography variant="body1">Başlangıç test sonucu bulunamadı.</Typography>
      )}

      {/* Aşama Test Sonuçları */}
      {stages.map((stage, index) => {
        const comprehensionRate = getComprehensionRate(stage, initialTestResult);

        return (
          <Box key={index} sx={{ marginBottom: 4 }}>
            <Typography variant="h6" gutterBottom>
              Aşama {stage.stageNumber} Test Sonucu:
            </Typography>
            {stage.testResult ? (
              <>
                <Typography variant="body1">
                  Okuduğunuz Kelime Sayısı: {stage.testResult.wordCount}
                </Typography>
                <Typography variant="body1">
                  Toplam Süre: {formatTime(stage.testResult.totalTime)}
                </Typography>
                <Typography variant="body1">
                  Okuma Hızınız: {stage.testResult.readingSpeed} kelime/dakika
                </Typography>

                {/* Yeni Eklenen Alanlar */}
                {typeof stage.testResult.totalQuestions === 'number' && (
                  <Typography variant="body1">
                    Toplam Soru Sayısı: {stage.testResult.totalQuestions}
                  </Typography>
                )}
                {typeof stage.testResult.correctAnswers === 'number' && (
                  <Typography variant="body1">
                    Doğru Cevap Sayısı: {stage.testResult.correctAnswers}
                  </Typography>
                )}
                {typeof stage.testResult.incorrectAnswers === 'number' && (
                  <Typography variant="body1">
                    Yanlış Cevap Sayısı: {stage.testResult.incorrectAnswers}
                  </Typography>
                )}

                <Typography variant="body1">
                  Anlama Oranı: {comprehensionRate}%
                </Typography>
              </>
            ) : (
              <Typography variant="body1">Test sonucu bulunamadı.</Typography>
            )}
          </Box>
        );
      })}
    </Box>
  );
}

export default ComprehensionRateSection;
