import React, { useEffect, useState } from 'react';
import {
  Typography,
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  IconButton,
  Tooltip,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Divider,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
} from '@mui/icons-material';
import axios from 'axios';
import { API_URLS } from '../../../../config/config';

function EgiticiListesi() {
  const [egiticiler, setEgiticiler] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedEgitici, setSelectedEgitici] = useState(null);

  // Detayları görüntüleme için state
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [selectedViewEgitici, setSelectedViewEgitici] = useState(null);

  // Yetkilendirme Token'ını alıyoruz.
  const token = localStorage.getItem('userToken');

  useEffect(() => {
    const fetchEgiticiler = async () => {
      try {
        const response = await axios.get(`${API_URLS.EGITICI_BASVURU_URL}/basvuru`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setEgiticiler(response.data.data);
        setLoading(false);
      } catch (err) {
        console.error('Eğitici verileri alınırken bir hata oluştu:', err);
        setError('Eğitici verileri alınırken bir hata oluştu.');
        setLoading(false);
      }
    };

    fetchEgiticiler();
  }, [token]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = (egitici) => {
    setSelectedEgitici(egitici);
    setDeleteDialogOpen(true);
  };

  const confirmDelete = async () => {
    try {
      await axios.delete(`${API_URLS.EGITICI_URL}/${selectedEgitici._id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setEgiticiler((prev) =>
        prev.filter((item) => item._id !== selectedEgitici._id)
      );
      setDeleteDialogOpen(false);
      setSelectedEgitici(null);
    } catch (err) {
      console.error('Eğitici silinirken bir hata oluştu:', err);
      setError('Eğitici silinirken bir hata oluştu.');
      setDeleteDialogOpen(false);
      setSelectedEgitici(null);
    }
  };

  const cancelDelete = () => {
    setDeleteDialogOpen(false);
    setSelectedEgitici(null);
  };

  // Detayları görüntüleme işlemi için handler
  const handleView = (egitici) => {
    setSelectedViewEgitici(egitici);
    setViewDialogOpen(true);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" mt={4}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" mt={4}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Eğitici Kayıtları
      </Typography>
      {egiticiler.length === 0 ? (
        <Typography>Kayıt bulunamadı.</Typography>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table aria-label="eğitici tablosu">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Ad Soyad</TableCell>
                  <TableCell>Diploma/Derece</TableCell>
                  <TableCell>Üniversite Adı</TableCell>
                  <TableCell>Bölüm</TableCell>
                  <TableCell>Şehir</TableCell>
                  <TableCell>İlçe</TableCell>
                  <TableCell>Telefon</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Tarih</TableCell>
                  <TableCell align="center">İşlemler</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {egiticiler
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((egitici) => (
                    <TableRow key={egitici._id} hover>
                      <TableCell>{egitici._id}</TableCell>
                      <TableCell>{egitici.fullName}</TableCell>
                      <TableCell>{egitici.graduation}</TableCell>
                      <TableCell>{egitici.universityName}</TableCell>
                      <TableCell>{egitici.branch}</TableCell>
                      <TableCell>{egitici.city}</TableCell>
                      <TableCell>{egitici.district}</TableCell>
                      <TableCell>{egitici.phoneNumber}</TableCell>
                      <TableCell>{egitici.email}</TableCell>
                      <TableCell>
                        {new Date(egitici.createdAt).toLocaleDateString()}
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip title="Detayları Görüntüle">
                          <IconButton
                            color="primary"
                            onClick={() => handleView(egitici)}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Düzenle">
                          <IconButton
                            color="secondary"
                            onClick={() =>
                              alert('Düzenleme işlemi henüz eklenmedi.')
                            }
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Sil">
                          <IconButton
                            color="error"
                            onClick={() => handleDelete(egitici)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={egiticiler.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />

          {/* Silme Onayı Dialog'u */}
          <Dialog open={deleteDialogOpen} onClose={cancelDelete}>
            <DialogTitle>Eğitici Kaydını Sil</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {`"${selectedEgitici?.fullName}" adlı eğitici kaydını silmek istediğinize emin misiniz? Bu işlem geri alınamaz.`}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={cancelDelete} color="primary">
                İptal
              </Button>
              <Button onClick={confirmDelete} color="error">
                Sil
              </Button>
            </DialogActions>
          </Dialog>

          {/* Detayları Görüntüleme Dialog'u */}
          <Dialog
            open={viewDialogOpen}
            onClose={() => setViewDialogOpen(false)}
            fullWidth
            maxWidth="sm"
            PaperProps={{
              sx: {
                borderRadius: 3,
                padding: 2,
                background: 'linear-gradient(135deg, #f5f7fa, #c3cfe2)',
                boxShadow: '0px 4px 12px rgba(0,0,0,0.2)',
              },
            }}
          >
            <DialogTitle
              sx={{
                backgroundColor: '#3f51b5',
                color: '#fff',
                fontWeight: 'bold',
                borderRadius: 1,
                p: 2,
              }}
            >
              Eğitici Detayları
            </DialogTitle>
            <DialogContent dividers sx={{ backgroundColor: '#fff', p: 3 }}>
              {selectedViewEgitici && (
                <Box sx={{ p: 2 }}>
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    <strong>ID:</strong> {selectedViewEgitici._id}
                  </Typography>
                  <Divider sx={{ mb: 1 }} />
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    <strong>Ad Soyad:</strong> {selectedViewEgitici.fullName}
                  </Typography>
                  <Divider sx={{ mb: 1 }} />
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    <strong>Diploma/Derece:</strong> {selectedViewEgitici.graduation}
                  </Typography>
                  <Divider sx={{ mb: 1 }} />
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    <strong>Üniversite Adı:</strong> {selectedViewEgitici.universityName}
                  </Typography>
                  <Divider sx={{ mb: 1 }} />
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    <strong>Bölüm:</strong> {selectedViewEgitici.branch}
                  </Typography>
                  <Divider sx={{ mb: 1 }} />
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    <strong>Şehir:</strong> {selectedViewEgitici.city}
                  </Typography>
                  <Divider sx={{ mb: 1 }} />
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    <strong>İlçe:</strong> {selectedViewEgitici.district}
                  </Typography>
                  <Divider sx={{ mb: 1 }} />
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    <strong>Telefon:</strong> {selectedViewEgitici.phoneNumber}
                  </Typography>
                  <Divider sx={{ mb: 1 }} />
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    <strong>Email:</strong> {selectedViewEgitici.email}
                  </Typography>
                  <Divider sx={{ mb: 1 }} />
                  <Typography variant="body1">
                    <strong>Tarih:</strong>{' '}
                    {new Date(selectedViewEgitici.createdAt).toLocaleDateString()}
                  </Typography>
                </Box>
              )}
            </DialogContent>
            <DialogActions sx={{ p: 2, backgroundColor: '#f5f7fa' }}>
              <Button onClick={() => setViewDialogOpen(false)} color="primary">
                Kapat
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Box>
  );
}

export default EgiticiListesi;
