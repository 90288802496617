// src/components/KayanYazilarIkiserOyunu/hooks/useKayanYazilarIkiserOyunu.js

import { useState, useEffect, useCallback } from 'react';
import { API_URLS } from '../../../../config/config';
import axiosInstance from '../../../../utils/axiosInstance';

/**
 * Kayan Yazılar (İkişer Kelime) Oyunu için Hook
 */
export const useKayanYazilarIkiserOyunu = (navigate, userData, token, stage) => {
  // 1) Aşama Bilgisine Göre Zorluk
  const stageNumber = stage?.number || null;
  let initialDifficulty = 'easy';
  let difficultyLocked = false;

  if (stageNumber) {
    difficultyLocked = true;
    if (stageNumber >= 1 && stageNumber <= 7) {
      initialDifficulty = 'easy';
    } else if (stageNumber >= 8 && stageNumber <= 14) {
      initialDifficulty = 'medium';
    } else {
      initialDifficulty = 'hard';
    }
  }

  // 2) State’ler
  const [difficulty, setDifficulty] = useState(initialDifficulty);
  const [paragraphsList, setParagraphsList] = useState([]);
  const [selectedParagraphIndex, setSelectedParagraphIndex] = useState(0);

  // Kelime çiftleri
  const [displayedWords, setDisplayedWords] = useState([]);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  // 3) Hız Ayarı: speedFactor
  const [speedFactor, setSpeedFactor] = useState(1);
  const baseInterval = 1000;      // 1 saniye
  const [intervalDuration, setIntervalDuration] = useState(baseInterval);

  // 4) Zaman
  const [elapsedTime, setElapsedTime] = useState(0); // saniye
  const [gameTime, setGameTime] = useState(0);       // saniye
  const [timeLeft, setTimeLeft] = useState(0);       // saniye
  const [message, setMessage] = useState('');
  const [gameOver, setGameOver] = useState(false);
  const [allWords, setAllWords] = useState([]);

  // 5) speedFactor değiştikçe interval’i hesapla
  useEffect(() => {
    const newInterval = baseInterval / speedFactor; 
    // 20 ms altına inmesin (aşırı hızdan kaçınmak için).
    const limitedInterval = Math.max(newInterval, 20); 
    setIntervalDuration(limitedInterval);
  }, [speedFactor]);

  // 6) Paragrafları API’den çek
  const prepareWords = (content) => {
    const words = content.trim().split(/\s+/);
    setAllWords(words);
  };

  useEffect(() => {
    const fetchParagraphs = async () => {
      try {
        const url = `${API_URLS.HIZLIOKUMA_URL}akanyazi/${difficulty}`;
        const response = await axiosInstance.get(url);
        const data = response.data;

        setParagraphsList(data);
        setSelectedParagraphIndex(0);

        if (data[0]) {
          prepareWords(data[0].content);
        }
        handleReset();
      } catch (error) {
        console.error('Paragraflar alınırken hata:', error);
      }
    };
    fetchParagraphs();
    // eslint-disable-next-line
  }, [difficulty, token]);

  useEffect(() => {
    if (paragraphsList[selectedParagraphIndex]) {
      prepareWords(paragraphsList[selectedParagraphIndex].content);
      handleReset();
    }
    // eslint-disable-next-line
  }, [selectedParagraphIndex, paragraphsList]);

  // 7) allWords veya intervalDuration => timeLeft hesapla
  useEffect(() => {
    if (paragraphsList[selectedParagraphIndex]) {
      const wordCount = allWords.length;
      const totalWordPairs = Math.ceil(wordCount / 2);
      // Her step = intervalDuration / 1000 sn
      const stepSec = intervalDuration / 1000; 
      const estimatedTimeSec = totalWordPairs * stepSec;

      // Inactive user -> 15 sn kısıtlaması
      const maxTime = userData
        ? userData.status === 'inactive'
          ? 15
          : estimatedTimeSec
        : 15;

      setGameTime(maxTime);
      setTimeLeft(maxTime);
    }
  }, [paragraphsList, selectedParagraphIndex, intervalDuration, userData, allWords]);

  // 8) Kelimeleri ikişer ikişer güncelle
  const updateWords = useCallback(() => {
    if (!isPlaying || !paragraphsList[selectedParagraphIndex]) return;

    if (currentWordIndex >= allWords.length) {
      setIsPlaying(false);
      setGameOver(true);
      return;
    }

    // 2 kelime ekle
    const nextWords = allWords.slice(currentWordIndex, currentWordIndex + 2);
    setDisplayedWords((prev) => [...prev, nextWords.join(' ')]);
    setCurrentWordIndex((prev) => prev + 2);

    const stepSec = intervalDuration / 1000;
    setElapsedTime((prev) => prev + stepSec);
    setTimeLeft((prev) => {
      const newTL = prev - stepSec;
      if (newTL <= 0) {
        setIsPlaying(false);
        setGameOver(true);
        return 0;
      }
      return newTL;
    });
  }, [
    isPlaying,
    paragraphsList,
    selectedParagraphIndex,
    currentWordIndex,
    allWords,
    intervalDuration,
  ]);

  useEffect(() => {
    let wordInterval;
    if (isPlaying) {
      wordInterval = setInterval(() => {
        updateWords();
      }, intervalDuration);
    }
    return () => clearInterval(wordInterval);
  }, [isPlaying, intervalDuration, updateWords]);

  // 9) Kontroller
  const handlePlayPause = () => {
    if (gameOver) return;
    if (isPlaying) {
      setMessage(`Okunan kelime sayısı: ${currentWordIndex}`);
    } else {
      setMessage('');
    }
    setIsPlaying(!isPlaying);
  };

  const handleReset = () => {
    setIsPlaying(false);
    setDisplayedWords([]);
    setCurrentWordIndex(0);
    setElapsedTime(0);
    setMessage('');
    setGameOver(false);

    if (paragraphsList[selectedParagraphIndex]) {
      const wordCount = allWords.length;
      const totalWordPairs = Math.ceil(wordCount / 2);
      const stepSec = intervalDuration / 1000;
      const estimatedTime = totalWordPairs * stepSec;

      const maxTime = userData
        ? userData.status === 'inactive'
          ? 15
          : estimatedTime
        : 15;

      setGameTime(maxTime);
      setTimeLeft(maxTime);
    }
  };

  // 10) Hız ayarını kaldırdık, 3’ten yukarısı da mümkün
  const handleSpeedIncrease = () => {
    setSpeedFactor((prev) => parseFloat((prev + 0.1).toFixed(1)));
  };

  const handleSpeedDecrease = () => {
    setSpeedFactor((prev) => {
      const nextValue = prev - 0.1;
      return nextValue < 0.1 ? 0.1 : parseFloat(nextValue.toFixed(1));
    });
  };

  // 11) Zorluk & Paragraf
  const handleParagraphChange = (e) => {
    setSelectedParagraphIndex(Number(e.target.value));
  };

  const handleDifficultyChange = (e) => {
    if (!difficultyLocked) {
      setDifficulty(e.target.value);
    }
  };

  return {
    difficulty,
    paragraphsList,
    selectedParagraphIndex,
    displayedWords,
    currentWordIndex,
    isPlaying,
    speedFactor,
    handleSpeedIncrease,
    handleSpeedDecrease,
    elapsedTime,
    gameTime,
    timeLeft,
    message,
    gameOver,
    setGameOver,
    allWords,
    handlePlayPause,
    handleReset,
    handleParagraphChange,
    handleDifficultyChange,
    updateWords,
    difficultyLocked,
  };
};
