import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { useKayanYazilarIkiserOyunu } from './hooks/useKayanYazilarIkiserOyunu';
import HeaderSection from './components/GameHeader';
import GameDisplay from './components/GameDisplay';
import GameOverModal from './components/GameOverModal';
import GameTimerIkiser from './components/GameTimer';
import SettingsModalIkiser from './components/SettingsModalIkiser'; 
import { API_URLS } from '../../../config/config';
import axiosInstance from '../../../utils/axiosInstance';
import './styles/kayanYazilarIkiserStyle.css';

const KayanYazilarIkiserOyunu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { stage, exercise } = location.state || {};
  const token = localStorage.getItem('userToken');

  // Kullanıcı Bilgileri
  const [userData, setUserData] = useState(null);
  const [userRole, setUserRole] = useState(null);

  // Intro - bitiş modalları
  const [showInstructions, setShowInstructions] = useState(true);
  const [showGameOver, setShowGameOver] = useState(false);

  // Tema - yazı tipleri
  const [theme, setTheme] = useState('default');
  const [font, setFont] = useState('Arial');
  const [fontSize, setFontSize] = useState('1rem');

  // -- AYARLAR MODALI --
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const toggleSettingsModal = () => {
    setShowSettingsModal(!showSettingsModal);
  };

  // useKayanYazilarIkiserOyunu Hook
  const {
    difficulty,
    paragraphsList,
    selectedParagraphIndex,
    displayedWords,
    isPlaying,
    speedFactor,
    handleSpeedIncrease,
    handleSpeedDecrease,
    elapsedTime,
    timeLeft,
    message,
    gameOver,
    setGameOver,
    updateWords,
    handlePlayPause,
    handleReset,
    handleParagraphChange,
    handleDifficultyChange,
    gameTime,
    difficultyLocked,
  } = useKayanYazilarIkiserOyunu(navigate, userData, token, stage);

  // Kullanıcı verisini çek
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const studentResponse = await axiosInstance.get(`${API_URLS.STUDENT_URL}/me`);
        if (
          studentResponse.status === 200 &&
          studentResponse.data &&
          studentResponse.data.status === 'active'
        ) {
          setUserData(studentResponse.data);
          setUserRole('student');
          return;
        }
      } catch (error) {
        console.error('Öğrenci verisi alınırken hata:', error);
      }

      try {
        const teacherResponse = await fetch(`${API_URLS.TEACHER_URL}/me`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (teacherResponse.ok) {
          const teacherData = await teacherResponse.json();
          setUserData(teacherData);
          setUserRole('teacher');
          return;
        }
      } catch (error) {
        console.error('Öğretmen verisi alınırken hata:', error);
      }

      // Hiçbiri değilse
      setUserData(null);
      setUserRole(null);
    };
    fetchUserData();
  }, [token]);

  // Intro ekranı -> Oyunu başlat
  const startGame = () => {
    handleReset();
    setShowInstructions(false);
  };

  // Game Over -> modal aç
  useEffect(() => {
    if (gameOver && !showGameOver) {
      setShowGameOver(true);
    }
  }, [gameOver, showGameOver]);

  // Oyun bitti -> Progress Güncelle
  useEffect(() => {
    if (gameOver) {
      const updateProgress = async () => {
        try {
          if (userData && userRole) {
            const payload = {
              stageNumber: stage?.number || 1,
              gameName: exercise?.gameName || 'Kayan Yazılar 2',
              score: displayedWords.length * 2,
              time: Math.floor(elapsedTime),
              wordCount: displayedWords.length * 2,
              completed: true,
            };

            if (userRole === 'student') {
              const apiUrl = `${API_URLS.STUDENT_URL}akillikurs/progress/update-game`;
              await axiosInstance.post(apiUrl, payload);
            } else {
              const apiUrl = `${API_URLS.TEACHER_URL}akillikurs/progress/update-game`;
              const teacherToken = localStorage.getItem('userToken');
              await axios.post(apiUrl, payload, {
                headers: {
                  Authorization: `Bearer ${teacherToken}`,
                  'Content-Type': 'application/json',
                },
              });
            }
          }
        } catch (error) {
          console.error('İlerleme güncellenirken hata:', error);
        }
      };
      updateProgress();
    }
  }, [gameOver, displayedWords.length, elapsedTime, userData, userRole, stage, exercise, token]);

  // Yeniden Başlat
  const handleRestart = () => {
    setShowGameOver(false);
    setGameOver(false);
    handleReset();
  };

  // Geri Dön
  const handleReturnToPreviousStage = () => {
    if (stage) {
      navigate(
        userRole === 'student' ? '/ogrenci-dashboard' : '/ogretmen-dashboard',
        {
          state: {
            selectedMenu: 'ExerciseList',
            selectedStage: stage,
          },
        }
      );
    } else {
      navigate(-1);
    }
  };

  // Modal kapat
  const handleClose = () => {
    setShowGameOver(false);
    setGameOver(false);
  };

  // Tema / font / yazı boyutu
  const handleThemeChangeLocal = (e) => setTheme(e.target.value);
  const handleFontChange = (e) => setFont(e.target.value);
  const handleFontSizeChangeLocal = (e) => setFontSize(e.target.value);

  return (
    <>
      {showInstructions ? (
        <div
          className={`kayan-yazilar-ikiser-intro-container game-container-bg theme-${theme}`}
          style={{ fontFamily: font, fontSize:'16px' }}
        >
          <div className="intro-container mt-4">
      
        <HeaderSection
        stage={stage} 
        handleReturnToPreviousStage={handleReturnToPreviousStage}
        handleSettingsClick={toggleSettingsModal}
        hideSettings={true} 
      />


      {/* Başlık */}
      <h1 className="intro-title mt-4">Kayan Yazılar 2 Çalışması</h1>

      {/* Koşullu Açıklama (stage varsa / yoksa) */}
      {stage ? (
        <p className="intro-description">
          Bu çalışmada aşamanıza göre zorluk seviyesi otomatik belirlenecek.
          İkişer kelime halinde ekranda beliren metni en hızlı şekilde okuyun.
        </p>
      ) : (
        <p className="intro-description">
          Bu çalışmada seçtiğiniz zorluk seviyesine göre 
          ekranda ikişer kelime halinde metinler belirecek.
        </p>
      )}

      {/* Talimatlar Kutusu */}
      <div className="intro-instructions-box">
        <h3 className="intro-instructions-title">Talimatlar</h3>
        <ol
          className="intro-instructions-list"
          style={{ listStyleType: "decimal", paddingLeft: "20px" }}
        >
          {[
            stage ? "Aşamanıza göre zorluk seviyesi otomatik ayarlandı." : "Zorluk seviyesini seçin.",
            "\"Başlat\" butonuna tıklayarak çalışmayı başlatın.",
            "Ekranda beliren ikili kelimeleri takip edin ve okuyun."
          ].map((instruction, index) => (
            <li key={index}>{instruction}</li>
          ))}
        </ol>
      </div>


      {/* Başlat Butonu */}
      <button className="intro-button" onClick={startGame}>
        BAŞLAT
      </button>
    </div>
        </div>
      ) : (
        <div
          className={`kayan-yazilar-ikiser-game-container game-container-bg theme-${theme}`}
          style={{ fontFamily: font, fontSize }}
        >
          <div className="kayan-yazilar-ikiser-gameplay">
            <HeaderSection 
             stage={stage} 
              handleReturnToPreviousStage={handleReturnToPreviousStage}
              handleSettingsClick={toggleSettingsModal}
            />

        

            {/* Timer */}
            <GameTimerIkiser
              timeLeft={timeLeft}
              isPlaying={isPlaying}
              gameTime={gameTime}
              elapsedTime={elapsedTime}
            />

         
            <GameDisplay
              isPlaying={isPlaying}
              paragraphsList={paragraphsList}
              selectedParagraphIndex={selectedParagraphIndex}
              displayedWords={displayedWords}
              updateWords={updateWords}
              handlePlayPause={handlePlayPause}
              handleReset={handleReset}
              message={message}
              gameOver={gameOver}
              timeLeft={timeLeft}
              gameTime={gameTime}
              speedFactor={speedFactor}
              handleSpeedIncrease={handleSpeedIncrease}
              handleSpeedDecrease={handleSpeedDecrease}
            />


          </div>
        </div>
      )}

      {/* Oyun Bitti Modalı */}
      {showGameOver && (
        <GameOverModal
          score={displayedWords.length * 2}
          elapsedTime={Math.floor(elapsedTime)}
          onRestart={handleRestart}
          onClose={handleClose}
          userData={userData}
        />
      )}

      {/* AYARLAR MODALI */}
      {showSettingsModal && (
        <SettingsModalIkiser
          onClose={toggleSettingsModal}
          difficulty={difficulty}
          paragraphsList={paragraphsList}
          selectedParagraphIndex={selectedParagraphIndex}
          handleDifficultyChange={handleDifficultyChange}
          handleParagraphChange={handleParagraphChange}
          fontSize={fontSize}
          handleFontSizeChange={handleFontSizeChangeLocal}
          theme={theme}
          handleThemeChange={handleThemeChangeLocal}
          font={font}
          handleFontChange={handleFontChange}
          difficultyLocked={difficultyLocked}
        />
      )}
    </>
  );
};

export default KayanYazilarIkiserOyunu;
