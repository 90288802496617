// EditStudentDialog.jsx
import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { API_URLS } from '../../../../config/config';

const EditStudentDialog = ({ open, student, sehirListesi, onClose, onUpdate }) => {
  const [editedStudent, setEditedStudent] = useState({
    _id: '',
    ad: '',
    soyad: '',
    okulAdi: '',
    email: '',
    telefon: '',
    sehirId: '',
    ilceId: '',
    sinif: '',
    yas: '',
    ilceler: [],
  });
  
  useEffect(() => {
    if (student) {
      setEditedStudent({
        _id: student._id,
        ad: student.ad,
        soyad: student.soyad,
        okulAdi: student.okul_adi,
        email: student.email,
        telefon: student.telefon,
        sehirId: student.sehir,
        ilceId: student.ilce,
        sinif: student.sinif,
        yas: student.yas,
        ilceler: [],
      });
      fetchIlceler(student.sehir, student.ilce);
    }
  }, [student]);
  
  const fetchIlceler = async (sehirId, seciliIlceId) => {
    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}sehirler/ilceler/${sehirId}`);
      if (!response.ok) throw new Error('İlçeler yüklenirken bir hata oluştu.');
      const data = await response.json();
      const ilceler = data.ilceler ? data.ilceler : [];
      setEditedStudent(prev => ({
        ...prev,
        ilceler: ilceler,
        ilceId: seciliIlceId && ilceler.some(ilce => ilce._id === seciliIlceId) ? seciliIlceId : '',
      }));
    } catch (error) {
      console.error('İlçeler yüklenirken bir hata oluştu:', error);
    }
  };
  
  const handleSehirChange = (e) => {
    const newSehirId = e.target.value;
    setEditedStudent(prev => ({ ...prev, sehirId: newSehirId, ilceId: '' }));
    const selectedSehir = sehirListesi.find(sehir => sehir._id === newSehirId);
    const ilceler = selectedSehir ? selectedSehir.ilceler : [];
    setEditedStudent(prev => ({ ...prev, ilceler }));
  };
  
  const handleIlceChange = (e) => {
    setEditedStudent(prev => ({ ...prev, ilceId: e.target.value }));
  };
  
  const handleUpdate = () => {
    onUpdate(editedStudent);
  };
  
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Öğrenci Düzenle</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          label="Adı"
          value={editedStudent.ad}
          onChange={(e) => setEditedStudent({ ...editedStudent, ad: e.target.value })}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Soyadı"
          value={editedStudent.soyad}
          onChange={(e) => setEditedStudent({ ...editedStudent, soyad: e.target.value })}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Okul Adı"
          value={editedStudent.okulAdi}
          onChange={(e) => setEditedStudent({ ...editedStudent, okulAdi: e.target.value })}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Sınıf"
          value={editedStudent.sinif}
          onChange={(e) => setEditedStudent({ ...editedStudent, sinif: e.target.value })}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Yaş"
          value={editedStudent.yas}
          onChange={(e) => setEditedStudent({ ...editedStudent, yas: e.target.value })}
          margin="normal"
        />
        <FormControl fullWidth margin="dense">
          <InputLabel id="sehir-select-label-edit">Şehir</InputLabel>
          <Select
            labelId="sehir-select-label-edit"
            value={editedStudent.sehirId}
            onChange={handleSehirChange}
            displayEmpty
          >
            <MenuItem value="">
              <em>Şehir Seçiniz</em>
            </MenuItem>
            {sehirListesi.map(sehir => (
              <MenuItem key={sehir._id} value={sehir._id}>
                {sehir.sehir_adi}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {editedStudent.sehirId && (
          <FormControl fullWidth margin="dense">
            <InputLabel id="ilce-select-label-edit">İlçe</InputLabel>
            <Select
              labelId="ilce-select-label-edit"
              value={editedStudent.ilceId}
              onChange={handleIlceChange}
              displayEmpty
            >
              <MenuItem value="">
                <em>İlçe Seçiniz</em>
              </MenuItem>
              {editedStudent.ilceler.map(ilce => (
                <MenuItem key={ilce._id} value={ilce._id}>
                  {ilce.ilce_adi}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <TextField
          fullWidth
          label="Email"
          value={editedStudent.email}
          onChange={(e) => setEditedStudent({ ...editedStudent, email: e.target.value })}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Telefon"
          value={editedStudent.telefon}
          onChange={(e) => setEditedStudent({ ...editedStudent, telefon: e.target.value })}
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>İptal</Button>
        <Button onClick={handleUpdate} color="primary" variant="contained">Güncelle</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditStudentDialog;
