import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faHome, faCog } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const HeaderSection = ({ stage, handleReturnToPreviousStage, openSettings, hideSettings }) => {
  const navigate = useNavigate();

  const handleBackButton = () => {
    if (stage) {
      handleReturnToPreviousStage();
    } else {
      navigate(-1);
    }
  };

  const backButtonLabel = stage ? 'Önceki Aşamaya Dön' : 'Geri Dön'; 

  return (
    <div className="header-buttons">    
      <button
        className="header-button"
        onClick={handleBackButton}
        aria-label={backButtonLabel}
      >
        <FontAwesomeIcon icon={faArrowLeft} /> {backButtonLabel}
      </button>

      {/* Ana Sayfa Butonu */}
      <button
        className="header-button"
        onClick={() => navigate('/')}
        aria-label="Ana Sayfa"
      >
        <FontAwesomeIcon icon={faHome} /> Ana Sayfa
      </button>

      {/* Ayarlar Butonu - sadece hideSettings false ise render edilsin */}
      {!hideSettings && (
        <button
          className="header-button"
          onClick={openSettings}
          aria-label="Ayarlar"
        >
          <FontAwesomeIcon icon={faCog} /> Ayarlar
        </button>
      )}
    </div>
  );
};

export default HeaderSection;
