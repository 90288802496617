import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faHome, faCog } from '@fortawesome/free-solid-svg-icons';


const GameHeader = ({ stage, handleReturnToPreviousStage, handleSettingsClick, hideSettings }) => {
  const navigate = useNavigate();

  // Eğer stage varsa handleReturnToPreviousStage, yoksa navigate(-1) çalışsın
  const handleBackButton = () => {
    if (stage) {
      handleReturnToPreviousStage();
    } else {
      navigate(-1);
    }
  };

  const backButtonLabel = stage ? 'Önceki Aşamaya Dön' : 'Geri Dön';

  return (
    <div className="header-buttons">
      <button
        className="header-button"
        onClick={handleBackButton}
        aria-label={backButtonLabel}
      >
        <FontAwesomeIcon icon={faArrowLeft} /> {backButtonLabel}
      </button>

      <button
        className="header-button"
        onClick={() => navigate('/')}
        aria-label="Ana Sayfa"
      >
        <FontAwesomeIcon icon={faHome} /> Ana Sayfa
      </button>

      {!hideSettings && (
        <button
          className="header-button"
          onClick={handleSettingsClick}
          aria-label="Ayarlar"
        >
          <FontAwesomeIcon icon={faCog} /> Ayarlar
        </button>
      )}
    </div>
  );
};

export default GameHeader;
