import React, { useState } from 'react';
import {Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button,} from '@mui/material';
import axios from 'axios';
import { API_URLS } from '../../../../config/config';

const StudentDialogs = ({ fetchOgrenciListesi, setSnackbar }) => {
  const [openDeleteTestDialog, setOpenDeleteTestDialog] = useState(false);
  const [openDeleteProgressDialog, setOpenDeleteProgressDialog] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);

  // Diyalogları açan fonksiyonlar, örneğin:
  const handleOpenDeleteTestDialog = (student) => {
    setSelectedStudent(student);
    setOpenDeleteTestDialog(true);
  };

  const handleCloseDeleteTestDialog = () => {
    setOpenDeleteTestDialog(false);
    setSelectedStudent(null);
  };

  const handleConfirmDeleteTest = async () => {
    try {
      const token = localStorage.getItem('userToken');
      if (!token) {
        setSnackbar({ open: true, message: 'Token bulunamadı.', severity: 'error' });
        return;
      }
      await axios.delete(
        `${API_URLS.SCHOOL_URL}student/akillikursrapor/ogrenci/akillikursrapor/${selectedStudent._id}/initial-test`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSnackbar({ open: true, message: 'Test sonucu başarıyla silindi.', severity: 'success' });
      setOpenDeleteTestDialog(false);
      setSelectedStudent(null);
      fetchOgrenciListesi();
    } catch (error) {
      setSnackbar({ open: true, message: 'Test sonucu silinirken hata oluştu.', severity: 'error' });
      setOpenDeleteTestDialog(false);
    }
  };

  const handleOpenDeleteProgressDialog = (student) => {
    setSelectedStudent(student);
    setOpenDeleteProgressDialog(true);
  };

  const handleCloseDeleteProgressDialog = () => {
    setOpenDeleteProgressDialog(false);
    setSelectedStudent(null);
  };

  const handleConfirmDeleteProgress = async () => {
    try {
      const token = localStorage.getItem('userToken');
      if (!token) {
        setSnackbar({ open: true, message: 'Token bulunamadı.', severity: 'error' });
        return;
      }
      await axios.delete(`${API_URLS.SCHOOL_URL}student/akillikursrapor/${selectedStudent._id}/progress`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSnackbar({ open: true, message: 'Öğrenci ilerlemesi başarıyla silindi.', severity: 'success' });
      setOpenDeleteProgressDialog(false);
      setSelectedStudent(null);
      fetchOgrenciListesi();
    } catch (error) {
      setSnackbar({ open: true, message: 'İlerleme silinirken hata oluştu.', severity: 'error' });
      setOpenDeleteProgressDialog(false);
    }
  };

  return (
    <>
      {/* İlk Okuma Hızı Testi Silme Diyaloğu */}
      <Dialog open={openDeleteTestDialog} onClose={handleCloseDeleteTestDialog}>
        <DialogTitle>İlk Okuma Hızı Testini Sil</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Seçili öğrencinin ilk okuma hızı test sonucunu silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteTestDialog}>İptal</Button>
          <Button onClick={handleConfirmDeleteTest} color="error">
            Sil
          </Button>
        </DialogActions>
      </Dialog>

      {/* İlerleme Silme Diyaloğu */}
      <Dialog open={openDeleteProgressDialog} onClose={handleCloseDeleteProgressDialog}>
        <DialogTitle>Aşamaları Sil</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Seçili öğrencinin tüm aşama ilerlemelerini silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteProgressDialog}>İptal</Button>
          <Button onClick={handleConfirmDeleteProgress} color="warning">
            Sil
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default StudentDialogs;
