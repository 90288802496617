// Ogretmen.js
import React, { useState, useEffect } from 'react';
import {Typography, Paper, Grid, Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button,} from '@mui/material';
import { API_URLS } from '../../../config/config';
import OgretmenKayıtFormu from './components/OgretmenKayıtFormu';
import OgretmenListesi from './components/OgretmenListesi';
import OgretmenDuzenleDialog from './components/OgretmenDuzenleDialog';
import BakiyeGuncelleDialog from './components/BakiyeGuncelleDialog';
import FiltrelemeAlanlari from './components/FiltrelemeAlanlari';
import SonucDialogu from './components/SonucDialogu';

const Ogretmen = () => {
  const [ogretmenListesi, setOgretmenListesi] = useState([]);
  const [sehirListesi, setSehirListesi] = useState([]);
  const [secilenSehirId, setSecilenSehirId] = useState('');
  const [secilenIlceId, setSecilenIlceId] = useState('');
  const [shouldFetch, setShouldFetch] = useState(true);

  // Form alanları
  const [ad, setAd] = useState('');
  const [soyad, setSoyad] = useState('');
  const [telefon, setTelefon] = useState('');
  const [telefonHata, setTelefonHata] = useState(false);
  const [email, setEmail] = useState('');
  const [sifre, setSifre] = useState('');
  const [durum, setDurum] = useState('inactive');
  const [okulAdi, setOkulAdi] = useState(''); 

  // Diyaloglar, mesajlar vs.
  const [dialogContent, setDialogContent] = useState('');
  const [dialogSeverity, setDialogSeverity] = useState('info');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [resultDialogOpen, setResultDialogOpen] = useState(false);
  const [resultDialogMessage, setResultDialogMessage] = useState('');
  const [resultDialogSeverity, setResultDialogSeverity] = useState('info');
  const [eklemeBasariliDialogOpen, setEklemeBasariliDialogOpen] = useState(false);

  // Düzenleme
  const [duzenlemeDialogAcik, setDuzenlemeDialogAcik] = useState(false);
  const [duzenlenenOgretmen, setDuzenlenenOgretmen] = useState({
    ad: '',
    soyad: '',
    okulAdi: '',
    email: '',
    telefon: '',
    sehirId: '',
    ilceId: '',
    ilceler: [],
    status: '',
    password: '',
  });

  // Bakiye
  const [balanceDialogOpen, setBalanceDialogOpen] = useState(false);
  const [selectedKurum, setSelectedKurum] = useState(null);
  const [balanceAmount, setBalanceAmount] = useState(0);
  const [balanceReason, setBalanceReason] = useState('');
  const [selectedPackageType, setSelectedPackageType] = useState('');
  const [currentPackageBalance, setCurrentPackageBalance] = useState(0);

  // Filtreleme
  const [searchQuery, setSearchQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [cityFilter, setCityFilter] = useState('');
  const [ilceFilter, setIlceFilter] = useState('');
  const [ekleyenFilter, setEkleyenFilter] = useState(''); // Input
  const [ekleyenSelect, setEkleyenSelect] = useState(''); // SelectBox

  // Silme onayı
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
  const [ogretmenToDelete, setOgretmenToDelete] = useState(null);

  // Yardımcı fonksiyon: “Ekleyen” metnini döndürür
  const getEkleyenValue = (ogretmen) => {
    if (ogretmen.adminAdi && ogretmen.adminAdi !== 'Bulunamadı' && ogretmen.adminAdi !== 'null') {
      return ogretmen.adminAdi;
    } else if (
      ogretmen.okulAdi &&
      ogretmen.okulAdi !== 'Bulunamadı' &&
      ogretmen.okulAdi !== 'null'
    ) {
      return ogretmen.okulAdi;
    } else if (
      ogretmen.kurumAdi &&
      ogretmen.kurumAdi !== 'Bulunamadı' &&
      ogretmen.kurumAdi !== 'null'
    ) {
      return ogretmen.kurumAdi;
    } else {
      return 'Kendi';
    }
  };

  // Verileri çekme
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Şehirler
        const sehirResponse = await fetch(`${API_URLS.ADMIN_URL}sehirler`);
        if (!sehirResponse.ok) throw new Error('Şehirler yüklenirken bir hata oluştu.');
        const sehirler = await sehirResponse.json();
        setSehirListesi(sehirler);

        // Öğretmenler
        const ogretmenResponse = await fetch(`${API_URLS.ADMIN_URL}teachers`);
        if (!ogretmenResponse.ok)
          throw new Error('Öğretmen listesi yüklenirken bir hata oluştu.');
        const ogretmenler = await ogretmenResponse.json();

        // Sehir / ilce adlarını ekle
        const enhancedData = ogretmenler.map((ogretmen) => {
          const sehir = sehirler.find((s) => s._id === ogretmen.sehir);
          const ilce = sehir?.ilceler.find((i) => i._id === ogretmen.ilce);

          return {
            ...ogretmen,
            sehirAdi: sehir ? sehir.sehir_adi : 'Bulunamadı',
            ilceAdi: ilce ? ilce.ilce_adi : 'Bulunamadı',
            status: ogretmen.status || 'inactive',
            bakiye: ogretmen.bakiye || 0,
          };
        });

        setOgretmenListesi(enhancedData);
      } catch (error) {
        console.error('Veriler yüklenirken hata:', error);
      } finally {
        setShouldFetch(false);
      }
    };

    if (shouldFetch) {
      fetchData();
    }
  }, [shouldFetch]);

  // İlçe verilerini çekme (Düzenleme diyaloğunda)
  const fetchIlceler = async (sehirId, seciliIlceId) => {
    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}sehirler/ilceler/${sehirId}`);
      if (!response.ok) throw new Error('İlçeler yüklenirken bir hata oluştu.');
      const data = await response.json();
      const ilceler = data.ilceler ? data.ilceler : [];

      setDuzenlenenOgretmen((prev) => ({
        ...prev,
        ilceler: ilceler,
        ilceId:
          seciliIlceId && ilceler.some((ilce) => ilce._id === seciliIlceId)
            ? seciliIlceId
            : prev.ilceId,
      }));
    } catch (error) {
      console.error('İlçeleri yüklerken hata:', error);
    }
  };

  // Telefon formatlama
  const handleTelefonChange = (value) => {
    const telefonRegex = /^0(\d{3}) (\d{3}) (\d{4})$/;
    const numbers = value.replace(/\D/g, '');
    let formatted = numbers.substring(0, 4);
    if (numbers.length > 4) formatted += ' ' + numbers.substring(4, 7);
    if (numbers.length > 7) formatted += ' ' + numbers.substring(7, 11);

    setTelefonHata(!telefonRegex.test(formatted) && formatted !== '');
    setTelefon(formatted);
  };

  // Ekle
  const handleEkle = async () => {
    let eksikAlanlar = [];
    if (!ad) eksikAlanlar.push('Adı');
    if (!soyad) eksikAlanlar.push('Soyadı');
    if (!email) eksikAlanlar.push('Email');
    if (!sifre) eksikAlanlar.push('Şifre');
    if (!telefon) eksikAlanlar.push('Telefon');
    if (!secilenSehirId) eksikAlanlar.push('Şehir');
    if (!secilenIlceId) eksikAlanlar.push('İlçe');
    if (!okulAdi) eksikAlanlar.push('Okul');

    if (eksikAlanlar.length > 0) {
      setDialogSeverity('error');
      setDialogContent(`Lütfen ${eksikAlanlar.join(', ')} alanlarını doldurunuz.`);
      setEklemeBasariliDialogOpen(true);
      return;
    }

    if (telefonHata) {
      setDialogSeverity('error');
      setDialogContent('Lütfen geçerli bir telefon numarası giriniz.');
      setEklemeBasariliDialogOpen(true);
      return;
    }

    try {
      const requestBody = {
        ad,
        soyad,
        email,
        password: sifre,
        password_open: sifre,
        telefon,
        sehir: secilenSehirId,
        ilce: secilenIlceId,
        status: durum,
        okul_adi: okulAdi.trim() !== '' ? okulAdi : '',
      };

      const response = await fetch(`${API_URLS.ADMIN_URL}teachers`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody),
      });
      const responseData = await response.json();
      if (!response.ok) throw new Error(responseData.message || 'Bir hata oluştu');

      setShouldFetch(true);
      setDialogSeverity('success');
      setDialogContent('Yeni Öğretmen başarıyla eklendi.');
      setEklemeBasariliDialogOpen(true);

      // Formu sıfırla
      setAd('');
      setSoyad('');
      setEmail('');
      setSifre('');
      setTelefon('');
      setSecilenSehirId('');
      setSecilenIlceId('');
      setOkulAdi('');
      setDurum('inactive');
    } catch (err) {
      setDialogSeverity('error');
      setDialogContent(err.message);
      setEklemeBasariliDialogOpen(true);
    }
  };

  // Silme onayı
  const handleDeleteConfirmation = (ogretmenId) => {
    const ogretmen = ogretmenListesi.find((o) => o._id === ogretmenId);
    setOgretmenToDelete(ogretmen);
    setConfirmDeleteDialogOpen(true);
  };
  const handleDeleteOgretmen = async () => {
    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}teachers/${ogretmenToDelete._id}`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
      });
      if (!response.ok) throw new Error('Öğretmen silinirken bir hata oluştu.');
      setShouldFetch(true);

      setResultDialogMessage('Öğretmen başarıyla silindi.');
      setResultDialogSeverity('success');
      setResultDialogOpen(true);
    } catch (error) {
      setResultDialogMessage(error.message);
      setResultDialogSeverity('error');
      setResultDialogOpen(true);
    } finally {
      setConfirmDeleteDialogOpen(false);
      setOgretmenToDelete(null);
    }
  };

  // Düzenle
  const handleEdit = (ogretmenId) => {
    const secili = ogretmenListesi.find((o) => o._id === ogretmenId);
    if (!secili) return console.error('Seçilen öğretmen bulunamadı.');

    const sehirId = secili.sehir;
    setDuzenlenenOgretmen({
      _id: secili._id,
      ad: secili.ad,
      soyad: secili.soyad,   
     okulAdi: secili.okul_adi || '',  // <-- Burada 'okul_adi' olarak düzelt
      email: secili.email,
      telefon: secili.telefon,
      sehirId,
      ilceId: secili.ilce,
      ilceler: [],
      status: secili.status,
      password: '',
    });
    fetchIlceler(sehirId, secili.ilce);
    setDuzenlemeDialogAcik(true);
  };

  const handleGuncelle = async () => {
    const {
      _id,
      ad,
      soyad,
      okulAdi,
      email,
      telefon,
      sehirId,
      ilceId,
      status,
      password,
    } = duzenlenenOgretmen;

    let eksikAlanlar = [];
    if (!ad) eksikAlanlar.push('Adı');
    if (!soyad) eksikAlanlar.push('Soyadı');
    if (!email) eksikAlanlar.push('Email');
    if (!telefon) eksikAlanlar.push('Telefon');
    if (!sehirId) eksikAlanlar.push('Şehir');
    if (!ilceId) eksikAlanlar.push('İlçe');

    if (eksikAlanlar.length > 0) {
      setDialogSeverity('error');
      setDialogContent(`Lütfen ${eksikAlanlar.join(', ')} alanlarını doldurunuz.`);
      setDialogOpen(true);
      return;
    }

    try {
      const updateData = {
        ad,
        soyad,
        email,
        telefon,
        sehir: sehirId,
        ilce: ilceId,
        status,
        okul_adi: okulAdi.trim(), 
      };
      if (password.trim() !== '') {
        updateData.password = password;
        updateData.password_open = password;
      }

      const response = await fetch(`${API_URLS.ADMIN_URL}teachers/${_id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(updateData),
      });
      const data = await response.json();
      if (!response.ok) throw new Error(data.message || 'Bir hata oluştu');

      setShouldFetch(true);
      setDialogSeverity('success');
      setDialogContent('Öğretmen başarıyla güncellendi.');
      setDialogOpen(true);
      setDuzenlemeDialogAcik(false);
    } catch (err) {
      setDialogSeverity('error');
      setDialogContent(err.message);
      setDialogOpen(true);
    }
  };

  // Durum değiştirme
  const handleStatusChange = async (ogretmenId, currentStatus) => {
    const newStatus = currentStatus === 'active' ? 'inactive' : 'active';
    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}teachers/${ogretmenId}/status`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
        body: JSON.stringify({ status: newStatus }),
      });
      if (!response.ok) throw new Error('Durum güncellenirken bir hata oluştu.');
      setShouldFetch(true);

      setResultDialogMessage('Durum başarıyla güncellendi.');
      setResultDialogSeverity('success');
      setResultDialogOpen(true);
    } catch (error) {
      setResultDialogMessage(error.message);
      setResultDialogSeverity('error');
      setResultDialogOpen(true);
    }
  };

  // Bakiye güncelle
  const handleAdjustBalance = (ogretmen) => {
    setSelectedKurum(ogretmen);
    setBalanceDialogOpen(true);
    setSelectedPackageType('');
    setBalanceAmount(0);
    setBalanceReason('');
    setCurrentPackageBalance(0);
  };

  const handleBalanceSubmit = async () => {
    if (!balanceReason) {
      setDialogSeverity('error');
      setDialogContent('Lütfen bir neden giriniz.');
      setDialogOpen(true);
      return;
    }
    if (selectedPackageType === '') {
      setDialogSeverity('error');
      setDialogContent('Lütfen bir paket seçiniz.');
      setDialogOpen(true);
      return;
    }

    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}bakiye/balance-adjustment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
        body: JSON.stringify({
          userType: 'Ogretmen',
          userId: selectedKurum._id,
          packageType: selectedPackageType,
          newBalance: balanceAmount,
          reason: balanceReason,
        }),
      });
      const data = await response.json();
      if (!response.ok) throw new Error(data.message || 'Bakiye güncellenirken bir hata oluştu.');

      setShouldFetch(true);
      setDialogSeverity('success');
      setDialogContent('Bakiye başarıyla güncellendi.');
      setDialogOpen(true);
      setBalanceDialogOpen(false);
      setBalanceAmount(0);
      setBalanceReason('');
      setSelectedPackageType('');
    } catch (error) {
      setDialogSeverity('error');
      setDialogContent(error.message);
      setDialogOpen(true);
    }
  };

  // Filtrelenmiş liste
  const filteredOgretmenListesi = ogretmenListesi.filter((ogretmen) => {
    // 1) Arama (ad, soyad, telefon, email, sehirAdi, ilceAdi)
    const matchesSearch =
      ogretmen.ad.toLowerCase().includes(searchQuery.toLowerCase()) ||
      ogretmen.soyad.toLowerCase().includes(searchQuery.toLowerCase()) ||
      ogretmen.telefon.includes(searchQuery) ||
      ogretmen.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (ogretmen.sehirAdi &&
        ogretmen.sehirAdi.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (ogretmen.ilceAdi &&
        ogretmen.ilceAdi.toLowerCase().includes(searchQuery.toLowerCase()));

    // 2) Durum filtresi
    const matchesStatus = statusFilter ? ogretmen.status === statusFilter : true;

    // 3) Şehir filtresi
    const matchesCity = cityFilter ? ogretmen.sehir === cityFilter : true;

    // 4) İlçe filtresi
    const matchesIlce = ilceFilter ? ogretmen.ilce === ilceFilter : true;

    // 5) Ekleyen
    const ekleyen = getEkleyenValue(ogretmen);
    const matchesEkleyenFilter = ekleyenFilter
      ? ekleyen.toLowerCase().includes(ekleyenFilter.toLowerCase())
      : true;
    const matchesEkleyenSelect = ekleyenSelect ? ekleyen === ekleyenSelect : true;

    return (
      matchesSearch &&
      matchesStatus &&
      matchesCity &&
      matchesIlce &&
      matchesEkleyenFilter &&
      matchesEkleyenSelect
    );
  });

  // Dialog kapat
  const handleDialogClose = () => {
    setDialogOpen(false);
    setResultDialogOpen(false);
  };

  // Ekleyen SelectBox opsiyonları
  const ekleyenOptions = React.useMemo(() => {
    const allEkleyen = ogretmenListesi.map(getEkleyenValue);
    return [...new Set(allEkleyen)]; // unique
  }, [ogretmenListesi]);

  return (
    <Paper style={{ padding: 20 }}>
      <Grid container spacing={3}>
        {/* Öğretmen Kayıt Formu */}
        <Grid item xs={12}>
          <Typography variant="h5" component="h3" marginBottom={2}>
            Öğretmen Kayıt Formu
          </Typography>
          <OgretmenKayıtFormu
            ad={ad}
            setAd={setAd}
            soyad={soyad}
            setSoyad={setSoyad}
            okulAdi={okulAdi}
            setOkulAdi={setOkulAdi}
            secilenSehirId={secilenSehirId}
            setSecilenSehirId={(e) => setSecilenSehirId(e.target.value)}
            sehirListesi={sehirListesi}
            secilenIlceId={secilenIlceId}
            setSecilenIlceId={setSecilenIlceId} 
            secilenSehirinIlceleri={
              sehirListesi.find((sehir) => sehir._id === secilenSehirId)?.ilceler || []
            }
            telefon={telefon}
            setTelefon={handleTelefonChange}
            telefonHata={telefonHata}
            email={email}
            setEmail={setEmail}
            sifre={sifre}
            setSifre={setSifre}
            durum={durum}
            setDurum={setDurum}
            handleEkle={handleEkle}
          />
        </Grid>

        {/* Öğretmen Listesi (collapsible tablo) */}
        <Grid item xs={12} md={12}>
          <Box mb={2}>
            <Typography variant="h5" component="h3" gutterBottom>
              Öğretmen Listesi
            </Typography>
          </Box>

          <Box component={Paper} padding={2} elevation={3}>
            <Box mb={3}>
              <FiltrelemeAlanlari
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                statusFilter={statusFilter}
                setStatusFilter={setStatusFilter}
                cityFilter={cityFilter}
                setCityFilter={setCityFilter}
                ilceFilter={ilceFilter}
                setIlceFilter={setIlceFilter}
                sehirListesi={sehirListesi}
                // Ekleyen
                ekleyenFilter={ekleyenFilter}
                setEkleyenFilter={setEkleyenFilter}
                ekleyenSelect={ekleyenSelect}
                setEkleyenSelect={setEkleyenSelect}
                ekleyenOptions={ekleyenOptions}
              />
            </Box>

            <OgretmenListesi
              ogretmenListesi={filteredOgretmenListesi}
              handleStatusChange={handleStatusChange}
              handleEdit={handleEdit}
              handleDeleteOgretmen={handleDeleteConfirmation}
              handleAdjustBalance={handleAdjustBalance}
            />
          </Box>
        </Grid>
      </Grid>

      {/* Silme Onay Dialogu */}
      <Dialog
        open={confirmDeleteDialogOpen}
        onClose={() => setConfirmDeleteDialogOpen(false)}
      >
        <DialogTitle>Öğretmeni Sil</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {ogretmenToDelete && (
              <>
                <strong>
                  {ogretmenToDelete.ad} {ogretmenToDelete.soyad}
                </strong>{' '}
                isimli öğretmeni silmek istediğinize emin misiniz?
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDeleteDialogOpen(false)} color="primary">
            İptal
          </Button>
          <Button onClick={handleDeleteOgretmen} color="secondary">
            Sil
          </Button>
        </DialogActions>
      </Dialog>

      {/* Bakiye Güncelle Dialogu */}
      <BakiyeGuncelleDialog
        open={balanceDialogOpen}
        handleClose={() => setBalanceDialogOpen(false)}
        selectedKurum={selectedKurum}
        selectedPackageType={selectedPackageType}
        setSelectedPackageType={setSelectedPackageType}
        currentPackageBalance={currentPackageBalance}
        setCurrentPackageBalance={setCurrentPackageBalance}
        balanceAmount={balanceAmount}
        setBalanceAmount={setBalanceAmount}
        balanceReason={balanceReason}
        setBalanceReason={setBalanceReason}
        handleBalanceSubmit={handleBalanceSubmit}
      />

      {/* Sonuç Dialogları */}
      <SonucDialogu
        open={eklemeBasariliDialogOpen}
        onClose={() => setEklemeBasariliDialogOpen(false)}
        title={dialogSeverity === 'error' ? 'Hata Oluştu' : 'İşlem Başarılı'}
        message={dialogContent}
        severity={dialogSeverity}
      />

      <SonucDialogu
        open={resultDialogOpen}
        onClose={handleDialogClose}
        title={'İşlem Sonucu'}
        message={resultDialogMessage}
        severity={resultDialogSeverity}
      />

      {/* Düzenleme Dialogu */}
      <OgretmenDuzenleDialog
        open={duzenlemeDialogAcik}
        handleClose={() => setDuzenlemeDialogAcik(false)}
        duzenlenenOgretmen={duzenlenenOgretmen}
        setDuzenlenenOgretmen={setDuzenlenenOgretmen}
        sehirListesi={sehirListesi}
        handleSehirChangeDuzenle={(e) => {
          const yeniSehirId = e.target.value;
          setDuzenlenenOgretmen((prev) => ({
            ...prev,
            sehirId: yeniSehirId,
            ilceId: '',
            ilceler:
              sehirListesi.find((sehir) => sehir._id === yeniSehirId)?.ilceler || [],
          }));
        }}
        handleIlceChangeDuzenle={(e) =>
          setDuzenlenenOgretmen((prev) => ({ ...prev, ilceId: e.target.value }))
        }
        handleGuncelle={handleGuncelle}
      />
    </Paper>
  );
};

export default Ogretmen;
