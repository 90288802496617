// src/components/CancellationRefundForm.js

import React from 'react';
import {
  Container,
  Card,
  CardContent,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  Box,
  Grid
} from '@mui/material';

// 1) Orientation bazlı sorgu için
import { useMediaQuery as useOrientationQuery } from 'react-responsive';

import Header from '../Header';
import Footer from '../Footer';

function CancellationRefundForm() {
  // 2) Orientation
  const isPortrait = useOrientationQuery({ query: '(orientation: portrait)' });

  return (
    <div>
      <Header />

      {/* 3) isPortrait'e göre margin-top/bottom değişiyor */}
      <Container
        maxWidth="md"
        sx={{
          mt: isPortrait ? 3 : 5,
          mb: isPortrait ? 3 : 5,
        }}
      >
        <Card raised sx={{ p: isPortrait ? 2 : 3 }}>
          <CardContent>
            <Typography
              variant="h4"
              align="center"
              gutterBottom
              sx={{
                fontSize: isPortrait ? '1.8rem' : '2rem',
                fontWeight: 'bold',
              }}
            >
              İptal ve İade Koşulları
            </Typography>

            <Divider sx={{ mb: isPortrait ? 2 : 3 }} />

            <Typography
              variant="h6"
              gutterBottom
              sx={{
                fontWeight: 'bold',
                fontSize: isPortrait ? '1.2rem' : '1.4rem',
              }}
            >
              Genel Şartlar
            </Typography>
            <Typography variant="body1" paragraph>
              Hızlı Okuma ve Anlama Eğitim Platformu üzerinden elektronik ortamda sipariş
              verdiğiniz takdirde, size sunulan ön bilgilendirme formunu ve mesafeli satış
              sözleşmesini kabul etmiş sayılırsınız. Alıcılar, satın aldıkları hizmetin satış
              ve teslimi ile ilgili olarak 6502 sayılı Tüketicinin Korunması Hakkında Kanun
              ve Mesafeli Sözleşmeler Yönetmeliği hükümlerine tabidir.
            </Typography>

            <Divider sx={{ my: isPortrait ? 2 : 3 }} />

            <Typography
              variant="h6"
              gutterBottom
              sx={{
                fontWeight: 'bold',
                fontSize: isPortrait ? '1.2rem' : '1.4rem',
              }}
            >
              İade Koşulları
            </Typography>
            <List dense>
              <ListItem>
                <ListItemText
                  primary="İade Süresi:"
                  secondary="Hızlı Okuma ve Anlama Eğitim Platformu'ndan satın alınan hizmetler için, hizmetin başlatıldığı tarihten itibaren 1 (bir) gün içerisinde iade talebinde bulunabilirsiniz."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="İade Şartları:"
                  secondary="İade talebi, satın alma işleminin yapıldığı e-posta adresi üzerinden veya platformumuzun belirttiği iletişim kanalları aracılığıyla yazılı olarak bildirilmelidir."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  secondary="İade talebi, hizmete erişim sağlanmadan veya hizmetin kullanımı başlamadan önce yapılmalıdır."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  secondary="İade edilen hizmet bedeli, ödemenin yapıldığı yöntemle aynı şekilde ve en geç 14 (on dört) gün içerisinde iade edilecektir."
                />
              </ListItem>
            </List>

            <Divider sx={{ my: isPortrait ? 2 : 3 }} />

            <Typography
              variant="h6"
              gutterBottom
              sx={{
                fontWeight: 'bold',
                fontSize: isPortrait ? '1.2rem' : '1.4rem',
              }}
            >
              Cayma Hakkı
            </Typography>
            <Typography variant="body1" paragraph>
              Alıcı, satın aldığı hizmetin kendisine teslim edilmesinden itibaren 1 (bir) gün
              içerisinde, herhangi bir gerekçe göstermeksizin cayma hakkını kullanabilir.
            </Typography>
            <Typography variant="body1" paragraph>
              Cayma hakkının kullanılması için, hizmetin başlatılmasından önce iade talebi
              yazılı olarak bildirilmelidir. Hizmet bedelinin iadesi, talebin kabul
              edilmesinden itibaren en geç 14 (on dört) gün içerisinde gerçekleştirilecektir.
            </Typography>

            <Divider sx={{ my: isPortrait ? 2 : 3 }} />

            <Typography
              variant="h6"
              gutterBottom
              sx={{
                fontWeight: 'bold',
                fontSize: isPortrait ? '1.2rem' : '1.4rem',
              }}
            >
              Ödeme ve Teslimat
            </Typography>
            <List dense>
              <ListItem>
                <ListItemText
                  primary="Kredi Kartı:"
                  secondary="Sitemiz üzerinden kredi kartlarınız ile online tek ödeme veya online taksit imkânlarından yararlanabilirsiniz."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Banka Havalesi/EFT:"
                  secondary="Banka havalesi veya EFT ile yapılan ödemelerin siparişinizin tamamlanması için referans numarası ile birlikte yapılması gerekmektedir."
                />
              </ListItem>
            </List>

            <Divider sx={{ my: isPortrait ? 2 : 3 }} />

            <Typography
              variant="h6"
              gutterBottom
              sx={{
                fontWeight: 'bold',
                fontSize: isPortrait ? '1.2rem' : '1.4rem',
              }}
            >
              Sorumluluklar
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box>
                  <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>
                    Alıcının Yükümlülükleri:
                  </Typography>
                  <Typography variant="body2">
                    - Alıcı, satın aldığı hizmeti kullanmadan önce hizmet şartlarını ve
                    kullanım kılavuzunu dikkatlice okuma ve anlama yükümlülüğündedir.
                  </Typography>
                  <Typography variant="body2">
                    - Cayma hakkını kullanmak isteyen alıcı, hizmeti kullanmamış olmalıdır.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box>
                  <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>
                    Satıcının Yükümlülükleri:
                  </Typography>
                  <Typography variant="body2">
                    - İade taleplerini en kısa sürede işleme almak ve alıcıya geri dönüş
                    sağlamak.
                  </Typography>
                  <Typography variant="body2">
                    - Alıcıya iade edilecek tutarı, ödemenin yapıldığı yöntemle aynı şekilde
                    ve belirtilen süre içerisinde geri ödemek.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
      <Footer />
    </div>
  );
}

export default CancellationRefundForm;
