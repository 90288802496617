import React from 'react';
import '../styles/KelimeTamamlamaOyunu.css';

const Description = () => {
  return (
    <div className="intro-instructions-box">
      <h3 className="intro-instructions-title">Talimatlar</h3>
      <ol className="intro-instructions-list" style={{ listStyleType: 'decimal', paddingLeft: '20px' }}>
        <li>Çalışmayı başlatmak için "Başlat" butonuna basın.</li>
        <li>Size verilen paragrafta eksik kelimeleri tamamlayın.</li>
        <li>Seçeneklerden doğru kelimeleri seçin ve boşluklara yerleştirin.</li>
        <li>Çalışma süresi dolmadan tüm kelimeleri tamamlamaya çalışın.</li>
      </ol>
    </div>
  );
};

export default Description;
