import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { useLetterGridGame } from './hooks/useLetterGridGame';
import HeaderSection from './components/HeaderSection';
import GameControls from './components/GameControls';
import GameStatus from './components/GameStatus';
import LetterGrid from './components/LetterGrid';
import MessageAlert from './components/MessageAlert';
import GameOverModal from './components/GameOverModal';
import { API_URLS } from '../../../config/config';
import axiosInstance from '../../../utils/axiosInstance';
import './styles/harfIzgarasiStyle.css';
import SettingsModal from './components/SettingsModal';
 
const HarfIzgarasiOyunu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { stage, exercise } = location.state || {};
  const token = localStorage.getItem('userToken');

  const [userData, setUserData] = useState(null);
  const [userRole, setUserRole] = useState(null);
 
    // Ayarlar için modal state ve fonksiyonları
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);
    const openSettings = () => setIsSettingsOpen(true);
    const closeSettings = () => setIsSettingsOpen(false);

  // Aşamaya göre otomatik zorluk kilidi
  const stageNumber = stage?.number || null;
  let initialDifficulty = 'easy';
  let difficultyLocked = false;

  if (stageNumber) {
    difficultyLocked = true;
    if (stageNumber >= 1 && stageNumber <= 7) {
      initialDifficulty = 'easy';
    } else if (stageNumber >= 8 && stageNumber <= 14) {
      initialDifficulty = 'medium';
    } else {
      initialDifficulty = 'hard';
    }
  }

  // Kullanıcı verileri çekme
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // Öğrenci verisi
        const studentResponse = await axiosInstance.get(`${API_URLS.STUDENT_URL}/me`);
        if (
          studentResponse.status === 200 &&
          studentResponse.data &&
          studentResponse.data.status === 'active'
        ) {
          setUserData(studentResponse.data);
          setUserRole('student');
          return;
        } else {
          setUserData(null);
          setUserRole(null);
        }
      } catch (error) {
        console.error('Öğrenci verisi alınırken hata oluştu:', error);
      }

      // Öğrenci yoksa veya aktif değilse öğretmene bak
      try {
        const teacherResponse = await fetch(`${API_URLS.TEACHER_URL}/me`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (teacherResponse.ok) {
          const teacherData = await teacherResponse.json();
          setUserData(teacherData);
          setUserRole('teacher');
          return;
        }
      } catch (error) {
        console.error('Öğretmen verisi alınırken hata oluştu:', error);
      }

      setUserData(null);
      setUserRole(null);
    };

    fetchUserData();
  }, [token]);

  const [finalScore, setFinalScore] = useState(0);
  const [finalCorrectSelections, setFinalCorrectSelections] = useState(0);
  const [finalIncorrectSelections, setFinalIncorrectSelections] = useState(0);

  // Oyun bittiğinde modal açıp skorları set et
  const onGameOver = (score, correctSelections, incorrectSelections) => {
    setShowGameOver(true);
    setFinalScore(score);
    setFinalCorrectSelections(correctSelections);
    setFinalIncorrectSelections(incorrectSelections);
    updateProgress(score);
  };

  // useLetterGridGame hook
  const {
    letters,
    targetLetters,
    message,
    timeRemaining,
    score,
    isPlaying,
    clickedLetters,
    difficulty,
    gridSize,
    handlePlayPause,
    handleReset,
    handleLetterClick,
    handleDifficultyChange: originalHandleDifficultyChange,
  } = useLetterGridGame(userData, onGameOver, initialDifficulty);

  // Giriş Ekranı
  const [showInstructions, setShowInstructions] = useState(true);
  const [showGameOver, setShowGameOver] = useState(false);

  // Tema / Font / Boyut ayarları
  const [theme, setTheme] = useState('default');
  const handleThemeChange = (e) => {
    setTheme(e.target.value);
  };

  const [font, setFont] = useState('Arial');
  const handleFontChange = (e) => {
    setFont(e.target.value);
  };

  const [fontSize, setFontSize] = useState('1rem');
  const handleFontSizeChange = (e) => {
    setFontSize(e.target.value);
  };

  // İlerleme güncelleme
  const updateProgress = async (score) => {
    if (!userData || !userRole) return;
    try {
      const payload = {
        stageNumber: stage?.number || 1,
        gameName: exercise?.gameName || 'Harf Izgara',
        score: score,
        time:
          (userData && userData.status === 'inactive' ? 15 : 60) - timeRemaining,
        completed: true,
      };

      if (userRole === 'student') {
        // Öğrencinin ilerlemesi
        const apiUrl = `${API_URLS.STUDENT_URL}akillikurs/progress/update-game`;
        await axiosInstance.post(apiUrl, payload);
      } else {
        // Öğretmenin ilerlemesi
        const apiUrl = `${API_URLS.TEACHER_URL}akillikurs/progress/update-game`;
        const teacherToken = localStorage.getItem('userToken');
        await axios.post(apiUrl, payload, {
          headers: {
            Authorization: `Bearer ${teacherToken}`,
            'Content-Type': 'application/json',
          },
        });
      }
    } catch (error) {
      console.error('İlerleme güncellenirken hata oluştu:', error);
    }
  };

  // Oyunu başlatmak için Intro'yu kapat
  const startGame = () => {
    setShowInstructions(false);
  };

  const handleRestart = () => {
    setShowGameOver(false);
    handleReset();
  };

  // Bir önceki aşama sayfasına / dashboard'a dön
  const handleReturnToPreviousStage = () => {
    if (stage) {
      navigate(
        userRole === 'student' ? '/ogrenci-dashboard' : '/ogretmen-dashboard',
        {
          state: {
            selectedMenu: 'ExerciseList',
            selectedStage: stage,
          },
        }
      );
    } else {
      alert('Aşama bilgisi bulunmamaktadır.');
    }
  };

  const handleCloseModal = () => {
    setShowGameOver(false);
  };

  // Zorluk değişimi (eğer aşamaya göre kilitlenmediyse)
  const handleDifficultyChange = (e) => {
    if (difficultyLocked) return;
    originalHandleDifficultyChange(e);
  };

  return (
    <div
      className={`harf-izgarasi-container game-container-bg theme-${theme}`}
      style={{ fontFamily: font, fontSize }}
    >
      {showInstructions ? (
      
       <div className={`intro-container theme-${theme}`}>
      {/* Geri dönüş butonu vb. */}
      <HeaderSection
        stage={stage} 
        handleReturnToPreviousStage={handleReturnToPreviousStage}
        openSettings={openSettings}
        hideSettings={true} 
      />
    
      <h1 className="intro-title mt-4">Harf Izgarası Çalışması</h1>

      {/* Açıklama Metni (aşama varsa / yoksa) */}
      {stage ? (
        <p className="intro-description">
          Bu çalışmada aşamanıza göre zorluk seviyesi otomatik belirlenecek.
          Verilen süre içinde hedef harfleri ızgarada bulmaya çalışın.
        </p>
      ) : (
        <p className="intro-description">
          Bu çalışma amacı, verilen süre içerisinde hedef harfleri ızgarada bulmaktır.
        </p>
      )}

      {/* Talimatlar Kutusu */}
      <div className="intro-instructions-box">
        <h3 className="intro-instructions-title">Talimatlar</h3>
        <ul className="intro-instructions-list">
          <li> 1.  Verilen hedef harfleri ızgarada bulup tıklayın.
          </li>
          <li>2.  Her doğru seçim için puan kazanırsınız.
          </li>
          <li>3.  Süre dolduğunda çalışma sona erer.
          </li>
        </ul>
      </div>

      {/* Başlat Butonu */}
      <button className="intro-button" onClick={startGame}>
        BAŞLAT
      </button>
    </div>
     
      ) : (
        <div className="harf-izgarasi-gameplay">
         <HeaderSection
          stage={stage} 
          handleReturnToPreviousStage={handleReturnToPreviousStage}
          openSettings={openSettings}
        />

          {/* Oyun Kontrolleri */}
          <GameControls
            isPlaying={isPlaying}
            handlePlayPause={handlePlayPause}
            handleReset={handleReset}       
          />

  <SettingsModal
            isOpen={isSettingsOpen}
            onClose={closeSettings}
            difficulty={difficulty}
            handleDifficultyChange={handleDifficultyChange}
            difficultyLocked={difficultyLocked}
            theme={theme}
            handleThemeChange={handleThemeChange}
            font={font}
            handleFontChange={handleFontChange}
            fontSize={fontSize}
            handleFontSizeChange={handleFontSizeChange}
          />

          {/* Oyun Durumu */}
          <GameStatus timeRemaining={timeRemaining} score={score} />

          {/* Izgara */}
          <LetterGrid
            letters={letters}
            targetLetters={targetLetters}
            clickedLetters={clickedLetters}
            handleLetterClick={handleLetterClick}
            gridSize={gridSize}
          />

          {/* Geribildirim Mesajı */}
          <MessageAlert message={message} />
        </div>
      )}

      {/* Oyun Bitti Modal */}
      {showGameOver && (
        <GameOverModal
          score={finalScore}
          correctCount={finalCorrectSelections}
          incorrectCount={finalIncorrectSelections}
          onRestart={handleRestart}
          userData={userData}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default HarfIzgarasiOyunu;
