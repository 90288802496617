import React, { useState, useEffect } from 'react';
import {Typography, TextField, Button, Select, MenuItem, InputLabel, FormControl,  Card,  CardContent,  Grid, CircularProgress,} from '@mui/material';
import { API_URLS } from '../../../config/config';

function PaymentForm({ userType, userId }) {
  const [paketSuresi, setPaketSuresi] = useState('');
  const [adet, setAdet] = useState(1);
  const [maxAdet, setMaxAdet] = useState(1000);
  const [indirimOrani, setIndirimOrani] = useState(0);
  const [toplamTutar, setToplamTutar] = useState(0);
  const [message, setMessage] = useState('');

  // Dinamik fiyatları saklamak için state
  const [prices, setPrices] = useState({});
  const [loading, setLoading] = useState(true);
  const [priceError, setPriceError] = useState('');

  useEffect(() => {
    // Fiyatları backend'den çekmek için fonksiyon
    const fetchPrices = async () => {
      try {
        const token = localStorage.getItem('userToken'); // Kullanıcı token'ını al
        const response = await fetch(`${API_URLS.SCHOOL_URL}fiyat/prices`, {
          headers: {
            Authorization: `Bearer ${token}`, // Yetkilendirme başlığı
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Fiyatlar yüklenirken bir hata oluştu.');
        }

        const data = await response.json();
        console.log('Fetched Prices:', data); // Fiyatları kontrol et

        // Veriyi { 3: 750, 6: 1250, 12: 2000 } şeklinde map'lemek
        const priceMap = {};
        data.forEach((price) => {
          const paketSuresiNum = Number(price.paketSuresi);
          const basePriceNum = Number(price.basePrice);
          const campaignPriceNum = price.campaignPrice ? Number(price.campaignPrice) : null;

          if (!isNaN(paketSuresiNum)) {
            priceMap[paketSuresiNum] = campaignPriceNum || basePriceNum;
          } else {
            console.warn(`Geçersiz paket süresi: ${price.paketSuresi}`);
          }
        });
        console.log('Mapped Price Map:', priceMap); // Map'lenmiş fiyatları kontrol et
        setPrices(priceMap);

        // Eğer paketSuresi henüz belirlenmediyse, ilk paket süresini seç
        if (paketSuresi === '' && Object.keys(priceMap).length > 0) {
          const firstPaketSuresi = Number(Object.keys(priceMap)[0]);
          setPaketSuresi(firstPaketSuresi);
        }

        setLoading(false);
      } catch (error) {
        console.error(error);
        setPriceError(error.message);
        setLoading(false);
      }
    };

    fetchPrices();
  }, []);

  useEffect(() => {
    if (Object.keys(prices).length === 0 || paketSuresi === '') {
      setToplamTutar(0);
      setIndirimOrani(0);
      return;
    }

    // İndirim oranını hesapla
    const discountRate = calculateDiscount(adet);
    setIndirimOrani(discountRate);

    // Paket fiyatını al
    const basePricePerUnit = prices[paketSuresi];
    if (!basePricePerUnit) {
      setMessage('Geçersiz paket süresi.');
      setToplamTutar(0);
      return;
    }

    // Toplam tutarı hesapla
    const basePrice = basePricePerUnit * adet;
    const discountAmount = basePrice * discountRate;
    const totalPrice = basePrice - discountAmount;
    setToplamTutar(totalPrice);
  }, [paketSuresi, adet, prices]);

  // İndirim oranını hesaplayan fonksiyon (ilk formülden kopyalandı)
  function calculateDiscount(adet) {
    if (adet > 50) {
      return 0.30; // %50 indirim
    } else if (adet > 30 && adet <= 50) {
      return 0.30; // %40 indirim
    } else if (adet >= 10 && adet <= 30) {
      return 0.30; // %30 indirim
    } else if (adet >= 5 && adet < 10) {
      return 0.20; // %20 indirim
    } else {
      return 0; 
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Ödeme isteği gönder
    try {
      const token = localStorage.getItem('userToken'); // Kullanıcı token'ını al
      const response = await fetch(API_URLS.PAYMENT_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Yetkilendirme başlığı
        },
        body: JSON.stringify({
          userType,
          paketSuresi,
          adet,
          userId,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        window.location.href = data.paymentPageUrl;
      } else {
        setMessage('Ödeme başarısız: ' + data.message);
      }
    } catch (error) {
      console.error('Ödeme işlemi sırasında bir hata oluştu', error);
      setMessage('Ödeme işlemi sırasında bir hata oluştu.');
    }
  };

  // Fiyatlar yüklenirken göstermek için yüklenme durumu
  if (loading) {
    return (
      <Card>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Bakiye Yükle
          </Typography>
          <CircularProgress />
        </CardContent>
      </Card>
    );
  }

  // Fiyatları çekerken hata oluşursa
  if (priceError) {
    return (
      <Card>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Bakiye Yükle
          </Typography>
          <Typography variant="body1" color="error">
            {priceError}
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Bakiye Yükle
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {/* Paket Süresi Seçimi */}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="paket-suresi-label">Paket Süresi</InputLabel>
                <Select
                  labelId="paket-suresi-label"
                  value={paketSuresi}
                  onChange={(e) => setPaketSuresi(e.target.value)}
                  label="Paket Süresi"
                >
                  {Object.keys(prices).map((suresi) => (
                    <MenuItem key={suresi} value={Number(suresi)}>
                      {suresi} Aylık
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Adet Girişi */}
            <Grid item xs={12}>
              <TextField
                label="Adet"
                type="number"
                value={adet}
                onChange={(e) => {
                  const value = Math.min(Number(e.target.value), maxAdet);
                  setAdet(value);
                }}
                fullWidth
                inputProps={{ min: 1, max: maxAdet }}
              />
            </Grid>

            {/* İndirim Oranı ve Toplam Tutar */}
            <Grid item xs={12}>
              <Typography variant="body1">
                İndirim Oranı: %{indirimOrani * 100}
              </Typography>
              <Typography variant="body1">Toplam Tutar: {toplamTutar} TL</Typography>
            </Grid>

            {/* Ödeme Yap Butonu */}
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary" fullWidth>
                Ödeme Yap
              </Button>
            </Grid>
          </Grid>
        </form>

        {/* Hata veya Başarı Mesajları */}
        {message && (
          <Typography variant="body1" color="error" sx={{ mt: 2 }}>
            {message}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}

export default PaymentForm;
