import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faRedo,  } from '@fortawesome/free-solid-svg-icons';
import '../styles/harfIzgarasiStyle.css';


const GameControls = ({
  isPlaying,
  handlePlayPause,
  handleReset,

}) => {
 

  return (
<div className="game-buttons">
  <button className="game-button" onClick={handlePlayPause}>
    <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} /> {isPlaying ? 'Duraklat' : 'Başlat'}
  </button>
  <button className="game-button" onClick={handleReset}>
    <FontAwesomeIcon icon={faRedo} /> Yeniden Başlat
  </button>
</div>

  );
};

export default GameControls;
