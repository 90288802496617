import React from 'react';
import '../styles/silinenYaziStyle.css'; // <-- Dosya adı

const ProgressBar = ({ progress }) => {
  return (
    <div className="silinen-yazi-progress-bar-container"> {/* <-- YENİ CLASS */}
      <div
        className="silinen-yazi-progress-bar-fill" 
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );
};

export default ProgressBar;
