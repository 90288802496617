import React, { useEffect, useState } from 'react';
import {
  Typography,
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  IconButton,
  Tooltip,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Divider,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
} from '@mui/icons-material';
import axios from 'axios';
import { API_URLS } from '../../../../../config/config';

function BayilikBasvuru() {
  const [basvurular, setBasvurular] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedBasvuru, setSelectedBasvuru] = useState(null);

  // Detayları görüntüleme için state'ler
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [selectedViewBasvuru, setSelectedViewBasvuru] = useState(null);

  // Yetkilendirme Token'ını Al
  const token = localStorage.getItem('userToken');

  useEffect(() => {
    const fetchBasvurular = async () => {
      try {
        const response = await axios.get(`${API_URLS.BAYILIK_BASVURU_URL}/basvuru`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setBasvurular(response.data.data);
        setLoading(false);
      } catch (err) {
        console.error('Bayilik başvuruları alınırken bir hata oluştu:', err);
        setError('Bayilik başvuruları alınırken bir hata oluştu.');
        setLoading(false);
      }
    };

    fetchBasvurular();
  }, [token]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = (basvuru) => {
    setSelectedBasvuru(basvuru);
    setDeleteDialogOpen(true);
  };

  const confirmDelete = async () => {
    try {
      await axios.delete(`${API_URLS.BAYILIK_BASVURU_URL}/basvuru/${selectedBasvuru._id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setBasvurular((prev) => prev.filter((b) => b._id !== selectedBasvuru._id));
      setDeleteDialogOpen(false);
      setSelectedBasvuru(null);
    } catch (err) {
      console.error('Bayilik başvurusu silinirken bir hata oluştu:', err);
      setError('Bayilik başvurusu silinirken bir hata oluştu.');
      setDeleteDialogOpen(false);
      setSelectedBasvuru(null);
    }
  };

  const cancelDelete = () => {
    setDeleteDialogOpen(false);
    setSelectedBasvuru(null);
  };

  // Detayları görüntüleme işlemi için handler
  const handleView = (basvuru) => {
    setSelectedViewBasvuru(basvuru);
    setViewDialogOpen(true);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" mt={4}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" mt={4}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Bayilik Başvuruları
      </Typography>
      {basvurular.length === 0 ? (
        <Typography>No applications found.</Typography>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table aria-label="bayilik başvuruları tablosu">
              <TableHead>
                <TableRow>
                  <TableCell>Başvuru ID</TableCell>
                  <TableCell>Şirket Adı</TableCell>
                  <TableCell>İletişim Kişisi</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Telefon</TableCell>
                  <TableCell>Adres</TableCell>
                  <TableCell>Şehir</TableCell>
                  <TableCell>Web Sitesi</TableCell>
                  <TableCell>Ek Bilgi</TableCell>
                  <TableCell>Tarih</TableCell>
                  <TableCell align="center">İşlemler</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {basvurular
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((basvuru) => (
                    <TableRow
                      key={basvuru._id}
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell>{basvuru._id}</TableCell>
                      <TableCell>{basvuru.companyName}</TableCell>
                      <TableCell>{basvuru.contactPerson}</TableCell>
                      <TableCell>{basvuru.email}</TableCell>
                      <TableCell>{basvuru.phone}</TableCell>
                      <TableCell>{basvuru.address}</TableCell>
                      <TableCell>{basvuru.city}</TableCell>
                      <TableCell>
                        {basvuru.website ? (
                          <a href={basvuru.website} target="_blank" rel="noopener noreferrer">
                            {basvuru.website}
                          </a>
                        ) : (
                          'N/A'
                        )}
                      </TableCell>
                      <TableCell>{basvuru.additionalInfo || 'N/A'}</TableCell>
                      <TableCell>{new Date(basvuru.createdAt).toLocaleDateString()}</TableCell>
                      <TableCell align="center">
                        <Tooltip title="Detayları Görüntüle">
                          <IconButton color="primary" onClick={() => handleView(basvuru)}>
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Düzenle">
                          <IconButton
                            color="secondary"
                            onClick={() => alert('Düzenleme işlemi henüz eklenmedi.')}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Sil">
                          <IconButton color="error" onClick={() => handleDelete(basvuru)}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={basvurular.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />

          {/* Silme Onayı Dialog'u */}
          <Dialog open={deleteDialogOpen} onClose={cancelDelete}>
            <DialogTitle>Bayilik Başvurusunu Sil</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {`"${selectedBasvuru?.companyName}" adlı bayilik başvurusunu silmek istediğinize emin misiniz? Bu işlem geri alınamaz.`}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={cancelDelete} color="primary">
                İptal
              </Button>
              <Button onClick={confirmDelete} color="error">
                Sil
              </Button>
            </DialogActions>
          </Dialog>

          {/* Detayları Görüntüleme Dialog'u */}
          <Dialog
            open={viewDialogOpen}
            onClose={() => setViewDialogOpen(false)}
            fullWidth
            maxWidth="sm"
            PaperProps={{
              sx: {
                borderRadius: 3,
                padding: 2,
                background: 'linear-gradient(135deg, #f5f7fa, #c3cfe2)',
                boxShadow: '0px 4px 12px rgba(0,0,0,0.2)',
              },
            }}
          >
            <DialogTitle
              sx={{
                backgroundColor: '#3f51b5',
                color: '#fff',
                fontWeight: 'bold',
                borderRadius: 1,
                p: 2,
              }}
            >
              Bayilik Başvurusu Detayları
            </DialogTitle>
            <DialogContent dividers sx={{ backgroundColor: '#fff', p: 3 }}>
              {selectedViewBasvuru && (
                <Box sx={{ p: 2 }}>
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    <strong>Başvuru ID:</strong> {selectedViewBasvuru._id}
                  </Typography>
                  <Divider sx={{ mb: 1 }} />
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    <strong>Şirket Adı:</strong> {selectedViewBasvuru.companyName}
                  </Typography>
                  <Divider sx={{ mb: 1 }} />
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    <strong>İletişim Kişisi:</strong> {selectedViewBasvuru.contactPerson}
                  </Typography>
                  <Divider sx={{ mb: 1 }} />
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    <strong>Email:</strong> {selectedViewBasvuru.email}
                  </Typography>
                  <Divider sx={{ mb: 1 }} />
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    <strong>Telefon:</strong> {selectedViewBasvuru.phone}
                  </Typography>
                  <Divider sx={{ mb: 1 }} />
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    <strong>Adres:</strong> {selectedViewBasvuru.address}
                  </Typography>
                  <Divider sx={{ mb: 1 }} />
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    <strong>Şehir:</strong> {selectedViewBasvuru.city}
                  </Typography>
                  <Divider sx={{ mb: 1 }} />
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    <strong>Web Sitesi:</strong>{' '}
                    {selectedViewBasvuru.website ? (
                      <a
                        href={selectedViewBasvuru.website}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none', color: '#3f51b5' }}
                      >
                        {selectedViewBasvuru.website}
                      </a>
                    ) : (
                      'N/A'
                    )}
                  </Typography>
                  <Divider sx={{ mb: 1 }} />
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    <strong>Ek Bilgi:</strong> {selectedViewBasvuru.additionalInfo || 'N/A'}
                  </Typography>
                  <Divider sx={{ mb: 1 }} />
                  <Typography variant="body1">
                    <strong>Tarih:</strong>{' '}
                    {new Date(selectedViewBasvuru.createdAt).toLocaleDateString()}
                  </Typography>
                </Box>
              )}
            </DialogContent>
            <DialogActions sx={{ p: 2, backgroundColor: '#f5f7fa' }}>
              <Button onClick={() => setViewDialogOpen(false)} color="primary">
                Kapat
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Box>
  );
}

export default BayilikBasvuru;
