import React from 'react';
import { Card, CardContent, Typography, Divider } from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

function StudentInfoCard({ ogrenci }) {
  return (
    <Card
      sx={{
        boxShadow: 6,
        borderRadius: 3,
        backgroundImage: 'linear-gradient(to right, #ff512f, #dd2476)',
        color: '#fff',
        transition: 'transform 0.3s',
        '&:hover': {
          transform: 'scale(1.02)',
          boxShadow: 8,
        },
      }}
    >
      <CardContent>
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            color: '#fff',
          }}
        >
          <SchoolIcon />
          Öğrenci Bilgileri
        </Typography>
        <Divider sx={{ marginY: 2, backgroundColor: '#fff' }} />
        <Typography variant="body1" sx={{ mb: 1 }}>
          <EmailIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
          <strong>Email:</strong> {ogrenci.email}
        </Typography>
        <Typography variant="body1" sx={{ mb: 1 }}>
          <PhoneIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
          <strong>Telefon:</strong> {ogrenci.telefon}
        </Typography>
        <Typography variant="body1" sx={{ mb: 1 }}>
          <SchoolIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
          <strong>Okul:</strong> {ogrenci.okul_adi}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default StudentInfoCard;
