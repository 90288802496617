import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Button, Drawer, List, ListItem, ListItemIcon, ListItemText, Box, Menu, MenuItem, Avatar, Collapse, useMediaQuery
} from '@mui/material';
import { Menu as MenuIcon, Home as HomeIcon, Speed as SpeedIcon, FitnessCenter as FitnessCenterIcon, Assignment as AssignmentIcon, TrendingUp as TrendingUpIcon,
  Login as LoginIcon, PersonAdd as PersonAddIcon, AccountCircle as AccountCircleIcon, Close as CloseIcon, Logout as LogoutIcon, School as SchoolIcon,
  Article as ArticleIcon, ExpandLess, ExpandMore, Info as InfoIcon, ContactMail as ContactMailIcon, AssignmentInd as AssignmentIndIcon} from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import axios from '../../utils/axiosInstance';
import { API_URLS } from '../../config/config';
import useFetchStudentDataForHome from '../../hooks/ogrenci/useFetchStudentDataForHome';
import useFetchTeacherDataForHome from '../../hooks/ogretmen/useFetchTeacherDataForHome';
import Logo from '../../assets/images/logo/120.png';


function Header() {
  const isBelow1024 = useMediaQuery('(max-width: 1024px)');
  const layoutVariant = isBelow1024 ? 'mobile' : 'desktop';

  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleDrawerToggle = () => setDrawerOpen(!drawerOpen);

  // Profil menüsü
  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenuOpen = (e) => setAnchorEl(e.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  // Kurumsal menüsü
  const [kurumsalAnchorEl, setKurumsalAnchorEl] = useState(null);
  const handleKurumsalMenuOpen = (e) => setKurumsalAnchorEl(e.currentTarget);
  const handleKurumsalMenuClose = () => setKurumsalAnchorEl(null);

  // Drawer içi "Kurumsal/Bayilik" collapse
  const [drawerKurumsalOpen, setDrawerKurumsalOpen] = useState(false);
  const handleDrawerKurumsalToggle = () => setDrawerKurumsalOpen(!drawerKurumsalOpen);

  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [teacherData, setTeacherData] = useState(null);

  useFetchStudentDataForHome(setUserData);
  useFetchTeacherDataForHome(setTeacherData);

  // Logout
  const handleLogoutStudent = async () => {
    try {
      await axios.post(`${API_URLS.STUDENT_URL}/logout`);
      navigate('/ogrenci-giris');
    } catch (error) {
      console.error('Çıkış işlemi sırasında bir hata oluştu', error);
    }
  };

  const handleLogoutTeacher = async () => {
    try {
      await fetch(`${API_URLS.TEACHER_URL}logout`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'Content-Type': 'application/json',
        },
      });
      localStorage.removeItem('userToken');
      navigate('/ogretmen-giris');
    } catch (error) {
      console.error('Çıkış işlemi sırasında bir hata oluştu', error);
    }
  };

  const navigateToAkilliKurs = () => {
    navigate('/ogrenci-dashboard', { state: { selectedMenu: 'AkilliKurs' } });
  };
  const navigateToTeacherDashboard = () => {
    navigate('/ogretmen-dashboard');
  };

  const isHamburgerMenu = layoutVariant === 'mobile';
  const getDrawerWidth = () => (layoutVariant === 'mobile' ? '70vw' : '25vw');

  return (
    <HeaderContainer position="sticky" layoutVariant={layoutVariant}>
      <Toolbar>
        {isHamburgerMenu ? (
          <>
            <AnimatedIconButton edge="start" aria-label="menu" onClick={handleDrawerToggle}>
              <MenuIcon />
            </AnimatedIconButton>

            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <LogoContainer layoutVariant={layoutVariant}>
                <Link to="/">
                  <LogoImage layoutVariant={layoutVariant} src={Logo} alt="Okumaks Logo" />
                </Link>
              </LogoContainer>
            </Box>

            {(userData || teacherData) && (
              <AnimatedIconButton onClick={handleMenuOpen}>
                <AccountCircleIcon sx={{ fontSize: 30 }} />
              </AnimatedIconButton>
            )}

            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
              {userData && !teacherData && (
                <>
                  <MenuItem component={Link} to="/ogrenci-dashboard" onClick={handleMenuClose}>
                    Profilim/Ayarlar
                  </MenuItem>
                  <MenuItem onClick={handleLogoutStudent}>Çıkış Yap (Öğrenci)</MenuItem>
                </>
              )}
              {teacherData && (
                <>
                  <MenuItem component={Link} to="/ogretmen-dashboard" onClick={handleMenuClose}>
                    Öğretmen Profil/Ayarlar
                  </MenuItem>
                  <MenuItem onClick={handleLogoutTeacher}>Çıkış Yap (Öğretmen)</MenuItem>
                </>
              )}
            </Menu>

            {/* Drawer */}
            <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
              <Box sx={{ width: getDrawerWidth() }}>
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <Avatar sx={{ bgcolor: 'transparent' }}>
                        <CloseIcon onClick={handleDrawerToggle} />
                      </Avatar>
                    </ListItemIcon>
                  </ListItem>

                  <ListItem button component={Link} to="/" onClick={handleDrawerToggle}>
                    <ListItemIcon>
                      <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Anasayfa" />
                  </ListItem>

                  <ListItem button onClick={handleDrawerKurumsalToggle}>
                    <ListItemIcon>
                      <SchoolIcon />
                    </ListItemIcon>
                    <ListItemText primary="Bayilik Başvurusu" />
                    {drawerKurumsalOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={drawerKurumsalOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItem
                        button
                        component={Link}
                        to="/bayilik-basvuru"
                        onClick={handleDrawerToggle}
                        sx={{ pl: 4 }}
                      >
                        <ListItemIcon>
                          <AssignmentIndIcon />
                        </ListItemIcon>
                        <ListItemText primary="Bayilik Başvuru Formu" />
                      </ListItem>
                      <ListItem
                        button
                        component={Link}
                        to="/hakkimizda"
                        onClick={handleDrawerToggle}
                        sx={{ pl: 4 }}
                      >
                        <ListItemIcon>
                          <InfoIcon />
                        </ListItemIcon>
                        <ListItemText primary="Hakkımızda" />
                      </ListItem>
                      <ListItem
                        button
                        component={Link}
                        to="/iletisim"
                        onClick={handleDrawerToggle}
                        sx={{ pl: 4 }}
                      >
                        <ListItemIcon>
                          <ContactMailIcon />
                        </ListItemIcon>
                        <ListItemText primary="İletişim" />
                      </ListItem>
                    </List>
                  </Collapse>

                  <ListItem button component={Link} to="/hizli-okuma" onClick={handleDrawerToggle}>
                    <ListItemIcon>
                      <SpeedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Hızlı Okuma" />
                  </ListItem>
                  <ListItem button component={Link} to="/egsersizler" onClick={handleDrawerToggle}>
                    <ListItemIcon>
                      <FitnessCenterIcon />
                    </ListItemIcon>
                    <ListItemText primary="Egzersizler" />
                  </ListItem>
                  <ListItem button component={Link} to="/testler" onClick={handleDrawerToggle}>
                    <ListItemIcon>
                      <AssignmentIcon />
                    </ListItemIcon>
                    <ListItemText primary="Testler" />
                  </ListItem>
                  <ListItem
                    button
                    component={Link}
                    to="/seviye-tespit-sinav"
                    onClick={handleDrawerToggle}
                  >
                    <ListItemIcon>
                      <TrendingUpIcon />
                    </ListItemIcon>
                    <ListItemText primary="Hız Testi" />
                  </ListItem>
                  <ListItem button component={Link} to="/blog" onClick={handleDrawerToggle}>
                    <ListItemIcon>
                      <ArticleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Blog" />
                  </ListItem>

                  {/* Akıllı Kurs / Öğretmen Paneli */}
                  {userData && (
                    <ListItem
                      button
                      onClick={() => {
                        navigateToAkilliKurs();
                        handleDrawerToggle();
                      }}
                    >
                      <ListItemIcon>
                        <SchoolIcon />
                      </ListItemIcon>
                      <ListItemText primary="Akıllı Kurs (Öğrenci)" />
                    </ListItem>
                  )}
                  {teacherData && (
                    <ListItem
                      button
                      onClick={() => {
                        navigateToTeacherDashboard();
                        handleDrawerToggle();
                      }}
                    >
                      <ListItemIcon>
                        <SchoolIcon />
                      </ListItemIcon>
                      <ListItemText primary="Öğretmen Paneli" />
                    </ListItem>
                  )}

                  {/* Giriş/Çıkış */}
                  {!userData && !teacherData ? (
                    <>
                      <ListItem button component={Link} to="/login" onClick={handleDrawerToggle}>
                        <ListItemIcon>
                          <LoginIcon />
                        </ListItemIcon>
                        <ListItemText primary="Giriş Yap" />
                      </ListItem>
                      <ListItem
                        button
                        component={Link}
                        to="/register"
                        onClick={handleDrawerToggle}
                      >
                        <ListItemIcon>
                          <PersonAddIcon />
                        </ListItemIcon>
                        <ListItemText primary="Kayıt Ol" />
                      </ListItem>
                    </>
                  ) : (
                    <>
                      {userData && !teacherData && (
                        <>
                          <ListItem
                            button
                            component={Link}
                            to="/ogrenci-dashboard"
                            onClick={handleDrawerToggle}
                          >
                            <ListItemIcon>
                              <AccountCircleIcon />
                            </ListItemIcon>
                            <ListItemText primary="Profilim/Ayarlar" />
                          </ListItem>
                          <ListItem button onClick={handleLogoutStudent}>
                            <ListItemIcon>
                              <LogoutIcon />
                            </ListItemIcon>
                            <ListItemText primary="Çıkış Yap (Öğrenci)" />
                          </ListItem>
                        </>
                      )}
                      {teacherData && (
                        <>
                          <ListItem
                            button
                            component={Link}
                            to="/ogretmen-dashboard"
                            onClick={handleDrawerToggle}
                          >
                            <ListItemIcon>
                              <AccountCircleIcon />
                            </ListItemIcon>
                            <ListItemText primary="Öğretmen Profil/Ayarlar" />
                          </ListItem>
                          <ListItem button onClick={handleLogoutTeacher}>
                            <ListItemIcon>
                              <LogoutIcon />
                            </ListItemIcon>
                            <ListItemText primary="Çıkış Yap (Öğretmen)" />
                          </ListItem>
                        </>
                      )}
                    </>
                  )}
                </List>
              </Box>
            </Drawer>
          </>
        ) : (
          /* 1025px ve üzeri: Tam genişlikte menü */
          <>
            <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
              <LogoContainer layoutVariant={layoutVariant}>
                <Link to="/">
                  <LogoImage layoutVariant={layoutVariant} src={Logo} alt="Okumaks Logo" />
                </Link>
              </LogoContainer>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <StyledButton
                layoutVariant={layoutVariant}
                component={Link}
                to="/"
                startIcon={<HomeIcon />}
              >
                Anasayfa
              </StyledButton>

              <StyledButton
                layoutVariant={layoutVariant}
                aria-controls="kurumsal-menu"
                aria-haspopup="true"
                onClick={handleKurumsalMenuOpen}
                startIcon={<SchoolIcon />}
              >
                Kurumsal
              </StyledButton>
              <Menu
                id="kurumsal-menu"
                anchorEl={kurumsalAnchorEl}
                keepMounted
                open={Boolean(kurumsalAnchorEl)}
                onClose={handleKurumsalMenuClose}
              >
                <MenuItem component={Link} to="/bayilik-basvuru" onClick={handleKurumsalMenuClose}>
                  <AssignmentIndIcon sx={{ marginRight: 1 }} /> Bayilik Başvuru Formu
                </MenuItem>
                <MenuItem component={Link} to="/egitici-basvuru" onClick={handleKurumsalMenuClose}>
                  <AssignmentIndIcon sx={{ marginRight: 1 }} /> Eğitici Başvuru Formu
                </MenuItem>
                <MenuItem component={Link} to="/hakkimizda" onClick={handleKurumsalMenuClose}>
                  <InfoIcon sx={{ marginRight: 1 }} /> Hakkımızda
                </MenuItem>
                <MenuItem component={Link} to="/iletisim" onClick={handleKurumsalMenuClose}>
                  <ContactMailIcon sx={{ marginRight: 1 }} /> İletişim
                </MenuItem>
              </Menu>

              <StyledButton
                layoutVariant={layoutVariant}
                component={Link}
                to="/hizli-okuma"
                startIcon={<SpeedIcon />}
              >
                Hızlı Okuma
              </StyledButton>
              <StyledButton
                layoutVariant={layoutVariant}
                component={Link}
                to="/egsersizler"
                startIcon={<FitnessCenterIcon />}
              >
                Egzersizler
              </StyledButton>
              <StyledButton
                layoutVariant={layoutVariant}
                component={Link}
                to="/testler"
                startIcon={<AssignmentIcon />}
              >
                Testler
              </StyledButton>
              <StyledButton
                layoutVariant={layoutVariant}
                component={Link}
                to="/seviye-tespit-sinav"
                startIcon={<TrendingUpIcon />}
              >
                Hız Testi
              </StyledButton>
              <StyledButton
                layoutVariant={layoutVariant}
                component={Link}
                to="/blog"
                startIcon={<ArticleIcon />}
              >
                Blog
              </StyledButton>

              {userData && (
                <StyledButton
                  layoutVariant={layoutVariant}
                  onClick={navigateToAkilliKurs}
                  startIcon={<SchoolIcon />}
                >
                  Akıllı Kurs (Öğrenci)
                </StyledButton>
              )}
              {teacherData && (
                <StyledButton
                  layoutVariant={layoutVariant}
                  onClick={navigateToTeacherDashboard}
                  startIcon={<SchoolIcon />}
                >
                  Öğretmen Paneli
                </StyledButton>
              )}

              {!userData && !teacherData && (
                <>
                  <StyledButton
                    layoutVariant={layoutVariant}
                    component={Link}
                    to="/login"
                    startIcon={<LoginIcon />}
                  >
                    Giriş Yap
                  </StyledButton>
                  <StyledButton
                    layoutVariant={layoutVariant}
                    component={Link}
                    to="/register"
                    startIcon={<PersonAddIcon />}
                  >
                    Kayıt Ol
                  </StyledButton>
                </>
              )}

              {(userData || teacherData) && (
                <AnimatedIconButton onClick={handleMenuOpen}>
                  <AccountCircleIcon sx={{ fontSize: 30 }} />
                </AnimatedIconButton>
              )}

              <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                {userData && !teacherData && (
                  <>
                    <MenuItem component={Link} to="/ogrenci-dashboard" onClick={handleMenuClose}>
                      Profilim/Ayarlar
                    </MenuItem>
                    <MenuItem onClick={handleLogoutStudent}>Çıkış Yap (Öğrenci)</MenuItem>
                  </>
                )}
                {teacherData && (
                  <>
                    <MenuItem component={Link} to="/ogretmen-dashboard" onClick={handleMenuClose}>
                      Öğretmen Profil/Ayarlar
                    </MenuItem>
                    <MenuItem onClick={handleLogoutTeacher}>Çıkış Yap (Öğretmen)</MenuItem>
                  </>
                )}
              </Menu>
            </Box>
          </>
        )}
      </Toolbar>
    </HeaderContainer>
  );
}

export default Header;

/* ------------ STYLE KISMI ------------ */

const HeaderContainer = styled(AppBar)(({ layoutVariant }) => {
  /*
   * 1024px altı -> layoutVariant = 'mobile'
   * 1025px üstü -> layoutVariant = 'desktop'
   */
  // Daha da azaltılmış dikey padding:
  let paddingVal = layoutVariant === 'mobile' ? '0.5rem 0' : '0.2rem 0';

  return {
    background: 'linear-gradient(135deg, #0A192F 0%, #203A43 25%, #2C5364 50%, #47688E 75%, #6F9AB8 100%)',
    backdropFilter: 'blur(10px)',
    backgroundSize: '200% 200%',
    color: '#FFFFFF',
    transition: 'background 0.5s ease',
    padding: paddingVal,
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    '&:hover': {
      background: 'linear-gradient(135deg, #6F9AB8 0%, #47688E 25%, #2C5364 50%, #203A43 75%, #0A192F 100%)',
    },
  };
});

const AnimatedIconButton = styled(IconButton)(() => ({
  color: '#FFFFFF',
  '&:hover': {
    color: '#FFD700',
    transform: 'scale(1.1)',
    transition: 'transform 0.3s, color 0.3s',
  },
}));

const StyledButton = styled(Button)(({ layoutVariant }) => ({
  /*
   * Buton font-range: clamp(0.6rem, 0.6vw, 0.8rem)
   * margin: 0 (butonları tamamen bitiştirir)
   */
  fontSize: 'clamp(0.6rem, 0.6vw, 0.8rem)',
  color: '#FFFFFF',
  fontWeight: 'bold',
  margin: 0,
  fontFamily: 'Roboto, sans-serif',
  whiteSpace: 'nowrap',
  '&:hover': {
    color: '#FFD700',
    transform: 'scale(1.03)',
    transition: 'transform 0.3s, color 0.3s',
  },
}));

const LogoImage = styled('img')(({ layoutVariant }) => ({
  // Logo boyutunu da biraz daha küçülttük
  width: layoutVariant === 'mobile'
    ? 'clamp(70px, 18vw, 140px)'
    : 'clamp(110px, 10vw, 150px)',
  height: 'auto',
}));

const LogoContainer = styled(Box)(({ layoutVariant }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  padding: layoutVariant === 'mobile' ? '0.3rem' : '0.2rem',
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
}));
