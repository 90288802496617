import React from 'react';
import '../styles/kelimeBulmaca.css'; 

const SettingsModal = ({ onClose, theme, handleThemeChange }) => {
  return (
    <div className="settings-modal-backdrop">
      <div className="settings-modal-content">
        {/* Modal Kapatma Butonu */}
        <button
          className="settings-modal-close-button"
          onClick={onClose}
          aria-label="Kapat"
        >
          X
        </button>

        {/* Modal Başlık */}
        <h2 className="settings-modal-title">Ayarlar</h2>

        {/* SADECE TEMA SEÇİMİ */}
        <div className="settings-form-group">
          <label className="settings-form-label" htmlFor="themeSelect">
            Tema Seç:
          </label>
          <select
            id="themeSelect"
            className="settings-form-select"
            value={theme}
            onChange={handleThemeChange}
          >
            <option value="default">Varsayılan (Yeşil Desen)</option>
            <option value="dark">Koyu</option>
            <option value="light">Açık</option>
            <option value="blue">Mavi</option>
            <option value="green">Yeşil</option>
            <option value="red">Kırmızı</option>
            <option value="purple">Mor</option>
            <option value="orange">Turuncu</option>
            <option value="pink">Pembe</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default SettingsModal;
