import React from 'react';
import '../styles/cumleleriOkuStyle.css';

const SpeedControl = ({ speed, handleSpeedChange }) => {
  const incrementSpeed = () => {
    const newSpeed = Math.min(speed + 0.1, 4);
    handleSpeedChange({ target: { value: newSpeed }});
  };

  const decrementSpeed = () => {
    const newSpeed = Math.max(speed - 0.1, 0.1);
    handleSpeedChange({ target: { value: newSpeed }});
  };

  return (
    <div className="speed-control-container">
      <div className="speed-label">Hız Ayarı (sn)</div>
      <div className="speed-display">
        <button className="speed-button" onClick={decrementSpeed}>-</button>
        <div className="speed-value">{speed.toFixed(1)}</div>
        <button className="speed-button" onClick={incrementSpeed}>+</button>
      </div>
  
    </div>
  );
};

export default SpeedControl;
