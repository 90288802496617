import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faRedo } from '@fortawesome/free-solid-svg-icons';
import '../styles/KelimeTamamlamaOyunu.css';

const GameDisplay = ({
  isPlaying,
  handlePlayPause,
  handleReset,
  timeRemaining,
  paragraph,
  getParagraphWithBlanks,
  options,
  userSelections,
  handleWordSelect,
  handleBlankClick,


}) => {
  // Süreyi mm:ss formatında göstermek isterseniz
  const formatTime = (seconds) => {
    if (seconds < 0) seconds = 0;
    const minutes = Math.floor(seconds / 60).toString().padStart(2, '0');
    const sec = (seconds % 60).toString().padStart(2, '0');
    return `${minutes}:${sec}`;
  };

  return (
    <>
   
     {/* 2) Kalan Süre */}
     <div className="timer">
        <span>Kalan Süre: {formatTime(timeRemaining)}</span>
      </div>
      <div className="game-buttons">
      <button className="game-button" onClick={handlePlayPause}>
        <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} /> {isPlaying ? 'Duraklat' : 'Başlat'}
      </button>
      <button className="game-button" onClick={handleReset}>
        <FontAwesomeIcon icon={faRedo} /> Yeniden Başlat
      </button>
    </div>    

      {/* 3) Seçenek Kutucukları */}
      <div className="kt-options-container">
        {options.map((word, index) => {
          const isDisabled = Object.values(userSelections.filledWords).some(
            (filled) => filled.word === word && filled.isCorrect
          );
          return (
            <button
              key={index}
              className={`btn ${
                userSelections.selectedWord === word ? 'btn-primary' : 'btn-outline-primary'
              }`}
              onClick={() => handleWordSelect(word)}
              disabled={!isPlaying || isDisabled}
            >
              {word}
            </button>
          );
        })}
      </div>

      {/* 4) Paragraf İçindeki Boşluklar */}
      <div className="kt-game-display">
        {paragraph ? getParagraphWithBlanks() : 'Paragraf yükleniyor...'}
      </div>
    </>
  );
};

export default GameDisplay;
