import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Grid, Snackbar, Alert, CircularProgress,} from '@mui/material';
import { styled } from '@mui/material/styles';
import { API_URLS } from '../../../config/config';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Header from '../Header';
import Footer from '../Footer';

// 1) react-responsive kullanımı
import { useMediaQuery as useOrientationQuery } from 'react-responsive';

// Stil Tanımlaması: Dikey/yatay mod farkı
const FormContainer = styled(Container)(({ isPortrait }) => ({
  padding: isPortrait ? '16px' : '24px',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  borderRadius: '8px',
  boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
  marginTop: isPortrait ? '24px' : '32px',
  marginBottom: isPortrait ? '24px' : '32px',
}));

// Yup doğrulama şeması: Eğitici formuna uygun alanlar
const validationSchema = Yup.object().shape({
  fullName: Yup.string().required('Ad Soyad gerekli'),
  graduation: Yup.string().required('Diploma/Derece bilgisi gerekli'),
  universityName: Yup.string().required('Üniversite adı gerekli'),
  branch: Yup.string().required('Bölüm bilgisi gerekli'),
  city: Yup.string().required('Şehir gerekli'),
  district: Yup.string().required('İlçe gerekli'),
  phoneNumber: Yup.string().required('Telefon numarası gerekli'),
  email: Yup.string().email('Geçersiz Email').required('Email gerekli'),
});

function EgiticiFormu() {
  const [submitStatus, setSubmitStatus] = useState({
    loading: false,
    success: false,
    error: '',
  });

  // Orientation sorgusu
  const isPortrait = useOrientationQuery({ query: '(orientation: portrait)' });

  // Form gönderimi: API isteği
  const handleSubmit = async (values, { resetForm }) => {
    setSubmitStatus({ loading: true, success: false, error: '' });
    try {
      // API_URLS.EGITICI_URL; ilgili URL'nin config dosyanızda tanımlı olduğundan emin olun.
      const response = await fetch(`${API_URLS.EGITICI_BASVURU_URL}/basvuru`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(values),
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Eğitici oluşturulurken hata oluştu');
      }
      setSubmitStatus({ loading: false, success: true, error: '' });
      resetForm();
    } catch (error) {
      setSubmitStatus({
        loading: false,
        success: false,
        error: error.message,
      });
    }
  };

  const handleCloseSnackbar = () => {
    setSubmitStatus({ ...submitStatus, success: false, error: '' });
  };

  return (
    <>
      <Header />

      <FormContainer maxWidth="md" isPortrait={isPortrait}>
        <Typography variant="h4" gutterBottom align="center" color="#FFD700">
          Eğitici Oluşturma Formu
        </Typography>
        <Formik
          initialValues={{
            fullName: '',
            graduation: '',
            universityName: '',
            branch: '',
            city: '',
            district: '',
            phoneNumber: '',
            email: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, handleChange, values }) => (
            <Form>
              <Grid container spacing={3}>
                {/* Ad Soyad */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    label="Ad Soyad"
                    name="fullName"
                    value={values.fullName}
                    onChange={handleChange}
                    error={touched.fullName && Boolean(errors.fullName)}
                    helperText={touched.fullName && errors.fullName}
                  />
                </Grid>
                {/* Diploma/Derece */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    label="Diploma/Derece"
                    name="graduation"
                    value={values.graduation}
                    onChange={handleChange}
                    error={touched.graduation && Boolean(errors.graduation)}
                    helperText={touched.graduation && errors.graduation}
                  />
                </Grid>
                {/* Üniversite Adı */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    label="Üniversite Adı"
                    name="universityName"
                    value={values.universityName}
                    onChange={handleChange}
                    error={touched.universityName && Boolean(errors.universityName)}
                    helperText={touched.universityName && errors.universityName}
                  />
                </Grid>
                {/* Bölüm */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    label="Bölüm"
                    name="branch"
                    value={values.branch}
                    onChange={handleChange}
                    error={touched.branch && Boolean(errors.branch)}
                    helperText={touched.branch && errors.branch}
                  />
                </Grid>
                {/* Şehir */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    label="Şehir"
                    name="city"
                    value={values.city}
                    onChange={handleChange}
                    error={touched.city && Boolean(errors.city)}
                    helperText={touched.city && errors.city}
                  />
                </Grid>
                {/* İlçe */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    label="İlçe"
                    name="district"
                    value={values.district}
                    onChange={handleChange}
                    error={touched.district && Boolean(errors.district)}
                    helperText={touched.district && errors.district}
                  />
                </Grid>
                {/* Telefon Numarası */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    label="Telefon Numarası"
                    name="phoneNumber"
                    value={values.phoneNumber}
                    onChange={handleChange}
                    error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                    helperText={touched.phoneNumber && errors.phoneNumber}
                  />
                </Grid>
                {/* Email */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    label="Email"
                    name="email"
                    type="email"
                    value={values.email}
                    onChange={handleChange}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Grid>
                {/* Submit Butonu */}
                <Grid item xs={12} align="center">
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={submitStatus.loading}
                    startIcon={submitStatus.loading && <CircularProgress size={20} />}
                    sx={{
                      backgroundColor: '#FFD700',
                      color: '#0A192F',
                      '&:hover': {
                        backgroundColor: '#e6c200',
                      },
                      paddingX: 4,
                      paddingY: 1.5,
                    }}
                  >
                    {submitStatus.loading ? 'Gönderiliyor...' : 'Eğitici Oluştur'}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>

        {/* Başarı Snackbar */}
        <Snackbar
          open={submitStatus.success}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
            Eğitici başarıyla oluşturuldu!
          </Alert>
        </Snackbar>

        {/* Hata Snackbar */}
        <Snackbar
          open={Boolean(submitStatus.error)}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            {submitStatus.error}
          </Alert>
        </Snackbar>
      </FormContainer>

      <Footer />
    </>
  );
}

export default EgiticiFormu;
