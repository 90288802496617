import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faTimes } from '@fortawesome/free-solid-svg-icons';

const GameOverModal = ({ score, onRestart, userData, onClose }) => {
  const navigate = useNavigate();

  // Kayıt ol / giriş yap sayfasına yönlendirme fonksiyonu
  const handleLoginRedirect = () => {
    navigate('/register');
  };

  // ESC tuşunu dinleyerek modal'ı kapatma
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [onClose]);

  const isActiveUser = userData && userData.status !== 'inactive';

  return (
    <div className="dot-tracking-modal-backdrop" onClick={onClose} aria-modal="true" role="dialog">
      <div className="dot-tracking-modal-content" onClick={(e) => e.stopPropagation()}>
        {/* Kapatma Butonu */}
        <button className="dot-tracking-close-button" onClick={onClose} aria-label="Close Modal">
          <FontAwesomeIcon icon={faTimes} />
        </button>

        <h2 className="dot-tracking-modal-title">Bitti!</h2>

        {isActiveUser ? (
          <>
            <p className="dot-tracking-modal-score">Toplam Skorunuz: {Math.floor(score)}</p>
            <div className="dot-tracking-button-group">
              <button className="dot-tracking-btn dot-tracking-btn-outline" onClick={onClose}>
                Bitti
              </button>
              <button className="dot-tracking-btn dot-tracking-btn-secondary" onClick={() => navigate(-1)}>
                <FontAwesomeIcon icon={faArrowLeft} /> Geri Dön
              </button>
            </div>
          </>
        ) : (
          <>
            <p className="dot-tracking-modal-message">Giriş yapmadığınız için skoru göremiyorsunuz.</p>
            <div className="dot-tracking-button-group">
              <button className="dot-tracking-btn dot-tracking-btn-primary" onClick={handleLoginRedirect}>
                Kayıt Ol
              </button>
              <button className="dot-tracking-btn dot-tracking-btn-secondary" onClick={() => navigate(-1)}>
                <FontAwesomeIcon icon={faArrowLeft} /> Geri Dön
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default GameOverModal;
