import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlay,
  faPause,
  faRedo,
  faMinus,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import '../styles/nesneyiTakipEt.css';

export default function GameControls({

  speedFactor,
  handleSpeedIncrease,
  handleSpeedDecrease,
  isPlaying,
  setIsPlaying,
  resetGame,
  shapeSides,
  increaseSides,
  decreaseSides,
}) {
  return (
    <div className="nesneyi-takip-et-game-controls">
      {/* Hız Ayarı */}
      <div className="speed-control-container">
  <div className="speed-label">Hız Ayarı</div>
  <div className="speed-display">
    <button className="speed-button" onClick={handleSpeedDecrease} aria-label="Hızı Azalt">
      <FontAwesomeIcon icon={faMinus} />
    </button>
    <div className="speed-value">
      {speedFactor.toFixed(1)}
    </div>
    <button className="speed-button" onClick={handleSpeedIncrease} aria-label="Hızı Arttır">
      <FontAwesomeIcon icon={faPlus} />
    </button>
  </div>
</div>


      {/* Köşe Sayısı Kontrolleri (stage varsa kilitli) */}
      {increaseSides && decreaseSides ? (
        <div className="sides-control">
          <button onClick={decreaseSides}>
            <FontAwesomeIcon icon={faMinus} /> Köşe
          </button>
          <span>{shapeSides}</span>
          <button onClick={increaseSides}>
            <FontAwesomeIcon icon={faPlus} /> Köşe
          </button>
        </div>
      ) : (
        <span>Köşe: {shapeSides} (Kilitli)</span>
      )}

      {/* Başlat/Duraklat ve Yeniden Başlat */}
      <button onClick={() => setIsPlaying(!isPlaying)}>
        <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />{' '}
        {isPlaying ? 'Duraklat' : 'Başlat'}
      </button>
      <button onClick={resetGame}>
        <FontAwesomeIcon icon={faRedo} /> Yeniden Başlat
      </button>
    </div>
  );
}
