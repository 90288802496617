import React from 'react';
import { Container, Typography, Box, Stack, Card, CardActionArea, CardContent } from '@mui/material';
import Header from '../home/components/Header';
import Footer from '../home/components/Footer';
import { useNavigate } from 'react-router-dom';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import EditIcon from '@mui/icons-material/Edit';
import GridOnIcon from '@mui/icons-material/GridOn';
import VisibilityIcon from '@mui/icons-material/Visibility';

function HizliOku() {
  const navigate = useNavigate();

  const menuItems = [
    {
      title: 'Silinen Yazılar',
      description: 'Vurgulanan kelime veya kelime gruplarına ayak uydurun',
      path: '/silinen-yazilar',
      icon: <TextFieldsIcon sx={{ fontSize: 60, color: '#fff' }} />,
    },
    {
      title: 'Rastgele Kelimeler',
      description: 'Ekranda beliren kelime veya kelime gruplarına ayak uydurun',
      path: '/rastgele-kelime',
      icon: <TextFieldsIcon sx={{ fontSize: 60, color: '#fff' }} />,
    },
    {
      title: 'Kayan Yazılar 1',
      description:
        'Sıra ile gelen kelime veya kelime gruplarına ayak uydurun. Bu çalışma okumanıza tempo kazandıracaktır.',
      path: '/kayan-yazilar',
      icon: <TextFieldsIcon sx={{ fontSize: 60, color: '#fff' }} />,
    },
    {
      title: 'Kayan Yazılar 2',
      description:
        'Sıra ile ikişer gelen kelime veya kelime gruplarına ayak uydurun. Bu çalışma okumanıza tempo kazandıracaktır.',
      path: '/kayan-yazilar-ikiser',
      icon: <TextFieldsIcon sx={{ fontSize: 60, color: '#fff' }} />,
    },
    {
      title: 'Kayan Yazılar 3',
      description:
        'Sıra ile üçer gelen kelime veya kelime gruplarına ayak uydurun. Bu çalışma okumanıza tempo kazandıracaktır.',
      path: '/kayan-yazilar-ucer',
      icon: <TextFieldsIcon sx={{ fontSize: 60, color: '#fff' }} />,
    },
    {
      title: 'Kelime Bulma ve Seçme',
      description:
        'Bu çalışma, kullanıcının hızlı okuma ve dikkat becerilerini geliştirmeyi amaçlar.',
      path: '/kelime-secme-ve-bulma-oyunu',
      icon: <FindInPageIcon sx={{ fontSize: 60, color: '#fff' }} />,
    },
    {
      title: 'Kelime Tamamlama',
      description:
        'Belirli bir süre içinde bir paragraftaki eksik kelimeleri doğru seçeneklerden seçerek tamamlamanızı gerektiren bir çalışmadır.',
      path: '/kelime-tamamlama-oyunu',
      icon: <EditIcon sx={{ fontSize: 60, color: '#fff' }} />,
    },
    {
      title: 'Harf Izgara',
      description:
        'Bu çalışma, belirli bir süre içinde verilen hedef harfi ızgara üzerindeki harfler arasından bulmanızı gerektirir.',
      path: '/harf-izgara-oyunu',
      icon: <GridOnIcon sx={{ fontSize: 60, color: '#fff' }} />,
    },
    {
      title: 'Takistoskopik Okuma',
      description:
        'Ekranda beliren kelime veya kelime gruplarına ayak uydurun. Bu çalışma okumanıza tempo kazandıracaktır.',
      path: '/takistoskopik-okuma-oyunu',
      icon: <VisibilityIcon sx={{ fontSize: 60, color: '#fff' }} />,
    },
  ];

  const handleCardClick = (path) => {
    navigate(path);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header />
      
      {/* Arka plan için teal tonları ve desenli yapı */}
      <div className="bg-main">
       <Box
               component="main"
               sx={{
                 flexGrow: 1,
                 py: 5,
                 px: 2,
                 display: 'flex',
                 justifyContent: 'center',
                 alignItems: 'center',     
               }}
             >
               <Container
              className='game-container-bg'
                 maxWidth={false} 
                 sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  borderRadius: '12px',
                
                  boxShadow: '0 4px 12px rgba(0,0,0,0.3)',
                  p: { xs: 2, sm: 4 },
                  maxWidth: '1024px',
                  margin: '0 auto',
                  width: '100%',
                  color: '#f9f9f9',
                }}
               >
            <Typography
              variant="h4"
              component="h1"
              sx={{
                mb: 4,
                fontWeight: 'bold',
                color: '#ffdd57', 
              }}
              align="center"
            >
              Hızlı Okuma Uygulamaları
            </Typography>
            <Box
              sx={{
                maxHeight: '60vh',
                overflowY: 'auto',
                width: '100%',
                '&::-webkit-scrollbar': {
                  width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                  background: '#3D8D7A',
                  borderRadius: '10px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#ffdd57',
                  borderRadius: '10px',
                },
              }}
            >
              <Stack
                direction="column"
                spacing={3}
                sx={{ width: '100%' }}
              >
                {menuItems.map((item, index) => (
                  <Card
                    key={index}
                    sx={{
                      borderRadius: '15px',
                      backgroundColor: 'rgba(0, 0, 0, 0.2)',
                      boxShadow: '0 4px 12px rgba(0,0,0,0.3)',
                      transition: 'transform 0.3s, box-shadow 0.3s',
                      '&:hover': {
                        transform: 'translateY(-5px)',
                        boxShadow: '0 8px 20px rgba(0,0,0,0.5)',
                      },
                    }}
                  >
                    <CardActionArea
                      onClick={() => handleCardClick(item.path)}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Box
                        sx={{
                          p: 2,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          background: '#00796B', 
                          borderRadius: '15px 0 0 15px',
                          minWidth: '100px',
                        }}
                      >
                        {item.icon}
                      </Box>
                      <CardContent sx={{ flexGrow: 1, padding: '16px' }}>
                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: '600',
                            color: '#ffdd57', 
                          }}
                        >
                          {item.title}
                        </Typography>
                        <Typography variant="body2"  
                        sx={{
                        color: '#fff',
                        mt: 1,
                        fontWeight: '700',
                        textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
                      }}>
                          {item.description}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                ))}
              </Stack>
            </Box>
          </Container>
        </Box>
      </div>
      <Footer />
    </Box>
  );
}

export default HizliOku;
