// GameHeader.js

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faHome } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import '../styles/rotatingRectangleGame.css'; 

const GameHeader = ({ stage, handleReturnToPreviousStage }) => {
  const navigate = useNavigate();
  const handleBackButton = () => {
    if (stage) {
      handleReturnToPreviousStage();
    } else {
      navigate(-1);
    }
  };

  const backButtonLabel = stage ? 'Önceki Aşamaya Dön' : 'Geri Dön'; 


  return (
    <div className="header-buttons">
      <button
                className="header-button"
                onClick={handleBackButton}
                aria-label={backButtonLabel}
              >
                <FontAwesomeIcon icon={faArrowLeft} /> {backButtonLabel}
              </button>
      <button className="header-button" onClick={() => navigate('/')}>
        <FontAwesomeIcon icon={faHome} /> Ana Sayfa
      </button>
    
    </div>
  );
};

export default GameHeader;
