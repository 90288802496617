// HeaderBanner.js
import React from 'react';
import { Box, Card, ThemeProvider, createTheme, Typography} from '@mui/material';
import { styled, keyframes } from '@mui/system';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useMediaQuery as useOrientationQuery } from 'react-responsive';

const theme = createTheme({
  palette: {
    primary: {
      main: '#00796B',
    },
    secondary: {
      main: '#4DB6AC',
    },
    background: {
      default: '#ede7f6',
      paper: '#fff',
    },
  },
});

// Animasyon tanımları (aynen durabilir)
const fadeIn = keyframes`
  0% { opacity: 0; transform: translateY(20px);}
  100% { opacity: 1; transform: translateY(0);}
`;

const scaleCycle = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
`;

const floatArrows = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-6px); }
  100% { transform: translateY(0); }
`;

const sparkle = keyframes`
  0% { opacity: 0; transform: scale(0.5); }
  50% { opacity: 1; transform: scale(1.2); }
  100% { opacity: 0; transform: scale(0.5); }
`;

const gradientShift = keyframes`
  0% { background: linear-gradient(135deg, #00e676, #76ff03); }
  33% { background: linear-gradient(135deg, #ffa726, #ffca28); }
  66% { background: linear-gradient(135deg, #ff1744, #f44336); }
  100% { background: linear-gradient(135deg, #00e676, #76ff03); }
`;

/**
 * 2) AnimatedText bileşenini portrait / landscape'e göre
 *    fontSize koşullandırıyoruz.
 */
const AnimatedText = styled(Typography)(({ isPortrait }) => ({
  fontFamily: 'Montserrat, sans-serif',
  fontWeight: 700,
  lineHeight: 1.3,
  textAlign: 'center',
  color: '#fff',
  animation: `${fadeIn} 2s ease-out forwards`,
  // Dikey modda biraz daha küçük font, yatayda daha büyük
  fontSize: isPortrait ? '2.4rem' : '3rem',
}));

const ArrowIcon = styled(ArrowForwardIosIcon)(() => ({
  position: 'absolute',
  color: '#ffffff88',
  fontSize: '2rem',
  animation: `${floatArrows} 3s ease-in-out infinite`,
}));

/**
 * 3) StyledCard bileşenine de portrait / landscape'e göre
 *    maxWidth, padding vb. değiştiriyoruz.
 */
const StyledCard = styled(Card)(({ isPortrait }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // Dikey modda maxWidth = 80vw, Yatay modda = 60vw
  maxWidth: isPortrait ? '80vw' : '60vw',
  width: '90%',
  backdropFilter: 'blur(5px)',
  borderRadius: '32px',
  // Dikey modda padding biraz daha fazla
  padding: isPortrait ? '6vh 2vw' : '4vh 2vw',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.5)',
  textAlign: 'center',
  color: '#fff',
  overflow: 'hidden',
  animation: `${gradientShift} 12s infinite ease-in-out`,

  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '10px',
    backgroundColor: 'rgba(255,255,255,0.3)',
    zIndex: 1,
  },
}));

const SparkleContainer = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  pointerEvents: 'none',
  overflow: 'hidden',
});

const SparkleElement = styled('div')({
  position: 'absolute',
  width: '10px',
  height: '10px',
  backgroundColor: '#fff',
  borderRadius: '50%',
  boxShadow: `
    0 0 10px 2px rgba(255,255,255,0.7),
    0 0 20px 4px rgba(255,255,255,0.4),
    0 0 30px 6px rgba(255,255,255,0.1)
  `,
  animation: `${sparkle} 2s infinite ease-in-out`,
});

/**
 * 4) Outer Box'a da height'i vw/vh bazlı verelim.
 *    Dikey modda height: 60vh, yatay modda 80vh gibi.
 */
const StyledOuterBox = styled(Box)(({ isPortrait }) => ({
  width: '100%',
  height: isPortrait ? '60vh' : '80vh',
  position: 'relative',
  overflow: 'hidden',
  boxShadow: '-3px 8px 23px -2px rgba(0,0,0,0.75)',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundImage: 'url("/images/header.webp")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center -100px',
    zIndex: -1,
  },
}));

const HeaderBanner = () => {
  // 5) Orientation yakalama
  const isPortrait = useOrientationQuery({ query: '(orientation: portrait)' });

  return (
    <ThemeProvider theme={theme}>
      {/* Outer Box'a isPortrait prop'u geçiyoruz */}
      <StyledOuterBox isPortrait={isPortrait}>
        {/* StyledCard'a da isPortrait prop'u veriyoruz */}
        <StyledCard isPortrait={isPortrait}>
          <ArrowIcon sx={{ top: '20%', left: '10%', transform: 'rotate(-45deg)' }} />
          <ArrowIcon sx={{ bottom: '20%', right: '10%', transform: 'rotate(135deg)' }} />

          {/* AnimatedText'e prop vererek fontSize'i oryantasyona göre yönetiyoruz */}
          <AnimatedText isPortrait={isPortrait} variant="h2">
            Hızlı Oku, Derin Anla
          </AnimatedText>

          <Typography
            variant="h6"
            sx={{
              marginTop: '24px',
              color: '#ffffff',
              fontFamily: 'Montserrat, sans-serif',
              fontWeight: 400,
              // Dikey/yatay modda fontSize farkı:
              fontSize: isPortrait ? '1.6rem' : '1.8rem',
            }}
          >
            Düşüncelerini Besle, Bilgiyi İçselleştir, Başarıyı Yakala.
          </Typography>

          <SparkleContainer>
            <SparkleElement style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
            <SparkleElement style={{ top: '20%', left: '30%' }} />
            <SparkleElement style={{ bottom: '25%', right: '40%' }} />
            <SparkleElement style={{ top: '10%', right: '20%' }} />
            <SparkleElement style={{ bottom: '10%', left: '70%' }} />
            <SparkleElement style={{ top: '30%', right: '10%' }} />
          </SparkleContainer>
        </StyledCard>
      </StyledOuterBox>
    </ThemeProvider>
  );
};

export default HeaderBanner;
