import React from 'react';

function SpeedControl({ speed, setSpeed }) {
  // Hız artışı ve azalışındaki adımı 0.1 olarak koruyoruz (isterseniz 0.05 yapabilirsiniz).
  const handleSpeedIncrease = () => {
    setSpeed((prev) => parseFloat((prev + 0.1).toFixed(1)));
  };

  const handleSpeedDecrease = () => {
    setSpeed((prev) => {
      const nextValue = prev - 0.1;
      return nextValue < 0.1 ? 0.1 : parseFloat(nextValue.toFixed(1));
    });
  };

  return (
    <div className="zigzag-speed-control-container">
      <div className="zigzag-speed-label">Hız Ayarı</div>
      <div className="zigzag-speed-display">
        <button className="zigzag-speed-button" onClick={handleSpeedDecrease}>-</button>
        <div className="zigzag-speed-value">{speed.toFixed(1)}</div>
        <button className="zigzag-speed-button" onClick={handleSpeedIncrease}>+</button>
      </div>
      <div className="zigzag-speed-instructions">
      Hızı 0.1 başlayarak ile hızı artırabilirsiniz.
      </div>
    </div>
  );
}

export default SpeedControl;
