import React from 'react';
import '../styles/rasgeleKelimelerStyle.css';

const SettingsModalRandomWords = ({
  onClose,
  difficulty,
  handleDifficultyChange,
  paragraphsList,
  selectedParagraphIndex,
  handleParagraphChange,
  theme,
  font,
  fontSize,
  setTheme,
  setFont,
  setFontSize,
}) => {
  // Yardımcı: Paragraf kelime sayısı
  const getWordCount = (htmlContent) => {
    if (!htmlContent) return 0;
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent;
    const textContent = tempDiv.textContent || tempDiv.innerText || '';
    const words = textContent.trim().split(/\s+/);
    return words.filter((w) => w).length;
  };

  return (
    <div className="settings-modal-backdrop">
      <div className="settings-modal-content">
        <button
          className="settings-modal-close-button"
          onClick={onClose}
          aria-label="Kapat"
        >
          X
        </button>
        <h2 className="settings-modal-title">Ayarlar</h2>

        {/* Zorluk Seç */}
        <div className="settings-form-group">
          <label className="settings-form-label" htmlFor="difficultySelect">
            Zorluk Seç:
          </label>
          <select
            id="difficultySelect"
            className="settings-form-select"
            value={difficulty}
            onChange={handleDifficultyChange}
          >
            <option value="easy">Kolay</option>
            <option value="medium">Orta</option>
            <option value="hard">Zor</option>
          </select>
        </div>

        {/* Paragraf Seç */}
        <div className="settings-form-group">
          <label className="settings-form-label" htmlFor="paragraphSelect">
            Paragraf Seç:
          </label>
          <select
            id="paragraphSelect"
            className="settings-form-select"
            value={selectedParagraphIndex}
            onChange={handleParagraphChange}
          >
            {paragraphsList.map((p, index) => {
              const wordCount = getWordCount(p.content);
              return (
                <option key={index} value={index}>
                  {p.title} - {wordCount} kelime
                </option>
              );
            })}
          </select>
        </div>

        {/* Tema Seç */}
        <div className="settings-form-group">
          <label className="settings-form-label" htmlFor="themeSelect">
            Tema Seç:
          </label>
          <select
            id="themeSelect"
            className="settings-form-select"
            value={theme}
            onChange={(e) => setTheme(e.target.value)}
          >
            <option value="default">Varsayılan (Açık)</option>
            <option value="tomer">Tömer</option>            
            <option value="dark">Koyu</option>
            <option value="light">Açık</option>
            <option value="blue">Mavi</option>
            <option value="green">Yeşil</option>
            <option value="red">Kırmızı</option>
            <option value="purple">Mor</option>
            <option value="orange">Turuncu</option>
            <option value="pink">Pembe</option>
          </select>
        </div>

        {/* Yazı Tipi Seç */}
        <div className="settings-form-group">
          <label className="settings-form-label" htmlFor="fontSelect">
            Yazı Tipi:
          </label>
          <select
            id="fontSelect"
            className="settings-form-select"
            value={font}
            onChange={(e) => setFont(e.target.value)}
          >
            <option value="Arial">Arial</option>
            <option value="Courier New">Courier New</option>
            <option value="Poppins">Poppins</option>
            <option value="Orbitron">Orbitron</option>
            <option value="Roboto">Roboto</option>
          </select>
        </div>

        {/* Font Boyutu */}
        <div className="settings-form-group">
          <label className="settings-form-label" htmlFor="fontSizeSelect">
            Yazı Boyutu:
          </label>
          <select
            id="fontSizeSelect"
            className="settings-form-select"
            value={fontSize}
            onChange={(e) => setFontSize(e.target.value)}
          >
            <option value="1.6rem">Küçük</option>
            <option value="1.8rem">Orta</option>
            <option value="2rem">Büyük</option>
          </select>
        </div>

        {/* Alt "Kapat" butonu */}
        <div style={{ marginTop: '1rem', textAlign: 'center' }}>
          <button
            className="settings-modal-bottom-close-button"
            onClick={onClose}
          >
            Kapat
          </button>
        </div>
      </div>
    </div>
  );
};

export default SettingsModalRandomWords;
