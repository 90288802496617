// src/components/AboutUs.js
import React from 'react';
import {
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box
} from '@mui/material';
import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';

// 1) Orientation bazlı sorgu için react-responsive
import { useMediaQuery as useOrientationQuery } from 'react-responsive';

import Header from '../Header';
import Footer from '../Footer';

function AboutUs() {
  // 2) `isPortrait` -> cihaz dikey modda mı?
  const isPortrait = useOrientationQuery({ query: '(orientation: portrait)' });

  return (
    <div>
      <Header />

      {/* 3) Container margin-top/bottom vs. isPortrait'e göre */}
      <Container
        maxWidth="md"
        sx={{
          mt: isPortrait ? 3 : 5,
          mb: isPortrait ? 3 : 5,
        }}
      >
        <Card>
          <CardContent>
            <Typography
              variant="h4"
              align="center"
              gutterBottom
              sx={{
                fontWeight: 'bold',
                fontSize: isPortrait ? '1.8rem' : '2rem',
              }}
            >
              Hakkımızda
            </Typography>

            <Typography variant="body1" paragraph>
              <strong>Hızlı Okuma Programımız</strong> ile öğrencilerin okuma hızlarını
              3-4 katına çıkarıyoruz. 21 derslik yapay zeka destekli programımız sayesinde,
              öğrenciler seviyelerine uygun metinlerle göz egzersizleri yapmaktadır. Bu
              sayede okuduğunu anlama yeteneği gelişmekte ve hafıza teknikleri
              öğrenilmektedir.
            </Typography>

            <Typography variant="body1" paragraph>
              Özellikle sınavlara katılacak öğrenciler için tasarlanmış olan programımız,
              uzun ve karmaşık soruları hızlı ve kolay bir şekilde anlamalarını sağlamaktadır.
            </Typography>

            <Typography
              variant="h5"
              gutterBottom
              sx={{
                mt: isPortrait ? 2 : 3,
                fontSize: isPortrait ? '1.4rem' : '1.6rem',
                fontWeight: 'bold',
              }}
            >
              Programımızın Özellikleri:
            </Typography>

            <List>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Toplam 21 derslik program" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Okuma hızını 150-200 kelimeden 350-400 kelimeye çıkarma" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Okuduğunu anlama ve hafıza teknikleri" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Online veya yüz yüze eğitim seçenekleri" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Programımızı öğrenciler ve adaylar kendileri uygulayabilmektedir." />
              </ListItem>
            </List>

            <Typography variant="body1" paragraph>
              Göz egzersizleri ve alıştırmaları bilgisayar veya tablet üzerinden
              yapılması gerekmektedir.
            </Typography>

            <Typography
              variant="h5"
              gutterBottom
              sx={{
                mt: isPortrait ? 2 : 3,
                fontSize: isPortrait ? '1.4rem' : '1.6rem',
                fontWeight: 'bold',
              }}
            >
              İletişim
            </Typography>
            <Typography variant="body1" paragraph>
              Eğitim danışmanlarımızdan bilgi almak için bizimle iletişime geçebilirsiniz.
            </Typography>

            <Typography
              variant="h6"
              align="center"
              sx={{
                fontSize: isPortrait ? '1rem' : '1.2rem',
                fontWeight: 'bold',
              }}
            >
              Telefon - 1: +90 530 303 3539
            </Typography>
          </CardContent>
        </Card>
      </Container>

      <Footer />
    </div>
  );
}

export default AboutUs;
