import { useRef, useEffect, useState, useCallback } from 'react';

export default function useGameLogic(userData, onGameOver) {
  const canvasRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [position, setPosition] = useState(0);

  // Varsayılan hızı 0.2 yerine 0.05’e indiriyoruz (daha da yavaş).
  const [speed, setSpeed] = useState(0.05);

  const [orientation, setOrientation] = useState('horizontal');
  const [canvasSize, setCanvasSize] = useState({ width: 800, height: 600 });
  const [score, setScore] = useState(0);

  // Kullanıcının durumuna göre süre
  const [timeRemaining, setTimeRemaining] = useState(
    userData ? (userData.status === 'inactive' ? 15 : 60) : 15
  );

  const [difficulty, setDifficulty] = useState('seciniz');
  const [showModal, setShowModal] = useState(false);

  const updateCanvasSize = useCallback(() => {
    const container = document.querySelector('.zigzag-canvas-container');
    if (container) {
      const maxWidth = window.innerWidth * 0.9; 
      const maxHeight = window.innerHeight * 0.7;

      let width = maxWidth;
      let height =
        orientation === 'horizontal'
          ? width * 0.75
          : width * 1.33;

      if (height > maxHeight) {
        const scale = maxHeight / height;
        width *= scale;
        height *= scale;
      }

      setCanvasSize({ width, height });
    }
  }, [orientation]);

  useEffect(() => {
    window.addEventListener('resize', updateCanvasSize);
    updateCanvasSize();
    return () => window.removeEventListener('resize', updateCanvasSize);
  }, [updateCanvasSize]);

  const draw = useCallback(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    const { width, height } = canvasSize;

    // Arkaplan temizleme
    ctx.clearRect(0, 0, width, height);
    ctx.fillStyle = '#fafafa';
    ctx.fillRect(0, 0, width, height);

    // Zigzag çizimi
    const margin = 20;
    const amplitude =
      orientation === 'horizontal'
        ? (height / 2) - margin
        : (width / 2) - margin;
    const wavelength = 200;

    ctx.beginPath();
    ctx.lineWidth = 3;
    ctx.strokeStyle = 'rgba(255, 102, 0, 0.7)';

    if (orientation === 'horizontal') {
      ctx.moveTo(0, height / 2);
      for (let x = 0; x < width; x += 1) {
        ctx.lineTo(
          x,
          height / 2 + Math.sin((x / wavelength) * 2 * Math.PI) * amplitude
        );
      }
    } else {
      const shift = (width - amplitude * 2) / 2;
      for (let y = 0; y < height; y++) {
        const x =
          shift +
          amplitude +
          amplitude * Math.sin((y / wavelength) * 2 * Math.PI);
        ctx.lineTo(x, y);
      }
    }
    ctx.stroke();

    // Zigzag üzerinde hareket eden top
    const posY =
      orientation === 'horizontal'
        ? height / 2 + Math.sin((position / wavelength) * 2 * Math.PI) * amplitude
        : position;

    const posX =
      orientation === 'horizontal'
        ? position
        : (width - amplitude * 2) / 2 +
          amplitude +
          amplitude * Math.sin((position / wavelength) * 2 * Math.PI);

    // Topu çiz
    ctx.beginPath();
    ctx.arc(posX, posY, 12, 0, 2 * Math.PI);
    ctx.fillStyle = '#ffcc00';
    ctx.fill();
    ctx.strokeStyle = '#000';
    ctx.lineWidth = 1;
    ctx.stroke();
  }, [canvasSize, orientation, position]);

  useEffect(() => {
    let animationFrameId;

    const gameLoop = () => {
      if (isPlaying) {
        setPosition((prevPosition) => {
          const maxPosition =
            orientation === 'horizontal' ? canvasSize.width : canvasSize.height;
          return (prevPosition + speed) % maxPosition;
        });
        // Skoru da hıza bağlı olarak artıralım.
        setScore((prevScore) => prevScore + (speed / 100));
      }
      draw();
      animationFrameId = requestAnimationFrame(gameLoop);
    };

    gameLoop();
    return () => cancelAnimationFrame(animationFrameId);
  }, [isPlaying, speed, orientation, canvasSize, draw]);

  // Sayaç (timeRemaining)
  useEffect(() => {
    let timer;
    if (isPlaying && timeRemaining > 0) {
      timer = setInterval(() => {
        setTimeRemaining((prev) => prev - 1);
      }, 1000);
    } else if (timeRemaining === 0 && isPlaying) {
      // Oyun Bitti
      setIsPlaying(false);
      setShowModal(true);
      if (onGameOver) onGameOver();
    }
    return () => clearInterval(timer);
  }, [isPlaying, timeRemaining, onGameOver]);

  // Reset / Yeni Oyun
  const resetGame = () => {
    setIsPlaying(false);
    setScore(0);
    setTimeRemaining(
      userData ? (userData.status === 'inactive' ? 15 : 60) : 15
    );
    setPosition(0);
    // Varsayılan hızı yine 0.05 olsun (daha yavaş).
    setSpeed(0.05);
    setDifficulty('seciniz');
    setShowModal(false);
  };

  // Zorluk seviyesi seçimi
  const handleDifficultyChange = (event) => {
    const newDifficulty = event.target.value;
    setDifficulty(newDifficulty);
    switch (newDifficulty) {
      case 'easy':
        // Kolay = 0.05
        setSpeed(0.05);
        break;
      case 'normal':
        // Normal = 0.1
        setSpeed(0.1);
        break;
      case 'hard':
        // Zor = 0.15
        setSpeed(0.15);
        break;
      default:
        // Seçilmedi ise yine 0.05 olsun
        setSpeed(0.05);
    }
  };

  return {
    canvasRef,
    isPlaying,
    setIsPlaying,
    position,
    setPosition,
    speed,
    setSpeed,
    orientation,
    setOrientation,
    canvasSize,
    setCanvasSize,
    score,
    setScore,
    timeRemaining,
    setTimeRemaining,
    difficulty,
    setDifficulty,
    updateCanvasSize,
    draw,
    resetGame,
    handleDifficultyChange,
    showModal,
    setShowModal,
  };
}
