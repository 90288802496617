import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

const DeleteProgressDialog = ({ open, student, onClose, onConfirm }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Aşamaları Sil</DialogTitle>
      <DialogContent>
        Seçili öğrencinin tüm aşama ilerlemelerini silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>İptal</Button>
        <Button onClick={onConfirm} color="warning">Sil</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteProgressDialog;
