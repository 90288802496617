import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { API_URLS } from '../../../config/config';
import useFetchUserData from '../../../hooks/okul/useFetchUserData'; // OKUL için custom hook
import { Box, Typography, Grid, Button, Card,} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SchoolStudentInfoCard from './components/rapor/SchoolStudentInfoCard';
import SchoolContactInfoCard from './components/rapor/SchoolContactInfoCard';
import SchoolComprehensionRateSection from './components/rapor/SchoolComprehensionRateSection';
import SchoolOverallCharts from './components/rapor/SchoolOverallCharts';
import SchoolStageTabs from './components/rapor/SchoolStageTabs';


function SchoolStudentProgressReport() {
  const [userData, setUserData] = useState({
    ad: '',
    soyad: '',
    sehir: '',
    ilce: '',
    telefon: '',
  });

  const location = useLocation();
  const navigate = useNavigate();

  // location.state ile gelen öğrenci
  const { ogrenci } = location.state || {};

  const [progressData, setProgressData] = useState(null);
  const [initialTestResult, setInitialTestResult] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedStage, setSelectedStage] = useState(0);

  // Okul için custom hook
  useFetchUserData(setUserData);

  useEffect(() => {
    const fetchProgressData = async () => {
      try {
        const token = localStorage.getItem('userToken');
        if (!token) {
          console.log('Yetkiniz yok');
          setErrorMessage('Yetkiniz yok, lütfen giriş yapın.');
          navigate('/okul-giris');
          return;
        }

        if (!ogrenci || !ogrenci._id) {
          setErrorMessage('Öğrenci bilgisi eksik.');
          return;
        }

        // Okul endpointleri
        const [progressResponse, initialTestResponse] = await Promise.all([
          fetch(
            `${API_URLS.SCHOOL_URL}student/akillikursrapor/${ogrenci._id}/progress`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            }
          ),
          fetch(
            `${API_URLS.SCHOOL_URL}student/akillikursrapor/${ogrenci._id}/test/initial-test`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            }
          ),
        ]);

        if (progressResponse.status === 401 || initialTestResponse.status === 401) {
          console.log('Yetkiniz yok');
          setErrorMessage('Yetkiniz yok, lütfen giriş yapın.');
          navigate('/okul-giris');
          return;
        }

        if (!progressResponse.ok) {
          throw new Error('Bir hata oluştu');
        }

        const progressDataJson = await progressResponse.json();
        setProgressData(progressDataJson.progress);

        if (initialTestResponse.ok) {
          const initialTestData = await initialTestResponse.json();
          setInitialTestResult(initialTestData.testResult);
        } else {
          setInitialTestResult(null);
        }
      } catch (error) {
        console.error('İlerleme verileri alınırken bir hata oluştu:', error);
        setErrorMessage('Veri alırken bir hata oluştu veya yetkiniz olmayabilir.');
      }
    };

    fetchProgressData();
  }, [ogrenci, navigate]);

  const handlePrintReport = () => {
  
    window.print();
  };

  // Veri yoksa veya hata varsa
  if (!progressData) {
    return (
      <Box sx={{ padding: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {errorMessage ? (
          <Card
            sx={{
              maxWidth: 500,
              padding: 4,
              borderRadius: 3,
              boxShadow: 6,
              backgroundImage: 'linear-gradient(to right, #f44336, #e57373)',
              textAlign: 'center',
              color: '#fff',
            }}
          >
            <Typography
              variant="h5"
              sx={{ fontWeight: 'bold', marginBottom: 2, textShadow: '1px 1px rgba(0,0,0,0.2)' }}
            >
              {errorMessage}
            </Typography>
            <Typography
              variant="body1"
              sx={{ marginBottom: 3, textShadow: '1px 1px rgba(0,0,0,0.1)' }}
            >
              Lütfen tekrar deneyin veya geri dönün.
            </Typography>
            <Button
              variant="contained"
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate(-1)}
              sx={{
                backgroundColor: '#c62828',
                color: '#fff',
                fontWeight: 'bold',
                '&:hover': {
                  backgroundColor: '#b71c1c',
                },
              }}
            >
              Geri Dön
            </Button>
          </Card>
        ) : (
          <Card
            sx={{
              maxWidth: 500,
              padding: 4,
              borderRadius: 3,
              boxShadow: 6,
              backgroundImage: 'linear-gradient(to right, #fff3cd, #ffecb3)',
              textAlign: 'center',
              color: '#856404',
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: 'bold', marginBottom: 2, textShadow: '1px 1px rgba(0,0,0,0.1)' }}
            >
              Henüz teste başlamadığı için rapor görüntülenmemektedir.
            </Typography>
            <Button
              variant="contained"
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate(-1)}
              sx={{
                backgroundColor: '#ffca28',
                color: '#000',
                fontWeight: 'bold',
                '&:hover': {
                  backgroundColor: '#ffb300',
                },
              }}
            >
              Geri Dön
            </Button>

     
          </Card>
        )}
      </Box>
    );
  }

  // Aşamaları al
  const stages = progressData.stages;

  // Seçili aşama indexine göre tablo/grafik verisi hazırla
  const prepareChartData = (stageIndex) => {
    const chartData = [];
    const stage = stages[stageIndex];
    if (stage) {
      stage.games.forEach((game) => {
        chartData.push({
          gameName: game.gameName,
          score: game.score || 0,
          time: game.time || 0,
          wordCount: game.wordCount || 0,
          completedAt: game.completedAt
            ? new Date(game.completedAt).toLocaleDateString()
            : 'Tamamlanmadı',
        });
      });
    }
    return chartData;
  };

  // Genel grafikler için aşama toplam skor, süre, kelime sayısı
  const prepareOverallChartData = () => {
    const overallChartData = [];

    stages.forEach((stage) => {
      const stageData = {
        stageNumber: `Aşama ${stage.stageNumber}`,
        totalScore: 0,
        totalTime: 0,
        totalWordCount: 0,
      };

      stage.games.forEach((game) => {
        stageData.totalScore += game.score || 0;
        stageData.totalTime += game.time || 0;
        stageData.totalWordCount += game.wordCount || 0;
      });

      overallChartData.push(stageData);
    });

    return overallChartData;
  };

  const overallChartData = prepareOverallChartData();

  // Sekme değişimini yönet
  const handleTabChange = (event, newValue) => {
    setSelectedStage(newValue);
  };

  return (
    <Box
      sx={{
        padding: { xs: 2, md: 4 },
        backgroundImage: 'linear-gradient(to right, #ff7e5f, #feb47b)',
        minHeight: '100vh',
      }}
    >
      {/* Geri Dön Butonu */}
      <Button
        variant="contained"
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate(-1)}
        sx={{
          mb: 3,
          backgroundColor: '#ff5722',
          color: '#fff',
          fontWeight: 'bold',
          '&:hover': {
            backgroundColor: '#e64a19',
          },
        }}
      >
        Geri Dön
      </Button>
   
        <Button
        variant="contained"
        onClick={handlePrintReport}
        sx={{
          mb: 3,
          ml: 2,
          backgroundColor: '#4caf50',
          color: '#fff',
          fontWeight: 'bold',
          '&:hover': {
            backgroundColor: '#43a047',
          },
        }}
      >
        Raporu Yazdır / Kaydet
      </Button>
      <Typography
        variant="h4"
        gutterBottom
        align="center"
        sx={{
          marginBottom: 4,
          color: '#fff',
          fontWeight: 'bold',
          textShadow: '2px 2px #ff5722',
        }}
      >
        {ogrenci.ad} {ogrenci.soyad} - İlerleme Raporu
      </Typography>

      <Grid container spacing={4} sx={{ marginBottom: 4 }}>
        {/* Öğrenci Bilgileri */}
        <Grid item xs={12} md={6}>
          <SchoolStudentInfoCard ogrenci={ogrenci} />
        </Grid>

        {/* İletişim Bilgileri */}
        <Grid item xs={12} md={6}>
          <SchoolContactInfoCard ogrenci={ogrenci} />
        </Grid>
      </Grid>

      {/* Anlama Oranı */}
      <SchoolComprehensionRateSection
        stages={stages}
        initialTestResult={initialTestResult}
      />

      {/* Genel Grafikler */}
      <SchoolOverallCharts overallChartData={overallChartData} />

      {/* Sekmeler: Aşamaların detaylı verileri */}
      <SchoolStageTabs
        stages={stages}
        selectedStage={selectedStage}
        onTabChange={handleTabChange}
        prepareChartData={prepareChartData}
      />
    </Box>
  );
}

export default SchoolStudentProgressReport;
