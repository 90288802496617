import React from 'react';
import '../styles/rotatingDiamondGame.css';

const GameTimerDisplay = ({ gameTime, initialGameTime, userRole }) => {
  const formatTime = (seconds) => {
    if (seconds < 0) seconds = 0;
    const minutes = Math.floor(seconds / 60).toString().padStart(2, '0');
    const remainingSeconds = (seconds % 60).toString().padStart(2, '0');
    return `${minutes}:${remainingSeconds}`;
  };

  const percentage = (gameTime / initialGameTime) * 100;

  const getProgressColor = (perc) => {
    if (perc > 50) return '#2ecc71'; // Yeşil
    if (perc > 20) return '#f1c40f'; // Sarı
    return '#e74c3c'; // Kırmızı
  };

  return (
    <div
      className={`game-timer-container ${
        userRole === 'teacher' ? 'adult-timer' : ''
      }`}
    >
      <div className="game-timer">{formatTime(gameTime)}</div>
      <div className="timer-progress-bar">
        <div
          className="timer-progress"
          style={{
            width: `${percentage}%`,
            backgroundColor: getProgressColor(percentage),
          }}
        ></div>
      </div>
    </div>
  );
};

export default GameTimerDisplay;
