import React from 'react';
import '../styles/harfIzgarasiStyle.css';

const LetterGrid = ({
  letters,
  targetLetters,
  clickedLetters,
  handleLetterClick,
  gridSize,
}) => {
  // Grid boyutunu hesaplama
  const gridColumns = Math.sqrt(gridSize);

  return (
    <div className="text-center">
      <div className="harf-izgarasi-target-letter">
        Hedef Harfler: {targetLetters.join(', ')}
      </div>

      {/* Yeni kapsayıcı */}
      <div className="letter-grid-container">
        <div
          className="harf-izgarasi-letter-grid"
          style={{
            gridTemplateColumns: `repeat(${gridColumns}, 1fr)`,
          }}
        >
          {letters.map((letter, index) => (
            <span
              key={index}
              className={`harf-izgarasi-letter ${
                clickedLetters.includes(index)
                  ? targetLetters.includes(letter)
                    ? 'correct'
                    : 'incorrect'
                  : ''
              }`}
              onClick={() => handleLetterClick(letter, index)}
            >
              {letter}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LetterGrid;
