import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useCumleleriOkuLogic from './hooks/useCumleleriOkuLogic';
import Header from './components/Header';
import Instructions from './components/Instructions';
import GameControls from './components/GameControls';
import CumleGameArea from './components/CumleGameArea';
import GameOverModal from './components/GameOverModal';
import CumleTimer from './components/CumleTimer';
import './styles/cumleleriOkuStyle.css';
import axiosInstance from '../../../utils/axiosInstance';
import { API_URLS } from '../../../config/config';

const CumleleriOkuGame = () => {
  const navigate = useNavigate();
    const [stage, setStage] = useState('');
  const [showInstructions, setShowInstructions] = useState(true);
  const [userData, setUserData] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const token = localStorage.getItem('userToken');

  // Kullanıcı (öğrenci/öğretmen) verisini al
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const studentResponse = await axiosInstance.get(`${API_URLS.STUDENT_URL}/me`);
        if (
          studentResponse.status === 200 &&
          studentResponse.data &&
          studentResponse.data.status === 'active'
        ) {
          setUserData(studentResponse.data);
          setUserRole('student');
          return;
        } else {
          setUserData(null);
          setUserRole(null);
        }
      } catch (error) {
        console.error('Öğrenci verisi alınırken hata oluştu:', error);
      }

      // Öğrenci yoksa veya aktif değilse öğretmen kontrolü
      try {
        const teacherResponse = await fetch(`${API_URLS.TEACHER_URL}/me`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (teacherResponse.ok) {
          const teacherData = await teacherResponse.json();
          setUserData(teacherData);
          setUserRole('teacher');
          return;
        }
      } catch (error) {
        console.error('Öğretmen verisi alınırken hata oluştu:', error);
      }

      setUserData(null);
      setUserRole(null);
    };

    fetchUserData();
  }, [token]);

  // Oyun mantığı Hook
  const {
    difficulty,
    displayedSentences,
    isPlaying,
    isFinished,
    speed,
    elapsedTime,
    gameTime,
    timeLeft,
    handlePlayPause,
    handleReset,
    handleDifficultyChange,
    handleSpeedChange,
    getSpeedInSeconds
  } = useCumleleriOkuLogic(userData, token);

  const startGame = () => {
    setShowInstructions(false);
    handleReset();
  };

  const handleReturnToPreviousStage = () => {
    navigate(-1); 
  };

  const handleClose = () => {
    handleReset();
  };

  // Bitince ilerlemeyi güncelle
  useEffect(() => {
    if (isFinished) {
      const updateProgress = async () => {
        try {
          if (userData && userRole) {
            const payload = {
              stageNumber: 1,
              gameName: 'Cümleleri Oku',
              score: displayedSentences.length,
              time: Math.floor(elapsedTime / 1000),
              wordCount: displayedSentences.length,
              completed: true,
            };

            let apiUrl;
            if (userRole === 'student') {
              apiUrl = `${API_URLS.STUDENT_URL}akillikurs/progress/update-game`;
              await axiosInstance.post(apiUrl, payload);
            } else {
              apiUrl = `${API_URLS.TEACHER_URL}akillikurs/progress/update-game`;
              const teacherToken = localStorage.getItem('userToken');
              await axiosInstance.post(apiUrl, payload, {
                headers: {
                  Authorization: `Bearer ${teacherToken}`,
                  'Content-Type': 'application/json',
                },
              });
            }
          }
        } catch (error) {
          console.error('İlerleme güncellenirken hata oluştu:', error);
        }
      };
      updateProgress();
    }
  }, [isFinished, displayedSentences, elapsedTime, userData, userRole]);

  return (
    <div className="cumleleri-oku-container game-container-bg">
      {isFinished ? (
        <GameOverModal
          displayedWords={displayedSentences}
          onRestart={handleReset}
          onClose={handleClose}
          userData={userData}
        />
      ) : (
        <>
    
          {showInstructions ? (
            <Instructions
              startGame={startGame}
              title="Cümleyi Oku"
              description="Bu çalışmada kelime grupları alt kısımdan yukarı doğru sırayla ekranda belirecek. Zorluk seviyesini seçip başlattıktan sonra kelimeleri izleyin."
            />
          ) : (
            <div className="cumleleri-oku-gameplay">
                  <Header handleReturnToPreviousStage={handleReturnToPreviousStage} 
                   stage={stage} 
                  />
   
              <div className="cumleleri-oku-status">
                <CumleTimer timeLeft={timeLeft} isPlaying={isPlaying} />
              </div>

              <CumleGameArea displayedSentences={displayedSentences} />

              <div className="cumleleri-oku-control-row">
                <GameControls
                  difficulty={difficulty}
                  handleDifficultyChange={handleDifficultyChange}
                  isPlaying={isPlaying}
                  handlePlayPause={handlePlayPause}
                  handleReset={handleReset}
                  speed={speed}
                  handleSpeedChange={handleSpeedChange}
                  getSpeedInSeconds={getSpeedInSeconds}
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CumleleriOkuGame;
