import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faRedo } from '@fortawesome/free-solid-svg-icons';
import SpeedControl from './SpeedControl'; 
import '../styles/cumleleriOkuStyle.css';

const GameControls = ({
  difficulty,
  handleDifficultyChange,
  isPlaying,
  handlePlayPause,
  handleReset,
  speed,
  handleSpeedChange
}) => {
  return (
    <div className="game-buttons">  
      <select
        className="game-select"
        value={difficulty}
        onChange={handleDifficultyChange}
      >
        <option value="easy">Kolay</option>
        <option value="medium">Orta</option>
        <option value="hard">Zor</option>
      </select>

      {/* Başlat / Duraklat */}
      <button onClick={handlePlayPause} className="game-button">
        <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />{' '}
        {isPlaying ? 'Duraklat' : 'Başlat'}
      </button>

      {/* Yeniden Başlat */}
      <button onClick={handleReset} className="game-button">
        <FontAwesomeIcon icon={faRedo} /> Yeniden Başlat
      </button>

  
      <SpeedControl
        speed={speed / 1000}
        handleSpeedChange={handleSpeedChange}
      />
    </div>
  );
};

export default GameControls;
