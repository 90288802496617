// OkulAtamaDialog.jsx
import React, { useState, useEffect } from 'react';
import {Dialog, DialogTitle, DialogContent,  DialogActions, Button, FormControl, InputLabel, Select, MenuItem, Typography,} from '@mui/material';
import { API_URLS } from '../../../../../config/config';

const OkulAtamaDialog = ({
  open,
  onClose,
  selectedSchoolId,
  handleAssign, // Parent fonksiyon
}) => {
  const [secilenKurumId, setSecilenKurumId] = useState('');
  const [kurumListesi, setKurumListesi] = useState([]);

  // Dialog açıldığında kurumları çek
  useEffect(() => {
    const fetchKurumlar = async () => {
      try {
        const response = await fetch(API_URLS.ADMIN_URL + 'kurumsal');
        if (!response.ok) throw new Error('Kurumlar yüklenirken bir hata oluştu.');
        const data = await response.json();
    
        setKurumListesi(data);
      } catch (error) {
        console.error(error.message);
      }
    };

    if (open) {
      fetchKurumlar();
    }
  }, [open]);

  const handleSubmit = () => {
    if (!secilenKurumId) {
      alert('Lütfen atamak istediğiniz kurumu seçiniz!');
      return;
    }
    // Parent’taki handleAssign fonksiyonuna gidiyoruz
    handleAssign(selectedSchoolId, secilenKurumId);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Okulu Bayiye Atama</DialogTitle>
      <DialogContent dividers>
        <Typography variant="body2" gutterBottom>
          Seçili okulu, istediğiniz kuruma atayabilirsiniz.
        </Typography>

        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="kurum-select-label">Bayi Seç</InputLabel>
          <Select
            labelId="kurum-select-label"
            value={secilenKurumId}
            label="Bayi Seç"
            onChange={(e) => setSecilenKurumId(e.target.value)}
          >
            <MenuItem value="">
              <em>Seçiniz...</em>
            </MenuItem>
            {kurumListesi.map((kurum) => (
              <MenuItem key={kurum._id} value={kurum._id}>
                {kurum.kurum_adi}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Kapat
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          Atamayı Tamamla
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OkulAtamaDialog;
