import React, { useState, useEffect } from 'react';
import parse, { domToReact } from 'html-react-parser';
import { useNavigate } from 'react-router-dom';
import { faPlay, faPause, faRedo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GameHeader from './components/GameHeader';
import ProgressBar from './components/ProgressBar';
import GameTimer from './components/GameTimer';
import GameOverModal from './components/GameOverModal';
import GameSettingsModal from './components/GameSettingsModal'; 
import { useSilinenYaziOyunu } from './hooks/useSilinenYaziOyunu';
import './styles/silinenYaziStyle.css';

const SilinenYaziOyunu = () => {
  const navigate = useNavigate();
  const [stage, setStage] = useState('');
  const token = localStorage.getItem('userToken');
  const [showIntroduction, setShowIntroduction] = useState(true);
  const enterGame = () => {
    setShowIntroduction(false);
  };

  // Hook'tan gelen veriler
  const {
    userData,
    userRole,
    paragraphs,
    selectedParagraphIndex,
    setSelectedParagraphIndex,
    difficulty,
    handleDifficultyChange,
    handleParagraphChange,
    theme: gameTheme, 
    handleThemeChange: handleGameThemeChange,
    speedFactor,
    setSpeedFactor,
    timeLeft,
    gizlenenKelimeSayisi,
    calisiyorMu,
    oyunBitti: oyunGercektenBitti,
    progress,
    handlePlayPause: hookPlayPause,
    handleReset,
    kelimeler,
  } = useSilinenYaziOyunu(token);

  // Oyunun "başladı mı" durumu
  const [oyunBasladi, setOyunBasladi] = useState(false);

  // Oyun bitti mi? (oyun başladıysa ve hook "oyunBitti" diyorsa)
  const oyunBitti = oyunBasladi && oyunGercektenBitti;

  // Game Over Modal
  const [showGameOver, setShowGameOver] = useState(false);

  // Ayarlar Modal
  const [showSettingsModal, setShowSettingsModal] = useState(false);

  // Modal açma/kapama (Game Over)
  useEffect(() => {
    if (!oyunBasladi) return;
    if (oyunBitti && !showGameOver) {
      setShowGameOver(true);
    } else if (!oyunBitti && showGameOver) {
      setShowGameOver(false);
    }
  }, [oyunBitti, showGameOver, oyunBasladi]);

  const handleCloseModal = () => {
    setShowGameOver(false);
    setOyunBasladi(false);
    handleReset();
  };

  const handleRestartGame = () => {
    setShowGameOver(false);
    setOyunBasladi(false);
    handleReset();
  };

  const handleReturnToPreviousStage = () => {
    navigate(-1);
  };

  // Play/Pause
  const handlePlayPause = () => {
    if (!oyunBasladi) {
      setOyunBasladi(true);
    }
    if (oyunBitti) return;
    hookPlayPause();
  };

  // HTML parse options
  const voidElements = new Set([
    'area', 'base', 'br', 'col', 'embed', 'hr',
    'img', 'input', 'keygen', 'link', 'meta',
    'param', 'source', 'track', 'wbr',
  ]);

  let globalWordCounter = 0;
  const parseOptions = {
    replace: (node) => {
      if (node.type === 'tag') {
        if (voidElements.has(node.name)) {
          return React.createElement(
            node.name,
            { key: node.name, ...node.attribs },
            null
          );
        } else {
          return React.createElement(
            node.name,
            { key: node.name, ...node.attribs },
            domToReact(node.children, parseOptions)
          );
        }
      }
      if (node.type === 'text') {
        const rawWords = node.data.split(' ');

        const replacedWords = rawWords.map((word, idx) => {
          const isHidden =
            globalWordCounter < gizlenenKelimeSayisi && word.trim() !== '';
          globalWordCounter++;

          const wordWithSpace = idx < rawWords.length - 1 ? word + ' ' : word;

          return (
            <span
              key={`word-${globalWordCounter}`}
              style={{
                visibility: isHidden ? 'hidden' : 'visible',
                marginRight: '2px',
              }}
            >
              {wordWithSpace}
            </span>
          );
        });

        return <>{replacedWords}</>;
      }
      return undefined;
    },
  };

  // Paragraf içeriği
  const currentContent = paragraphs[selectedParagraphIndex]?.content || '';

  // ============== TEMA, FONT, FONTSIZE ==============
  const [appTheme, setAppTheme] = useState('default');
  const [font, setFont] = useState('Arial');
  const [fontSize, setFontSize] = useState('1rem');

  // Handler fonksiyonları (Modal'daki kontroller için)
  const handleAppThemeChange = (e) => setAppTheme(e.target.value);
  const handleFontChange = (e) => setFont(e.target.value);
  const handleFontSizeChange = (e) => setFontSize(e.target.value);
  const handleSpeedIncrease = () => {
    setSpeedFactor((prev) => Math.min(prev + 0.1, 6));
  };
  const handleSpeedDecrease = () => {
    setSpeedFactor((prev) => Math.max(prev - 0.1, 0.1));
  };

  return (
    <>
      {/** GİRİŞ EKRANI */}
      {showIntroduction ? (
        <div 
        className="silinen-yazi-game-container game-container-bg silinen-yazi-theme-default"
        style={{ fontFamily: font, fontSize:'16px' }}
        >
          <div className="intro-container mt-2">
        
            <GameHeader
              stage={stage}
              handleReturnToPreviousStage={handleReturnToPreviousStage}
              onOpenSettings={() => setShowSettingsModal(true)}
              hideSettings={true}
            />

            {/* Başlık */}
            <h1 className="intro-title mt-4">Silinen Yazılar</h1>

            {/* Açıklama Metni */}
            <p className="intro-description">
              Bu çalışmada, belirli kelimeler kısa süre içinde görünmez hale gelecek.
              Metni takip etmeye devam edebilir misiniz? Zamanla daha fazla kelime
              kaybolacak, bakalım ne kadarını hatırlayacaksınız!
            </p>

            {/* Talimatlar Kutusu */}
            <div className="intro-instructions-box">
              <h3 className="intro-instructions-title">Talimatlar</h3>
              <ul className="intro-instructions-list">
                <li>1. Ayarlar menüsünden zorluk ve paragraf seç.</li>
                <li>2. "Başlat" tuşuna bas ve metni dikkatlice takip et.</li>
                <li>3. Bazı kelimeler silinmeye başlayacak. Takibi bırakma!</li>
                <li>4. Süre veya tüm kelimelerin silinmesiyle çalışma biter.</li>
              </ul>
            </div>

            {/* Başlat Butonu */}
            <button className="intro-button mt-2" onClick={enterGame}>
              BAŞLAT
            </button>
          </div>
        </div>
      ) : (
        /** ASIL OYUN EKRANI */
        <div
          className={`silinen-yazi-game-container game-container-bg silinen-yazi-theme-${appTheme}`}
          style={{ fontFamily: font, fontSize }}
        >
          {/* Üst kısım Header */}
          <GameHeader
            stage={stage}
            handleReturnToPreviousStage={handleReturnToPreviousStage}
            onOpenSettings={() => setShowSettingsModal(true)}
          />
      
          <GameTimer timeLeft={timeLeft} />
          <ProgressBar progress={progress} />

          {/* Butonlar */}
          <div className="game-buttons">
            <button
              onClick={handlePlayPause}
              className="game-button"
              disabled={oyunBitti}
            >
              <FontAwesomeIcon icon={calisiyorMu ? faPause : faPlay} /> 
              {calisiyorMu ? ' Duraklat ' : ' Başlat '}
            </button>
            <button
              onClick={handleRestartGame}
              className="game-button"
            >
              <FontAwesomeIcon icon={faRedo} /> 
              Yeniden Başlat
            </button>
          </div>

          <div className="silinen-yazi-controls">
            <div className="speed-control-container">
              <div className="speed-label">Hız Ayarı</div>
              <div className="speed-display">
                <button className="speed-button" onClick={handleSpeedDecrease}>-</button>
                <div className="speed-value">{speedFactor.toFixed(1)}</div>
                <button className="speed-button" onClick={handleSpeedIncrease}>+</button>
              </div>
            </div>
          </div>

          {/* Metin Gösterimi */}
          <div className="silinen-yazi-game-display">
            <div
              className="silinen-yazi-content"
              key={`${selectedParagraphIndex}-${gizlenenKelimeSayisi}`}
            >
              {parse(currentContent, parseOptions)}
            </div>
          </div>

          {/* Durum Mesajı */}
          <p className="silinen-yazi-message">
            {oyunBitti
              ? 'Çalışma Bitti! Süre doldu veya tüm kelimeler silindi.'
              : `Silinen kelime sayısı: ${gizlenenKelimeSayisi}/${kelimeler.length}`}
          </p>

          {/* Oyun Bittiyse Modal */}
          {showGameOver && (
            <GameOverModal
              score={gizlenenKelimeSayisi}
              elapsedTime={((500 * gizlenenKelimeSayisi) / 1000).toFixed(1)}
              onRestart={handleRestartGame}
              onClose={handleCloseModal}
              userData={userData}
            />
          )}

          {/* Ayarlar Modal */}
          <GameSettingsModal
            isOpen={showSettingsModal}
            onClose={() => setShowSettingsModal(false)}
            difficulty={difficulty}
            onDifficultyChange={handleDifficultyChange}
            paragraphs={paragraphs}
            selectedParagraphIndex={selectedParagraphIndex}
            onParagraphChange={setSelectedParagraphIndex}
            appTheme={appTheme}
            onAppThemeChange={handleAppThemeChange}
            font={font}
            onFontChange={handleFontChange}
            fontSize={fontSize}
            onFontSizeChange={handleFontSizeChange}
          />
        </div>
      )}
    </>
  );
};

export default SilinenYaziOyunu;
