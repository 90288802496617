import React, { useRef, useEffect } from 'react';
import parse, { domToReact } from 'html-react-parser';
import { faPlay, faPause, faRedo, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../styles/kayanYazilarStyle.css';
import ProgressBar from './ProgressBar'; 
import GameTimer from './GameTimer';

const GameDisplay = ({
  isPlaying,
  paragraphsList,
  selectedParagraphIndex,
  displayedWords,
  allWords,
  handlePlayPause,
  handleReset,
  message,
  gameOver,
  fontSize,
  speedFactor,
  handleSpeedIncrease,
  handleSpeedDecrease,
  timeLeft,
}) => {
  const voidElements = new Set([
    'area','base','br','col','embed','hr','img','input','keygen',
    'link','meta','param','source','track','wbr',
  ]);

  const options = {
    replace: (node) => {
      if (node.type === 'tag') {
        if (voidElements.has(node.name)) {
          // İç içe çocuk eleman bulunmayan (self-closing) tag'ler
          return React.createElement(
            node.name,
            { key: node.name, ...node.attribs },
            null
          );
        } else {
          // Diğer HTML etiketleri
          return React.createElement(
            node.name,
            { key: node.name, ...node.attribs },
            domToReact(node.children, options)
          );
        }
      }
    },
  };

  // İlerleme yüzdesini hesapla
  const progress =
    allWords.length > 0
      ? (displayedWords.length / allWords.join('').length) * 100
      : 0;

  // Otomatik aşağı kaydırma
  const containerRef = useRef(null);
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [displayedWords]);

  return (
    <>

    <GameTimer timeLeft={timeLeft} isPlaying={isPlaying}  />
    <ProgressBar progress={progress} />
      {/* Kontroller */}
      <div className="game-buttons">
  <button
    className="game-button"
    onClick={handlePlayPause}
    disabled={gameOver}
    aria-label={isPlaying ? 'Duraklat' : 'Başlat'}
  >
       <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />   {isPlaying ? ' Duraklat' : ' Başlat'}
  </button>
  <button
    className="game-button"
    onClick={handleReset}
    aria-label="Yeniden Başlat"
  >
    <FontAwesomeIcon icon={faRedo} /> 
    Yeniden Başlat
  </button>
</div>


      <div className="speed-control-container" style={{ marginBottom: '20px' }}>
        <div className="speed-label">Hız Ayarı</div>
        <div className="speed-display">
          <button
            className="speed-button"
            onClick={handleSpeedDecrease}
            aria-label="Hızı Azalt"
            disabled={gameOver}
          >
            -
          </button>
          <div className="speed-value">
            {(speedFactor || 1).toFixed(1)}
          </div>
          <button
            className="speed-button"
            onClick={handleSpeedIncrease}
            aria-label="Hızı Artır"
            disabled={gameOver}
          >
            +
          </button>
        </div>
      </div>



      {/* Oyun Metni */}
      <div
        className="kayan-yazilar-game-display"
        style={{ fontSize }}
        ref={containerRef}
      >
        {!paragraphsList[selectedParagraphIndex] ? (
          <div className="kayan-yazilar-message">
            Paragraf bulunamadı. Lütfen farklı bir paragraf veya zorluk seviyesi seçin.
          </div>
        ) : (
          <div className="kayan-yazilar-content">
            {parse(displayedWords, options)}
          </div>
        )}
      </div>

      {/* Bilgilendirme Mesajı */}
      {message && <div className="kayan-yazilar-message">{message}</div>}
    </>
  );
};

export default GameDisplay;
