import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { useKayanYazilarOyunu } from './hooks/useKayanYazilarOyunu';
import HeaderSection from './components/GameHeader';
import GameDisplay from './components/GameDisplay';
import GameOverModal from './components/GameOverModal';
import SettingsModal from './components/SettingsModal';
import { API_URLS } from '../../../config/config';
import axiosInstance from '../../../utils/axiosInstance';
import './styles/kayanYazilarStyle.css';

const KayanYazilarOyunu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { stage, exercise } = location.state || {};
  const token = localStorage.getItem('userToken');
  const [userData, setUserData] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [showInstructions, setShowInstructions] = useState(true);
  const [showGameOver, setShowGameOver] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [fontSize, setFontSize] = useState('1.5rem');
  const [theme, setTheme] = useState('default');
  const [font, setFont] = useState('Arial');

  // Ayarlar Modalı aç/kapat
  const toggleSettingsModal = () => {
    setShowSettingsModal(!showSettingsModal);
  };

  // Font ve tema değişiklik fonksiyonları
  const handleFontSizeChange = (e) => {
    setFontSize(e.target.value);
  };
  const handleThemeChange = (e) => {
    setTheme(e.target.value);
  };
  const handleFontChange = (e) => {
    setFont(e.target.value);
  };

  // Kullanıcı datasını çek (öğrenci/öğretmen ayrımı)
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // 1) Öğrenci verisi
        const studentResponse = await axiosInstance.get(`${API_URLS.STUDENT_URL}/me`);
        if (
          studentResponse.status === 200 &&
          studentResponse.data &&
          studentResponse.data.status === 'active'
        ) {
          setUserData(studentResponse.data);
          setUserRole('student');
          return;
        } else {
          setUserData(null);
          setUserRole(null);
        }
      } catch (error) {
        console.error('Öğrenci verisi alınırken hata:', error);
      }

      // 2) Öğrenci yoksa öğretmen
      try {
        const teacherResponse = await fetch(`${API_URLS.TEACHER_URL}/me`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (teacherResponse.ok) {
          const teacherData = await teacherResponse.json();
          setUserData(teacherData);
          setUserRole('teacher');
          return;
        }
      } catch (error) {
        console.error('Öğretmen verisi alınırken hata:', error);
      }

      // 3) Hiçbiri değilse
      setUserData(null);
      setUserRole(null);
    };

    fetchUserData();
  }, [token]);

  // useKayanYazilarOyunu hook
  const {
    paragraphsList,
    selectedParagraphIndex,
    displayedWords,
    isPlaying,
    speedFactor,
    handleSpeedIncrease,
    handleSpeedDecrease,
    elapsedTime,
    timeLeft,
    message,
    gameOver,
    setGameOver: setGameOverFromHook,
    updateWords,
    allWords,
    handlePlayPause,
    handleReset,
    handleParagraphChange,
    handleDifficultyChange,
    onTimeUp,
    difficulty,
    difficultyLocked,
  } = useKayanYazilarOyunu(navigate, userData, token, stage);

  // Intro ekranı -> Oyunu başlat
  const startGame = () => {
    handleReset();
    setShowInstructions(false);
  };

  // Game Over durumu
  useEffect(() => {
    if (gameOver && !showGameOver) {
      setShowGameOver(true);
    }
  }, [gameOver, showGameOver]);

  // Game Over -> Progress güncelle
  useEffect(() => {
    if (gameOver) {
      const updateProgress = async () => {
        try {
          if (userData && userRole) {
            const payload = {
              stageNumber: stage?.number || 1,
              gameName: exercise?.gameName || 'Kayan Yazı 1',
              score: displayedWords.trim().split(/\s+/).length,
              time: Math.floor(elapsedTime / 1000),
              wordCount: displayedWords.trim().split(/\s+/).length,
              completed: true,
            };

            let apiUrl;
            if (userRole === 'student') {
              apiUrl = `${API_URLS.STUDENT_URL}akillikurs/progress/update-game`;
              await axiosInstance.post(apiUrl, payload);
            } else {
              apiUrl = `${API_URLS.TEACHER_URL}akillikurs/progress/update-game`;
              const teacherToken = localStorage.getItem('userToken');
              await axios.post(apiUrl, payload, {
                headers: {
                  Authorization: `Bearer ${teacherToken}`,
                  'Content-Type': 'application/json',
                },
              });
            }
          }
        } catch (error) {
          console.error('İlerleme güncellenirken hata oluştu:', error);
        }
      };
      updateProgress();
    }
  }, [gameOver, displayedWords, elapsedTime, userData, userRole, stage, exercise, token]);

  // Restart
  const handleRestart = () => {
    setShowGameOver(false);
    setGameOverFromHook(false);
    handleReset();
  };

  // Geri dönüş
  const handleReturnToPreviousStage = () => {
    if (stage) {
      navigate(
        userRole === 'student' ? '/ogrenci-dashboard' : '/ogretmen-dashboard',
        {
          state: {
            selectedMenu: 'ExerciseList',
            selectedStage: stage,
          },
        }
      );
    } else {
      navigate(-1);
    }
  };

  // Modal kapatma
  const handleClose = () => {
    setShowGameOver(false);
    setGameOverFromHook(false);
  };

  return (
    <>
      {/* 1) INTRO EKRANI */}
      {showInstructions ? (
        <div
        className={`kayan-yazilar-game-container game-container-bg theme-${theme} `}
          style={{ fontFamily: font, fontSize:'16px' }}
        >
          <div 
           className={`intro-container mt-4`}
          >
      {/* HEADER */}
      <HeaderSection 
        stage={stage} 
        handleReturnToPreviousStage={handleReturnToPreviousStage}
        handleSettingsClick={toggleSettingsModal}
        hideSettings={true} 
      />

 
      {/* Başlık */}
      <h1 className="intro-title mt-4">Kayan Yazılar Çalışması</h1>

      {/* Koşullu Açıklama (stage varsa / yoksa) */}
      {stage ? (
        <p className="intro-description">
          Bu çalışmada aşamanıza göre zorluk seviyesi otomatik belirlenecek.
          Amacınız ekranda beliren kelimeleri en hızlı şekilde okumak.
        </p>
      ) : (
        <p className="intro-description">
          Bu çalışmada seçtiğiniz zorluk seviyesine göre kelimeler ekranda belirecek.
          Amacınız bu kelimeleri en hızlı şekilde okumak.
        </p>
      )}

      {/* Talimatlar Kutusu */}
      <div className="intro-instructions-box">
  <h3 className="intro-instructions-title">Talimatlar</h3>
  <ol
    className="intro-instructions-list"
    style={{ listStyleType: "decimal", paddingLeft: "20px" }}
  >
    {[
      stage ? "Aşamanıza göre zorluk seviyesi otomatik ayarlandı." : "Zorluk seviyesini seçin.",
      "\"Başlat\" butonuna tıklayarak çalışmayı başlatın.",
      "Ekranda beliren kelimeleri takip edin ve okuyun."
    ].map((instruction, index) => (
      <li key={index}>{instruction}</li>
    ))}
  </ol>
</div>


      {/* Başlat Butonu */}
      <button className="intro-button mt-4" onClick={startGame}>
        BAŞLAT
      </button>
    </div>
        </div>
      ) : (
        /* 2) OYUN EKRANI */
        <div
          className={`kayan-yazilar-game-container game-container-bg theme-${theme}`}
          style={{ fontFamily: font, fontSize }}
        >
          <div className="kayan-yazilar-gameplay">
            {/* Üst kısımdaki butonlar: Geri, AnaSayfa, ÖncekiSayfa, Ayarlar */}
            <HeaderSection 
            stage={stage} 
            handleReturnToPreviousStage={handleReturnToPreviousStage}
            handleSettingsClick={toggleSettingsModal}
            hideSettings={false} 
          />


            {/* Ana Oyun Alanı */}
            <GameDisplay
              isPlaying={isPlaying}
              paragraphsList={paragraphsList}
              selectedParagraphIndex={selectedParagraphIndex}
              displayedWords={displayedWords}
              allWords={allWords}
              handlePlayPause={handlePlayPause}
              handleReset={handleReset}
              message={message}
              gameOver={gameOver}
              fontSize={fontSize}
              speedFactor={speedFactor}
              handleSpeedIncrease={handleSpeedIncrease}
              handleSpeedDecrease={handleSpeedDecrease}
              timeLeft={timeLeft}
            />
          </div>
        </div>
      )}

      {/* 3) OYUN BİTTİ MODAL */}
      {showGameOver && (
        <GameOverModal
          score={displayedWords.trim().split(/\s+/).length}
          elapsedTime={Math.floor(elapsedTime / 1000)}
          onRestart={handleRestart}
          onClose={handleClose}
          userData={userData}
        />
      )}

      {/* 4) AYARLAR MODALI */}
      {showSettingsModal && (
        <SettingsModal
          onClose={toggleSettingsModal}
          difficulty={difficulty}
          paragraphsList={paragraphsList}
          selectedParagraphIndex={selectedParagraphIndex}
          fontSize={fontSize}
          theme={theme}
          handleDifficultyChange={handleDifficultyChange}
          handleParagraphChange={handleParagraphChange}
          handleFontSizeChange={handleFontSizeChange}
          handleThemeChange={handleThemeChange}
          difficultyLocked={difficultyLocked}
          font={font}
          handleFontChange={handleFontChange}
        />
      )}
    </>
  );
};

export default KayanYazilarOyunu;
