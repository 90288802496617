// useSilinenYaziOyunu.js

import { useState, useEffect } from 'react';
import axiosInstance from '../../../../utils/axiosInstance';
import { API_URLS } from '../../../../config/config';

export const useSilinenYaziOyunu = (token) => {
  // Kullanıcı verisi
  const [userData, setUserData] = useState(null);
  const [userRole, setUserRole] = useState(null);

  // Paragraflar ve seçili paragraf
  const [paragraphs, setParagraphs] = useState([]);
  const [selectedParagraphIndex, setSelectedParagraphIndex] = useState(0);

  // Zorluk, tema ve hız faktörü
  const [difficulty, setDifficulty] = useState('easy');
  const [theme, setTheme] = useState('dark');

  // Hız ile ilgili ayarlar
  const [speedFactor, setSpeedFactor] = useState(1); 
  const baseSpeed = 500; 

  // Kelimeler
  const [kelimeler, setKelimeler] = useState([]);
  const [gizlenenKelimeSayisi, setGizlenenKelimeSayisi] = useState(0);

  // Oyun durumu
  const [calisiyorMu, setCalisiyorMu] = useState(false);

  // Süre
  const [initialTime, setInitialTime] = useState(10000); // ms cinsinden
  const [timeLeft, setTimeLeft] = useState(initialTime);

  // Yüklenme / Hata
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // 1) Kullanıcı verisini çekme
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // Öğrenci
        const studentResponse = await axiosInstance.get(
          `${API_URLS.STUDENT_URL}/me`
        );
        if (
          studentResponse.status === 200 &&
          studentResponse.data?.status === 'active'
        ) {
          setUserData(studentResponse.data);
          setUserRole('student');
          return;
        }
      } catch (err) {
        // Öğrenci değilse hata alırız, yakalıyoruz.
      }

      // Öğretmen
      try {
        const teacherResponse = await fetch(`${API_URLS.TEACHER_URL}/me`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (teacherResponse.ok) {
          const teacherData = await teacherResponse.json();
          setUserData(teacherData);
          setUserRole('teacher');
          return;
        }
      } catch (err) {
        // Öğretmen de yok
      }

      // Misafir
      setUserData(null);
      setUserRole(null);
    };
    fetchUserData();
  }, [token]);

  // 2) Paragrafları çekme
  const fetchParagraphs = async (diff) => {
    setIsLoading(true);
    setErrorMessage('');
    try {
      const url = `${API_URLS.HIZLIOKUMA_URL}akanyazi/${diff}`;
      const res = await axiosInstance.get(url);
      if (res.data) {
        setParagraphs(res.data);
        setSelectedParagraphIndex(0);
      }
    } catch (err) {
      setErrorMessage('Paragraflar alınırken hata oluştu.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchParagraphs(difficulty);
    // eslint-disable-next-line
  }, [difficulty]);

  // 3) Kullanıcı durumuna + paragraf uzunluğuna göre başlangıç süresini ayarla
  useEffect(() => {
    if (!paragraphs.length) return; // Paragraflar henüz gelmediyse bekle
    const currentContent = paragraphs[selectedParagraphIndex]?.content || '';
    const wordCount = currentContent.trim().split(/\s+/).length;

    if (userData) {
      if (userData.status === 'inactive') {
        setInitialTime(15000); 
      } else {
        // Aktif kullanıcıysa, her kelime için 500 ms verip en az 30sn tutuyoruz.
        // Bu formülü ihtiyacınıza göre değiştirebilirsiniz.
        const dynamicTime = Math.max(wordCount * 500, 30000);
        setInitialTime(dynamicTime);
      }
    } else {
      setInitialTime(15000); 
    }
  }, [userData, paragraphs, selectedParagraphIndex]);

  // 4) initialTime değişince reset
  useEffect(() => {
    setTimeLeft(initialTime);
    setCalisiyorMu(false);
    setGizlenenKelimeSayisi(0);
  }, [initialTime]);

  // 5) Paragraf seçimi değişince kelimeleri yeniden ayarla
  useEffect(() => {
    if (paragraphs[selectedParagraphIndex]) {
      const content = paragraphs[selectedParagraphIndex].content || '';
      const parcali = content.trim().split(/\s+/);
      if (parcali.length > 1) {
        setKelimeler(parcali);
      } else {
        // Yedek varsayılan cümleler
        setKelimeler([
          'Bu', 'bir', 'örnek', 'cümledir.',
          'Hızlı', 'okuma', 'çalışması', 'için', 'kullanılıyor.'
        ]);
      }
      setTimeLeft(initialTime);
      setGizlenenKelimeSayisi(0);
      setCalisiyorMu(false);
    }
  }, [paragraphs, selectedParagraphIndex, initialTime]);

  // 6) Kelimeleri yavaş yavaş gizle
  useEffect(() => {
    let kelimeInterval;
    if (calisiyorMu) {
      const actualSpeed = baseSpeed / speedFactor;
      kelimeInterval = setInterval(() => {
        setGizlenenKelimeSayisi((prev) => {
          if (prev >= kelimeler.length) {
            clearInterval(kelimeInterval);
            setCalisiyorMu(false);
            return prev;
          }
          return prev + 1;
        });
      }, actualSpeed);
    }
    return () => clearInterval(kelimeInterval);
  }, [calisiyorMu, speedFactor, kelimeler.length]);

  // 7) Zaman geri sayımı
  useEffect(() => {
    let zamanInterval;
    if (calisiyorMu) {
      zamanInterval = setInterval(() => {
        setTimeLeft((prev) => {
          const newVal = prev - 100;
          if (newVal <= 0) {
            clearInterval(zamanInterval);
            setCalisiyorMu(false);
            return 0;
          }
          return newVal;
        });
      }, 100);
    }
    return () => clearInterval(zamanInterval);
  }, [calisiyorMu]);

  // 8) Oyun bitti mi?
  const oyunBitti = timeLeft <= 0 || gizlenenKelimeSayisi >= kelimeler.length;

  // 9) Progress (yüzde)
  const progress = (timeLeft / initialTime) * 100;

  // Kontroller
  const handlePlayPause = () => {
    if (oyunBitti) return;
    setCalisiyorMu((prev) => !prev);
  };

  const handleReset = () => {
    setCalisiyorMu(false);
    setTimeLeft(initialTime);
    setGizlenenKelimeSayisi(0);
  };

  const handleDifficultyChange = (value) => {
    setDifficulty(value);
  };

  const handleParagraphChange = (value) => {
    setSelectedParagraphIndex(value);
  };

  const handleThemeChange = (e) => {
    setTheme(e.target.value);
  };

  return {
    userData,
    userRole,
    paragraphs,
    selectedParagraphIndex,
    difficulty,
    theme,
    speedFactor,
    setSpeedFactor,
    timeLeft,
    gizlenenKelimeSayisi,
    kelimeler,
    calisiyorMu,
    oyunBitti,
    progress,
    handleDifficultyChange,
    handleParagraphChange,
    handleThemeChange,
    handlePlayPause,
    handleReset,
    setSelectedParagraphIndex,
    isLoading,
    errorMessage,
  };
};
