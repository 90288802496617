import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItem, ListItemIcon, ListItemText, CssBaseline, Collapse, Divider, Box, useTheme, useMediaQuery,
  Tooltip, Grid, Avatar, createTheme, ThemeProvider, Paper, keyframes} from '@mui/material';
import { Menu as MenuIcon, Dashboard as DashboardIcon, School as SchoolIcon, Settings as SettingsIcon, Logout as LogoutIcon, ExpandLess, ExpandMore, Payment as PaymentIcon,
  Report as ReportIcon, LocationCity as CityIcon, Business as BranchIcon, Person as TeacherIcon, Security as SecurityIcon, People as PeopleIcon, FormatListBulleted as FormatListBulletedIcon,
  LibraryBooks as LibraryBooksIcon, AddBox as AddBoxIcon, Assignment as AssignmentIcon, MonetizationOn as MonetizationOnIcon, AccountBalanceWallet as AccountBalanceWalletIcon
} from '@mui/icons-material';
import BusinessIcon from '@mui/icons-material/Business';
import { styled } from '@mui/system';
import SehirlerContent from './kurumlar/Sehirler';
import KurumlarContent from './kurumlar/Kurumlar';
import OkullarContent from './kurumlar/Okullar';
import Users from './users/Users';
import BookUploadForm from './ogretmen/kutuphane/Add';
import BookListe from './ogretmen/kutuphane/Liste';
import Tests from './ogretmen/kutuphane/Test';
import Ogretmen from './ogretmen/Ogretmen';
import Ogrenci from './ogrenci/Ogrenci';
import BlogList  from './blog/BlogList';
import BlogAdd  from './blog/BlogAdd';
import RolePermissionManagement from './users/Role';
import PaymentList from './payments/PaymentList';
import PriceManagement from './kampanya/PriceManagement';
import PaymentReports from './payments/PaymentReports';
import BalanceList from './payments/BalanceList';
import BayilikBasvuru from './kurumlar/bayi/basvurular/BayilikBasvuru'; 
import EgiticiListesi from './ogretmen/basvuru/EgiticiListesi'; 

import { API_URLS } from '../../config/config';
import useFetchUserData from '../../hooks/admin/useFetchUserData';

const drawerWidth = 240;

const theme = createTheme({
  palette: {
    primary: {
      main: '#3f51b5', 
    },
    background: {
      default: '#f4f6f8',
      paper: '#ffffff',
    },
    text: {
      primary: '#333',
      secondary: '#555',
    },
  },
  typography: {
    fontFamily: 'Inter, Roboto, Helvetica, Arial, sans-serif',
    h4: {
      fontWeight: 600
    },
    h5: {
      fontWeight: 500
    },
    body1: {
      fontSize: 15
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#3f51b5',
          boxShadow: '0 3px 10px rgba(0,0,0,0.2)'
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          width: drawerWidth,
          backgroundColor: '#2c3e50',
          color: '#ecf0f1',
          borderRight: 'none',
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        button: {
          borderRadius: '8px',
          margin: '4px 8px',
        }
      }
    }
  }
});

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: open ? `${drawerWidth}px` : 0,
    [theme.breakpoints.down('sm')]: { marginLeft: 0 },
    marginTop: '64px',
    overflow: 'auto',
    height: `calc(100vh - 64px)`,
    backgroundColor: theme.palette.background.default,
  })
);

const AppBarStyled = styled(AppBar, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ open }) => ({
    transition: 'width 0.3s ease',
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
    }),
  })
);

// Animasyonlar
const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  20% { background-position: 25% 50%; }
  40% { background-position: 50% 50%; }
  60% { background-position: 75% 50%; }
  80% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const iconFloat = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-4px); }
`;

// Kartların degrade renkleri için bir fonksiyon
const getGradient = (variant) => {
  switch (variant) {
    case 'payment-daily':
      return 'linear-gradient(-45deg, #FF4500, #FF6347, #FF7F50, #FF8C00, #FF0000)';
    case 'payment-weekly':
      return 'linear-gradient(-45deg, #FF8C00, #FFA500, #FFB347, #FF6347, #FF4500)';
    case 'payment-monthly':
      return 'linear-gradient(-45deg, #FFD700, #FFA500, #FF8C00, #FF6347, #FF4500)';
    case 'student':
      return 'linear-gradient(-45deg, #00BFFF, #1E90FF, #4169E1, #0000CD, #000080)';
    case 'school':
      return 'linear-gradient(-45deg, #3CB371, #2E8B57, #228B22, #006400, #00FF7F)';
    case 'bayi':
      return 'linear-gradient(-45deg, #8A2BE2, #9932CC, #9400D3, #800080, #4B0082)';
    case 'teacher':
      return 'linear-gradient(-45deg, #FF1493, #FF69B4, #FFB6C1, #C71585, #DB7093)';
    default:
      return 'linear-gradient(-45deg, #FF4500, #FF6347, #FF7F50, #FF8C00, #FF0000)';
  }
};

// Kart tasarımı
const StyledCard = styled(Paper)(({ theme, variant }) => ({
  borderRadius: 16,
  padding: theme.spacing(3),
  color: '#fff',
  textAlign: 'center',
  position: 'relative',
  overflow: 'hidden',
  boxShadow: '0 8px 24px rgba(0,0,0,0.2)',
  backdropFilter: 'blur(15px)',
  background: getGradient(variant),
  backgroundSize: '600% 600%',
  animation: `${gradientAnimation} 10s ease infinite`,
  transition: 'transform 0.4s ease, box-shadow 0.4s ease',
  cursor: 'pointer',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 12px 32px rgba(0,0,0,0.3)',
    '& svg': {
      animation: `${iconFloat} 1s ease-in-out infinite`,
    }
  }
}));

function AdminDashboard() {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [userData, setUserData] = useState(null);
  const [openMenus, setOpenMenus] = useState({});
  const [selectedMenu, setSelectedMenu] = useState('Anasayfa');
  const navigate = useNavigate();
  const themeMUI = useTheme();
  const isMobile = useMediaQuery(themeMUI.breakpoints.down('sm'));
  const [paymentSummary, setPaymentSummary] = useState(null);

  const [teacherCounts, setTeacherCounts] = useState({ totalOgretmen: 0, activeOgretmen: 0, inactiveOgretmen: 0 });
  const [loadingTeacherCounts, setLoadingTeacherCounts] = useState(true);

  const [studentCounts, setStudentCounts] = useState({ total: 0, active: 0, inactive: 0 });
  const [loadingStudentCounts, setLoadingStudentCounts] = useState(true);

  const [schoolCounts, setSchoolCounts] = useState({ total: 0, active: 0, inactive: 0 });
  const [loadingSchoolCounts, setLoadingSchoolCounts] = useState(true);

  const [kurumCounts, setKurumCounts] = useState({ total: 0, active: 0, inactive: 0 });
  const [loadingKurumCounts, setLoadingKurumCounts] = useState(true);

  useFetchUserData(setUserData);

  const toggleDrawer = () => setDrawerOpen(!drawerOpen);

  const handleClick = (menu) => setOpenMenus((prevOpen) => ({ ...prevOpen, [menu]: !prevOpen[menu] }));

  const handleLogout = async () => {
    try {
      await fetch(`${API_URLS.ADMIN_URL}logout`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'Content-Type': 'application/json',
        },
      });
      localStorage.removeItem('userToken');
      navigate('/login');
    } catch (error) {
      console.error('Çıkış işlemi sırasında bir hata oluştu', error);
    }
  };

  const allMenuItems = [
    { text: 'Anasayfa', icon: <DashboardIcon />, action: () => setSelectedMenu('Anasayfa') },
    {
      text: 'Kullanıcılar',
      icon: <PeopleIcon />,
      requiredAccess: 'Kullanıcılar',
      subMenus: [
        { 
          text: 'Kullanıcılar', 
          icon: <PeopleIcon />, 
          parent: 'Kullanıcılar', 
          requiredAccess: 'Kullanıcılar', 
          action: () => setSelectedMenu('Kullanıcılar') 
        },
        { 
          text: 'Roller ve Yetkiler', 
          icon: <SecurityIcon />, 
          parent: 'Kullanıcılar', 
          requiredAccess: 'Roller ve Yetkiler', 
          action: () => setSelectedMenu('Roller') 
        },
      ],
      action: () => handleClick('Kullanıcılar'),
    },
    {
      text: 'Kurumlar',
      icon: <SchoolIcon />,
      requiredAccess: 'Kurumlar',
      subMenus: [
        { text: 'Şehirler', icon: <CityIcon />, parent: 'Kurumlar', requiredAccess: 'Şehirler', action: () => setSelectedMenu('Şehirler') },
        { text: 'Bayiler', icon: <BranchIcon />, parent: 'Kurumlar', requiredAccess: 'Bayiler', action: () => setSelectedMenu('Bayiler') },
        { text: 'Kurum', icon: <BranchIcon />, parent: 'Kurumlar', requiredAccess: 'Okullar', action: () => setSelectedMenu('Okullar') },
      ],
      action: () => handleClick('Kurumlar'),
    },
    {
      text: 'Kütüphane',
      icon: <LibraryBooksIcon />,
      requiredAccess: 'Kütüphane',
      subMenus: [
        { text: 'Kitap Ekle', icon: <AddBoxIcon />, parent: 'Kütüphane', requiredAccess: 'Kitap Ekle', action: () => setSelectedMenu('KitapEkle') },
        { text: 'Tüm Kitap Listesi', icon: <FormatListBulletedIcon />, parent: 'Kütüphane', requiredAccess: 'Tüm Kitap Listesi', action: () => setSelectedMenu('KitapListe') },
        { text: 'Testler', icon: <AssignmentIcon />, parent: 'Kütüphane', requiredAccess: 'Testler', action: () => setSelectedMenu('Testler') },
      ],
      action: () => handleClick('Kütüphane'),
    },
    { text: 'Öğretmenler', icon: <TeacherIcon />, requiredAccess: 'Öğretmenler', action: () => setSelectedMenu('Öğretmenler') },
    { text: 'Öğrenciler', icon: <PeopleIcon />, requiredAccess: 'Öğrenciler', action: () => setSelectedMenu('Öğrenciler') },
    {
      text: 'Blog',
      icon: <LibraryBooksIcon />,
      requiredAccess: 'Blog', 
      subMenus: [
        { text: 'Blog Listesi', icon: <FormatListBulletedIcon />, parent: 'Blog', requiredAccess: 'Blog', action: () => setSelectedMenu('BlogList') },
        { text: 'Yeni Blog Ekle', icon: <AddBoxIcon />, parent: 'Blog', requiredAccess: 'Blog', action: () => setSelectedMenu('BlogAdd') },
      ],
      action: () => handleClick('Blog'),
    },
    { text: 'Ödemeler', icon: <PaymentIcon />, requiredAccess: 'Ödemeler', action: () => setSelectedMenu('Ödemeler') },
    { text: 'Kampanyalar', icon: <PaymentIcon />, requiredAccess: 'Kampanyalar', action: () => setSelectedMenu('Kampanyalar') },
    {
      text: 'Raporlar',
      icon: <ReportIcon />,
      requiredAccess: 'Raporlar',
      subMenus: [
        { text: 'Ödeme Raporları', icon: <MonetizationOnIcon />, parent: 'Raporlar', requiredAccess: 'Ödeme Raporları', action: () => setSelectedMenu('Ödeme Raporları') },
        { text: 'Bakiyeler', icon: <AccountBalanceWalletIcon />, parent: 'Raporlar', requiredAccess: 'Bakiyeler', action: () => setSelectedMenu('Bakiyeler') },
      ],
      action: () => handleClick('Raporlar'),
    },
    { text: 'Ayarlar', icon: <SettingsIcon />, requiredAccess: 'Ayarlar', action: () => setSelectedMenu('Ayarlar') },
    {
      text: 'Başvurular',
      icon: <AssignmentIcon />,
      subMenus: [
        { 
          text: 'Bayilik Başvuruları', 
          icon: <AssignmentIcon />, 
          parent: 'Başvurular', 
          requiredAccess: 'Bayilik Başvuruları', 
          action: () => setSelectedMenu('Bayilik Başvuruları') 
        },
        { 
          text: 'Eğitici Başvuruları', 
          icon: <TeacherIcon />, 
          parent: 'Başvurular', 
          requiredAccess: 'Eğitici Başvuruları', 
          action: () => setSelectedMenu('Eğitici Başvuruları') 
        },
      ],
      action: () => handleClick('Başvurular'),
    },  { text: 'Çıkış Yap', icon: <LogoutIcon />, action: handleLogout },
  ];

  const menuItems = allMenuItems.filter(item => {
    if (!item.requiredAccess) return true;
    return userData && userData.accessControls && userData.accessControls.includes(item.requiredAccess);
  }).map(item => {
    if (item.subMenus) {
      const filteredSubMenus = item.subMenus.filter(subItem => {
        if (!subItem.requiredAccess) return true;
        return userData && userData.accessControls && userData.accessControls.includes(subItem.requiredAccess);
      });
      return { ...item, subMenus: filteredSubMenus };
    }
    return item;
  });

  const formatter = new Intl.NumberFormat('tr-TR', {
    style: 'currency',
    currency: 'TRY',
    minimumFractionDigits: 2,
  });

  const renderAktifPasif = (aktifSayi, pasifSayi) => (
    <Box mt={2} display="flex" justifyContent="space-around" width="100%">
      <Box textAlign="center">
        <Typography variant="body2" sx={{ fontWeight: 600 }}>Aktif</Typography>
        <Typography variant="h6" sx={{ fontWeight: 700 }}>
          {aktifSayi}
        </Typography>
      </Box>
      <Box textAlign="center">
        <Typography variant="body2" sx={{ fontWeight: 600 }}>Pasif</Typography>
        <Typography variant="h6" sx={{ fontWeight: 700 }}>
          {pasifSayi}
        </Typography>
      </Box>
    </Box>
  );

  const renderContent = () => {
    if (!userData) return <Typography paragraph>Yükleniyor...</Typography>;

    switch (selectedMenu) {
      case 'Anasayfa':
        return (
          <>
            <Typography variant="h4" gutterBottom>
              Yönetim Paneli
            </Typography>
            <Typography variant="body1" paragraph>
              Hoşgeldiniz, {userData.fullname}. Rolleriniz: {userData.roles.map(role => role.name).join(', ')}<br/>
              Erişim izniniz olan modüller: {userData.accessControls.join(', ')}
            </Typography>
            <Grid container spacing={3} sx={{ mt: 2 }}>
              
              <Grid item xs={12} sm={6} md={4}>
                <StyledCard variant="payment-daily" onClick={() => setSelectedMenu('Ödemeler')}>
                  <PaymentIcon sx={{ fontSize: 48, mb: 1 }} />
                  <Typography variant="body1" sx={{ fontWeight: 600 }}>Günlük Toplam Ödeme</Typography>
                  <Typography variant="h5" sx={{ fontWeight: 900, mt: 1 }}>
                    {paymentSummary ? `${formatter.format(paymentSummary.dailyTotal)}` : 'Yükleniyor...'}
                  </Typography>
                </StyledCard>
              </Grid>
      
              <Grid item xs={12} sm={6} md={4}>
                <StyledCard variant="payment-weekly" onClick={() => setSelectedMenu('Ödemeler')}>
                  <PaymentIcon sx={{ fontSize: 48, mb: 1 }} />
                  <Typography variant="body1" sx={{ fontWeight: 600 }}>Haftalık Toplam Ödeme</Typography>
                  <Typography variant="h5" sx={{ fontWeight: 900, mt: 1 }}>
                    {paymentSummary ? `${formatter.format(paymentSummary.weeklyTotal)}` : 'Yükleniyor...'}
                  </Typography>
                </StyledCard>
              </Grid>
      
              <Grid item xs={12} sm={6} md={4}>
                <StyledCard variant="payment-monthly" onClick={() => setSelectedMenu('Ödemeler')}>
                  <PaymentIcon sx={{ fontSize: 48, mb: 1 }} />
                  <Typography variant="body1" sx={{ fontWeight: 600 }}>Aylık Toplam Ödeme</Typography>
                  <Typography variant="h5" sx={{ fontWeight: 900, mt: 1 }}>
                    {paymentSummary ? `${formatter.format(paymentSummary.monthlyTotal)}` : 'Yükleniyor...'}
                  </Typography>
                </StyledCard>
              </Grid>
      
              <Grid item xs={12} sm={6} md={4}>
                <StyledCard variant="student" onClick={() => setSelectedMenu('Öğrenciler')}>
                  <PeopleIcon sx={{ fontSize: 48, mb: 1 }} />
                  <Typography variant="body1" sx={{ fontWeight: 600 }}>Toplam Öğrenci</Typography>
                  <Typography variant="h5" sx={{ fontWeight: 900, mt: 1 }}>
                    {loadingStudentCounts ? 'Yükleniyor...' : studentCounts.total}
                  </Typography>
                  {!loadingStudentCounts && renderAktifPasif(studentCounts.active, studentCounts.inactive)}
                </StyledCard>
              </Grid>
      
              <Grid item xs={12} sm={6} md={4}>
                <StyledCard variant="school" onClick={() => setSelectedMenu('Okullar')}>
                  <SchoolIcon sx={{ fontSize: 48, mb: 1 }} />
                  <Typography variant="body1" sx={{ fontWeight: 600 }}>Toplam Okul</Typography>
                  <Typography variant="h5" sx={{ fontWeight: 900, mt: 1 }}>
                    {loadingSchoolCounts ? 'Yükleniyor...' : schoolCounts.total}
                  </Typography>
                  {!loadingSchoolCounts && renderAktifPasif(schoolCounts.active, schoolCounts.inactive)}
                </StyledCard>
              </Grid>
      
              <Grid item xs={12} sm={6} md={4}>
                <StyledCard variant="bayi" onClick={() => setSelectedMenu('Bayiler')}>
                  <BusinessIcon sx={{ fontSize: 48, mb: 1 }} />
                  <Typography variant="body1" sx={{ fontWeight: 600 }}>Toplam Bayi</Typography>
                  <Typography variant="h5" sx={{ fontWeight: 900, mt: 1 }}>
                    {loadingKurumCounts ? 'Yükleniyor...' : kurumCounts.total}
                  </Typography>
                  {!loadingKurumCounts && renderAktifPasif(kurumCounts.active, kurumCounts.inactive)}
                </StyledCard>
              </Grid>
      
              <Grid item xs={12} sm={6} md={4}>
                <StyledCard variant="teacher" onClick={() => setSelectedMenu('Öğretmenler')}>
                  <TeacherIcon sx={{ fontSize: 48, mb: 1 }} />
                  <Typography variant="body1" sx={{ fontWeight: 600 }}>Toplam Öğretmen</Typography>
                  <Typography variant="h5" sx={{ fontWeight: 900, mt: 1 }}>
                    {loadingTeacherCounts ? 'Yükleniyor...' : teacherCounts.totalOgretmen}
                  </Typography>
                  {!loadingTeacherCounts && renderAktifPasif(teacherCounts.activeOgretmen, teacherCounts.inactiveOgretmen)}
                </StyledCard>
              </Grid>
            </Grid>
          </>
        );
      
      case 'Şehirler':
        return userData.accessControls.includes('Şehirler') ? <SehirlerContent /> : <Typography paragraph>Erişim izniniz yok.</Typography>;
      case 'Bayiler':
        return userData.accessControls.includes('Bayiler') ? <KurumlarContent /> : <Typography paragraph>Erişim izniniz yok.</Typography>;
      case 'Okullar':
        return userData.accessControls.includes('Okullar') ? <OkullarContent /> : <Typography paragraph>Erişim izniniz yok.</Typography>;
      case 'Kullanıcılar':
        return userData.accessControls.includes('Kullanıcılar') ? <Users /> : <Typography paragraph>Erişim izniniz yok.</Typography>;
      case 'Roller':
        return userData.accessControls.includes('Roller ve Yetkiler') ? <RolePermissionManagement /> : <Typography paragraph>Erişim izniniz yok.</Typography>;
      case 'KitapEkle':
        return userData.accessControls.includes('Kitap Ekle') ? <BookUploadForm /> : <Typography paragraph>Erişim izniniz yok.</Typography>;
      case 'KitapListe':
        return userData.accessControls.includes('Tüm Kitap Listesi') ? <BookListe /> : <Typography paragraph>Erişim izniniz yok.</Typography>;
      case 'Testler':
        return userData.accessControls.includes('Testler') ? <Tests /> : <Typography paragraph>Erişim izniniz yok.</Typography>;
      case 'Öğretmenler':
        return userData.accessControls.includes('Öğretmenler') ? <Ogretmen /> : <Typography paragraph>Erişim izniniz yok.</Typography>;
      case 'Öğrenciler':
        return userData.accessControls.includes('Öğrenciler') ? <Ogrenci /> : <Typography paragraph>Erişim izniniz yok.</Typography>;
      case 'BlogList':
        return userData.accessControls.includes('Blog') ? <BlogList /> : <Typography paragraph>Erişim izniniz yok.</Typography>;
      case 'BlogAdd':
        return userData.accessControls.includes('Blog') ? <BlogAdd /> : <Typography paragraph>Erişim izniniz yok.</Typography>;
      case 'Ödemeler':
        return userData.accessControls.includes('Ödemeler') ? <PaymentList /> : <Typography paragraph>Erişim izniniz yok.</Typography>;
      case 'Kampanyalar':
        return userData.accessControls.includes('Kampanyalar') ? <PriceManagement /> : <Typography paragraph>Erişim izniniz yok.</Typography>;
      case 'Ödeme Raporları':
        return userData.accessControls.includes('Ödeme Raporları') ? <PaymentReports /> : <Typography paragraph>Erişim izniniz yok.</Typography>;
      case 'Bakiyeler':
        return userData.accessControls.includes('Bakiyeler') ? <BalanceList /> : <Typography paragraph>Erişim izniniz yok.</Typography>;
      case 'Bayilik Başvuruları':
        return userData.accessControls.includes('Bayilik Başvuruları') ? <BayilikBasvuru /> : <Typography paragraph>Erişim izniniz yok.</Typography>;
      case 'Eğitici Başvuruları':
      return userData.accessControls.includes('Eğitici Başvuruları') ? <EgiticiListesi /> : <Typography paragraph>Erişim izniniz yok.</Typography>;
      default:
      return <Typography paragraph>Anasayfa</Typography>;
    }
  };

  useEffect(() => {
    const fetchTeacherCounts = async () => {
      try {
        const response = await fetch(`${API_URLS.ADMIN_URL}teachers/count`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        if (response.ok) {
          setTeacherCounts({
            totalOgretmen: data.totalOgretmen,
            activeOgretmen: data.activeOgretmen,
            inactiveOgretmen: data.inactiveOgretmen,
          });
        } else {
          console.error('Öğretmen sayıları alınamadı:', data.message);
        }
      } catch (error) {
        console.error('Öğretmen sayıları alınırken bir hata oluştu:', error);
      } finally {
        setLoadingTeacherCounts(false);
      }
    };

    fetchTeacherCounts();
  }, []);

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const response = await fetch(`${API_URLS.ADMIN_URL}student/total`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        if (response.ok) {
          setStudentCounts({
            total: data.total,
            active: data.active,
            inactive: data.inactive,
          });
        } else {
          console.error('Toplam öğrenci alınamadı:', data.message);
        }
      } catch (error) {
        console.error('Toplam öğrenci alınırken bir hata oluştu:', error);
      } finally {
        setLoadingStudentCounts(false);
      }
    };
    fetchStudents();
  }, []);

  useEffect(() => {
    const fetchSchools = async () => {
      try {
        const response = await fetch(`${API_URLS.ADMIN_URL}schools/total`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        if (response.ok) {
          setSchoolCounts({
            total: data.total,
            active: data.active,
            inactive: data.inactive,
          });
        } else {
          console.error('Toplam okul sayısı alınamadı:', data.message);
        }
      } catch (error) {
        console.error('Toplam okul sayısı alınırken bir hata oluştu:', error);
      } finally {
        setLoadingSchoolCounts(false);
      }
    };
    fetchSchools();
  }, []);

  useEffect(() => {
    const fetchKurums = async () => {
      try {
        const response = await fetch(`${API_URLS.ADMIN_URL}kurumsal/total`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        if (response.ok) {
          setKurumCounts({
            total: data.total,
            active: data.active,
            inactive: data.inactive,
          });
        } else {
          console.error('Toplam kurum sayısı alınamadı:', data.message);
        }
      } catch (error) {
        console.error('Toplam kurum sayısı alınırken bir hata oluştu:', error);
      } finally {
        setLoadingKurumCounts(false);
      }
    };
    fetchKurums();
  }, []);

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const response = await fetch(`${API_URLS.ADMIN_URL}payments/summary`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          },
        });
        const data = await response.json();
        if (response.ok) {
          setPaymentSummary(data);
        } else {
          console.error('Toplam ödemeler alınamadı:', data.message);
        }
      } catch (error) {
        console.error('Toplam ödemeler alınırken bir hata oluştu:', error);
      }
    };

    fetchPayments();
  }, []);

  useEffect(() => {
    if (selectedMenu) {
      menuItems.forEach((item) => {
        if (item.subMenus) {
          item.subMenus.forEach((subItem) => {
            if (subItem.text === selectedMenu && subItem.parent) {
              setOpenMenus((prevOpen) => ({ ...prevOpen, [subItem.parent]: true }));
            }
          });
        }
      });
    }
  }, [selectedMenu, menuItems]);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', minHeight: '100vh', backgroundColor: theme.palette.background.default }}>
        <CssBaseline />
        <AppBarStyled position="fixed" open={drawerOpen}>
          <Toolbar>
            <IconButton color="inherit" aria-label="open drawer" onClick={toggleDrawer} edge="start" sx={{ mr: 2 }}>
              <MenuIcon />
            </IconButton>
            <Box flexGrow={1}>
              <Typography variant="h6" noWrap component="div">
                {userData ? `Hoş Geldiniz, ${userData.fullname}` : 'Yönetim Paneli'}
              </Typography>
            </Box>
            <Avatar alt={userData?.fullname || 'Admin'} src="" sx={{ bgcolor: 'secondary.main' }} />
          </Toolbar>
        </AppBarStyled>
        <Drawer
          variant={isMobile ? 'temporary' : 'persistent'}
          anchor="left"
          open={drawerOpen}
          onClose={toggleDrawer}
          sx={{ '& .MuiDrawer-paper': { boxSizing: 'border-box' } }}
        >
          <Toolbar />
          <Divider />
          <List>
            {menuItems.map((item, index) => (
              <React.Fragment key={index}>
                <ListItem
                  button
                  onClick={() => item.action && item.action()}
                  selected={item.text === selectedMenu}
                  sx={{
                    '&.Mui-selected': {
                      backgroundColor: 'rgba(255, 255, 255, 0.2)',
                    },
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    },
                  }}
                >
                  <ListItemIcon sx={{ color: '#ecf0f1' }}>
                    <Tooltip title={item.text} placement="right" disableHoverListener={!isMobile}>
                      {item.icon}
                    </Tooltip>
                  </ListItemIcon>
                  <ListItemText primary={item.text} />
                  {item.subMenus && item.subMenus.length > 0 && (openMenus[item.text] ? <ExpandLess /> : <ExpandMore />)}
                </ListItem>
                {item.subMenus && item.subMenus.length > 0 && (
                  <Collapse in={openMenus[item.text]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {item.subMenus.map((subItem, subIndex) => (
                        <ListItem
                          button
                          key={subIndex}
                          onClick={subItem.action}
                          selected={subItem.text === selectedMenu}
                          sx={{
                            pl: 4,
                            '&.Mui-selected': { backgroundColor: 'rgba(255, 255, 255, 0.2)' },
                            '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' }
                          }}
                        >
                          <ListItemIcon sx={{ color: '#ecf0f1' }}>
                            <Tooltip title={subItem.text} placement="right" disableHoverListener={!isMobile}>
                              {subItem.icon}
                            </Tooltip>
                          </ListItemIcon>
                          <ListItemText primary={subItem.text} />
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                )}
              </React.Fragment>
            ))}
          </List>
        </Drawer>
        <Main open={drawerOpen}>
          {renderContent()}
        </Main>
      </Box>
    </ThemeProvider>
  );
}

export default AdminDashboard;
