// src/pages/Oyunlar/TachistoscopicReading/components/SettingsModalTachisto.js

import React from 'react';
import '../styles/tachistoscopicReadingStyle.css'; // kendi stillerinizi ekleyebilirsiniz

const SettingsModalTachisto = ({
  onClose,
  difficulty,
  onDifficultyChange,
  theme,
  setTheme,
  font,
  setFont,
  fontSize,
  setFontSize,
  speed,
  handleSpeedChange,
  getSpeedInSeconds,
}) => {
  return (
    <div className="settings-modal-backdrop">
      <div className="settings-modal-content">
        {/* Sağ üstteki kapatma butonu */}
        <button
          className="settings-modal-close-button"
          onClick={onClose}
          aria-label="Kapat"
        >
          X
        </button>

        <h2 className="settings-modal-title">Ayarlar</h2>

        {/* Zorluk Seçimi */}
        <div className="settings-form-group">
          <label className="settings-form-label" htmlFor="difficultySelect">
            Zorluk Seç:
          </label>
          <select
            id="difficultySelect"
            className="settings-form-select"
            value={difficulty}
            onChange={(e) => onDifficultyChange(e.target.value)}
          >
            <option value="easy">Kolay</option>
            <option value="medium">Orta</option>
            <option value="hard">Zor</option>
          </select>
        </div>

        {/* Tema Seçimi */}
        <div className="settings-form-group">
          <label className="settings-form-label" htmlFor="themeSelect">
            Tema Seç:
          </label>
          <select
            id="themeSelect"
            className="settings-form-select"
            value={theme}
            onChange={(e) => setTheme(e.target.value)}
          >
            <option value="default">Varsayılan (Açık)</option>
            <option value="tomer">Tömer</option>
            <option value="dark">Koyu</option>         
            <option value="blue">Mavi</option>
            <option value="green">Yeşil</option>
            <option value="red">Kırmızı</option>
            <option value="purple">Mor</option>
            <option value="orange">Turuncu</option>
            <option value="pink">Pembe</option>
          </select>
        </div>

        {/* Font Seçimi */}
        <div className="settings-form-group">
          <label className="settings-form-label" htmlFor="fontSelect">
            Yazı Tipi:
          </label>
          <select
            id="fontSelect"
            className="settings-form-select"
            value={font}
            onChange={(e) => setFont(e.target.value)}
          >
            <option value="Poppins">Poppins</option>
            <option value="Orbitron">Orbitron</option>
            <option value="Roboto">Roboto</option>
            <option value="Arial">Arial</option>
            <option value="Courier New">Courier New</option>
          </select>
        </div>

        {/* Yazı Boyutu Seçimi */}
        <div className="settings-form-group">
          <label className="settings-form-label" htmlFor="fontSizeSelect">
            Yazı Boyutu:
          </label>
          <select
            id="fontSizeSelect"
            className="settings-form-select"
            value={fontSize}
            onChange={(e) => setFontSize(e.target.value)}
          >
            <option value="1rem">Küçük</option>
            <option value="1.5rem">Orta</option>
            <option value="2rem">Büyük</option>
          </select>
        </div>

        {/* Hız Ayarı */}
        <div className="settings-form-group">
          <label className="settings-form-label" htmlFor="speedRange">
            Hız: {getSpeedInSeconds(speed)} sn
          </label>
          <input
            type="range"
            id="speedRange"
            value={speed / 1000}
            onChange={handleSpeedChange}
            min="1"
            max="5"
            step="0.5"
            className="settings-form-range"
          />
        </div>

        {/* Alt "Kapat" butonu */}
        <div style={{ marginTop: '1rem', textAlign: 'center' }}>
          <button
            className="settings-modal-bottom-close-button"
            onClick={onClose}
          >
            Kapat
          </button>
        </div>
      </div>
    </div>
  );
};

export default SettingsModalTachisto;
