import React, { useEffect } from 'react';

const GameTimer = ({ isPlaying, timeRemaining, initialGameTime, setTimeRemaining }) => {
  useEffect(() => {
    let timer;
    if (isPlaying && timeRemaining > 0) {
      timer = setInterval(() => {
        setTimeRemaining((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isPlaying, timeRemaining, setTimeRemaining]);

  const formatTime = (seconds) => {
    if (seconds < 0) seconds = 0;
    const minutes = Math.floor(seconds / 60).toString().padStart(2, '0');
    const remainSec = (seconds % 60).toString().padStart(2, '0');
    return `${minutes}:${remainSec}`;
  };

  const percentage = (timeRemaining / initialGameTime) * 100;

  const getProgressColor = (pct) => {
    if (pct > 50) return '#2ecc71'; // Yeşil
    if (pct > 20) return '#f1c40f'; // Sarı
    return '#e74c3c'; // Kırmızı
  };

  return (
    <div className="zigzag-timer-container">
      <div className="zigzag-timer">{formatTime(timeRemaining)}</div>
      <div className="zigzag-timer-progress-bar">
        <div
          className="zigzag-timer-progress"
          style={{
            width: `${percentage}%`,
            backgroundColor: getProgressColor(percentage),
          }}
        ></div>
      </div>
    </div>
  );
};

export default GameTimer;
