import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Grid,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Button,
  Typography,
  CardActions,
  Container
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/material/styles';

// 1) react-responsive importu
import { useMediaQuery as useOrientationQuery } from 'react-responsive';

import Header from '../components/Header';
import Footer from '../components/Footer';

// ----- MUI Tema (Kartların stil overrides) -----
const theme = createTheme({
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          maxWidth: 345,
          borderRadius: '16px',
          boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
          transition: '0.3s',
          '&:hover': {
            boxShadow: '0 16px 32px 0 rgba(0,0,0,0.3)',
            transform: 'scale(1.05)',
          },
          '.MuiCardMedia-root': {
            height: 140,
            transition: 'transform 0.15s ease-in-out',
            '&:hover': {
              transform: 'scale(1.03)',
            },
          },
          '.MuiCardContent-root': {
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
          },
          '.MuiButton-root': {
            backgroundColor: '#d32f2f',
            color: '#fff',
            '&:hover': {
              backgroundColor: '#9a0007',
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '8px 16px',
        },
      },
    },
  },
});

// ----- Kayıt seçenekleri (kart içerikleri) -----
const loginOptions = [
  {
    title: 'Kurum Kayıt',
    imageUrl: '/images/corporate-login.webp',
    path: '/okul-kayit',
  },
  {
    title: 'Öğretmen Kayıt',
    imageUrl: '/images/teacher-login.webp',
    path: '/ogretmen-kayit',
  },
  {
    title: 'Öğrenci Kayıt',
    imageUrl: '/images/student-login.webp',
    path: '/ogrenci-kayit',
  },
];

// ----- .bg-main yerine styled bileşen -----
const BgMain = styled('div')(({ isPortrait }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  overflow: 'hidden',
  // Dikeyte (portrait) daha az padding, yatayda (landscape) daha fazla
  padding: isPortrait ? '40px' : '60px',
  // Yükseklik de duruma göre
  minHeight: isPortrait ? '80vh' : '90vh',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    // Arka plan resmi
    backgroundImage: 'url("/images/egzersiz_bg.webp")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    filter: 'blur(3px)',
    zIndex: -1,
  },
}));

function MainRegister() {
  // 2) Orientation yakalama
  const isPortrait = useOrientationQuery({ query: '(orientation: portrait)' });
  const navigate = useNavigate();

  const handleLoginClick = (path) => {
    navigate(path);
  };

  // 3) Kartların sütun sayısını orientation'a göre belirliyoruz
  const gridColumnSize = isPortrait ? 12 : 4;

  return (
    <ThemeProvider theme={theme}>
      <Header />

      {/* Arka planlı container */}
      <BgMain isPortrait={isPortrait}>
        <Container
          maxWidth="lg"
          sx={{
            // Dikey modda az, yatayda daha fazla margin-top, margin-bottom
            mt: isPortrait ? 2 : 8,
            mb: isPortrait ? 4 : 10,
            maxHeight: isPortrait ? '65vh' : '70vh',
            overflowY: 'auto',
          }}
        >
          <Grid container spacing={4} alignItems="stretch">
            {loginOptions.map((option, index) => (
              <Grid item xs={gridColumnSize} key={index}>
                <Card
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                  <CardActionArea
                    onClick={() => handleLoginClick(option.path)}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      flexGrow: 1,
                    }}
                  >
                    <CardMedia
                      component="img"
                      image={option.imageUrl}
                      alt={option.title}
                    />
                    <CardContent
                      sx={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        textAlign="center"
                      >
                        {option.title}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions sx={{ justifyContent: 'center', padding: '16px' }}>
                    <Button
                      size="large"
                      color="primary"
                      variant="contained"
                      fullWidth
                      onClick={() => handleLoginClick(option.path)}
                    >
                      KAYIT OL
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </BgMain>

      <Footer />
    </ThemeProvider>
  );
}

export default MainRegister;
