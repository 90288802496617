import React from 'react';
import { Box, Tabs, Tab, Typography, Grid, Card, CardContent } from '@mui/material';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';

function StageTabs({
  stages,
  selectedStage,
  onTabChange,
  prepareChartData,
}) {
  const chartData = prepareChartData(selectedStage);

  return (
    <>
      <Box
        sx={{
          width: '100%',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          boxShadow: 6,
          borderRadius: 3,
          padding: 2,
          marginBottom: 4,
        }}
      >
        <Tabs
          value={selectedStage}
          onChange={onTabChange}
          variant="scrollable"
          scrollButtons="auto"
          indicatorColor="secondary"
          textColor="secondary"
        >
          {stages.map((stage, index) => (
            <Tab
              key={index}
              label={`Aşama ${stage.stageNumber}`}
              sx={{
                textTransform: 'none',
                fontWeight: 'bold',
                color: '#555',
                '&.Mui-selected': {
                  color: '#ff5722',
                },
              }}
            />
          ))}
        </Tabs>
      </Box>

      {chartData.length === 0 ? (
        <Box sx={{ marginTop: 4, textAlign: 'center' }}>
          <Typography variant="h6" color="#fff">
            Henüz tamamlanmış bir oyun bulunmamaktadır.
          </Typography>
        </Box>
      ) : (
        <Grid container spacing={4} sx={{ marginTop: 4 }}>
          {/* Skor Grafiği */}
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                boxShadow: 6,
                borderRadius: 3,
                backgroundImage: 'linear-gradient(to right, #8360c3, #2ebf91)',
                color: '#fff',
                transition: 'transform 0.3s',
                '&:hover': {
                  transform: 'scale(1.02)',
                  boxShadow: 8,
                },
              }}
            >
              <CardContent>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ fontWeight: 'bold', color: '#fff' }}
                >
                  Skor Grafiği
                </Typography>
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart
                    data={chartData}
                    margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" stroke="#fff" />
                    <XAxis dataKey="gameName" stroke="#fff" />
                    <YAxis stroke="#fff" />
                    <Tooltip
                      formatter={(value) => [value, 'Skor']}
                      contentStyle={{
                        backgroundColor: '#2ebf91',
                        color: '#fff',
                        borderRadius: '10px',
                      }}
                    />
                    <Legend wrapperStyle={{ color: '#fff' }} />
                    <Bar dataKey="score" name="Skor" fill="#ffeb3b" />
                  </BarChart>
                </ResponsiveContainer>
              </CardContent>
            </Card>
          </Grid>

          {/* Süre Grafiği */}
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                boxShadow: 6,
                borderRadius: 3,
                backgroundImage: 'linear-gradient(to right, #ff7e5f, #feb47b)',
                color: '#fff',
                transition: 'transform 0.3s',
                '&:hover': {
                  transform: 'scale(1.02)',
                  boxShadow: 8,
                },
              }}
            >
              <CardContent>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ fontWeight: 'bold', color: '#fff' }}
                >
                  Süre Grafiği
                </Typography>
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart
                    data={chartData}
                    margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" stroke="#fff" />
                    <XAxis dataKey="gameName" stroke="#fff" />
                    <YAxis stroke="#fff" />
                    <Tooltip
                      formatter={(value) => [`${value} saniye`, 'Süre']}
                      contentStyle={{
                        backgroundColor: '#ff7e5f',
                        color: '#fff',
                        borderRadius: '10px',
                      }}
                    />
                    <Legend wrapperStyle={{ color: '#fff' }} />
                    <Line
                      type="monotone"
                      dataKey="time"
                      name="Süre (saniye)"
                      stroke="#fff"
                      activeDot={{ r: 8 }}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </CardContent>
            </Card>
          </Grid>

          {/* Kelime Sayısı Grafiği (varsa) */}
          {chartData.some((d) => d.wordCount > 0) && (
            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  boxShadow: 6,
                  borderRadius: 3,
                  backgroundImage: 'linear-gradient(to right, #00b4db, #0083b0)',
                  color: '#fff',
                  transition: 'transform 0.3s',
                  '&:hover': {
                    transform: 'scale(1.02)',
                    boxShadow: 8,
                  },
                }}
              >
                <CardContent>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ fontWeight: 'bold', color: '#fff' }}
                  >
                    Kelime Sayısı Grafiği
                  </Typography>
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart
                      data={chartData}
                      margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" stroke="#fff" />
                      <XAxis dataKey="gameName" stroke="#fff" />
                      <YAxis stroke="#fff" />
                      <Tooltip
                        formatter={(value) => [value, 'Kelime Sayısı']}
                        contentStyle={{
                          backgroundColor: '#0083b0',
                          color: '#fff',
                          borderRadius: '10px',
                        }}
                      />
                      <Legend wrapperStyle={{ color: '#fff' }} />
                      <Bar dataKey="wordCount" name="Kelime Sayısı" fill="#ff5722" />
                    </BarChart>
                  </ResponsiveContainer>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
}

export default StageTabs;
