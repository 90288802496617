import React from 'react';
import ProgressBarIkiser from './ProgressBarIkiser';


const GameTimerIkiser = ({ timeLeft, isPlaying, gameTime, elapsedTime }) => {
 
  const progress = gameTime > 0 
    ? (elapsedTime / gameTime) * 100 
    : 0;

  return (
    <div className="timer mt-4">
 
      <span>Kalan Süre: {Math.floor(timeLeft)} sn</span>
      <ProgressBarIkiser progress={progress} />
    </div>
  );
};

export default GameTimerIkiser;
