// ActivateStudentDialog.jsx
import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import { API_URLS } from '../../../../config/config';

const ActivateStudentDialog = ({ open, onClose, student, onActivationSuccess }) => {
  const [selectedPackage, setSelectedPackage] = useState('');

  const handleActivate = async () => {
    if (!selectedPackage) {
      alert('Lütfen paket süresi seçiniz.');
      return;
    }
    try {
      const token = localStorage.getItem('userToken');
      const response = await fetch(
        `${API_URLS.KURUMSAL_URL}student/activate/${student._id}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ selectedPackageDuration: selectedPackage }),
        }
      );

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Öğrenci aktifleştirme hatası');
      }

      alert('Öğrenci başarıyla aktifleştirildi!');
      onClose();
      onActivationSuccess(); // Listeyi yenilemek için callback
    } catch (err) {
      alert(err.message);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Öğrenci Aktifleştir</DialogTitle>
      <DialogContent dividers>
        {student && (
          <div style={{ marginBottom: '1rem' }}>
            <p><strong>Öğrenci:</strong> {student.ad} {student.soyad}</p>
            <p><strong>Durum:</strong> {student.status}</p>
          </div>
        )}
        <FormControl fullWidth>
          <InputLabel>Paket Süresi</InputLabel>
          <Select
            value={selectedPackage}
            onChange={(e) => setSelectedPackage(e.target.value)}
          >
            <MenuItem value="">
              <em>Seçiniz</em>
            </MenuItem>
            <MenuItem value={3}>3 Aylık</MenuItem>
            <MenuItem value={6}>6 Aylık</MenuItem>
            <MenuItem value={12}>12 Aylık</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>İptal</Button>
        <Button onClick={handleActivate} variant="contained" color="primary">
          Aktifleştir
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ActivateStudentDialog;
