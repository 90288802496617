import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import useGameLogic from './hooks/useRotatingRectangleGame'; 
import GameControls from './components/GameControls';
import ScoreDisplay from './components/ScoreDisplay';
import GameTimerDisplay from './components/GameTimerDisplay';
import GameOverModal from './components/GameOverModal';
import GameHeader from './components/GameHeader';
import { API_URLS } from '../../../config/config';
import axiosInstance from '../../../utils/axiosInstance';

import './styles/rotatingRectangleGame.css'; 

const RotatingRectangleGame = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { stage, exercise } = location.state || {};
  const token = localStorage.getItem('userToken');

  const [userData, setUserData] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [showIntroduction, setShowIntroduction] = useState(true);

  // Kullanıcı verilerini (önce öğrenci, yoksa öğretmen) çek
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const studentResponse = await axiosInstance.get(`${API_URLS.STUDENT_URL}/me`);
        if (
          studentResponse.status === 200 &&
          studentResponse.data &&
          studentResponse.data.status === 'active'
        ) {
          setUserData(studentResponse.data);
          setUserRole('student');
          return;
        } else {
          setUserData(null);
          setUserRole(null);
        }
      } catch (error) {
        console.error('Öğrenci verisi alınırken hata oluştu:', error);
      }

      // Öğrenci yoksa öğretmen verisine bak
      try {
        const teacherResponse = await fetch(`${API_URLS.TEACHER_URL}/me`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (teacherResponse.ok) {
          const teacherData = await teacherResponse.json();
          setUserData(teacherData);
          setUserRole('teacher');
          return;
        }
      } catch (error) {
        console.error('Öğretmen verisi alınırken hata oluştu:', error);
      }

      setUserData(null);
      setUserRole(null);
    };

    fetchUserData();
  }, [token]);

  const {
    canvasRef,
    isPlaying,
    setIsPlaying,
    speed,
    setSpeed,
    score,
    gameTime,
    initialGameTime,
    drawRectangle,
    resetGame,
    isGameOver,
    setIsGameOver,
  } = useGameLogic(userData);

  // Oyun ilerlemesini (stage/exercise) güncelle
  const updateProgress = async () => {
    if (!userData || !userRole) return;
    try {
      const payload = {
        stageNumber: stage?.number || 1,
        gameName: exercise?.gameName || 'Harf Döngüsü 2',
        score: score,
        time: (userData.status === 'inactive' ? 15 : 60) - gameTime,
        completed: true,
      };

      if (userRole === 'student') {
        const apiUrl = `${API_URLS.STUDENT_URL}akillikurs/progress/update-game`;
        await axiosInstance.post(apiUrl, payload);
      } else {
        const apiUrl = `${API_URLS.TEACHER_URL}akillikurs/progress/update-game`;
        const teacherToken = localStorage.getItem('userToken');
        await axios.post(apiUrl, payload, {
          headers: {
            Authorization: `Bearer ${teacherToken}`,
            'Content-Type': 'application/json',
          },
        });
      }

      console.log('İlerleme başarıyla güncellendi.');
    } catch (error) {
      console.error('İlerleme güncellenirken hata oluştu:', error);
    }
  };

  // Oyun bittiğinde
  const onGameOver = () => {
    setIsPlaying(false);
    setIsGameOver(true);
    updateProgress();
  };

  // Oyun içi çizim, hızla orantılı tekrar
  useEffect(() => {
    if (isPlaying) {
      const interval = setInterval(() => {
        drawRectangle();
      }, Math.max(1000 / speed, 100));
      return () => clearInterval(interval);
    }
  }, [isPlaying, speed, drawRectangle]);

  // Zaman sıfırlandıysa
  useEffect(() => {
    if (gameTime === 0 && isPlaying) {
      onGameOver();
    }
  }, [gameTime, isPlaying]);

  // Intro ekrandan çıkış
  const startGame = () => {
    setShowIntroduction(false);
  };

  // Önceki sayfaya / aşamaya dön
  const handleReturnToPreviousStage = () => {
    if (stage) {
      navigate(
        userRole === 'student' ? '/ogrenci-dashboard' : '/ogretmen-dashboard',
        {
          state: {
            selectedMenu: 'ExerciseList',
            selectedStage: stage,
          },
        }
      );
    } else {
      alert('Aşama bilgisi bulunmamaktadır.');
    }
  };

  return (
    <div className="rotating-rectangle-container game-container-bg">
     
      {showIntroduction ? (
      <div className="intro-container">
         <GameHeader handleReturnToPreviousStage={handleReturnToPreviousStage}         
         stage ={stage}
         
         />

      {/* Başlık */}
      <h1 className="intro-title mt-4">Harf Döngüsü 2</h1>

      {/* Açıklama Metni */}
      <p className="intro-description">
        Bu egzerside amaç, ekrandaki döngü yapan harfleri takip etmektir.
      </p>

      {/* Talimatlar Kutusu */}
      <div className="intro-instructions-box">
        <h3 className="intro-instructions-title">Talimatlar</h3>
        <ul className="intro-instructions-list">
          <li>1.  Döngü yapan harfleri gözlerinizle izleyin.
          </li>
          <li>2.  Harflerin hızını artırarak dikkatinizi odaklayın.
          </li>
          <li>3.  Egzersizin hızını artırarak zorluğu yükseltin.
          </li>
        </ul>
      </div>

      {/* Başlat Butonu */}
      <button className="intro-button mt-4" onClick={startGame}>
        BAŞLAT
      </button>
    </div>
      ) : (
        <div className="rotating-rectangle-gameplay">
           <GameHeader handleReturnToPreviousStage={handleReturnToPreviousStage}
            stage ={stage}
           />

          <div className="rotating-rectangle-status">
            <GameTimerDisplay
              gameTime={gameTime}
              initialGameTime={initialGameTime}
              userRole={userRole}
            />
            {userData && <ScoreDisplay score={score} />}
          </div>

          <div className="rotating-rectangle-canvas-container">
            <canvas
              ref={canvasRef}
              width="800"
              height="600"
              className="rotating-rectangle-game-canvas"
            />
          </div>

          <GameControls
            speed={speed}
            setSpeed={setSpeed}
            isPlaying={isPlaying}
            setIsPlaying={setIsPlaying}
            resetGame={resetGame}
          />
        </div>
      )}
      {isGameOver && (
        <GameOverModal
          score={score}
          onRestart={resetGame}
          userData={userData}
          onClose={() => setIsGameOver(false)}
        />
      )}
    </div>
  );
};

export default RotatingRectangleGame;
