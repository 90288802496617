import { useRef, useState, useCallback, useEffect } from 'react';

const CANVAS_WIDTH = 800;
const CANVAS_HEIGHT = 600;

export default function useGameLogic(userData) {
  const canvasRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [speed, setSpeed] = useState(1);
  const [score, setScore] = useState(0);
  const [isGameOver, setIsGameOver] = useState(false);

  const scalingFactors = [
    [0.2, 0.2], [0.4, 0.4], [0.6, 0.6],
    [0.8, 0.8], [1.0, 1.0], [1.2, 1.0],
    // ... dilediğiniz kadar ekleyebilirsiniz ...
    [2.8, 1.0],
  ];
  const [animationStep, setAnimationStep] = useState(0);

  const getInitialGameTime = () => {
    if (userData) {
      return userData.status === 'inactive' ? 15 : 60;
    }
    return 15;
  };
  const [initialGameTime, setInitialGameTime] = useState(getInitialGameTime());
  const [gameTime, setGameTime] = useState(initialGameTime);

  // userData değişince oyun süresini güncelle
  useEffect(() => {
    if (userData) {
      const newTime = userData.status === 'inactive' ? 15 : 60;
      setGameTime(newTime);
      setInitialGameTime(newTime);
      setSpeed(1);
    } else {
      setGameTime(15);
      setInitialGameTime(15);
      setSpeed(1);
    }
  }, [userData]);

  // Rastgele harf seçici
  const updateLetters = useCallback(() => {
    const turkishAlphabet = [
      'A', 'B', 'C', 'Ç', 'D', 'E', 'F', 'G',
      'Ğ', 'H', 'I', 'İ', 'J', 'K', 'L', 'M',
      'N', 'O', 'Ö', 'P', 'R', 'S', 'Ş', 'T',
      'U', 'Ü', 'V', 'Y', 'Z',
    ];
    return Array.from({ length: 4 }, () =>
      turkishAlphabet[Math.floor(Math.random() * turkishAlphabet.length)]
    );
  }, []);

  const [letters, setLetters] = useState(updateLetters());

  // Süre azaltsın
  useEffect(() => {
    let timer;
    if (isPlaying && gameTime > 0) {
      timer = setInterval(() => {
        setGameTime((prevTime) => prevTime - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isPlaying, gameTime]);

  // Belirli aralıkta harfleri güncelle, skor art
  useEffect(() => {
    let lettersInterval;
    if (isPlaying && gameTime > 0) {
      const updateInterval = Math.max(600 / speed, 100);
      lettersInterval = setInterval(() => {
        setLetters(updateLetters());
        setScore((prev) => prev + 1);
        setAnimationStep((prevStep) => (prevStep + 1) % scalingFactors.length);
      }, updateInterval);
    }
    return () => clearInterval(lettersInterval);
  }, [isPlaying, speed, gameTime, updateLetters]);

  // Ana çizim fonksiyonu
  const drawDiamond = useCallback(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    const sideLength = Math.min(canvas.width, canvas.height) / 4;
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;

    const [scaleX, scaleY] = scalingFactors[animationStep];
    const scaledSideX = sideLength * scaleX;
    const scaledSideY = sideLength * scaleY;

    const maxSideX = canvas.width / 2 - 50;
    const maxSideY = canvas.height / 2 - 50;
    const adjustedSideX = Math.min(scaledSideX, maxSideX);
    const adjustedSideY = Math.min(scaledSideY, maxSideY);

    // Temizle
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Arka plan degrade
    const gradient = ctx.createLinearGradient(0, 0, canvas.width, canvas.height);
    gradient.addColorStop(0, '#ffecd2');
    gradient.addColorStop(1, '#fcb69f');
    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Elmas çizimi
    ctx.save();
    ctx.translate(centerX, centerY);
    ctx.lineWidth = 2;
    ctx.strokeStyle = '#ffffff';
    ctx.fillStyle = '#ffcc5c';

    const points = [
      { x: 0, y: -adjustedSideY },
      { x: adjustedSideX, y: 0 },
      { x: 0, y: adjustedSideY },
      { x: -adjustedSideX, y: 0 },
    ];

    ctx.beginPath();
    points.forEach((pt, i) => {
      if (i === 0) ctx.moveTo(pt.x, pt.y);
      else ctx.lineTo(pt.x, pt.y);
    });
    ctx.closePath();
    ctx.fill();
    ctx.stroke();

    // Çevreleyen 4 daire & harf
    points.forEach((pt, i) => {
      ctx.beginPath();
      ctx.arc(pt.x, pt.y, 30, 0, Math.PI * 2);
      ctx.fillStyle = '#fff';
      ctx.fill();
      ctx.strokeStyle = '#ffffff';
      ctx.stroke();

      ctx.font = `28px "Comic Sans MS", cursive, sans-serif`;
      ctx.fillStyle = '#000';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(letters[i], pt.x, pt.y);
    });

    ctx.restore();

    // Ortadaki siyah nokta
    ctx.beginPath();
    ctx.arc(centerX, centerY, 15, 0, Math.PI * 2);
    ctx.fillStyle = '#000000';
    ctx.fill();
  }, [letters, animationStep, scalingFactors]);

  // render-loop
  useEffect(() => {
    let animationFrameId;

    const render = () => {
      drawDiamond();
      animationFrameId = requestAnimationFrame(render);
    };

    if (isPlaying && gameTime > 0) {
      animationFrameId = requestAnimationFrame(render);
    }

    return () => cancelAnimationFrame(animationFrameId);
  }, [isPlaying, gameTime, drawDiamond]);

  // Reset
  const resetGame = () => {
    setIsPlaying(false);
    setScore(0);
    setGameTime(initialGameTime);
    setLetters(updateLetters());
    setIsGameOver(false);
    setAnimationStep(0);
  };


  return {
    canvasRef,
    isPlaying,
    setIsPlaying,
    speed,
    setSpeed,
    score,
    gameTime,
    initialGameTime,
    drawDiamond,
    resetGame,
    isGameOver,
    setIsGameOver,
  };
}
