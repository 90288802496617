import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const GameOverModal = ({ score, onRestart, userData, onClose }) => {
  const navigate = useNavigate();

  // ESC tuşu ile kapatma
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => window.removeEventListener('keydown', handleEsc);
  }, [onClose]);

  const handleLoginRedirect = () => {
    navigate('/register');
  };

  const isActiveUser = userData && userData.status !== 'inactive';

  return (
    <div className="zigzag-modal-backdrop" onClick={onClose} aria-modal="true" role="dialog">
      <div className="zigzag-modal-content" onClick={(e) => e.stopPropagation()}>
        {/* Kapatma Butonu */}
        <button className="zigzag-close-button" onClick={onClose} aria-label="Close Modal">
          <FontAwesomeIcon icon={faTimes} />
        </button>

        <h2 className="zigzag-modal-title">Oyun Bitti!</h2>

        {isActiveUser ? (
          <>
            <p className="zigzag-modal-score">Toplam Skorunuz: {Math.floor(score)}</p>
            <div className="zigzag-button-group">
              <button className="zigzag-btn zigzag-btn-outline" onClick={onClose}>
                Bitti
              </button>
              <button
                className="zigzag-btn zigzag-btn-secondary"
                onClick={() => navigate(-1)}
              >
                <FontAwesomeIcon icon={faArrowLeft} /> Geri Dön
              </button>
            </div>
          </>
        ) : (
          <>
            <p className="zigzag-modal-message">
              Giriş yapmadığınız için skoru görüntüleyemezsiniz.
            </p>
            <div className="zigzag-button-group">
              <button className="zigzag-btn zigzag-btn-primary" onClick={handleLoginRedirect}>
                Kayıt Ol / Giriş Yap
              </button>
              <button
                className="zigzag-btn zigzag-btn-secondary"
                onClick={() => navigate(-1)}
              >
                <FontAwesomeIcon icon={faArrowLeft} /> Geri Dön
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default GameOverModal;
