import React from 'react';
import '../styles/noktaTakipEt.css';

const GameTimerDisplay = ({ gameTime, initialGameTime }) => {
  const formatTime = (seconds) => {
    if (seconds < 0) seconds = 0;
    const minutes = Math.floor(seconds / 60).toString().padStart(2, '0');
    const remainingSeconds = (seconds % 60).toString().padStart(2, '0');
    return `${minutes}:${remainingSeconds}`;
  };

  const percentage = (gameTime / initialGameTime) * 100;

  const getProgressColor = (pct) => {
    if (pct > 50) return '#2ecc71'; 
    if (pct > 20) return '#f1c40f'; 
    return '#e74c3c'; 
  };

  return (
    <div className="dot-tracking-timer-container">
      <div className="dot-tracking-timer">
        {formatTime(gameTime)}
      </div>
      <div className="dot-tracking-timer-progress-bar">
        <div
          className="dot-tracking-timer-progress"
          style={{
            width: `${percentage}%`,
            backgroundColor: getProgressColor(percentage),
          }}
        ></div>
      </div>
    </div>
  );
};

export default GameTimerDisplay;
