import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import '../styles/tachistoscopicReadingStyle.css';
import Header from '../components/Header';

const Instructions = ({ stage, startGame, handleReturnToPreviousStage, toggleSettingsModal,  hideSettings}) => {
  return (
    <div className="intro-container">
       <Header
           stage={stage}
           handleReturnToPreviousStage={handleReturnToPreviousStage}
           handleSettingsClick={toggleSettingsModal} 
           hideSettings={true}
       
       
       />
      
    {/* Başlık */}
    <h1 className="intro-title mt-4">Takistoskopik Okuma Çalışması</h1>

    {/* Açıklama Metni */}
    <p className="intro-description">
      Takistoskopik Okuma Çalışması, kelimeleri kısa bir süre gösterip ardından hatırladığınız
      kelimeleri yazmanızı sağlar. Bu çalışma, hızlı okuma ve hafıza geliştirme becerilerinizi
      artırmak için tasarlanmıştır.
    </p>

    {/* Talimatlar Kutusu */}
    <div className="intro-instructions-box">
      <h3 className="intro-instructions-title">Talimatlar</h3>
      <ul className="intro-instructions-list">
        <li>1.  Kolay, Orta veya Zor seviyelerinden birini
          seçebilirsiniz.
        </li>
        <li>2.  "Başlat" butonuna tıklayarak çalışmayı başlatın.
        </li>
        <li>3.  Ekranda beliren kelimeleri dikkatlice takip
          edin.
        </li>
        <li>4.  Gösterim süresi bittikten sonra hatırladığınız
          kelimeleri giriş alanına yazın.
        </li>
        <li>5.  "Sonucu Göster" butonuna tıklayarak skorunuzu
          öğrenin.
        </li>
      </ul>
    </div>

    {/* Başlat Butonu */}
    <button className="intro-button mt-4" onClick={startGame}>
      <FontAwesomeIcon icon={faPlay} /> BAŞLAT
    </button>
  </div>
  );
};

export default Instructions;
