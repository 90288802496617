import React, { useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faRedo } from '@fortawesome/free-solid-svg-icons';
import parse, { domToReact } from 'html-react-parser';
import '../styles/kayanYazilarIkiserStyle.css';


const GameDisplay = ({
  isPlaying,
  paragraphsList,
  selectedParagraphIndex,
  displayedWords,
  handlePlayPause,
  handleReset,
  message,
  gameOver,
  timeLeft,
  gameTime,

  speedFactor,
  handleSpeedIncrease,
  handleSpeedDecrease,
}) => {
  const voidElements = new Set([
    'area','base','br','col','embed','hr','img','input',
    'keygen','link','meta','param','source','track','wbr',
  ]);

  const options = {
    replace: (node) => {
      if (node.type === 'tag') {
        if (voidElements.has(node.name)) {
          return React.createElement(node.name, { key: node.name, ...node.attribs }, null);
        } else {
          return React.createElement(
            node.name,
            { key: node.name, ...node.attribs },
            domToReact(node.children, options)
          );
        }
      }
    },
  };

  // Mevcut paragraf metni
  const currentParagraphValue =
    (paragraphsList && paragraphsList[selectedParagraphIndex]) || '';
  const currentParagraph =
    typeof currentParagraphValue === 'string' ? currentParagraphValue : '';

  // Scroll Down
  const containerRef = useRef(null);
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [displayedWords]);

  return (
    <>
      {/* OYUN KONTROLLERİ */}
      <div className="game-buttons">
  <button
    onClick={handlePlayPause}
    disabled={gameOver}
    className="game-button"
  >
    <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} /> {isPlaying ? 'Duraklat' : 'Başlat'}
  </button>
  <button
    onClick={handleReset}
    className="game-button"
  >
    <FontAwesomeIcon icon={faRedo} /> Yeniden Başlat
  </button>
</div> 


        {/* HIZ KONTROLÜ */}
        <div className="speed-control-container" style={{ marginBottom: '20px' }}>
        <div className="speed-label">Hız Ayarı</div>
        <div className="speed-display">
          <button
            className="speed-button"
            onClick={handleSpeedDecrease}
            aria-label="Hızı Azalt"
            disabled={gameOver}
          >
            -
          </button>
          <div className="speed-value">
            {(speedFactor || 1).toFixed(1)}
          </div>
          <button
            className="speed-button"
            onClick={handleSpeedIncrease}
            aria-label="Hızı Artır"
            disabled={gameOver}
          >
            +
          </button>
        </div>
      </div>

      {/* METİN GÖSTERİMİ */}
      <div className="kayan-yazilar-ikiser-game-display" ref={containerRef}>
        {!paragraphsList || !paragraphsList[selectedParagraphIndex] ? (
          <div className="kayan-yazilar-ikiser-message">
            Paragraf bulunamadı. Lütfen farklı bir paragraf veya zorluk seviyesi seçin.
          </div>
        ) : (
          <div className="kayan-yazilar-ikiser-content">
            {parse(displayedWords.join(' '), options)}
          </div>
        )}
      </div>

      {/* MESAJ */}
      {message && (
        <div className="kayan-yazilar-ikiser-message">{message}</div>
      )}
    </>
  );
};

export default GameDisplay;
