// src/pages/kurumsal/BayilikBasvuruFormu.js

import React, { useState } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Grid,
  Snackbar,
  Alert,
  CircularProgress,
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { API_URLS } from '../../../config/config';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Header from '../Header';
import Footer from '../Footer';

// 1) react-responsive importu
import { useMediaQuery as useOrientationQuery } from 'react-responsive';

// Stil Tanımlamaları
const FormContainer = styled(Container)(({ isPortrait }) => ({
  // Dikey modda az, yatay modda çok padding
  padding: isPortrait ? '16px' : '24px',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  borderRadius: '8px',
  boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
  // Dikey/yatayda margin-top/bottom
  marginTop: isPortrait ? '24px' : '32px',
  marginBottom: isPortrait ? '24px' : '32px',
}));

// Yup Doğrulama Şeması
const validationSchema = Yup.object().shape({
  companyName: Yup.string().required('Şirket Adı gerekli'),
  contactPerson: Yup.string().required('İletişim Kişisi gerekli'),
  email: Yup.string().email('Geçersiz Email').required('Email gerekli'),
  phone: Yup.string().required('Telefon Numarası gerekli'),
  address: Yup.string().required('Adres gerekli'),
  city: Yup.string().required('Şehir gerekli'),
  website: Yup.string().url("Geçersiz Web Sitesi URL'si").nullable(),
  additionalInfo: Yup.string(),
});

function BayilikBasvuruFormu() {
  const userId = '60fdb67a2ab79c4bc4fc1234';
  const [submitStatus, setSubmitStatus] = useState({
    loading: false,
    success: false,
    error: '',
  });

  // 2) Orientation sorgusu
  const isPortrait = useOrientationQuery({ query: '(orientation: portrait)' });

  const handleSubmit = async (values, { resetForm }) => {
    setSubmitStatus({ loading: true, success: false, error: '' });
    try {
      const response = await fetch(`${API_URLS.BAYILIK_BASVURU_URL}/basvuru`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(values),
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Başvuru gönderilirken bir hata oluştu');
      }
      setSubmitStatus({ loading: false, success: true, error: '' });
      resetForm();
    } catch (error) {
      setSubmitStatus({
        loading: false,
        success: false,
        error: error.message,
      });
    }
  };

  const handleCloseSnackbar = () => {
    setSubmitStatus({ ...submitStatus, success: false, error: '' });
  };

  return (
    <>
      <Header />

      {/* 3) Styled FormContainer'a prop gönderiyoruz */}
      <FormContainer maxWidth="md" isPortrait={isPortrait}>
        <Typography variant="h4" gutterBottom align="center" color="#FFD700">
          Bayilik Başvuru Formu
        </Typography>
        <Formik
          initialValues={{
            companyName: '',
            contactPerson: '',
            email: '',
            phone: '',
            address: '',
            city: '',
            website: '',
            additionalInfo: '',
            createdBy: userId,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, handleChange, values }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    label="Şirket Adı"
                    name="companyName"
                    value={values.companyName}
                    onChange={handleChange}
                    error={touched.companyName && Boolean(errors.companyName)}
                    helperText={touched.companyName && errors.companyName}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    label="İletişim Kişisi"
                    name="contactPerson"
                    value={values.contactPerson}
                    onChange={handleChange}
                    error={touched.contactPerson && Boolean(errors.contactPerson)}
                    helperText={touched.contactPerson && errors.contactPerson}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    label="Email"
                    name="email"
                    type="email"
                    value={values.email}
                    onChange={handleChange}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    label="Telefon Numarası"
                    name="phone"
                    value={values.phone}
                    onChange={handleChange}
                    error={touched.phone && Boolean(errors.phone)}
                    helperText={touched.phone && errors.phone}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    label="Adres"
                    name="address"
                    value={values.address}
                    onChange={handleChange}
                    error={touched.address && Boolean(errors.address)}
                    helperText={touched.address && errors.address}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    label="Şehir"
                    name="city"
                    value={values.city}
                    onChange={handleChange}
                    error={touched.city && Boolean(errors.city)}
                    helperText={touched.city && errors.city}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Web Sitesi"
                    name="website"
                    value={values.website}
                    onChange={handleChange}
                    error={touched.website && Boolean(errors.website)}
                    helperText={
                      touched.website && errors.website ? errors.website : 'Opsiyonel'
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Ek Bilgi"
                    name="additionalInfo"
                    multiline
                    rows={4}
                    value={values.additionalInfo}
                    onChange={handleChange}
                    helperText="Opsiyonel"
                  />
                </Grid>

                <Grid item xs={12} align="center">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={submitStatus.loading}
                    startIcon={submitStatus.loading && <CircularProgress size={20} />}
                    sx={{
                      backgroundColor: '#FFD700',
                      color: '#0A192F',
                      '&:hover': {
                        backgroundColor: '#e6c200',
                      },
                      paddingX: 4,
                      paddingY: 1.5,
                    }}
                  >
                    {submitStatus.loading ? 'Gönderiliyor...' : 'Başvuruyu Gönder'}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>

        {/* Başarı Snackbar */}
        <Snackbar
          open={submitStatus.success}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
            Başvurunuz başarıyla gönderildi!
          </Alert>
        </Snackbar>

        {/* Hata Snackbar */}
        <Snackbar
          open={Boolean(submitStatus.error)}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            {submitStatus.error}
          </Alert>
        </Snackbar>
      </FormContainer>

      <Footer />
    </>
  );
}

export default BayilikBasvuruFormu;
