// ./components/GameStatus.js

import React from 'react';
import '../styles/harfIzgarasiStyle.css';

const GameStatus = ({ timeRemaining, score }) => {
  return (
    <div className="harf-izgarasi-status">
      <div className="timer">
        <div>
          <span>Kalan Süre: </span>
          <span>{timeRemaining} sn</span>
        </div>
        <div>
          <span>Skor: </span>
          <span>{score}</span>
        </div>
      </div>
    </div>
  );
};

export default GameStatus;
