// ScoreDisplay.js

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';
import '../styles/rotatingRectangleGame.css'; 

export default function ScoreDisplay({ score }) {
  return (
    <div className="score-display-container">
      <FontAwesomeIcon icon={faTrophy} className="score-icon" />
      <div className="score-text">
        <span>Skor:</span>
        <strong>{score}</strong>
      </div>
    </div>
  );
}
