import React from 'react';
import '../styles/KelimeBulmaOyunu.css';

const GameExplanation = () => {
  return (
    <div className="intro-container mt-4">
    <div className="intro-instructions-box">
      <h3 className="intro-instructions-title">Talimatlar</h3>
      <ul className="intro-instructions-list">
        <li>1.  Zorluk seviyesini ve okumak istediğiniz paragrafı seçin.
        </li>
        <li>2.  "Başla" butonuna tıklayarak çalışmayı başlatın.
        </li>
        <li>3.  Hedef kelimeleri paragraf içinde bulun ve üzerine tıklayın.
        </li>
        <li>4.  Doğru kelimeleri buldukça puan kazanın.
        </li>
      </ul>
    </div>
    </div>
  );
};

export default GameExplanation;
