import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

const DeleteTestDialog = ({ open, student, onClose, onConfirm }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>İlk Okuma Hızı Testini Sil</DialogTitle>
      <DialogContent>
        Öğrencinin ilk okuma hızı test sonucunu silmek istediğinize emin misiniz? Bu işlem geri alınamaz.
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>İptal</Button>
        <Button onClick={onConfirm} color="error">Sil</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteTestDialog;
