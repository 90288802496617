import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate, useLocation } from 'react-router-dom';
import GameOverModal from './components/GameOverModal';
import GameHeader from './components/GameHeader';
import { API_URLS } from '../../../config/config';
import './styles/hangiSayiGordun.css';
import axiosInstance from '../../../utils/axiosInstance';
import axios from 'axios';

function HangiSayiGordun() {
  const navigate = useNavigate();
  const location = useLocation();
  const { stage, exercise } = location.state || {};

  const [difficulty, setDifficulty] = useState('kolay');
  const [numbers, setNumbers] = useState([]);
  const [input, setInput] = useState('');
  const [score, setScore] = useState(0);
  const [gameActive, setGameActive] = useState(false);
  const [timeLeft, setTimeLeft] = useState(15);
  const [showNumbers, setShowNumbers] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showIntroduction, setShowIntroduction] = useState(true);
  const canvasRef = useRef(null);
  const inputRef = useRef(null);
  const [speedFactor, setSpeedFactor] = useState(1);
  const baseDisplayDuration = 1000; 
  const [attempts, setAttempts] = useState([]);
  const [userData, setUserData] = useState(null);
  const [userRole, setUserRole] = useState(null);

  // ─────────────────────────────────────────────────────────────────
  //  FETCH USER
  // ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const studentResponse = await axiosInstance.get(`${API_URLS.STUDENT_URL}/me`);
        if (
          studentResponse.status === 200 &&
          studentResponse.data &&
          studentResponse.data.status === 'active'
        ) {
          const studentData = studentResponse.data;
          setUserData(studentData);
          setUserRole('student');
          return;
        }
      } catch (error) {
        console.error('Öğrenci verisi alınırken hata oluştu:', error);
      }

      // Öğrenci yoksa öğretmen verisine bak
      try {
        const token = localStorage.getItem('userToken');
        const teacherResponse = await fetch(`${API_URLS.TEACHER_URL}/me`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (teacherResponse.ok) {
          const teacherData = await teacherResponse.json();
          setUserData(teacherData);
          setUserRole('teacher');
          return;
        }
      } catch (error) {
        console.error('Öğretmen verisi alınırken hata oluştu:', error);
      }

      setUserData(null);
      setUserRole(null);
    };

    fetchUserData();
  }, []);

  // ─────────────────────────────────────────────────────────────────
  //  ADJUST TIME BASED ON USER STATUS
  // ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    if (userData && userData.status === 'active') {
      setTimeLeft(40);
    } else {
      setTimeLeft(15);
    }
  }, [userData]);

  // ─────────────────────────────────────────────────────────────────
  //  MAIN TIMER
  // ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    if (gameActive && timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    } else if (timeLeft === 0) {
      onGameOver();
    }
  }, [timeLeft, gameActive]);

  // ─────────────────────────────────────────────────────────────────
  //  CANVAS
  // ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    if (gameActive && showNumbers) {
      drawCanvas();
    } else {
      clearCanvas();
    }
  }, [gameActive, showNumbers, numbers]);

  // ─────────────────────────────────────────────────────────────────
  //  WHEN GAME STARTS, GENERATE NUMBERS
  // ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    if (gameActive) {
      generateNumbers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameActive]);

  // ─────────────────────────────────────────────────────────────────
  //  AFTER NUMBERS HIDE, FOCUS INPUT
  // ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    if (gameActive && !showNumbers && inputRef.current) {
      inputRef.current.focus();
    }
  }, [gameActive, showNumbers]);

  // ─────────────────────────────────────────────────────────────────
  //  INTRO SCREEN
  // ─────────────────────────────────────────────────────────────────
  const enterGame = () => {
    setShowIntroduction(false);
  };

  // ─────────────────────────────────────────────────────────────────
  //  START / STOP / RESET
  // ─────────────────────────────────────────────────────────────────
  const startGame = () => {
    setScore(0);
    const initialTime = userData && userData.status === 'active' ? 40 : 15;
    setTimeLeft(initialTime);
    setGameActive(true);
    setAttempts([]);
  };

  const stopGame = () => {
    setGameActive(false);
  };

  const resetGame = () => {
    clearCanvas();
    setGameActive(false);
    setScore(0);
    const initialTime = userData && userData.status === 'active' ? 40 : 15;
    setTimeLeft(initialTime);
    setNumbers([]);
    setInput('');
    setShowNumbers(false);
    setShowModal(false);
    setShowIntroduction(false);
    setAttempts([]);
  };

  // ─────────────────────────────────────────────────────────────────
  //  GAME OVER
  // ─────────────────────────────────────────────────────────────────
  const onGameOver = () => {
    setGameActive(false);
    setShowModal(true);
    updateProgress();
  };

  // ─────────────────────────────────────────────────────────────────
  //  CLAMP FOR PADDING
  // ─────────────────────────────────────────────────────────────────
  const clamp = (value, min, max) => {
    return Math.max(min, Math.min(value, max));
  };

  // ─────────────────────────────────────────────────────────────────
  //  GENERATE NUMBERS
  // ─────────────────────────────────────────────────────────────────
  const generateNumbers = () => {
    if (!canvasRef.current) return;

    const totalTime = userData && userData.status === 'active' ? 40 : 15;

    let numCount;
    switch (difficulty) {
      case 'kolay':
        numCount = 2;
        break;
      case 'orta':
        numCount = 3;
        break;
      case 'zor':
        // 4 veya 5 üret
        numCount = Math.floor(Math.random() * 2) + 4;
        break;
      default:
        numCount = 2;
    }

    const canvas = canvasRef.current;
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;

    // Kenarlardan taşmayı engellemek için padding
    const padding = 40;
    const maxRadius = canvas.width / 2 - 50;
    const minRadius = 50;
    const elapsed = totalTime - timeLeft;
    const baseRadius = minRadius + ((maxRadius - minRadius) * elapsed) / totalTime;

    const numsArray = [];
    const positions = [];

    // Zor seviyede yönleri karıştırma
    let directions = [];
    if (numCount === 2) {
      directions = [1, -1];
    } else {
      directions = [1, -1];
      for (let i = 2; i < numCount; i++) {
        directions.push(Math.random() < 0.5 ? -1 : 1);
      }
      directions = shuffleArray(directions);
    }

    // Her sayıyı yerleştirmeye çalışalım
    const maxAttempts = 50; // Rastgele pozisyon bulmada 50 deneme
    for (let i = 0; i < numCount; i++) {
      let placed = false;
      let tries = 0;
      while (!placed && tries < maxAttempts) {
        tries++;

        const direction = directions[i];
        const radius = baseRadius + i * 30;
        const xRaw = centerX + direction * radius;
        const x = clamp(xRaw, padding, canvas.width - padding);
        const y = centerY;

        // Overlap kontrolü
        const overlap = positions.some((pos) => {
          const dx = pos.x - x;
          const distance = Math.abs(dx);
          return distance < 30;
        });

        if (!overlap) {
          // Uygun pozisyon bulduk
          positions.push({ x });
          const randomNumber = Math.floor(Math.random() * 9) + 1;
          numsArray.push({ value: randomNumber, x, y });
          placed = true;
        }
      }
    }

    setNumbers(numsArray);
    setShowNumbers(true);

    // Here we compute the actual display duration based on speedFactor
    const computedDisplayDuration = baseDisplayDuration / speedFactor;
    setTimeout(() => {
      setShowNumbers(false);
      clearCanvas();
    }, computedDisplayDuration);
  };

  // ─────────────────────────────────────────────────────────────────
  //  SHUFFLE UTILITY
  // ─────────────────────────────────────────────────────────────────
  const shuffleArray = (array) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  // ─────────────────────────────────────────────────────────────────
  //  SUBMIT
  // ─────────────────────────────────────────────────────────────────
  const handleSubmit = (e) => {
    e.preventDefault();

    let userInput = input.replace(/\D/g, '').split('').map(Number);
    let correctNumbers = numbers.map((numObj) => numObj.value);

    if (userInput.some((num) => isNaN(num))) {
      alert('Lütfen geçerli sayılar girin.');
      return;
    }

    userInput.sort((a, b) => a - b);
    correctNumbers.sort((a, b) => a - b);

    const isCorrect =
      userInput.length === correctNumbers.length &&
      userInput.every((num, idx) => num === correctNumbers[idx]);

    setAttempts([
      ...attempts,
      {
        correctNumbers: [...correctNumbers],
        userInput: [...userInput],
        isCorrect,
      },
    ]);

    if (isCorrect) {
      setScore((prev) => prev + 1);
      setInput('');
      generateNumbers();
    } else {
      alert('Yanlış cevap!');
      setInput('');
      generateNumbers();
    }
  };

  // ─────────────────────────────────────────────────────────────────
  //  CANVAS DRAW
  // ─────────────────────────────────────────────────────────────────
  const drawCanvas = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;

    clearCanvas();

    // Merkezde küçük bir nokta
    ctx.beginPath();
    ctx.arc(centerX, centerY, 5, 0, 2 * Math.PI);
    ctx.fillStyle = '#888';
    ctx.fill();

    // Sayıları yaz
    numbers.forEach((numObj) => {
      const x = numObj.x;
      const y = numObj.y;

      ctx.fillStyle = '#ffcc00';
      ctx.font = 'bold 40px Arial';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(numObj.value, x, y);
    });
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }
  };

  // ─────────────────────────────────────────────────────────────────
  //  NAVIGATION
  // ─────────────────────────────────────────────────────────────────
  const handleReturnToPreviousStage = () => {
    if (stage) {
      navigate(
        userRole === 'student' ? '/ogrenci-dashboard' : '/ogretmen-dashboard',
        {
          state: {
            selectedMenu: 'ExerciseList',
            selectedStage: stage,
          },
        }
      );
    } else {
      alert('Aşama bilgisi bulunmamaktadır.');
    }
  };

  // ─────────────────────────────────────────────────────────────────
  //  PROGRESS UPDATE
  // ─────────────────────────────────────────────────────────────────
  const updateProgress = async () => {
    if (!userData || !userRole) return;
    const gameTime = (userData && userData.status === 'active') 
      ? 40 - timeLeft 
      : 15 - timeLeft;

    try {
      const apiUrl =
        userRole === 'student'
          ? `${API_URLS.STUDENT_URL}akillikurs/progress/update-game`
          : `${API_URLS.TEACHER_URL}akillikurs/progress/update-game`;

      if (userRole === 'student') {
        // Öğrenci
        await axiosInstance.post(apiUrl, {
          stageNumber: stage?.number || 1,
          gameName: exercise?.gameName || 'Hangi Sayıyı Gördün?',
          score: score,
          time: gameTime,
          completed: true,
        });
      } else {
        // Öğretmen
        const teacherToken = localStorage.getItem('userToken');
        await axios.post(
          apiUrl,
          {
            stageNumber: stage?.number || 1,
            gameName: exercise?.gameName || 'Hangi Sayıyı Gördün?',
            score: score,
            time: gameTime,
            completed: true,
          },
          {
            headers: {
              Authorization: `Bearer ${teacherToken}`,
              'Content-Type': 'application/json',
            },
          }
        );
      }
    } catch (error) {
      console.error('İlerleme güncellenirken hata oluştu:', error);
    }
  };

  // ─────────────────────────────────────────────────────────────────
  //  DIFFICULTY CHANGE
  // ─────────────────────────────────────────────────────────────────
  const handleDifficultyChange = (e) => {
    const newDifficulty = e.target.value;
    setDifficulty(newDifficulty);
    // Zorluk değişince oyunu resetleyelim
    if (gameActive) {
      resetGame();
    }
  };

  // ─────────────────────────────────────────────────────────────────
  //  MODAL
  // ─────────────────────────────────────────────────────────────────
  const handleClose = () => {
    setShowModal(false);
  };

  // ─────────────────────────────────────────────────────────────────
  //  RENDER
  // ─────────────────────────────────────────────────────────────────
  return (
    <div className="hangi-sayi-gordun-container game-container-bg">
      {showIntroduction ? (
        <div className="intro-container">
          {/* GameHeader (üst menü/bar vb.) */}
          <GameHeader
            handleReturnToPreviousStage={handleReturnToPreviousStage}
            stage={stage}
          />

          {/* Başlık */}
          <h1 className="intro-title mt-4">Hangi Sayıyı Gördün?</h1>

          {/* Açıklama Metni */}
          <p className="intro-description">
            Bu egzersizde, ekranda kısa bir süreliğine gösterilen sayıları hatırlayıp
            doğru şekilde girmeye çalışacaksınız.
          </p>

          {/* Talimatlar Kutusu */}
          <div className="intro-instructions-box">
            <h3 className="intro-instructions-title">Talimatlar</h3>
            <ol
              className="intro-instructions-list"
              style={{ listStyleType: 'decimal', paddingLeft: '20px' }}
            >
              <li>Sayılar ekranda belirecek, dikkatlice izleyin.</li>
              <li>Sayılar kaybolduktan sonra gördüğünüz sayıları girin.</li>
              <li>Zorluk seviyesini seçerek oyunu özelleştirebilirsiniz.</li>
            </ol>
          </div>

          {/* Başlat Butonu */}
          <button onClick={enterGame} className="intro-button mt-4">
            BAŞLAT
          </button>
        </div>
      ) : (
        <div className="hangi-sayi-gordun-gameplay">
          <div className="hangi-sayi-gordun-card">
            <GameHeader handleReturnToPreviousStage={handleReturnToPreviousStage} stage={stage} />

            <div className="hangi-sayi-gordun-card-body">
       
                <div className="hangi-sayi-gordun-setting">
                  <label 
                  htmlFor="difficultySelect"
                  className='game-select-label'
                  >Zorluk Seviyesi:</label>
                  <select
                    id="difficultySelect"
                    className="game-select"
                    value={difficulty}
                    onChange={handleDifficultyChange}
                  >
                    <option value="kolay">Kolay</option>
                    <option value="orta">Orta</option>
                    <option value="zor">Zor</option>
                  </select>
                </div>

              </div>
      
              <div className="game-buttons">
                {gameActive ? (
                  <>
                    <button onClick={stopGame} className="game-button">
                      Durdur
                    </button>
                    <button onClick={resetGame} className="game-button">
                      Yeniden Başlat
                    </button>
                  </>
                ) : (
                  <>
                    <button onClick={startGame} className="game-button">
                      Başlat
                    </button>
                    <button onClick={resetGame} className="game-button">
                      Yeniden Başlat
                    </button>
                  </>
                )}
              </div>

              <div className="hangi-sayi-gordun-status">
                <p>Kalan Süre: {timeLeft} saniye</p>
                <p>Skor: {score}</p>
              </div>

              <div className="hangi-sayi-gordun-canvas-container">
                <canvas
                  ref={canvasRef}
                  width={400}
                  height={400}
                  className="hangi-sayi-gordun-canvas"
                  style={{ display: gameActive ? 'block' : 'none' }}
                />
                {gameActive && !showNumbers && (
                  <form onSubmit={handleSubmit} className="hangi-sayi-gordun-input-form">
                    <input
                      ref={inputRef}
                      type="text"
                      value={input}
                      onChange={(e) => setInput(e.target.value)}
                      placeholder="Gördüğün sayıları (peş peşe) yaz"
                    />
                    <button type="submit" className="hangi-sayi-gordun-button">
                      Gönder
                    </button>
                  </form>
                )}
              </div>
            </div>
          </div>
      
      )}

      {showModal && (
        <GameOverModal
          score={score}
          onRestart={resetGame}
          userData={userData}
          onClose={handleClose}
          attempts={attempts}
        />
      )}
    </div>
  );
}

export default HangiSayiGordun;
