// src/components/KayanYazilarUcerOyunu/components/GameTimerUcer.js

import React from 'react';
import ProgressBarUcer from './ProgressBarUcer';
import '../styles/kayanYazilarUcerStyle.css';

/**
 * Kalan süreyi ve progress bar'ı gösteren basit zamanlayıcı bileşeni.
 *
 * @param {number} timeLeft    - Kalan süre (saniye)
 * @param {boolean} isPlaying  - Oyun oynanıyor mu
 * @param {number} gameTime    - Toplam oyun süresi (saniye)
 * @param {number} elapsedTime - Geçen süre (saniye)
 */
const GameTimerUcer = ({ timeLeft, isPlaying, gameTime, elapsedTime }) => {

  const progress = gameTime > 0
    ? (elapsedTime / gameTime) * 100
    : 0;

  const formatTime = (seconds) => {
    if (seconds < 0) seconds = 0;
    const totalSeconds = Math.ceil(seconds);
    const minutes = Math.floor(totalSeconds / 60)
      .toString()
      .padStart(2, '0');
    const remainingSeconds = (totalSeconds % 60).toString().padStart(2, '0');
    return `${minutes}:${remainingSeconds}`;
  };

  return (
    <div className="timer">
      <span>Kalan Süre: {formatTime(timeLeft)}</span>
      <ProgressBarUcer progress={progress} />
    </div>
  );
};

export default GameTimerUcer;
