import React from 'react';

function GameOptions({
  isPlaying,
  setIsPlaying,
  resetGame,
  orientation,
  setOrientation,
  difficulty,
  handleDifficultyChange,
}) {
  return (
    <div className="zigzag-game-controls">
      {!isPlaying && (
        <button onClick={() => setIsPlaying(true)}>Başlat</button>
      )}
      {isPlaying && (
        <button onClick={() => setIsPlaying(false)}>Durdur</button>
      )}
      <button onClick={resetGame}>Yeniden Başlat</button>

      {/* Yön Seçimi */}
      <select
        className="zigzag-select"
        value={orientation}
        onChange={(e) => setOrientation(e.target.value)}
      >
        <option value="horizontal">Yatay</option>
        <option value="vertical">Dikey</option>
      </select>

      {/* Zorluk Seçimi */}
      <select
        className="zigzag-select"
        value={difficulty}
        onChange={handleDifficultyChange}
      >
        <option value="seciniz">Zorluk Seç</option>
        <option value="easy">Kolay</option>
        <option value="normal">Normal</option>
        <option value="hard">Zor</option>
      </select>
    </div>
  );
}

export default GameOptions;
