import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../utils/axiosInstance';
import { API_URLS } from '../../../config/config';
import './styles/kelimeBulmaca.css';
import HeaderSection from './components/HeaderSection';
import GameTimer from './components/GameTimer';
import GameOverModal from './components/GameOverModal';
import SettingsModal from './components/SettingsModal';
import { faPlay, faPause, faRedo, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Türkçe karakterleri içeren harf listesi
const TURKISH_LETTERS = [
  "A","B","C","Ç","D","E","F","G","Ğ","H",
  "I","İ","J","K","L","M","N","O","Ö","P",
  "R","S","Ş","T","U","Ü","V","Y","Z"
];

// Zorluk derecelerine göre satır-sütun ayarları
function getGridSizeForDifficulty(difficulty) {
  switch(difficulty) {
    case 'medium':
      return { rows: 10, columns: 15 };
    case 'hard':
      return { rows: 10, columns: 20 };
    case 'easy':
    default:
      return { rows: 10, columns: 10 };
  }
}

// Zorluk derecesine göre maxWords, süre vb.
function getDifficultyParams(difficulty) {
  switch(difficulty) {
    case 'medium':
      return {
        maxWords: 6,
        time: 60
      };
    case 'hard': {
      // 8-10 arası rastgele
      const randomHardCount = 8 + Math.floor(Math.random() * 3); // 8, 9, 10
      return {
        maxWords: randomHardCount,
        time: 90
      };
    }
    case 'easy':
    default:
      return {
        maxWords: 4,
        time: 45
      };
  }
}

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

// Sadece yatay ve dikey
const DIRECTIONS = [
  { dRow: 0, dCol: 1 },  
  { dRow: 1, dCol: 0 },  
];

// Grid ve kelime pozisyonu üretme
function generateRandomGrid(words, rows, columns) {
  // En uzun kelime öne
  words.sort((a,b) => b.length - a.length);

  let grid = Array.from({ length: rows }, () => Array(columns).fill(null));
  const wordPositions = [];

  function canPlace(word, startRow, startCol, dRow, dCol) {
    for (let i = 0; i < word.length; i++) {
      const r = startRow + i * dRow;
      const c = startCol + i * dCol;
      if (r < 0 || r >= rows || c < 0 || c >= columns) return false;
      const cell = grid[r][c];
      if (cell !== null && cell !== word[i]) return false;
    }
    return true;
  }

  function place(word, startRow, startCol, dRow, dCol) {
    const positions = [];
    for (let i = 0; i < word.length; i++) {
      const r = startRow + i * dRow;
      const c = startCol + i * dCol;
      grid[r][c] = word[i];
      positions.push({ row: r, col: c });
    }
    wordPositions.push({ word, positions });
  }

  let allPositions = [];
  for (let r = 0; r < rows; r++) {
    for (let c = 0; c < columns; c++) {
      allPositions.push({r, c});
    }
  }

  // Kelimeleri yerleştirme
  for (const originalWord of words) {
    let placed = false;
    const attemptWord = originalWord; // Reverse vb. de eklenebilir

    const shuffledDirections = shuffleArray([...DIRECTIONS]);
    const shuffledPositions = shuffleArray([...allPositions]);

    for (const { dRow, dCol } of shuffledDirections) {
      if (placed) break;
      for (const pos of shuffledPositions) {
        if (canPlace(attemptWord, pos.r, pos.c, dRow, dCol)) {
          place(attemptWord, pos.r, pos.c, dRow, dCol);
          placed = true;
          break;
        }
      }
    }

    if (!placed) {
      console.warn(`Kelime yerleştirilemedi: ${originalWord}`);
    }
  }

  // Boş kalan hücrelere rastgele harf
  for (let r = 0; r < rows; r++) {
    for (let c = 0; c < columns; c++) {
      if (grid[r][c] === null) {
        grid[r][c] = TURKISH_LETTERS[Math.floor(Math.random() * TURKISH_LETTERS.length)];
      }
    }
  }

  return { grid, wordPositions };
}

function KelimeBulmaca() {
  const [userData, setUserData] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [stage, setStage] = useState('');

  // OYUN intro'sunu kontrol eden state:
  const [showIntroduction, setShowIntroduction] = useState(true);
  const enterGame = () => {
    setShowIntroduction(false);
  };

  const [difficulty, setDifficulty] = useState('easy');
  const { maxWords } = getDifficultyParams(difficulty);

  const [initialGameTime, setInitialGameTime] = useState(15);
  const [timeRemaining, setTimeRemaining] = useState(initialGameTime);

  const [grid, setGrid] = useState([]);
  const [puzzleWords, setPuzzleWords] = useState([]);
  const [wordPositions, setWordPositions] = useState([]);

  const [selectedCells, setSelectedCells] = useState([]);
  const [foundWords, setFoundWords] = useState([]);
  const [foundWordPositions, setFoundWordPositions] = useState([]);
  const [isSelecting, setIsSelecting] = useState(false);

  const [isPlaying, setIsPlaying] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [theme, setTheme] = useState('default');
  const [showSettingsModal, setShowSettingsModal] = useState(false);

   // Tema değişikliği
   const handleThemeChange = (e) => {
    setTheme(e.target.value);
  };

  // Modal aç/kapat
  const toggleSettingsModal = () => {
    setShowSettingsModal(!showSettingsModal);
  };


  // Kullanıcı datasını çek
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const studentResponse = await axiosInstance.get(`${API_URLS.STUDENT_URL}/me`);
        if (
          studentResponse.status === 200 &&
          studentResponse.data &&
          studentResponse.data.status === 'active'
        ) {
          const studentData = studentResponse.data;
          setUserData(studentData);
          setUserRole('student');
          return;
        } else {
          setUserData(null);
          setUserRole(null);
        }
      } catch (error) {
        console.error('Öğrenci verisi alınırken hata oluştu:', error);
      }

      try {
        const token = localStorage.getItem('userToken');
        const teacherResponse = await fetch(`${API_URLS.TEACHER_URL}/me`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (teacherResponse.ok) {
          const teacherData = await teacherResponse.json();
          setUserData(teacherData);
          setUserRole('teacher');
          return;
        }
      } catch (error) {
        console.error('Öğretmen verisi alınırken hata oluştu:', error);
      }

      setUserData(null);
      setUserRole(null);
    };
    fetchUserData();
  }, []);

  // Kullanıcı durumuna göre başlangıç süresi
  useEffect(() => {
    if (userData && userRole) {
      if (userRole === 'student' || userRole === 'teacher') {
        setInitialGameTime(userData.status === 'inactive' ? 15 : 40);
      }
    } else {
      setInitialGameTime(15);
    }
  }, [userData, userRole]);

  useEffect(() => {
    setTimeRemaining(initialGameTime);
  }, [initialGameTime]);

  // Yeni kelimeler çek
  const fetchParagraphs = async () => {
    try {
      const url = `${API_URLS.HIZLIOKUMA_URL}akanyazi/${difficulty}`;
      const response = await axiosInstance.get(url);
      const data = response.data;

      if (data && data.length > 0 && data[0].content) {
        // HTML tag'leri sil, büyük harfe çevir
        const cleanedContent = data[0].content.replace(/<[^>]*>/g, ' ');
        const allWords = cleanedContent
          .split(/\s+/)
          .map(w =>
            w
              .toLocaleUpperCase('tr-TR')
              .replace(/[^A-ZĞÜŞİÖÇ]/g, '')
              .trim()
          )
          .filter(
            w => w.length >= 3 && w.length <= 7 && !w.startsWith('SOK') // SOK örnek engelleme
          );

        const actualMaxWords = Math.min(maxWords, allWords.length);
        const selectedWords = [];
        for (let i = 0; i < actualMaxWords; i++) {
          const randomIndex = Math.floor(Math.random() * allWords.length);
          selectedWords.push(allWords.splice(randomIndex, 1)[0]);
        }

        setPuzzleWords(selectedWords);
      } else {
        console.warn('API den geçerli içerik gelmedi. Varsayılan kelimeler kullanılacak.');
        setPuzzleWords(["BİLGİSAYAR", "TEKNOLOJİ"]);
      }
    } catch (error) {
      console.error('Veri alınırken hata oluştu:', error);
      setPuzzleWords(["BİLGİSAYAR", "TEKNOLOJİ"]);
    }
  };

  const resetGame = () => {
    setIsPlaying(false);
    setFoundWords([]);
    setFoundWordPositions([]);
    setSelectedCells([]);
    setIsSelecting(false);
    setTimeRemaining(initialGameTime);
    setShowModal(false);
  };

  // difficulty değişince yeni kelimeler çek, resetle
  useEffect(() => {
    fetchParagraphs();
    resetGame();
  }, [difficulty]);

  // puzzleWords güncellendikçe grid oluştur
  useEffect(() => {
    if (puzzleWords.length > 0) {
      const { rows, columns } = getGridSizeForDifficulty(difficulty);
      const { grid: newGrid, wordPositions: newWordPositions } = generateRandomGrid(
        puzzleWords,
        rows,
        columns
      );
      setGrid(newGrid);
      setWordPositions(newWordPositions);
    } else {
      setGrid([]);
      setWordPositions([]);
    }
  }, [puzzleWords, difficulty]);

  // Mouse bırakıldığında seçimi finalize
  useEffect(() => {
    const handleMouseUp = () => {
      if (isSelecting) {
        finalizeSelection();
      }
    };
    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isSelecting]);

  // Zaman 0 veya tüm kelimeler bulunursa bitir
  useEffect(() => {
    if (timeRemaining === 0 && isPlaying) {
      onGameOver();
    }
  }, [timeRemaining, isPlaying]);

  useEffect(() => {
    if (puzzleWords.length > 0 && foundWords.length === puzzleWords.length && isPlaying) {
      onGameOver();
    }
  }, [foundWords, puzzleWords, isPlaying]);

  // Hard modda mobil/tablet yatay döndürme
  useEffect(() => {
    if (difficulty === 'hard') {
      const isMobileOrTablet = window.innerWidth < 1024;
      if (
        isMobileOrTablet &&
        typeof window !== 'undefined' &&
        window.screen &&
        window.screen.orientation &&
        window.screen.orientation.lock
      ) {
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen().then(() => {
            window.screen.orientation.lock('landscape-primary').catch(err => {
              console.error('Orientation lock başarısız:', err);
            });
          });
        }
      }
      document.body.classList.add('hard-mode-landscape');
    } else {
      document.body.classList.remove('hard-mode-landscape');
    }
  }, [difficulty]);

  const finalizeSelection = () => {
    const selectedString = selectedCells.map(({ row, col }) => grid[row][col]).join('');
    let foundWord = null;

    for (let w of puzzleWords) {
      if (selectedString.includes(w)) {
        foundWord = w;
        break;
      }
    }

    if (foundWord && !foundWords.includes(foundWord)) {
      const wpos = wordPositions.find(wp => wp.word === foundWord);
      if (wpos) {
        setFoundWords([...foundWords, foundWord]);
        setFoundWordPositions([...foundWordPositions, { word: foundWord, positions: wpos.positions }]);
      }
    }

    setSelectedCells([]);
    setIsSelecting(false);
  };

  // Hücreye tıklandığında (tek tıkla da kelime bulmak isterseniz)
  const handleCellClick = (row, col) => {
    if (!isPlaying) return;
    for (const wp of wordPositions) {
      if (!foundWords.includes(wp.word)) {
        const cellInWord = wp.positions.find(pos => pos.row === row && pos.col === col);
        if (cellInWord) {
          setFoundWords([...foundWords, wp.word]);
          setFoundWordPositions([...foundWordPositions, { word: wp.word, positions: wp.positions }]);
          return;
        }
      }
    }
  };

  const handleCellMouseDown = (row, col) => {
    if (!isPlaying) return;
    setIsSelecting(true);
    setSelectedCells([{ row, col }]);
  };

  const handleCellMouseEnter = (row, col) => {
    if (isSelecting && isPlaying) {
      const cellExists = selectedCells.some(cell => cell.row === row && cell.col === col);
      if (!cellExists) {
        setSelectedCells([...selectedCells, { row, col }]);
      }
    }
  };

  const isFoundCell = (row, col) => {
    return foundWordPositions.some(wp =>
      wp.positions.some(pos => pos.row === row && pos.col === col)
    );
  };

  const handleReturnToPreviousStage = () => {
    alert('Önceki aşamaya dön!');
  };

  const onGameOver = () => {
    setIsPlaying(false);
    setShowModal(true);
  };

  return (
    <div className={`game-container-bg kelimebulmaca-container theme-${theme} ${difficulty === 'hard' ? 'landscape-mode' : ''}`}>
       
       {showSettingsModal && (
        <SettingsModal
          onClose={toggleSettingsModal}
          theme={theme}
          handleThemeChange={handleThemeChange}
        />
      )}
      

      {showIntroduction ? (
     <div className="intro-container mt-4">
     <HeaderSection 
     handleReturnToPreviousStage={handleReturnToPreviousStage} 
     stage ={stage}
     />
     
     {/* Başlık */}
     <h1 className="intro-title mt-4">Kelime Bulmaca</h1>
     
     {/* Açıklama Metni */}
     <p className="intro-description">
       Ekrandaki kelime bulmacasında gizlenmiş sözcükleri bulmaya hazır mısın?
     </p>
     
     {/* Talimatlar Kutusu */}
     <div className="intro-instructions-box">
       <h3 className="intro-instructions-title">Talimatlar</h3>
       <ol className="intro-instructions-list" style={{ listStyleType: 'decimal', paddingLeft: '20px' }}>
         <li>Kelimeyi bulmak için ızgara üzerinde ilgili harfleri sürükleyebilirsin.</li>
         <li>İstersen tek tık ile de kelimenin harflerini yakalayabilirsin.</li>
         <li>Zorluk seviyesine göre farklı uzunlukta ve sayıda kelimeler vardır.</li>
         <li>Kalan süreye dikkat et ve tüm kelimeleri bulmaya çalış!</li>
       </ol>
     </div>
     
     {/* Başlat Butonu */}
     <button onClick={enterGame} className="intro-button mt-4">
       BAŞLAT
     </button>
   </div>
      ) : (
     
        <>
          <HeaderSection 
          handleReturnToPreviousStage={handleReturnToPreviousStage}
          onClick={toggleSettingsModal}
          stage ={stage}
           />

          {/* Üst bar */} 
          <div className="top-bar">
            <div className="timer-section">
              <GameTimer
                isPlaying={isPlaying}
                timeRemaining={timeRemaining}
                initialGameTime={initialGameTime}
                setTimeRemaining={setTimeRemaining}
              />
            </div>
            <div className="found-words-section">
              <div className="kelimebulmaca-score-container">
                <strong>Bulunan Kelime Sayısı: {foundWords.length}/{puzzleWords.length}</strong>
              </div>
              <p className="kelimebulmaca-info">
                <strong>Bulunacak Kelimeler:</strong>{' '}
                {puzzleWords.length > 0 ? puzzleWords.join(', ') : 'Yükleniyor...'}
              </p>
              <p className="kelimebulmaca-info">
                <strong>Bulunan Kelimeler:</strong>{' '}
                {foundWords.length > 0 ? foundWords.join(', ') : 'Henüz bulunmadı'}
              </p>
            </div>
          </div>

          {/* Oyun alanı (Grid) */}
          <div className="game-area">
            <div className="kelimebulmaca-puzzle-container">
              <div className={`kelimebulmaca-grid-container ${difficulty}`}>
                <div className="grid">
                  {grid.map((rowArr, rowIndex) => (
                    <div key={rowIndex} className="grid-row">
                      {rowArr.map((letter, colIndex) => {
                        const foundClass = isFoundCell(rowIndex, colIndex) ? 'found' : '';
                        return (
                          <div
                            key={colIndex}
                            className={`grid-cell ${foundClass}`}
                            onMouseDown={() => handleCellMouseDown(rowIndex, colIndex)}
                            onMouseEnter={() => handleCellMouseEnter(rowIndex, colIndex)}
                            onClick={() => handleCellClick(rowIndex, colIndex)}
                          >
                            {letter}
                          </div>
                        );
                      })}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Alt butonlar */}
          <div className="game-buttons">
            <button 
              className="game-button"
               onClick={() => setIsPlaying(!isPlaying)}>
            <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />    {isPlaying ? 'Duraklat' : 'Başlat'}
            </button>
            <button   className="game-button"
             onClick={resetGame}>
              
              <FontAwesomeIcon icon={faRedo} /> Yeniden Başlat</button>
            <select
                className="game-button"
              value={difficulty}
              onChange={(e) => setDifficulty(e.target.value)}
            >
              <option value="easy">Kolay</option>
              <option value="medium">Orta</option>
              <option value="hard">Zor</option>
            </select>
          </div>

          {/* Oyun Bitişi - Modal */}
          {showModal && (
            <GameOverModal
              score={foundWords.length}
              onRestart={resetGame}
              userData={userData}
              onClose={() => setShowModal(false)}
            />
          )}
        </>
      )}
    </div>
  );
}

export default KelimeBulmaca;
