// TeacherTable.js
import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Stack,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DeleteIcon from '@mui/icons-material/Delete';

const TeacherTable = ({
  ogretmenListesi,
  handleEdit,
  setSelectedTeacherId,
  setTransferDialogOpen,
  setRevokeDialogOpen,
  handleOpenRapor,
  handleOpenAtamaDialog,
  handleDeleteConfirmation, // <-- Dikkat: TeacherPage'den geliyor
}) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Adı</TableCell>
            <TableCell>Soyadı</TableCell>
            <TableCell>Şifre</TableCell>
            <TableCell>Telefon</TableCell>
            <TableCell>E-posta</TableCell>
            <TableCell>Şehir</TableCell>
            <TableCell>İlçe</TableCell>
            <TableCell>Bakiye 3 Ay</TableCell>
            <TableCell>Bakiye 6 Ay</TableCell>
            <TableCell>Bakiye 12 Ay</TableCell>
            <TableCell>Toplam Bakiye</TableCell>
            <TableCell>İşlemler</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ogretmenListesi.length > 0 ? (
            ogretmenListesi.map((ogretmen) => {
              const toplamBakiye =
                (ogretmen.bakiye3Ay || 0) +
                (ogretmen.bakiye6Ay || 0) +
                (ogretmen.bakiye12Ay || 0);

              return (
                <TableRow key={ogretmen._id}>
                  <TableCell>{ogretmen.ad}</TableCell>
                  <TableCell>{ogretmen.soyad}</TableCell>
                  <TableCell>{ogretmen.password_open}</TableCell>
                  <TableCell>{ogretmen.telefon}</TableCell>
                  <TableCell>{ogretmen.email}</TableCell>
                  <TableCell>{ogretmen.sehirAdi}</TableCell>
                  <TableCell>{ogretmen.ilceAdi}</TableCell>
                  <TableCell>{ogretmen.bakiye3Ay || 0}</TableCell>
                  <TableCell>{ogretmen.bakiye6Ay || 0}</TableCell>
                  <TableCell>{ogretmen.bakiye12Ay || 0}</TableCell>
                  <TableCell>{toplamBakiye}</TableCell>
                  <TableCell>
                    <Stack direction="row" spacing={1}>
                      {/* Düzenle */}
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<EditIcon />}
                        onClick={() => handleEdit(ogretmen._id)}
                      >
                        Düzenle
                      </Button>

                      {/* Bakiye Transferi */}
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() => {
                          setSelectedTeacherId(ogretmen._id);
                          setTransferDialogOpen(true);
                        }}
                      >
                        Bakiye Transferi
                      </Button>

                      {/* Bakiye Geri Al */}
                      <Button
                        variant="contained"
                        color="warning"
                        onClick={() => {
                          setSelectedTeacherId(ogretmen._id);
                          setRevokeDialogOpen(true);
                        }}
                      >
                        Bakiye Geri Al
                      </Button>

                      {/* Rapor */}
                      <Button
                        variant="outlined"
                        color="secondary"
                        startIcon={<VisibilityIcon />}
                        onClick={() =>
                          handleOpenRapor(
                            ogretmen._id,
                            `${ogretmen.ad} ${ogretmen.soyad}`
                          )
                        }
                      >
                        Rapor
                      </Button>

                      {/* Atama */}
                      <Button
                        variant="contained"
                        color="info"
                        startIcon={<AssignmentIcon />}
                        onClick={() => handleOpenAtamaDialog(ogretmen)}
                      >
                        Atama
                      </Button>

                      {/* Sil (Onay) */}
                  {/*     <Button
                        variant="contained"
                        color="error"
                        startIcon={<DeleteIcon />}
                        onClick={() => handleDeleteConfirmation(ogretmen._id)}
                      >
                        Sil
                      </Button> */}
                    </Stack>
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={12} align="center">
                Bu kuruma ait öğretmen bulunamadı.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TeacherTable;
