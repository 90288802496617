import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, OutlinedInput,
  Typography,} from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const StudentAtamaDialog = ({
  open,
  onClose,
  ogrenciId,
  okulListesi = [],
  kurumListesi = [],
  ogretmenListesi = [],
  handleAssign,
}) => {
  const [okulId, setOkulId] = useState('');
  const [kurumId, setKurumId] = useState('');
  const [selectedOgretmenIds, setSelectedOgretmenIds] = useState([]);

  const handleOgretmenChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedOgretmenIds(typeof value === 'string' ? value.split(',') : value);
  };

  const handleSubmit = () => {
    if (!okulId && !kurumId && selectedOgretmenIds.length === 0) {
      alert('Lütfen en az bir atama seçimi yapın (okul, kurum veya öğretmen)!');
      return;
    }
    handleAssign(ogrenciId, {
      okulId,
      kurumId,
      ogretmenId: selectedOgretmenIds,
    });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Öğrenci Atama</DialogTitle>
      <DialogContent dividers>
        <Typography variant="body2" gutterBottom>
          Burada öğrenciyi bir okula, bir kuruma veya birden çok öğretmene atayabilirsiniz.
        </Typography>

        {/* Okul Seçimi */}
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="okul-select-label">Okul Seç</InputLabel>
          <Select
            labelId="okul-select-label"
            value={okulId}
            label="Okul Seç (Opsiyonel)"
            onChange={(e) => setOkulId(e.target.value)}
          >
            <MenuItem value="">Seçmeyin</MenuItem>
            {okulListesi.map((okul) => (
              <MenuItem key={okul._id} value={okul._id}>
                {okul.okul_adi}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Kurum Seçimi */}
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="kurum-select-label">Bayi Seç</InputLabel>
          <Select
            labelId="kurum-select-label"
            value={kurumId}
            label="Kurum Seç (Opsiyonel)"
            onChange={(e) => setKurumId(e.target.value)}
          >
            <MenuItem value="">Seçmeyin</MenuItem>
            {kurumListesi.map((kurum) => (
              <MenuItem key={kurum._id} value={kurum._id}>
                {kurum.kurum_adi}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Öğretmen(ler) Seçimi */}
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="ogretmen-select-label">Öğretmen Seç</InputLabel>
          <Select
            labelId="ogretmen-select-label"
            multiple
            value={selectedOgretmenIds}
            onChange={handleOgretmenChange}
            input={<OutlinedInput label="Öğretmen Seç (Opsiyonel)" />}
            renderValue={(selected) => {
              const names = ogretmenListesi
                .filter((ogretmen) => selected.includes(ogretmen._id))
                .map((ogretmen) => `${ogretmen.ad} ${ogretmen.soyad}`);
              return names.join(', ');
            }}
            MenuProps={MenuProps}
          >
            {ogretmenListesi.map((ogretmen) => (
              <MenuItem key={ogretmen._id} value={ogretmen._id}>
                <Checkbox checked={selectedOgretmenIds.indexOf(ogretmen._id) > -1} />
                <ListItemText primary={`${ogretmen.ad} ${ogretmen.soyad}`} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          İptal
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          Atamayı Tamamla
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StudentAtamaDialog;
