import React from 'react';

const SettingsModal = ({
  isOpen,
  onClose,
  difficulty,
  handleDifficultyChange,
  difficultyLocked,
  theme,
  handleThemeChange,
  font,
  handleFontChange,
  fontSize,
  handleFontSizeChange,
}) => {
  if (!isOpen) return null;

  return (
    <div className="settings-modal-backdrop">
      <div className="settings-modal-content">
        <button className="settings-modal-close-button" onClick={onClose}>
          &times;
        </button>
        <h2 className="settings-modal-title">Ayarlar</h2>
        <div className="settings-form-group">
          <label className="settings-form-label">Zorluk Derecesi:</label>
          {difficultyLocked ? (
            <span>
              {difficulty === 'easy'
                ? 'Kolay'
                : difficulty === 'medium'
                ? 'Orta'
                : 'Zor'}
            </span>
          ) : (
            <select
              className="settings-form-select"
              value={difficulty}
              onChange={handleDifficultyChange}
            >
              <option value="easy">Kolay</option>
              <option value="medium">Orta</option>
              <option value="hard">Zor</option>
            </select>
          )}
        </div>
        <div className="settings-form-group">
          <label className="settings-form-label">Tema:</label>
          <select
            className="settings-form-select"
            value={theme}
            onChange={handleThemeChange}
          >
            <option value="default">Varsayılan (Açık)</option>
            <option value="tomer">Tömer</option>
            <option value="dark">Koyu</option>          
            <option value="blue">Mavi</option>
            <option value="green">Yeşil</option>
            <option value="red">Kırmızı</option>
            <option value="purple">Mor</option>
            <option value="orange">Turuncu</option>
            <option value="pink">Pembe</option>
          </select>
        </div>
        <div className="settings-form-group">
          <label className="settings-form-label">Yazı Tipi:</label>
          <select
            className="settings-form-select"
            value={font}
            onChange={handleFontChange}
          >
            <option value="Poppins">Poppins</option>
            <option value="Orbitron">Orbitron</option>
            <option value="Roboto">Roboto</option>
            <option value="Arial">Arial</option>
            <option value="Courier New">Courier New</option>
          </select>
        </div>
        <div className="settings-form-group">
          <label className="settings-form-label">Yazı Boyutu:</label>
          <select
            className="settings-form-select"
            value={fontSize}
            onChange={handleFontSizeChange}
          >
            <option value="1rem">Küçük</option>
            <option value="1.5rem">Orta</option>
            <option value="2rem">Büyük</option>
          </select>
        </div>
        {/* Alt kısımda bulunan "Kapat" butonu */}
        <div style={{ marginTop: '1rem', textAlign: 'center' }}>
          <button
            onClick={onClose}
            className="settings-modal-bottom-close-button"
          >
            Kapat
          </button>
        </div>
      </div>
    </div>
  );
};

export default SettingsModal;
