import React from 'react';
import { Card, CardContent, Typography, Divider } from '@mui/material';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import MapIcon from '@mui/icons-material/Map';
import ClassIcon from '@mui/icons-material/Class';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';

function ContactInfoCard({ ogrenci }) {
  return (
    <Card
      sx={{
        boxShadow: 6,
        borderRadius: 3,
        backgroundImage: 'linear-gradient(to right, #2196f3, #21cbf3)',
        color: '#fff',
        transition: 'transform 0.3s',
        '&:hover': {
          transform: 'scale(1.02)',
          boxShadow: 8,
        },
      }}
    >
      <CardContent>
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            color: '#fff',
          }}
        >
          <LocationCityIcon />
          İletişim Bilgileri
        </Typography>
        <Divider sx={{ marginY: 2, backgroundColor: '#fff' }} />

        <Typography variant="body1" sx={{ mb: 1 }}>
          <LocationCityIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
          <strong>Şehir:</strong> {ogrenci.sehirAdi}
        </Typography>
        <Typography variant="body1" sx={{ mb: 1 }}>
          <MapIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
          <strong>İlçe:</strong> {ogrenci.ilceAdi}
        </Typography>
        <Typography variant="body1" sx={{ mb: 1 }}>
          <ClassIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
          <strong>Sınıf:</strong> {ogrenci.sinif}
        </Typography>
        <Typography variant="body1" sx={{ mb: 1 }}>
          <EmojiPeopleIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
          <strong>Yaş:</strong> {ogrenci.yas}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default ContactInfoCard;
