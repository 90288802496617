import React, { useState, useEffect } from 'react';
import { Grid, Paper, Snackbar, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_URLS } from '../../../config/config';
import useFetchUserData from '../../../hooks/kurum/useFetchUserData';
import StudentRegistrationForm from './components/StudentRegistrationForm';
import StudentTable from './components/StudentTable';
import EditStudentDialog from './components/EditStudentDialog';
import DeleteTestDialog from './components/DeleteTestDialog';
import DeleteProgressDialog from './components/DeleteProgressDialog';
import ResultDialog from '../../../pages/components/Auth/Dialog';
import StudentAtamaDialog from './components/StudentAtamaDialog';

const Ogrenci = () => {
  const [ogrenciListesi, setOgrenciListesi] = useState([]);
  const [sehirListesi, setSehirListesi] = useState([]);
  const [shouldFetchSehir, setShouldFetchSehir] = useState(true);
  const [userData, setUserData] = useState(null);

  // Diyalog ve Snackbar durumları
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [deleteTestDialogOpen, setDeleteTestDialogOpen] = useState(false);
  const [deleteProgressDialogOpen, setDeleteProgressDialogOpen] = useState(false);
  const [resultDialog, setResultDialog] = useState({ open: false, message: '', severity: 'info' });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  // ATAMA İÇİN
  const [okulListesi, setOkulListesi] = useState([]);
  const [kurumListesi, setKurumListesi] = useState([]);
  const [ogretmenListesi, setOgretmenListesi] = useState([]);
  const [atamaDialogOpen, setAtamaDialogOpen] = useState(false);
  const [selectedStudentId, setSelectedStudentId] = useState(null);

  const navigate = useNavigate();
  useFetchUserData(setUserData);

  // Şehir listesini çek
  useEffect(() => {
    const fetchSehirler = async () => {
      if (!shouldFetchSehir) return;
      try {
        const response = await fetch(API_URLS.ADMIN_URL + 'sehirler');
        if (!response.ok) throw new Error('Veri çekme işlemi başarısız');
        const data = await response.json();
        setSehirListesi(data);
      } catch (error) {
        console.error('Şehirler yüklenirken bir hata oluştu:', error);
      } finally {
        setShouldFetchSehir(false);
      }
    };
    fetchSehirler();
  }, [shouldFetchSehir]);

  // Atama için okul, kurum ve öğretmen listelerini çek
  useEffect(() => {
    if (!userData?._id) return;

    // Okullar
    fetch(`${API_URLS.KURUMSAL_URL}schools/${userData._id}`)
      .then((res) => {
        if (!res.ok) throw new Error('Okullar yüklenirken bir hata oluştu.');
        return res.json();
      })
      .then((data) => setOkulListesi(data))
      .catch((err) => console.error('Okulları yükleme hatası:', err));

    // Kurumlar
    fetch(API_URLS.KURUMSAL_URL + 'kurumsal')
      .then((res) => res.json())
      .then((data) => setKurumListesi(data))
      .catch((err) => console.error(err));

    // Öğretmenler
    fetch(`${API_URLS.KURUMSAL_URL}teachers/${userData._id}`)
      .then((res) => {
        if (!res.ok) throw new Error('Öğretmen listesi yüklenirken bir hata oluştu.');
        return res.json();
      })
      .then((data) => setOgretmenListesi(data))
      .catch((err) => console.error(err));
  }, [userData]);

  // Öğrenci listesi
  const fetchOgrenciListesi = async () => {
    if (!userData || !userData._id) return;
    try {
      const response = await fetch(`${API_URLS.KURUMSAL_URL}student/${userData._id}`);
      if (!response.ok) throw new Error('Öğrenci listesi yüklenirken bir hata oluştu.');
      const data = await response.json();

      const enhancedData = data.map((ogrenci) => {
        const sehir = sehirListesi.find((s) => s._id === ogrenci.sehir);
        const ilce = sehir?.ilceler.find((i) => i._id === ogrenci.ilce);
        return {
          ...ogrenci,
          sehirAdi: sehir ? sehir.sehir_adi : 'Bulunamadı',
          ilceAdi: ilce ? ilce.ilce_adi : 'Bulunamadı',
          sinif: ogrenci.sinif?.toString() || '',
          yas: ogrenci.yas?.toString() || '',
        };
      });

      setOgrenciListesi(enhancedData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (userData && sehirListesi.length > 0) {
      fetchOgrenciListesi();
    }
  }, [userData, sehirListesi]);

  // Yeni öğrenci eklenince
  const handleStudentAdded = (newStudent) => {
    setOgrenciListesi((prev) => [...prev, newStudent]);
  };

  // Öğrenci güncelleme
  const handleUpdateStudent = async (updatedStudent) => {
    try {
      const response = await fetch(`${API_URLS.KURUMSAL_URL}student/${updatedStudent._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ad: updatedStudent.ad,
          soyad: updatedStudent.soyad,
          okul_adi: updatedStudent.okulAdi,
          email: updatedStudent.email,
          telefon: updatedStudent.telefon,
          sehir: updatedStudent.sehirId,
          ilce: updatedStudent.ilceId,
          sinif: updatedStudent.sinif,
          yas: updatedStudent.yas,
        }),
      });
      const data = await response.json();
      if (!response.ok) throw new Error(data.message || 'Güncelleme sırasında bir hata oluştu');

      setResultDialog({ open: true, message: 'Öğrenci başarıyla güncellendi.', severity: 'success' });
      setEditDialogOpen(false);
      await fetchOgrenciListesi();
    } catch (error) {
      console.error('Güncelleme hatası:', error);
      setResultDialog({ open: true, message: error.message, severity: 'error' });
    }
  };

  // Düzenleme diyaloğunu aç
  const handleEditStudent = (student) => {
    setSelectedStudent(student);
    setEditDialogOpen(true);
  };

  // Test silme diyaloğunu aç
  const handleOpenDeleteTestDialog = (student) => {
    setSelectedStudent(student);
    setDeleteTestDialogOpen(true);
  };

  // Aşamalar silme diyaloğunu aç
  const handleOpenDeleteProgressDialog = (student) => {
    setSelectedStudent(student);
    setDeleteProgressDialogOpen(true);
  };

  // ATAMA diyaloğunu aç
  const handleOpenAssignDialog = (student) => {
    setSelectedStudentId(student._id);
    setAtamaDialogOpen(true);
  };

  // Atama işlemi
  const handleAssign = async (ogrenciId, { okulId, kurumId, ogretmenId }) => {
    try {
      const body = {};
      if (okulId) body.okulId = okulId;
      if (kurumId) body.kurumId = kurumId;
      if (ogretmenId && ogretmenId.length > 0) body.ogretmenId = ogretmenId;
      const token = localStorage.getItem('userToken');

      const response = await fetch(`${API_URLS.KURUMSAL_URL}student/${ogrenciId}/assign`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
      if (!response.ok) throw new Error('Öğrenci atama işlemi başarısız.');

      const data = await response.json();
      alert('Öğrenci ataması başarılı: ' + data.message);
      setAtamaDialogOpen(false);
      fetchOgrenciListesi();
    } catch (error) {
      console.error('Öğrenci atama hatası:', error);
      alert('Öğrenci atama hatası: ' + error.message);
    }
  };

  // Snackbar kapama
  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  // ResultDialog kapama
  const handleResultDialogClose = () => {
    setResultDialog({ ...resultDialog, open: false });
  };

  return (
    <Paper style={{ padding: 20 }}>
      {/* 
        Yukarıda FORM - Aşağıda TABLO 
        (Formun içi zaten yan yana alanlar içeriyor, 
        bkz. StudentRegistrationForm)
      */}
      <Grid container direction="column" spacing={4}>
        {/* FORM ALANI */}
        <Grid item>
          <StudentRegistrationForm
            sehirListesi={sehirListesi}
            userData={userData}
            onStudentAdded={handleStudentAdded}
            setResultDialog={setResultDialog}
          />
        </Grid>

        {/* TABLO ALANI */}
        <Grid item>
          <StudentTable
            students={ogrenciListesi}
            sehirListesi={sehirListesi}
            onViewReport={(student) =>
              navigate(`/kurumsal/ogrenci/${student._id}/rapor`, { state: { ogrenci: student } })
            }
            onDeleteTest={handleOpenDeleteTestDialog}
            onDeleteProgress={handleOpenDeleteProgressDialog}
            onEditStudent={handleEditStudent}
            onAssignStudent={handleOpenAssignDialog}
          />
        </Grid>
      </Grid>

      {/* ----- DİYALOGLAR ----- */}
      <ResultDialog
        open={resultDialog.open}
        onClose={handleResultDialogClose}
        title={resultDialog.severity === 'error' ? 'Hata Oluştu' : 'İşlem Başarılı'}
        message={resultDialog.message}
        severity={resultDialog.severity}
      />

      <EditStudentDialog
        open={editDialogOpen}
        student={selectedStudent}
        sehirListesi={sehirListesi}
        onClose={() => setEditDialogOpen(false)}
        onUpdate={handleUpdateStudent}
      />

      <DeleteTestDialog
        open={deleteTestDialogOpen}
        student={selectedStudent}
        onClose={() => setDeleteTestDialogOpen(false)}
        onConfirm={async () => {
          // Test silme işlemi
          try {
            const token = localStorage.getItem('userToken');
            if (!token) {
              setSnackbar({ open: true, message: 'Token bulunamadı.', severity: 'error' });
              return;
            }
            await axios.delete(
              `${API_URLS.KURUMSAL_URL}ogrenci/akillikursrapor/student/${selectedStudent._id}/initial-test`,
              { headers: { Authorization: `Bearer ${token}` } }
            );
            setSnackbar({ open: true, message: 'Test sonucu başarıyla silindi.', severity: 'success' });
            setDeleteTestDialogOpen(false);
            setSelectedStudent(null);
            fetchOgrenciListesi();
          } catch (error) {
            console.error('Test sonucu silinirken hata oluştu:', error);
            setSnackbar({ open: true, message: 'Test sonucu silinirken bir hata oluştu.', severity: 'error' });
            setDeleteTestDialogOpen(false);
          }
        }}
      />

      <DeleteProgressDialog
        open={deleteProgressDialogOpen}
        student={selectedStudent}
        onClose={() => setDeleteProgressDialogOpen(false)}
        onConfirm={async () => {
          // İlerleme silme işlemi
          try {
            const token = localStorage.getItem('userToken');
            if (!token) {
              setSnackbar({ open: true, message: 'Token bulunamadı.', severity: 'error' });
              return;
            }
            await axios.delete(
              `${API_URLS.KURUMSAL_URL}ogrenci/akillikursrapor/student/${selectedStudent._id}/progress`,
              { headers: { Authorization: `Bearer ${token}` } }
            );
            setSnackbar({ open: true, message: 'Öğrenci ilerlemesi başarıyla silindi.', severity: 'success' });
            setDeleteProgressDialogOpen(false);
            setSelectedStudent(null);
            fetchOgrenciListesi();
          } catch (error) {
            console.error('İlerleme silinirken hata oluştu:', error);
            setSnackbar({ open: true, message: 'İlerleme silinirken bir hata oluştu.', severity: 'error' });
            setDeleteProgressDialogOpen(false);
          }
        }}
      />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      <StudentAtamaDialog
        open={atamaDialogOpen}
        onClose={() => setAtamaDialogOpen(false)}
        ogrenciId={selectedStudentId}
        okulListesi={okulListesi}
        kurumListesi={kurumListesi}
        ogretmenListesi={ogretmenListesi}
        handleAssign={handleAssign}
      />
    </Paper>
  );
};

export default Ogrenci;
