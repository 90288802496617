import React from 'react';


const Instructions = ({ onStart }) => {
  return (
    <div className="intro-container">
      <h2 className="intro-title">Sınava Hoş Geldiniz</h2>
      <p className="intro-description">
        Bu sınav, okuma hızınızı ölçmek için tasarlanmıştır. Karşınıza çıkacak metni
        mümkün olan en kısa sürede okuyun. "Sınavı Başlat" butonuna bastığınızda
        zamanlayıcı başlayacak ve metin görünecektir. Okumayı bitirdiğinizde
        "Okumayı Bitirdim" butonuna basarak sonucu görebilirsiniz.
      </p>
      
      {/* İsteğe bağlı olarak ek talimatlar için bir kutu ekleyebilirsiniz */}
      <div className="intro-instructions-box">
        <h3 className="intro-instructions-title">Talimatlar</h3>
        <ul className="intro-instructions-list">
          <li> 1. Metni dikkatlice okuyun.</li>
          <li>2. Sınavı başlatmak için butona tıklayın.</li>
          <li> 3. Okumayı tamamladığınızda sonucu görün.</li>
        </ul>
      </div>
      
      <button className="intro-button" onClick={onStart}>
        Sınavı Başlat
      </button>
    </div>
  );
};

export default Instructions;
