import React, { useState } from 'react';
import { TextField, Grid, FormControl, InputLabel, Select, MenuItem, Paper, Table, TableHead, TableBody, TableRow, TableCell, IconButton, Button, Collapse, Stack,  Chip, } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import BarChartIcon from '@mui/icons-material/BarChart';
import DeleteIcon from '@mui/icons-material/Delete';
import ActivateStudentDialog from './ActivateStudentDialog';


function CollapsibleStudentRow({
  index,
  student,
  onViewReport,
  onDeleteTest,
  onDeleteProgress,
  onEditStudent,
  onAssignStudent,
  onOpenActivateDialog, 
}) {
  const [open, setOpen] = useState(false);

  // Öğrencideki abonelik verileri
  const abonelik = student.abonelik;
  const paketSuresi = abonelik?.paketSuresi || '-';
  const baslangicTarihi = abonelik?.baslangicTarihi
    ? new Date(abonelik.baslangicTarihi).toLocaleDateString()
    : '-';
  const bitisTarihi = abonelik?.bitisTarihi
    ? new Date(abonelik.bitisTarihi).toLocaleDateString()
    : '-';

  return (
    <>
      {/* ANA SATIR */}
      <TableRow>  
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>{index + 1}</TableCell>
        <TableCell>{student.ad}</TableCell>
        <TableCell>{student.soyad}</TableCell>
        <TableCell>{student.telefon}</TableCell>
        <TableCell>{student.sinif}</TableCell>
          <TableCell>
          {student.status === 'active' ? (
            <Chip label="Aktif" color="success" />
          ) : (
            <Chip label="Pasif" color="error" />
          )}
        </TableCell>
        <TableCell>
          <Stack direction="row" spacing={1}>
            <Button
              variant="contained"
              color="info"
              startIcon={<BarChartIcon />}
              onClick={() => onViewReport(student)}
            >
              Rapor
            </Button>

            <Button
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => onDeleteTest(student)}
            >
              Test Sil
            </Button>

            <Button
              variant="contained"
              color="warning"
              startIcon={<DeleteIcon />}
              onClick={() => onDeleteProgress(student)}
            >
              Aşamaları Sil
            </Button>

       {/*      {onEditStudent && (
              <Button
                variant="contained"
                onClick={() => onEditStudent(student)}
              >
                Düzenle
              </Button>
            )} */}

            {onAssignStudent && (
              <Button
                variant="contained"
                color="success"
                onClick={() => onAssignStudent(student)}
              >
                Atama
              </Button>
            )}

            {student.status !== 'active' && (
              <Button
                variant="contained"
                onClick={() => onOpenActivateDialog(student)}
              >
                Aktifleştir
              </Button>
            )}
          </Stack>
        </TableCell>
      </TableRow>

      {/* DETAY SATIR (collapse) */}
      <TableRow>
        <TableCell colSpan={8} style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Paper sx={{ margin: 1, padding: 2, backgroundColor: '#f5f5f5' }}>
              <h4>Detay Bilgileri</h4>
              <p><strong>E-posta:</strong> {student.email}</p>
              <p><strong>Şifre:</strong> {student.password_open}</p>              
              <p><strong>Okul Adı:</strong> {student.okul_adi}</p>
              <p><strong>Yaş:</strong> {student.yas}</p>
              <p><strong>Şehir:</strong> {student.sehirAdi}</p>
              <p><strong>İlçe:</strong> {student.ilceAdi}</p>
              <p><strong>Paket Süresi:</strong> {paketSuresi} Ay</p>
              <p><strong>Başlangıç Tarihi:</strong> {baslangicTarihi}</p>
              <p><strong>Bitiş Tarihi:</strong> {bitisTarihi}</p>
            </Paper>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

/**
 * Asıl tablo bileşeni
 */
const StudentTable = ({
  students,
  sehirListesi,
  onViewReport,
  onDeleteTest,
  onDeleteProgress,
  onEditStudent,
  onAssignStudent,
  onStudentActivated,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filterSinif, setFilterSinif] = useState('');
  const [filterSehirId, setFilterSehirId] = useState('');
  const [filterPaketSuresi, setFilterPaketSuresi] = useState('');

  // Aktifleştirme Diyaloğu
  const [activateDialogOpen, setActivateDialogOpen] = useState(false);
  const [selectedStudentForActivation, setSelectedStudentForActivation] = useState(null);

  // Açıklamak için fonksiyon
  const handleOpenActivateDialog = (student) => {
    setSelectedStudentForActivation(student);
    setActivateDialogOpen(true);
  };
  const handleCloseActivateDialog = () => {
    setSelectedStudentForActivation(null);
    setActivateDialogOpen(false);
  };
  // Aktifleştirme başarılı
  const handleActivationSuccess = () => {
    setActivateDialogOpen(false);
    onStudentActivated && onStudentActivated();
  };

  // Filtreleme
  const filteredStudents = students.filter((student) => {
    const searchTermLower = searchTerm.toLowerCase();
    const abonelik = student.abonelik;
    const paketSuresi = abonelik?.paketSuresi;

    // Arama
    const matchesSearch =
      student.ad?.toLowerCase().includes(searchTermLower) ||
      student.soyad?.toLowerCase().includes(searchTermLower) ||
      student.email?.toLowerCase().includes(searchTermLower) ||
      student.okul_adi?.toLowerCase().includes(searchTermLower) ||
      student.telefon?.includes(searchTerm) ||
      student.sinif?.toString().toLowerCase().includes(searchTermLower) ||
      student.yas?.toString().toLowerCase().includes(searchTermLower) ||
      student.sehirAdi?.toLowerCase().includes(searchTermLower) ||
      student.ilceAdi?.toLowerCase().includes(searchTermLower);

    // Sınıf filtresi
    const matchesSinif = filterSinif ? student.sinif === filterSinif : true;
    // Şehir filtresi
    const matchesSehir = filterSehirId ? student.sehir === filterSehirId : true;
    // Paket süresi filtresi
    const matchesPackage = filterPaketSuresi ? paketSuresi == filterPaketSuresi : true;

    return matchesSearch && matchesSinif && matchesSehir && matchesPackage;
  });

  return (
    <>
      {/* Arama ve Filtreler */}
      <Grid container spacing={2} style={{ marginBottom: 16 }}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Ara"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel id="filter-sinif-select-label">Sınıf Filtrele</InputLabel>
            <Select
              labelId="filter-sinif-select-label"
              value={filterSinif}
              onChange={(e) => setFilterSinif(e.target.value)}
            >
              <MenuItem value="">
                <em>Tümü</em>
              </MenuItem>
              {Array.from(new Set(students.map((s) => s.sinif).filter(Boolean))).map((sinif) => (
                <MenuItem key={sinif} value={sinif}>
                  {sinif}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel id="filter-sehir-select-label">Şehir Filtrele</InputLabel>
            <Select
              labelId="filter-sehir-select-label"
              value={filterSehirId}
              onChange={(e) => setFilterSehirId(e.target.value)}
            >
              <MenuItem value="">
                <em>Tümü</em>
              </MenuItem>
              {sehirListesi.map((sehir) => (
                <MenuItem key={sehir._id} value={sehir._id}>
                  {sehir.sehir_adi}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel id="filter-package-select-label">Paket Süresi Filtrele</InputLabel>
            <Select
              labelId="filter-package-select-label"
              value={filterPaketSuresi}
              onChange={(e) => setFilterPaketSuresi(e.target.value)}
            >
              <MenuItem value="">
                <em>Tümü</em>
              </MenuItem>
              <MenuItem value={3}>3 Aylık</MenuItem>
              <MenuItem value={6}>6 Aylık</MenuItem>
              <MenuItem value={12}>12 Aylık</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {/* TABLO */}
      <Paper sx={{ width: '100%', overflowX: 'auto' }}>
        <Table sx={{ minWidth: 900 }}>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Sıra</TableCell>
              <TableCell>Ad</TableCell>
              <TableCell>Soyad</TableCell>
              <TableCell>Telefon</TableCell>
              <TableCell>Sınıf</TableCell>
              <TableCell>Durum</TableCell>
              <TableCell>İşlemler</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredStudents.map((student, index) => (
              <CollapsibleStudentRow
                key={student._id}
                index={index}
                student={student}
                onViewReport={onViewReport}
                onDeleteTest={onDeleteTest}
                onDeleteProgress={onDeleteProgress}
                onEditStudent={onEditStudent}
                onAssignStudent={onAssignStudent}
                onOpenActivateDialog={handleOpenActivateDialog}
              />
            ))}
          </TableBody>
        </Table>
      </Paper>

      {/* Aktifleştirme Diyaloğu */}
      <ActivateStudentDialog
        open={activateDialogOpen}
        onClose={handleCloseActivateDialog}
        student={selectedStudentForActivation}
        onActivationSuccess={handleActivationSuccess}
      />
    </>
  );
};

export default StudentTable;
