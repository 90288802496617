import React from 'react';

const GameSettingsModal = ({
  isOpen,
  onClose,
  difficulty,
  onDifficultyChange,
  paragraphs,
  selectedParagraphIndex,
  onParagraphChange,
  appTheme,
  onAppThemeChange,
  font,
  onFontChange,
  fontSize,
  onFontSizeChange,
}) => {
  if (!isOpen) return null;

  return (
    <div className="settings-modal-backdrop">
      <div className="settings-modal-content">
        {/* Sağ üst köşedeki "X" ikonu */}
        <button
          onClick={onClose}
          className="settings-modal-close-button"
          aria-label="Kapat"
        >
          &times;
        </button>

        <h2 className="settings-modal-title">Ayarlar</h2>

        {/* Zorluk seçimi */}
        <div className="settings-form-group">
          <label
            className="settings-form-label"
            htmlFor="difficultySelect"
          >
            Zorluk Seç:
          </label>
          <select
            id="difficultySelect"
            className="settings-form-select"
            value={difficulty}
            onChange={(e) => onDifficultyChange(e.target.value)}
            aria-label="Zorluk Seç"
          >
            <option value="easy">Kolay</option>
            <option value="medium">Orta</option>
            <option value="hard">Zor</option>
          </select>
        </div>

        {/* Paragraf seçimi */}
        <div className="settings-form-group">
          <label
            className="settings-form-label"
            htmlFor="paragraphSelect"
          >
            Paragraf Seç:
          </label>
          <select
            id="paragraphSelect"
            className="settings-form-select"
            value={selectedParagraphIndex}
            onChange={(e) => onParagraphChange(Number(e.target.value))}
            aria-label="Paragraf Seç"
          >
            {paragraphs.map((pg, idx) => (
              <option key={idx} value={idx}>
                {pg.title || `Paragraf ${idx + 1}`}
              </option>
            ))}
          </select>
        </div>

        {/* Tema seçimi */}
        <div className="settings-form-group">
          <label
            className="settings-form-label"
            htmlFor="appThemeSelect"
          >
            Tema Seç:
          </label>
          <select
            id="appThemeSelect"
            className="settings-form-select"
            value={appTheme}
            onChange={onAppThemeChange}
          >
            <option value="default">Varsayılan (Açık)</option>
            <option value="tomer">Tömer</option>
            <option value="dark">Koyu</option>
            <option value="light">Açık</option>
            <option value="blue">Mavi</option>
            <option value="green">Yeşil</option>
            <option value="red">Kırmızı</option>
            <option value="purple">Mor</option>
            <option value="orange">Turuncu</option>
            <option value="pink">Pembe</option>
          </select>
        </div>

        {/* Yazı Tipi seçimi */}
        <div className="settings-form-group">
          <label
            className="settings-form-label"
            htmlFor="fontSelect"
          >
            Yazı Tipi:
          </label>
          <select
            id="fontSelect"
            className="settings-form-select"
            value={font}
            onChange={onFontChange}
          >
            <option value="Poppins">Poppins</option>
            <option value="Orbitron">Orbitron</option>
            <option value="Roboto">Roboto</option>
            <option value="Arial">Arial</option>
            <option value="Courier New">Courier New</option>
          </select>
        </div>

        {/* Yazı Boyutu seçimi */}
        <div className="settings-form-group">
          <label
            className="settings-form-label"
            htmlFor="fontSizeSelect"
          >
            Yazı Boyutu:
          </label>
          <select
            id="fontSizeSelect"
            className="settings-form-select"
            value={fontSize}
            onChange={onFontSizeChange}
          >
            <option value="1rem">Küçük</option>
            <option value="1.5rem">Orta</option>
            <option value="2rem">Büyük</option>
          </select>
        </div>

        {/* Alt taraftaki "Kapat" butonu */}
        <div style={{ marginTop: '1rem', textAlign: 'center' }}>
          <button 
            onClick={onClose}
            className="settings-modal-bottom-close-button"
          >
            Kapat
          </button>
        </div>
      </div>
    </div>
  );
};

export default GameSettingsModal;
