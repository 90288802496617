// src/components/KayanYazilarUcerOyunu/hooks/useKayanYazilarUcerOyunu.js

import { useState, useEffect, useCallback } from 'react';
import { API_URLS } from '../../../../config/config';
import axiosInstance from '../../../../utils/axiosInstance';

/**
 * Üçer kelime kayan yazılar oyununa ait mantığı yönetir.
 *
 * @param {Function} navigate - React Router navigate fonksiyonu
 * @param {Object} userData   - Kullanıcı bilgileri
 * @param {string} token      - JWT token
 * @param {Object} stage      - Aşama bilgisi (varsa)
 * @returns {Object} - Hook fonksiyonları ve durum bilgileri
 */
export const useKayanYazilarUcerOyunu = (navigate, userData, token, stage) => {
  // Stage bilgisi varsa zorluk otomatik ayarlanır, yoksa kullanıcı seçebilir
  const stageNumber = stage?.number || null;

  let initialDifficulty = 'easy';
  let difficultyLocked = false;

  if (stageNumber) {
    difficultyLocked = true;
    if (stageNumber >= 1 && stageNumber <= 7) {
      initialDifficulty = 'easy';
    } else if (stageNumber >= 8 && stageNumber <= 14) {
      initialDifficulty = 'medium';
    } else {
      initialDifficulty = 'hard';
    }
  }

  // ---------------------------
  // 1) State Değişkenleri
  // ---------------------------
  const [difficulty, setDifficulty] = useState(initialDifficulty);
  const [paragraphsList, setParagraphsList] = useState([]);
  const [selectedParagraphIndex, setSelectedParagraphIndex] = useState(0);

  // Ekranda gösterilen "kelime grupları" üçer kelime (["Merhaba dünya nasılsın", "Bu bir örnek", ...])
  const [displayedWords, setDisplayedWords] = useState([]);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  // Oyun durumu
  const [isPlaying, setIsPlaying] = useState(false);

  // === YENİ: Hız Factor + Interval ===
  // speedFactor => 0.5 ile 3.0 arasında
  // intervalDuration => gerçek interval ms = baseInterval / speedFactor
  const [speedFactor, setSpeedFactor] = useState(1);
  const baseInterval = 1000; // 1 saniye
  const [intervalDuration, setIntervalDuration] = useState(baseInterval);

  // Zaman bilgileri
  const [elapsedTime, setElapsedTime] = useState(0);  // Geçen süre (saniye)
  const [gameTime, setGameTime] = useState(0);        // Toplam oyun süresi (saniye)
  const [timeLeft, setTimeLeft] = useState(0);        // Kalan süre (saniye)
  const [message, setMessage] = useState('');
  const [gameOver, setGameOver] = useState(false);

  // Bütün kelimelerin ham hali
  const [allWords, setAllWords] = useState([]);

  // ---------------------------
  // 2) speedFactor -> intervalDuration
  // ---------------------------
  useEffect(() => {
    const newInterval = baseInterval / speedFactor; // ms
    // 100 ms altına düşmesin (isteğe bağlı)
    const limitedInterval = Math.max(newInterval, 50);
    setIntervalDuration(limitedInterval);
  }, [speedFactor]);

  // ---------------------------
  // 3) Paragrafları çek
  // ---------------------------
  const prepareWords = (content) => {
    const words = content.trim().split(/\s+/);
    setAllWords(words);
  };

  const fetchParagraphs = async () => {
    try {
      const url = `${API_URLS.HIZLIOKUMA_URL}akanyazi/${difficulty}`;
      const response = await axiosInstance.get(url);
      if (response.data) {
        setParagraphsList(response.data);
        setSelectedParagraphIndex(0);

        if (response.data[0]) {
          prepareWords(response.data[0].content);
        }
        handleReset();
      }
    } catch (error) {
      console.error('Error fetching paragraphs:', error);
    }
  };

  // difficulty veya token değişince paragrafları çek
  useEffect(() => {
    fetchParagraphs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [difficulty, token]);

  // Paragraf değiştiğinde kelimeleri sıfırla
  useEffect(() => {
    if (paragraphsList[selectedParagraphIndex]) {
      prepareWords(paragraphsList[selectedParagraphIndex].content);
      handleReset();
    }
    // eslint-disable-next-line
  }, [selectedParagraphIndex, paragraphsList]);

  // ---------------------------
  // 4) allWords veya intervalDuration değişince totalTime ve timeLeft ayarla
  // ---------------------------
  useEffect(() => {
    if (allWords.length > 0) {
      const wordCount = allWords.length;
      // Kaç tane üçlü grup var
      const totalWordGroups = Math.ceil(wordCount / 3);

      // 1 “üçlü grup” göstermek => intervalDuration ms => stepSec = intervalDuration / 1000
      const stepSec = intervalDuration / 1000;
      const estimatedTimeSec = totalWordGroups * stepSec;

      // Kullanıcı “inactive” ise max 15sn, yoksa tahmini süre
      const maxTime = userData
        ? userData.status === 'inactive'
          ? 15
          : estimatedTimeSec
        : 15;

      setGameTime(maxTime);
      setTimeLeft(maxTime);
    }
  }, [allWords, intervalDuration, userData]);

  // ---------------------------
  // 5) Kelimeleri 3’er 3’er ekrana ekleyen fonksiyon
  // ---------------------------
  const updateWords = useCallback(() => {
    if (!isPlaying) return;
    if (allWords.length === 0) return;

    if (currentWordIndex >= allWords.length) {
      // Kelimeler bitti
      setIsPlaying(false);
      setGameOver(true);
      return;
    }

    // 3 kelimeyi al
    const nextWords = allWords.slice(currentWordIndex, currentWordIndex + 3).join(' ');
    setDisplayedWords((prev) => [...prev, nextWords]);
    setCurrentWordIndex((prev) => prev + 3);

    // Zaman hesaplaması
    const stepSec = intervalDuration / 1000; // sn cinsinden
    setElapsedTime((prev) => prev + stepSec);
    setTimeLeft((prev) => {
      const newTL = prev - stepSec;
      if (newTL <= 0) {
        setIsPlaying(false);
        setGameOver(true);
        return 0;
      }
      return newTL;
    });
  }, [
    isPlaying,
    allWords,
    currentWordIndex,
    intervalDuration,
  ]);

  // 6) setInterval -> updateWords
  useEffect(() => {
    let wordInterval;
    if (isPlaying) {
      wordInterval = setInterval(() => {
        updateWords();
      }, intervalDuration);
    }
    return () => clearInterval(wordInterval);
  }, [isPlaying, intervalDuration, updateWords]);

  // ---------------------------
  // 7) Kontroller
  // ---------------------------
  const handlePlayPause = () => {
    if (gameOver) return;
    if (isPlaying) {
      setMessage(`Şu ana kadar okuduğunuz kelime sayısı: ${currentWordIndex}`);
    } else {
      setMessage('');
    }
    setIsPlaying(!isPlaying);
  };

  const handleReset = () => {
    setIsPlaying(false);
    setDisplayedWords([]);
    setCurrentWordIndex(0);
    setElapsedTime(0);
    setMessage('');
    setGameOver(false);

    if (allWords.length > 0) {
      const wordCount = allWords.length;
      const totalWordGroups = Math.ceil(wordCount / 3);
      const stepSec = intervalDuration / 1000;
      const estimatedTimeSec = totalWordGroups * stepSec;
      const maxTime = userData
        ? userData.status === 'inactive'
          ? 15
          : estimatedTimeSec
        : 15;

      setGameTime(maxTime);
      setTimeLeft(maxTime);
    }
  };


  const handleSpeedIncrease = () => {
    setSpeedFactor((prev) => parseFloat((prev + 0.1).toFixed(1)));
  };

  const handleSpeedDecrease = () => {
    setSpeedFactor((prev) => {
      const nextValue = prev - 0.1;
      return nextValue < 0.1 ? 0.1 : parseFloat(nextValue.toFixed(1));
    });
  };

  const handleParagraphChange = (e) => {
    setSelectedParagraphIndex(Number(e.target.value));
  };

  const handleDifficultyChange = (e) => {
    if (!difficultyLocked) {
      setDifficulty(e.target.value);
    }
  };

  const onTimeUp = () => {
    setIsPlaying(false);
    setGameOver(true);
  };

  return {
    difficulty,
    paragraphsList,
    selectedParagraphIndex,
    displayedWords,
    currentWordIndex,
    isPlaying,

    // YENİ HIZ
    speedFactor,
    handleSpeedIncrease,
    handleSpeedDecrease,

    elapsedTime,
    gameTime,
    timeLeft,
    message,
    gameOver,
    setGameOver,
    handlePlayPause,
    handleReset,
    handleParagraphChange,
    handleDifficultyChange,
    onTimeUp,
    updateWords,
    difficultyLocked,
  };
};
