import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';
import '../styles/noktaTakipEt.css';

const ScoreDisplay = ({ score }) => {
  return (
    <div className="dot-tracking-score-container">
      <FontAwesomeIcon icon={faTrophy} className="dot-tracking-score-icon" />
      <div className="dot-tracking-score-text">
        <span>Skor:</span>
        <strong>{score}</strong>
      </div>
    </div>
  );
};

export default ScoreDisplay;
