import React from 'react';
import { Grid, Card, CardContent, Typography } from '@mui/material';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';

function OverallCharts({ overallChartData }) {
  return (
    <Grid container spacing={4} sx={{ marginBottom: 4 }}>
      {/* Toplam Skorlar */}
      <Grid item xs={12} md={6}>
        <Card
          sx={{
            boxShadow: 6,
            borderRadius: 3,
            backgroundImage: 'linear-gradient(to right, #ffe259, #ffa751)',
            color: '#fff',
            transition: 'transform 0.3s',
            '&:hover': {
              transform: 'scale(1.02)',
              boxShadow: 8,
            },
          }}
        >
          <CardContent>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ fontWeight: 'bold', color: '#fff' }}
            >
              Aşamaların Toplam Skorları
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart
                data={overallChartData}
                margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
              >
                <CartesianGrid strokeDasharray="3 3" stroke="#fff" />
                <XAxis dataKey="stageNumber" stroke="#fff" />
                <YAxis stroke="#fff" />
                <Tooltip
                  formatter={(value) => [value, 'Toplam Skor']}
                  contentStyle={{
                    backgroundColor: '#ffa751',
                    color: '#fff',
                    borderRadius: '10px',
                  }}
                />
                <Legend wrapperStyle={{ color: '#fff' }} />
                <Bar dataKey="totalScore" name="Toplam Skor" fill="#ff5722" />
              </BarChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>
      </Grid>

      {/* Toplam Süreler */}
      <Grid item xs={12} md={6}>
        <Card
          sx={{
            boxShadow: 6,
            borderRadius: 3,
            backgroundImage: 'linear-gradient(to right, #dd5e89, #f7bb97)',
            color: '#fff',
            transition: 'transform 0.3s',
            '&:hover': {
              transform: 'scale(1.02)',
              boxShadow: 8,
            },
          }}
        >
          <CardContent>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ fontWeight: 'bold', color: '#fff' }}
            >
              Aşamaların Toplam Süreleri
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart
                data={overallChartData}
                margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
              >
                <CartesianGrid strokeDasharray="3 3" stroke="#fff" />
                <XAxis dataKey="stageNumber" stroke="#fff" />
                <YAxis stroke="#fff" />
                <Tooltip
                  formatter={(value) => [`${value} saniye`, 'Toplam Süre']}
                  contentStyle={{
                    backgroundColor: '#dd5e89',
                    color: '#fff',
                    borderRadius: '10px',
                  }}
                />
                <Legend wrapperStyle={{ color: '#fff' }} />
                <Line
                  type="monotone"
                  dataKey="totalTime"
                  name="Toplam Süre (saniye)"
                  stroke="#fff"
                  activeDot={{ r: 8 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>
      </Grid>

      {/* Toplam Kelime Sayıları (opsiyonel) */}
      {overallChartData.some((data) => data.totalWordCount > 0) && (
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              boxShadow: 6,
              borderRadius: 3,
              backgroundImage: 'linear-gradient(to right, #43cea2, #185a9d)',
              color: '#fff',
              transition: 'transform 0.3s',
              '&:hover': {
                transform: 'scale(1.02)',
                boxShadow: 8,
              },
            }}
          >
            <CardContent>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: 'bold', color: '#fff' }}
              >
                Aşamaların Toplam Kelime Sayıları
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart
                  data={overallChartData}
                  margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
                >
                  <CartesianGrid strokeDasharray="3 3" stroke="#fff" />
                  <XAxis dataKey="stageNumber" stroke="#fff" />
                  <YAxis stroke="#fff" />
                  <Tooltip
                    formatter={(value) => [value, 'Toplam Kelime Sayısı']}
                    contentStyle={{
                      backgroundColor: '#185a9d',
                      color: '#fff',
                      borderRadius: '10px',
                    }}
                  />
                  <Legend wrapperStyle={{ color: '#fff' }} />
                  <Bar
                    dataKey="totalWordCount"
                    name="Toplam Kelime Sayısı"
                    fill="#ffeb3b"
                  />
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>
  );
}

export default OverallCharts;
