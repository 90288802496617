import { useState, useEffect, useCallback } from 'react';
import { API_URLS } from '../../../../config/config';
import axiosInstance from '../../../../utils/axiosInstance';

export const useRasgeleKelimelerOyunu = (navigate, userData, token) => {
  const [difficulty, setDifficulty] = useState('easy');
  const [paragraphsList, setParagraphsList] = useState([]);
  const [selectedParagraphIndex, setSelectedParagraphIndex] = useState(0);

  const [speedFactor, setSpeedFactor] = useState(1);

  const baseInterval = 500;

  // Diğer state’ler:
  const [allWords, setAllWords] = useState([]);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  // Kaç milisaniyede bir kelime göstereceğiz?
  const [intervalDuration, setIntervalDuration] = useState(baseInterval);
  
  // Zamanla ilgili:
  const [elapsedTime, setElapsedTime] = useState(0);
  const [timeLeft, setTimeLeft] = useState(0);
  const [message, setMessage] = useState('');
  const [gameOver, setGameOver] = useState(false);

  const [displayedWordsGroup, setDisplayedWordsGroup] = useState(null);

  // speedFactor değiştiğinde intervalDuration güncellenir
  useEffect(() => {
    const newInterval = baseInterval / speedFactor;
    // 50 ms altına düşmemesi için isterseniz:
    const limitedInterval = Math.max(newInterval, 50);
    setIntervalDuration(limitedInterval);
  }, [speedFactor]);

  const prepareWords = (content) => {
    const words = content
      .replace(/\n/g, ' ')
      .split(/\s+/)
      .filter((w) => w.trim().length > 0);
    setAllWords(words);
  };

  const fetchParagraphs = async () => {
    try {
      const url = `${API_URLS.HIZLIOKUMA_URL}akanyazi/${difficulty}`;
      const response = await axiosInstance.get(url);
      const data = response.data;
      setParagraphsList(data);
      setSelectedParagraphIndex(0);

      if (data[0]) {
        prepareWords(data[0].content);
      }
      handleReset();
    } catch (error) {
      console.error('Paragraflar alınırken hata oluştu:', error);
    }
  };

  useEffect(() => {
    fetchParagraphs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [difficulty, token]);

  useEffect(() => {
    if (paragraphsList[selectedParagraphIndex]) {
      prepareWords(paragraphsList[selectedParagraphIndex].content);
      handleReset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedParagraphIndex, paragraphsList]);

  // allWords veya intervalDuration değiştiğinde timeLeft baştan hesaplanır
  useEffect(() => {
    if (allWords.length > 0 && intervalDuration > 0) {
      const estimatedTime = allWords.length * intervalDuration;
      const maxTime = userData
        ? userData.status === 'inactive'
          ? Math.min(15 * 1000, estimatedTime)
          : estimatedTime
        : Math.min(15 * 1000, estimatedTime);

      setTimeLeft(maxTime);
    }
  }, [allWords, intervalDuration, userData]);

  const showNextWords = useCallback(
    (containerWidth, containerHeight) => {
      if (currentWordIndex >= allWords.length) {
        // Tüm kelimeler gösterildi, oyun bitti
        setIsPlaying(false);
        setGameOver(true);
        return;
      }

      // 1 veya 2 kelimelik grup
      const groupSize = Math.random() < 0.3 ? 2 : 1;
      const wordsToShow = allWords.slice(
        currentWordIndex,
        currentWordIndex + groupSize
      );

      // Rastgele konum
      const x = Math.floor(Math.random() * (containerWidth - 200));
      const y = Math.floor(Math.random() * (containerHeight - 200));

      const newGroup = {
        id: Date.now(),
        text: wordsToShow.join(' '),
        x,
        y,
      };

      setDisplayedWordsGroup(newGroup);

      setCurrentWordIndex((prev) => prev + wordsToShow.length);
      setElapsedTime((prev) => prev + intervalDuration);

      setTimeLeft((prevTimeLeft) => {
        const newTimeLeft = prevTimeLeft - intervalDuration;
        if (newTimeLeft <= 0) {
          setIsPlaying(false);
          setGameOver(true);
          return 0;
        }
        return newTimeLeft;
      });
    },
    [allWords, currentWordIndex, intervalDuration]
  );

  useEffect(() => {
    let wordInterval;
    if (isPlaying) {
      wordInterval = setInterval(() => {
        const container = document.getElementById('game-display-container');
        if (container) {
          const rect = container.getBoundingClientRect();
          showNextWords(rect.width, rect.height);
        }
      }, intervalDuration);
    }

    return () => clearInterval(wordInterval);
  }, [isPlaying, intervalDuration, showNextWords]);

  const handlePlayPause = () => {
    if (gameOver) return;
    if (isPlaying) {
      setMessage(`Şu ana kadar: ${currentWordIndex} kelime okundu`);
    } else {
      setMessage('');
    }
    setIsPlaying(!isPlaying);
  };

  const handleReset = () => {
    setIsPlaying(false);
    setDisplayedWordsGroup(null);
    setCurrentWordIndex(0);
    setElapsedTime(0);
    setMessage('');
    setGameOver(false);

    if (allWords.length > 0 && intervalDuration > 0) {
      const estimatedTime = allWords.length * intervalDuration;
      const maxTime = userData
        ? userData.status === 'inactive'
          ? Math.min(15 * 1000, estimatedTime)
          : estimatedTime
        : Math.min(15 * 1000, estimatedTime);

      setTimeLeft(maxTime);
    }
  };

  // Artık "speed" yerine butonlarla ayarlayacağımız "speedFactor" var,
  // handleSpeedChange fonksiyonu yerine handleSpeedIncrease/Decrease kullanacağız.
  const handleSpeedIncrease = () => {
    setSpeedFactor((prev) => Math.min(prev + 0.1, 3));
  };

  const handleSpeedDecrease = () => {
    setSpeedFactor((prev) => Math.max(prev - 0.1, 0.5));
  };

  const handleParagraphChange = (e) => {
    setSelectedParagraphIndex(Number(e.target.value));
  };

  const handleDifficultyChange = (e) => {
    setDifficulty(e.target.value);
  };

  return {
    difficulty,
    paragraphsList,
    selectedParagraphIndex,
    speedFactor,
    setSpeedFactor,
    isPlaying,
    elapsedTime,
    timeLeft,
    message,
    gameOver,
    setGameOver,
    displayedWordsGroup,
    allWords,
    currentWordIndex,
    handlePlayPause,
    handleReset,
    handleSpeedIncrease,
    handleSpeedDecrease, // <--- Bunu da dışarı veriyoruz
    handleParagraphChange,
    handleDifficultyChange,
  };
};
