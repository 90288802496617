import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import Header from '../components/Header';

const Instructions = ({ startGame, title, description, handleReturnToPreviousStage }) => {
  return (
    <div className="intro-container">
        <Header handleReturnToPreviousStage={handleReturnToPreviousStage} />
        
      {/* Başlık ve açıklama */}
      <h1 className="intro-title mt-4">{title}</h1>
      <p className="intro-description">{description}</p>

      {/* Talimatlar kutusu */}
      <div className="intro-instructions-box">
        <h3 className="intro-instructions-title">Talimatlar</h3>
        <ul className="intro-instructions-list">
          <li>1. Zorluk Seviyesi Seçin: Kolay, Orta veya Zor
          </li>
          <li>2. Çalışmayı Başlatın: "Başlat" butonuna tıklayın.
          </li>
          <li>3. Cümleleri İzleyin: Alt kısımdan cümleler gelecektir.
          </li>
        </ul>
    
      </div>
      <button className="intro-button mt-4" onClick={startGame}>
          <FontAwesomeIcon icon={faPlay} /> BAŞLAT
        </button>
    </div>
  );
};

export default Instructions;
