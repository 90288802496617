// src/components/KayanYazilarUcerOyunu/components/GameDisplay.js

import React, { useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faRedo } from '@fortawesome/free-solid-svg-icons';
import parse, { domToReact } from 'html-react-parser';
import '../styles/kayanYazilarUcerStyle.css';

const GameDisplay = ({
  isPlaying,
  paragraphsList,
  selectedParagraphIndex,
  displayedWords,
  updateWords,
  handlePlayPause,
  handleReset,
  message,
  gameOver,
  speedFactor,
  handleSpeedIncrease,
  handleSpeedDecrease,
}) => {
  const voidElements = new Set([
    'area','base','br','col','embed','hr','img','input',
    'keygen','link','meta','param','source','track','wbr',
  ]);

  const options = {
    replace: (node) => {
      if (node.type === 'tag') {
        if (voidElements.has(node.name)) {
          return React.createElement(node.name, { ...node.attribs }, null);
        } else {
          return React.createElement(
            node.name,
            { ...node.attribs },
            domToReact(node.children, options)
          );
        }
      }
    },
  };

  const containerRef = useRef(null);
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [displayedWords]);

  // HIZ AYARI + BUTONLAR
  return (
    <>

        {/* Oyun Kontrolleri */}
        <div className="game-buttons">
        <button
          onClick={handlePlayPause}
          disabled={gameOver}
          className="game-button"
        >
          <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} /> {isPlaying ? 'Duraklat' : 'Başlat'}
        </button>
        <button
          onClick={handleReset}
          className="game-button"
        >
          <FontAwesomeIcon icon={faRedo} /> Yeniden Başlat
        </button>
      </div>

      {/* Hız Ayarı */}
      <div className="speed-control-container" style={{ marginBottom: '20px' }}>
        <div className="speed-label">Hız Ayarı</div>
        <div className="speed-display">
          <button
            className="speed-button"
            onClick={handleSpeedDecrease}
            aria-label="Hızı Azalt"
            disabled={gameOver}
          >
            -
          </button>
          <div className="speed-value">{(speedFactor || 1).toFixed(1)}</div>
          <button
            className="speed-button"
            onClick={handleSpeedIncrease}
            aria-label="Hızı Artır"
            disabled={gameOver}
          >
            +
          </button>
        </div>
      </div>

      {/* Metin Gösterimi */}
      <div className="kayan-yazilar-ucer-game-display" ref={containerRef}>
        {!paragraphsList || !paragraphsList[selectedParagraphIndex] ? (
          <div className="kayan-yazilar-ucer-message">
            Paragraf bulunamadı. Lütfen farklı bir paragraf veya zorluk seviyesi seçin.
          </div>
        ) : (
          <div className="kayan-yazilar-ucer-content">
            {parse(displayedWords.join(' '), options)}
          </div>
        )}
      </div>

      {/* Mesaj */}
      {message && (
        <div className="kayan-yazilar-ucer-message">{message}</div>
      )}
    </>
  );
};

export default GameDisplay;
