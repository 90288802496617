import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Grid,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Button,
  Typography,
  CardActions,
  Container
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/material/styles';

// 1) react-responsive importu
import { useMediaQuery as useOrientationQuery } from 'react-responsive';

import Header from '../components/Header';
import Footer from '../components/Footer';

// ----- MUI Tema (mevcut overrides) -----
const theme = createTheme({
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
          boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
          transition: '0.3s',
          '&:hover': {
            boxShadow: '0 16px 32px 0 rgba(0,0,0,0.3)',
            transform: 'scale(1.05)',
          },
          '.MuiCardMedia-root': {
            height: 140,
            transition: 'transform 0.15s ease-in-out',
            '&:hover': {
              transform: 'scale(1.03)',
            },
          },
          '.MuiCardContent-root': {
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
          },
          '.MuiButton-root': {
            backgroundColor: '#d32f2f',
            color: '#fff',
            '&:hover': {
              backgroundColor: '#9a0007',
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '8px 16px',
        },
      },
    },
  },
});

// ----- Login seçenekleri (kartlar) -----
const loginOptions = [
  {
    title: 'Bayi Giriş',
    imageUrl: '/images/corporate-login.webp',
    path: '/kurumsal-giris',
  },
  {
    title: 'Kurum Girişi',
    imageUrl: '/images/corporate-login.webp',
    path: '/okul-giris',
  },
  {
    title: 'Öğretmen Giriş',
    imageUrl: '/images/teacher-login.webp',
    path: '/ogretmen-giris',
  },
  {
    title: 'Öğrenci Giriş',
    imageUrl: '/images/student-login.webp',
    path: '/ogrenci-giris',
  },
  // Daha fazla kart eklenince scrollbar devreye girecek.
];

// ----- Styled bileşen: Arkaplanlı Div (.bg-main yerine) -----
const BgMain = styled('div')(({ isPortrait }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  overflow: 'hidden',
  // Dikeyte daha az padding, yatayda daha fazla:
  padding: isPortrait ? '40px' : '60px',
  minHeight: isPortrait ? '80vh' : '90vh',

  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundImage: 'url("/images/egzersiz_bg.webp")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    filter: 'blur(3px)',
    zIndex: -1,
  },
}));

function MainLogin() {
  // 2) Orientation yakalama
  const isPortrait = useOrientationQuery({ query: '(orientation: portrait)' });

  const navigate = useNavigate();

  const handleLoginClick = (path) => {
    navigate(path);
  };

  /**
   * 3) Dikey modda tek sütun (Xs=12), yatay modda 4 sütun (Xs=3).
   *    MUI'deki "sm, md" gibi breakpoint'ler yerine "isPortrait" ile ayırıyoruz.
   */
  const gridColumnSize = isPortrait ? 12 : 3;

  return (
    <ThemeProvider theme={theme}>
      <Header />

      {/* Arka planlı container (dikey/yatay) */}
      <BgMain isPortrait={isPortrait}>
        <Container
          maxWidth="lg"
          sx={{
            // Yatay modda kutu biraz daha yüksek, dikeyde az
            mt: isPortrait ? 4 : 8,
            mb: isPortrait ? 6 : 10,
            maxHeight: '70vh',
            overflowY: 'auto',
          }}
        >
          <Grid container spacing={2} alignItems="stretch">
            {loginOptions.map((option, index) => (
              <Grid item xs={gridColumnSize} key={index}>
                <Card
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                  <CardActionArea
                    onClick={() => handleLoginClick(option.path)}
                    sx={{
                      flexGrow: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <CardMedia
                      component="img"
                      image={option.imageUrl}
                      alt={option.title}
                    />
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Typography gutterBottom variant="h5" component="div" textAlign="center">
                        {option.title}
                      </Typography>
                    </CardContent>
                  </CardActionArea>

                  <CardActions sx={{ justifyContent: 'center', padding: '16px' }}>
                    <Button
                      size="large"
                      color="primary"
                      variant="contained"
                      fullWidth
                      onClick={() => handleLoginClick(option.path)}
                    >
                      Giriş Yap
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </BgMain>

      <Footer />
    </ThemeProvider>
  );
}

export default MainLogin;
