// GameControls.js

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faRedo } from '@fortawesome/free-solid-svg-icons';
import '../styles/tachistoscopicReadingStyle.css';

const GameControls = ({
  difficulty,
  handleDifficultyChange,
  isPlaying,
  handlePlayPause,
  handleReset,


}) => {
  return (
    <div className="tachisto-game-controls">
      {/* Zorluk Seçimi */}
      <select
        className="tachisto-button"
        value={difficulty}
        onChange={handleDifficultyChange}
      >
        <option value="easy">Kolay</option>
        <option value="medium">Orta</option>
        <option value="hard">Zor</option>
      </select>

      {/* Başlat/Duraklat ve Yeniden Başlat Butonları */}
     <button className="game-button" onClick={handlePlayPause}>
    <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} /> {isPlaying ? 'Duraklat' : 'Başlat'}
  </button>
  <button className="game-button" onClick={handleReset}>
    <FontAwesomeIcon icon={faRedo} /> Yeniden Başlat
  </button>
   
    </div>
  );
};

export default GameControls;
