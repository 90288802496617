import './assets/App.css';

import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Typography, Button } from '@mui/material';
import AuthRouter from './route/AuthRouter';
import HomeRouter from './route/HomeRouter';
import EgzersizRouter from './route/EgzersizRouter';
import HizliOkuRouter from './route/HizliOkuRouter';
import TestRouter from './route/TestRouter';
import PaymentRouter from './route/PaymentRouter';
import useFetchStudentDataForHome from './hooks/ogrenci/useFetchStudentDataForHome';
import './assets/pages/GenelAyarlar.css';


// Tema
const theme = createTheme({
  components: {
    MuiCircularProgress: {
      styleOverrides: {
        circle: {
          stroke: 'url(#gradient)',
        },
      },
    },
  },
});

// Çerez Kabul/Reddet Banner Bileşeni
const CookieConsentBanner = () => {
  const [visible, setVisible] = useState(false);
  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'accepted');
    setVisible(false);
  
  };

  const handleReject = () => {
    localStorage.setItem('cookieConsent', 'rejected');
    setVisible(false);
    // Reddedilince yapılacak işlemler
  };

  const handleMoreInfo = () => {
    // Daha fazla bilgi için istenen sayfaya yönlendirme vb.
    window.location.href = '/gizliliksozlesmesi';
  };

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setVisible(true);
    }
  }, []);

  if (!visible) return null;

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        width: '100%',
        bgcolor: '#2c2c2c',
        color: 'white',
        py: 2,
        px: 2,
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        zIndex: 9999,
      }}
    >
      <Typography variant="body1" sx={{ mb: { xs: 1, sm: 0 }, mr: 2, flexGrow: 1 }}>
        Bu sitede deneyiminizi iyileştirmek için çerezler kullanıyoruz. Lütfen tercihlerinizi seçin:
      </Typography>
      <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', justifyContent: 'flex-end' }}>
        <Button
          variant="outlined"
          sx={{
            borderColor: 'white',
            color: 'white',
            fontWeight: 'bold',
            '&:hover': {
              borderColor: '#ff4081',
              color: '#ff4081',
            },
          }}
          onClick={handleMoreInfo}
        >
          Daha Fazla Bilgi
        </Button>
        <Button
          variant="contained"
          sx={{
            bgcolor: '#4caf50',
            '&:hover': {
              bgcolor: '#66bb6a',
            },
            color: 'white',
            fontWeight: 'bold',
          }}
          onClick={handleAccept}
        >
          Kabul Et
        </Button>
        <Button
          variant="contained"
          sx={{
            bgcolor: '#f44336',
            '&:hover': {
              bgcolor: '#e57373',
            },
            color: 'white',
            fontWeight: 'bold',
          }}
          onClick={handleReject}
        >
          Reddet
        </Button>
      </Box>
    </Box>
  );
};

// App Bileşeni
function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState(null);

  useFetchStudentDataForHome(setUserData);

  useEffect(() => {
    const handleLoad = () => {
      setIsLoading(false);
    };

    if (document.readyState === 'complete') {
      setIsLoading(false);
    } else {
      window.addEventListener('load', handleLoad);
      return () => window.removeEventListener('load', handleLoad);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Content isLoading={isLoading} userData={userData} />
      </Router>
    </ThemeProvider>
  );
}

function Content({ isLoading, userData }) {
  const location = useLocation();

  return isLoading ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        bgcolor: 'background.paper',
        position: 'relative',
      }}
    >
      <svg width="0" height="0">
        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop stopColor="#f3ec78" offset="0%" />
          <stop stopColor="#af4261" offset="100%" />
        </linearGradient>
      </svg>
      <CircularProgress size={250} thickness={2} />
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '25vh',
        }}
      >
        <img
          src="/images/load_spin.png"
          alt="Loading"
          style={{
            height: '100%',
          }}
        />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: '10%',
          width: '100%',
          textAlign: 'center',
          color: '#555555',
          fontSize: '1.2em',
        }}
      >
        Lütfen bekleyiniz, site açılıyor...
      </Box>
    </Box>
  ) : (
    <>
      <HomeRouter />
      <EgzersizRouter />
      <HizliOkuRouter />
      <AuthRouter />
      <TestRouter />
      <PaymentRouter />
      <CookieConsentBanner />
    </>
  );
}

export default App;
