import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate, useLocation } from 'react-router-dom';
import useTachistoscopicGameLogic from './hooks/useTachistoscopicGameLogic';
import GameOverModal from './components/GameOverModal';
import axios from 'axios';
import { API_URLS } from '../../../config/config';
import Header from './components/Header';
import Instructions from './components/Instructions';
import GameControls from './components/GameControls';
import GameArea from './components/GameArea';
import './styles/tachistoscopicReadingStyle.css';
import axiosInstance from '../../../utils/axiosInstance';
import SettingsModalTachisto from './components/SettingsModalTachisto';

const TachistoscopicReadingGame = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { stage, exercise } = location.state || {};

  const [showInstructions, setShowInstructions] = useState(true);
  const [showSettingsModal, setShowSettingsModal] = useState(false); // Modal state
  const [userData, setUserData] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const token = localStorage.getItem('userToken');

  // Tema, Font, FontSize state
  const [theme, setTheme] = useState('default');
  const [font, setFont] = useState('Arial');
  const [fontSize, setFontSize] = useState('1rem');

  // Kullanıcı verilerini çek
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const studentResponse = await axiosInstance.get(`${API_URLS.STUDENT_URL}/me`);
        if (
          studentResponse.status === 200 &&
          studentResponse.data &&
          studentResponse.data.status === 'active'
        ) {
          const studentData = studentResponse.data;
          setUserData(studentData);
          setUserRole('student');
          return;
        } else {
          setUserData(null);
          setUserRole(null);
        }
      } catch (error) {
        console.error('Öğrenci verisi alınırken hata oluştu:', error);
      }

      // Öğrenci yoksa öğretmen verisine bak
      try {
        const teacherResponse = await fetch(`${API_URLS.TEACHER_URL}/me`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (teacherResponse.ok) {
          const teacherData = await teacherResponse.json();
          setUserData(teacherData);
          setUserRole('teacher');
          return;
        }
      } catch (error) {
        console.error('Öğretmen verisi alınırken hata oluştu:', error);
      }

      setUserData(null);
      setUserRole(null);
    };

    fetchUserData();
  }, [token]);

  // Oyun hook'u
  const {
    difficulty,
    currentWord,
    input,
    displayedWords,
    enteredWords,
    showingTime,
    writingTime,
    totalShowingTime,
    totalWritingTime,
    isPlaying,
    isFinished,
    phase,
    speed,
    handlePlayPause,
    handleReset,
    handleSubmit,
    handleInputChange,
    handleKeyPress,
    handleDifficultyChange,
    handleSpeedChange,
    getSpeedInSeconds,
    setIsFinished,
  } = useTachistoscopicGameLogic(userData, userRole);

  const startGame = () => {
    setShowInstructions(false);
  };

  const handleReturnToPreviousStage = () => {
    if (stage) {
      navigate(
        userRole === 'student' ? '/ogrenci-dashboard' : '/ogretmen-dashboard',
        {
          state: {
            selectedMenu: 'ExerciseList',
            selectedStage: stage,
          },
        }
      );
    } else {
      alert('Aşama bilgisi bulunmamaktadır.');
    }
  };

  // Oyun bittiyse progres güncellenir
  useEffect(() => {
    if (isFinished) {
      const correctWords = enteredWords.filter((word) =>
        displayedWords.includes(word)
      );
      const score = correctWords.length;
      updateProgress(score);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFinished]);

  const updateProgress = async (score) => {
    if (!userData || !userRole) return;
    try {
      const payload = {
        stageNumber: stage?.number || 1,
        gameName: exercise?.gameName || 'Takistoskopik Okuma',
        score: score,
        time:
          (userData && userData.status === 'inactive' ? 15 : 60) -
          (showingTime + writingTime),
        completed: true,
      };

      if (userRole === 'student') {
        // öğrenci
        const apiUrl = `${API_URLS.STUDENT_URL}akillikurs/progress/update-game`;
        await axiosInstance.post(apiUrl, payload);
      } else {
        // öğretmen
        const apiUrl = `${API_URLS.TEACHER_URL}akillikurs/progress/update-game`;
        const teacherToken = localStorage.getItem('userToken');
        await axios.post(apiUrl, payload, {
          headers: {
            Authorization: `Bearer ${teacherToken}`,
            'Content-Type': 'application/json',
          },
        });
      }
    } catch (error) {
      console.error('İlerleme güncellenirken hata oluştu:', error);
    }
  };

  const handleClose = () => {
    setIsFinished(false);
  };

  // Modal aç/kapa fonksiyonları
  const toggleSettingsModal = () => {
    setShowSettingsModal(!showSettingsModal);
  };

  return (
    <div
      className={`tachisto-game-container game-container-bg theme-${theme}`}
      style={{ fontFamily: font, fontSize }}
    >
      {isFinished ? (
        <GameOverModal
          displayedWords={displayedWords}
          enteredWords={enteredWords}
          onRestart={handleReset}
          onClose={handleClose}
          userData={userData}
        />
      ) : (
        <>
          {showInstructions ? (
            <Instructions startGame={startGame} 
            stage={stage}
            handleReturnToPreviousStage={handleReturnToPreviousStage}
            handleSettingsClick={toggleSettingsModal} 
            hideSettings={true}
            
            />
          ) : (
            <div className="tachisto-gameplay">
              <Header
                stage={stage}
                handleReturnToPreviousStage={handleReturnToPreviousStage}
                handleSettingsClick={toggleSettingsModal} 

              />

              <GameArea
                phase={phase}
                showingTime={showingTime}
                writingTime={writingTime}
                totalShowingTime={totalShowingTime}
                totalWritingTime={totalWritingTime}
                isPlaying={isPlaying}
                currentWord={currentWord}
                input={input}
                handleInputChange={handleInputChange}
                handleKeyPress={handleKeyPress}
                enteredWords={enteredWords}
                handleSubmit={handleSubmit}
                isFinished={isFinished}
              />

              <GameControls
                difficulty={difficulty}
                handleDifficultyChange={handleDifficultyChange}
                isPlaying={isPlaying}
                handlePlayPause={handlePlayPause}
                handleReset={handleReset}                
              />
            </div>
          )}
        </>
      )}

      {/* Settings Modal */}
      {showSettingsModal && (
        <SettingsModalTachisto
          onClose={toggleSettingsModal}
          difficulty={difficulty}
          onDifficultyChange={handleDifficultyChange}
          theme={theme}
          setTheme={setTheme}
          font={font}
          setFont={setFont}
          fontSize={fontSize}
          setFontSize={setFontSize}
          speed={speed}
          handleSpeedChange={handleSpeedChange}
          getSpeedInSeconds={getSpeedInSeconds}
        />
      )}
    </div>
  );
};

export default TachistoscopicReadingGame;
