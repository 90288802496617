import { useRef, useState, useEffect } from 'react';

export default function useGameLogic(userData) {
  const canvasRef = useRef(null);
  const [canvasReady, setCanvasReady] = useState(false);

  // Replace integer ms speed with a float speedFactor (0.5 - 3)
  const [speedFactor, setSpeedFactor] = useState(1);

  const [isPlaying, setIsPlaying] = useState(false);
  const [score, setScore] = useState(0);
  const [isGameOver, setIsGameOver] = useState(false);

  const [gameTime, setGameTime] = useState(60);
  const [initialGameTime, setInitialGameTime] = useState(60);

  const [shapeSides, setShapeSides] = useState(3);
  const [positions, setPositions] = useState([]);

  const currentPositionIndexRef = useRef(0);
  const reverseLimitRef = useRef(0);
  const animationFrameIdRef = useRef(null);
  const scoreRef = useRef(0);
  const lastMoveTimeRef = useRef(0);

  // Kullanıcı bilgisi gelince süresi ayarla
  useEffect(() => {
    if (userData) {
      const newGameTime = userData.status === 'inactive' ? 15 : 60;
      setGameTime(newGameTime);
      setInitialGameTime(newGameTime);
    } else {
      setGameTime(15);
      setInitialGameTime(15);
    }
  }, [userData]);

  // Canvas hazır olduğunda veya shapeSides değiştiğinde pozisyonları yeniden hesapla
  useEffect(() => {
    if (canvasReady) {
      const newPositions = calculatePositions();
      setPositions(newPositions);
      currentPositionIndexRef.current = 0;
      reverseLimitRef.current = 0;
      drawShape();
    }
  }, [canvasReady, shapeSides]);

  // Zaman geriye doğru akarken
  useEffect(() => {
    if (!isPlaying) return;
    if (gameTime > 0) {
      const timer = setInterval(() => {
        setGameTime(prevTime => prevTime - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else {
      setIsPlaying(false);
      setIsGameOver(true);
    }
  }, [isPlaying, gameTime]);

  // Şeklin köşelerinin koordinatlarını hesapla
  const calculatePositions = () => {
    const canvas = canvasRef.current;
    if (!canvas) return [];

    const width = canvas.width;
    const height = canvas.height;

    const positions = [];
    const centerX = width / 2;
    const centerY = height / 2;
    const radius = Math.min(width, height) / 2.5;
    const angleOffset = (Math.PI * 2) / shapeSides;

    for (let i = 0; i < shapeSides; i++) {
      const angle = i * angleOffset - Math.PI / 2;
      const x = centerX + radius * Math.cos(angle);
      const y = centerY + radius * Math.sin(angle);
      positions.push({ x, y });
    }

    return positions;
  };

  // Ana şekli (çokgeni) çiz
  const drawShape = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    const width = canvas.width;
    const height = canvas.height;

    // Basit degrade arkaplan
    const gradient = ctx.createLinearGradient(0, 0, width, height);
    gradient.addColorStop(0, '#fefefe');
    gradient.addColorStop(1, '#dddddd');
    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, width, height);

    ctx.beginPath();
    ctx.strokeStyle = '#ff6600';
    ctx.lineWidth = 4;

    const centerX = width / 2;
    const centerY = height / 2;
    const radius = Math.min(width, height) / 2.5;
    const angleOffset = (Math.PI * 2) / shapeSides;

    for (let i = 0; i < shapeSides; i++) {
      const angle = i * angleOffset - Math.PI / 2;
      const x = centerX + radius * Math.cos(angle);
      const y = centerY + radius * Math.sin(angle);
      if (i === 0) {
        ctx.moveTo(x, y);
      } else {
        ctx.lineTo(x, y);
      }
    }
    ctx.closePath();
    ctx.stroke();
  };

  // Nesneyi belirli aralıklarla hareket ettir
  const animateObject = () => {
    const canvas = canvasRef.current;
    if (!canvas || positions.length === 0) return;

    const ctx = canvas.getContext('2d');

    // Önce şekli yeniden çiz
    drawShape();

    // Nesnenin yeni konumunu çiz
    const { x, y } = positions[currentPositionIndexRef.current];
    ctx.beginPath();
    ctx.arc(x, y, 15, 0, Math.PI * 2);
    ctx.fillStyle = '#ff6600';
    ctx.fill();

    // Sonraki index
    let nextIndex = currentPositionIndexRef.current + 1;

    // Başa sarma
    if (nextIndex >= positions.length) {
      nextIndex = 0;
      reverseLimitRef.current += 1;
      if (shapeSides > 2 && reverseLimitRef.current >= 5) {
        setPositions(prevPositions => [...prevPositions].reverse());
        reverseLimitRef.current = 0;
      }
    }

    currentPositionIndexRef.current = nextIndex;

    // Skor
    scoreRef.current += 1;
    setScore(scoreRef.current);
  };

  const animate = timestamp => {
    if (!isPlaying) return;

    if (!lastMoveTimeRef.current) {
      lastMoveTimeRef.current = timestamp;
    }

    const elapsed = timestamp - lastMoveTimeRef.current;

    // Instead of checking "elapsed >= speed" in ms,
    // we check something like 500 / speedFactor (base interval = 500ms).
    // Feel free to tweak that "500" base as you prefer.
    const baseInterval = 500; 
    if (elapsed >= baseInterval / speedFactor) {
      animateObject();
      lastMoveTimeRef.current = timestamp;
    }

    animationFrameIdRef.current = requestAnimationFrame(animate);
  };

  const startGameLoop = () => {
    if (animationFrameIdRef.current) {
      cancelAnimationFrame(animationFrameIdRef.current);
    }
    lastMoveTimeRef.current = null;
    animationFrameIdRef.current = requestAnimationFrame(animate);
  };

  const stopGameLoop = () => {
    if (animationFrameIdRef.current) {
      cancelAnimationFrame(animationFrameIdRef.current);
      animationFrameIdRef.current = null;
    }
  };

  useEffect(() => {
    if (isPlaying && canvasReady) {
      startGameLoop();
    } else {
      stopGameLoop();
    }
    return () => stopGameLoop();
  }, [isPlaying, speedFactor, canvasReady]);

  const resetGame = () => {
    setIsPlaying(false);
    setScore(0);
    scoreRef.current = 0;
    setGameTime(initialGameTime);
    setIsGameOver(false);
    currentPositionIndexRef.current = 0;
    reverseLimitRef.current = 0;
    lastMoveTimeRef.current = null;
    stopGameLoop();
    if (canvasReady) {
      const newPositions = calculatePositions();
      setPositions(newPositions);
      drawShape();
    }
  };


  const handleSpeedIncrease = () => {
    setSpeedFactor((prev) => parseFloat((prev + 0.1).toFixed(1)));
  };

  const handleSpeedDecrease = () => {
    setSpeedFactor((prev) => {
      const nextValue = prev - 0.1;
      return nextValue < 0.1 ? 0.1 : parseFloat(nextValue.toFixed(1));
    });
  };

  const increaseSides = () => {
    setShapeSides(prevSides => Math.min(prevSides + 1, 10));
  };

  const decreaseSides = () => {
    setShapeSides(prevSides => Math.max(prevSides - 1, 3));
  };

  return {
    canvasRef,
    canvasReady,
    setCanvasReady,
    isPlaying,
    setIsPlaying,
    speedFactor,
    handleSpeedIncrease,
    handleSpeedDecrease,
    score,
    gameTime,
    initialGameTime,
    resetGame,
    isGameOver,
    setIsGameOver,
    shapeSides,
    increaseSides,
    decreaseSides,
  };
}
