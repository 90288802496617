import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faRedo,} from '@fortawesome/free-solid-svg-icons';
import '../styles/rotatingDiamondGame.css';

export default function GameControls({
  speed,
  setSpeed,
  isPlaying,
  setIsPlaying,
  resetGame,
}) {


  const handleIncreaseSpeed = () => {
    setSpeed((prev) => parseFloat((prev + 0.1).toFixed(1)));
  };

  const handleDecreaseSpeed = () => {
    setSpeed((prev) => {
      const nextValue = prev - 0.1;
      return nextValue < 0.1 ? 0.1 : parseFloat(nextValue.toFixed(1));
    });
  };
 
  return (
    <div className="rotating-diamond-game-controls">
      {/* Hız Kontrolü */}
      <div className="zigzag-speed-control-container">
      <div className="zigzag-speed-label">Hız Ayarı</div>
      <div className="zigzag-speed-display">
        <button className="zigzag-speed-button" onClick={handleDecreaseSpeed}>-</button>
        <div className="zigzag-speed-value">{speed.toFixed(1)}</div>
        <button className="zigzag-speed-button" onClick={handleIncreaseSpeed}>+</button>
      </div>
      <div className="zigzag-speed-instructions">
      Hızı 0.1 başlayarak ile hızı artırabilirsiniz.
      </div>
    </div>

      {/* Başlat / Duraklat */}
      <button onClick={() => setIsPlaying(!isPlaying)}>
        <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />{' '}
        {isPlaying ? 'Duraklat' : 'Başlat'}
      </button>
      {/* Yeniden Başlat */}
      <button onClick={resetGame}>
        <FontAwesomeIcon icon={faRedo} /> Yeniden Başlat
      </button>
    </div>
  );
}
