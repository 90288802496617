import React, { useState } from 'react';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Stack,
} from '@mui/material';
import BarChartIcon from '@mui/icons-material/BarChart';
import DeleteIcon from '@mui/icons-material/Delete';
import { API_URLS } from '../../../../config/config';

const StudentTable = ({ ogrenciListesi, sehirListesi, fetchOgrenciListesi, navigate, setSnackbar, onAssignStudent }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filterSinif, setFilterSinif] = useState('');
  const [filterSehirId, setFilterSehirId] = useState('');
  const [filterPaketSuresi, setFilterPaketSuresi] = useState('');

  // Filtreleme işlemi
  const filteredOgrenciListesi = ogrenciListesi.filter((ogrenci) => {
    const searchTermLower = searchTerm.toLowerCase();
    const abonelik = ogrenci.abonelik;
    const paketSuresi = abonelik?.paketSuresi;
    const matchesSearchTerm =
      ogrenci.ad?.toLowerCase().includes(searchTermLower) ||
      ogrenci.soyad?.toLowerCase().includes(searchTermLower) ||
      ogrenci.email?.toLowerCase().includes(searchTermLower) ||
      ogrenci.okul_adi?.toLowerCase().includes(searchTermLower) ||
      ogrenci.telefon?.includes(searchTerm) ||
      ogrenci.sinif?.toString().toLowerCase().includes(searchTermLower) ||
      ogrenci.yas?.toString().toLowerCase().includes(searchTermLower) ||
      ogrenci.sehirAdi?.toLowerCase().includes(searchTermLower) ||
      ogrenci.ilceAdi?.toLowerCase().includes(searchTermLower);

    const matchesSinifFilter = filterSinif ? ogrenci.sinif === filterSinif : true;
    const matchesSehirFilter = filterSehirId ? ogrenci.sehir === filterSehirId : true;
    const matchesPackageFilter = filterPaketSuresi ? paketSuresi == filterPaketSuresi : true;

    return matchesSearchTerm && matchesSinifFilter && matchesSehirFilter && matchesPackageFilter;
  });

  // Öğrenci silme işlemi
  const handleDeleteOgrenci = async (ogrenciId) => {
    try {
      const response = await fetch(`${API_URLS.SCHOOL_URL}student/${ogrenciId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) throw new Error('Öğrenci silinirken hata oluştu.');
      fetchOgrenciListesi();
      setSnackbar({ open: true, message: 'Öğrenci başarıyla silindi.', severity: 'success' });
    } catch (error) {
      setSnackbar({ open: true, message: error.message, severity: 'error' });
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Stack spacing={2} sx={{ padding: 2 }}>
          <TextField
            fullWidth
            label="Ara"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <FormControl fullWidth>
            <InputLabel id="filter-sinif-select-label">Sınıf Filtrele</InputLabel>
            <Select
              labelId="filter-sinif-select-label"
              value={filterSinif}
              onChange={(e) => setFilterSinif(e.target.value)}
            >
              <MenuItem value="">
                <em>Tümü</em>
              </MenuItem>
              {Array.from(new Set(ogrenciListesi.map((ogr) => ogr.sinif)))
                .filter((sinif) => sinif)
                .map((sinif) => (
                  <MenuItem key={sinif} value={sinif}>
                    {sinif}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="filter-sehir-select-label">Şehir Filtrele</InputLabel>
            <Select
              labelId="filter-sehir-select-label"
              value={filterSehirId}
              onChange={(e) => setFilterSehirId(e.target.value)}
            >
              <MenuItem value="">
                <em>Tümü</em>
              </MenuItem>
              {sehirListesi.map((sehir) => (
                <MenuItem key={sehir._id} value={sehir._id}>
                  {sehir.sehir_adi}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="filter-package-select-label">Paket Süresi Filtrele</InputLabel>
            <Select
              labelId="filter-package-select-label"
              value={filterPaketSuresi}
              onChange={(e) => setFilterPaketSuresi(e.target.value)}
            >
              <MenuItem value="">
                <em>Tümü</em>
              </MenuItem>
              <MenuItem value={3}>3 Aylık</MenuItem>
              <MenuItem value={6}>6 Aylık</MenuItem>
              <MenuItem value={12}>12 Aylık</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Adı</TableCell>
              <TableCell>Soyadı</TableCell>
              <TableCell>Şifre</TableCell>
              <TableCell>Telefon</TableCell>
              <TableCell>Okul Adı</TableCell>
              <TableCell>Sınıfı</TableCell>
              <TableCell>Yaş</TableCell>
              <TableCell>E-posta</TableCell>
              <TableCell>Şehir</TableCell>
              <TableCell>İlçe</TableCell>
              <TableCell>Paket Süresi</TableCell>
              <TableCell>Başlangıç Tarihi</TableCell>
              <TableCell>Bitiş Tarihi</TableCell>
              <TableCell>İşlemler</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredOgrenciListesi.map((ogrenci) => {
              const abonelik = ogrenci.abonelik;
              const paketSuresi = abonelik?.paketSuresi || '-';
              const baslangicTarihi = abonelik?.baslangicTarihi
                ? new Date(abonelik.baslangicTarihi).toLocaleDateString()
                : '-';
              const bitisTarihi = abonelik?.bitisTarihi
                ? new Date(abonelik.bitisTarihi).toLocaleDateString()
                : '-';
              return (
                <TableRow key={ogrenci._id}>
                  <TableCell>{ogrenci.ad}</TableCell>
                  <TableCell>{ogrenci.soyad}</TableCell>
                  <TableCell>{ogrenci.password_open}</TableCell>
                  <TableCell>{ogrenci.telefon}</TableCell>
                  <TableCell>{ogrenci.okul_adi}</TableCell>
                  <TableCell>{ogrenci.sinif}</TableCell>
                  <TableCell>{ogrenci.yas}</TableCell>
                  <TableCell>{ogrenci.email}</TableCell>
                  <TableCell>{ogrenci.sehirAdi}</TableCell>
                  <TableCell>{ogrenci.ilceAdi}</TableCell>
                  <TableCell>{paketSuresi} Ay</TableCell>
                  <TableCell>{baslangicTarihi}</TableCell>
                  <TableCell>{bitisTarihi}</TableCell>
                  <TableCell align="right">
                    <Stack direction="row" spacing={1}>
                      <Button
                        variant="contained"
                        color="info"
                        startIcon={<BarChartIcon />}
                        onClick={() =>
                          navigate(`/okul/ogrenci/${ogrenci._id}/rapor`, { state: { ogrenci } })
                        }
                      >
                        Rapor Görüntüle
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        startIcon={<DeleteIcon />}
                        onClick={() => handleDeleteOgrenci(ogrenci._id)}
                      >
                        Sil
                      </Button>
                      {/* ATAMA BUTONU: onAssignStudent prop’u varsa buton görüntülenir */}
                      {onAssignStudent && (
                        <Button
                          variant="contained"
                          color="success"
                          onClick={() => onAssignStudent(ogrenci)}
                        >
                          Atama
                        </Button>
                      )}
                    </Stack>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default StudentTable;
